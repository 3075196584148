import React, { useEffect, useState } from 'react'
import '../styles/pages/loginPage.sass'
import BackgroundImage from '../resources/media/images/whitelabel/login-background.jpg'
import LogoImage from '../resources/media/images/whitelabel/login-header.png'
import CustomAxios from '../customComponents/customAxios'
import { useDispatch, useSelector } from 'react-redux'
import { logUser } from '../store/actions/groupedActions'
import { ROUTE_NAMES } from '../utils/routes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReducerData, Reducers } from '../types/reducers'
import { config } from '../config/config'

const LoginPage: React.FC = () => {
    const { configurations } = useSelector<Reducers, ReducerData>((store) => store.data)
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loginErrorFlag, setLoginErrorFlag] = useState<boolean>(false)
    const [logo, setLogo] = useState(LogoImage)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation()

    useEffect(() => {
        const aspectLogo = configurations.find((c) => c.key === 'aspect.logo')
        if (aspectLogo && aspectLogo.value !== '') {
            setLogo(`${config.api.baseURL}/${aspectLogo.value}`)
        }
    }, [configurations])

    const loginHandler = async () => {
        try {
            const tokenData = await CustomAxios.post('auth/login', {
                username,
                password,
            }).then((response) => response.data)

            setLoginErrorFlag(false)
            if (JSON.parse(tokenData.otpInfo) !== null && JSON.parse(tokenData.otpInfo).otp_enabled === true) {
                navigate(`${ROUTE_NAMES.VALIDATE_OTP}`, { state: tokenData })
            } else {
                dispatch(logUser(tokenData))
                navigate(`${ROUTE_NAMES.TODAY_ALARM}?page=0`)
            }
        } catch (e) {
            console.error('Login error: ', e)
            setLoginErrorFlag(true)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${BackgroundImage})`,
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <form
                className="containerLogin"
                onSubmit={(event) => {
                    event.preventDefault()
                    void loginHandler()
                }}
            >
                <img alt="" className="logo" src={logo} />
                <div className="inputLoginContainer">
                    <input
                        className="inputLogin"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        placeholder={t('loginPage.username')}
                        autoComplete="off"
                    />
                </div>
                <div className="inputLoginContainer">
                    <input
                        className="inputLogin"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder={t('loginPage.password')}
                        autoComplete="off"
                    />
                </div>
                {loginErrorFlag && <p className="error-message">{t('loginPage.wrongPasswordError')}</p>}
                <input
                    type="submit"
                    className="inputButton"
                    style={{
                        marginBottom: 20,
                        width: '21vw',
                    }}
                    value={t('loginPage.loginButton').toUpperCase()}
                    onClick={() => void loginHandler()}
                />
                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.application').replaceAll(
                        '{{instanceTitle}}',
                        configurations.find((c) => c.key === 'aspect.title')?.value || ''
                    )}
                </p>
                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.copyright', { year: new Date().getFullYear() })}
                </p>
            </form>
        </div>
    )
}

export default LoginPage
