import React from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'

interface Props {
    jsonObjectPre: string
    jsonObjectPost?: string
    displaySingleObject?: boolean
    closeLabel?: string
    onClose: () => void
}

const ChangesDisplayModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.close')

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" onClick={() => props.onClose()} />
            <div className="modal display-changes-modal medium-modal">
                <div className="changes-elements-container">
                    <div className="changes-element">
                        {!props.displaySingleObject && <h3>{t('common.before')}</h3>}
                        <code>{props.jsonObjectPre}</code>
                    </div>
                    {!props.displaySingleObject && (
                        <div className="changes-element">
                            <h3>{t('common.after')}</h3>
                            <code>{props.jsonObjectPost}</code>
                        </div>
                    )}
                </div>
                <div className="modal--actions-container">
                    <button className="close-action" onClick={() => props.onClose()}>
                        {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangesDisplayModal
