import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faMinus, faMinusCircle, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { DeviceEvent, OpenedEvent, ThresholdLevel, ThresholdRule } from '../../types/data/alarm'
import AlertModal from '../Modals/AlertModal'
import CustomAxios from '../../customComponents/customAxios'
import { singleDeviceEvent } from '../../utils/api-constants'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import { useTranslation } from 'react-i18next'
import { Reducers, ReducerUser } from '../../types/reducers'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { useTablePagination } from '../../utils/hooks/common'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import { statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { DeviceInterface } from '../../types/data/system'
import { COLORS } from '../../styles/constants'

export interface EventTableData {
    id: number
    eventsNumber: number
    openedEvents: OpenedEvent[]
    device: DeviceInterface
}

interface Props {
    filterParams?: any
    reloads?: boolean
    onFinish?: () => void
}

const TodayAlarmTable: React.FC<Props> = React.memo((props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [selectedEventIdToRemove, setSelectedEventIdToRemove] = useState(-1)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const { t } = useTranslation()
    const deleteMessage = t('systemMessages.removeElementFromList')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const initRemoveProcedure = (alarmId: number) => {
        setOpenAlertModal(true)
        setSelectedEventIdToRemove(alarmId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.patch(singleDeviceEvent(selectedEventIdToRemove), {
                hide: true,
                date_end: moment().format('YYYY-MM-DD HH:mm:ss'),
                forced_close_by: user.ID,
            })

            resetData(currentPage, '', '', true)
            exitRemoveProcedure()
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedEventIdToRemove(-1)
    }

    const getThresholdRule = (rule: ThresholdRule) => {
        switch (rule) {
            case 'higher':
                return t('thresholdRules.higherString')
            case 'not equal':
                return t('thresholdRules.notEqual')
            case 'lower':
            default:
                return t('thresholdRules.lower')
        }
    }

    const getThresholdLevel = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return t('alarmLevels.low')
            case 1:
                return t('alarmLevels.medium')
            case 2:
            default:
                return t('alarmLevels.high')
        }
    }

    const getThresholdIcon = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return <FontAwesomeIcon icon={faChevronDown} color={COLORS.events.low} />
            case 1:
                return <FontAwesomeIcon icon={faMinus} color={COLORS.events.medium} />
            case 2:
            default:
                return <FontAwesomeIcon icon={faChevronUp} color={COLORS.events.high} />
        }
    }

    const getOpenEventString = (deviceEvent: any): string => {
        if (deviceEvent.eventString) return deviceEvent.eventString
        const rule = deviceEvent.operator || 'higher'
        return t('thresholdEventDefaultMessage.fullPhrase', {
            level: getThresholdLevel(deviceEvent.level),
            variableName: deviceEvent.variable,
            measuredValue: deviceEvent.startValue,
            thresholdLevel: getThresholdRule(rule),
            thresholdValue: deviceEvent.referenceValue,
        })
    }

    const columns = [
        {
            title: t('fields.status'),
            field: 'status',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.maintenanceMode ? (
                            <div className="dot-container">
                                <div className="dot-tooltip">{t('fields.onMaintenanceMode')}</div>
                                <FontAwesomeIcon
                                    icon={faToolbox}
                                    color={COLORS.palette.darkBlue}
                                    style={{ width: '20px', height: '20px', marginLeft: '7px' }}
                                />
                            </div>
                        ) : (
                            <div className="dot-container">
                                <div className="dot-tooltip">{t(statusLabelForDevice(rowData))}</div>
                                <span className={statusPinStyleForDevice(rowData)} />
                            </div>
                        )}
                    </>
                )
            },
        },
        {
            title: t('fields.device'),
            field: 'device_name',
            width: '10%',
            render: (rowData: any) => {
                return (
                    <a
                        className={`colorRed table-link-value ${rowData.hasItsOwnEvents ? 'table-main-value' : ''} ${
                            rowData.parentId ? 'children-branch' : ''
                        }`}
                        // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
                        href={`/${ROUTE_NAMES.DEVICE}/${rowData.id}`}
                    >
                        <div
                            className={`${
                                rowData.parentId
                                    ? rowData.isLastChildren
                                        ? 'half-vertical-line'
                                        : 'full-vertical-line'
                                    : ''
                            }`}
                        />
                        {rowData.deviceName}
                    </a>
                )
            },
        },
        {
            title: t('fields.priority'),
            field: 'value',
            render: (rowData: any) => {
                return (
                    <div className="small-cell">
                        {!rowData.hasChild || rowData.parentId
                            ? rowData.openedEvents.map((event) => {
                                  return (
                                      <div key={event.id} className="table-event-value-block">
                                          <div className="input-row">
                                              <Tooltip title={getThresholdLevel(event.level)}>
                                                  <div>{getThresholdIcon(event.level)}</div>
                                              </Tooltip>
                                          </div>
                                      </div>
                                  )
                              })
                            : null}
                    </div>
                )
            },
        },
        {
            title: t('fields.event'),
            field: 'variable',
            render: (rowData: any) => {
                return (
                    <div>
                        {!rowData.hasChild || rowData.parentId
                            ? rowData.openedEvents.map((event) => {
                                  return (
                                      <div key={event.id} className="table-event-value-block">
                                          <span>{getOpenEventString(event)}</span>
                                      </div>
                                  )
                              })
                            : null}
                    </div>
                )
            },
        },
        {
            title: t('fields.dateEventOpened'),
            field: 'dateStart',
            render: (rowData: any) => {
                return (
                    <div>
                        <span>{moment(rowData.openedEvents[0].dateStart).format('DD/MM/YYYY - HH:mm:ss')}</span>
                    </div>
                )
            },
        },
    ]

    const actions = [
        user.idSysGrant === 0 &&
            ((rowData: any) => ({
                icon: () => (
                    <div className="multiple-action-per-element">
                        {rowData.openedEvents.map((event) => {
                            return (
                                <div key={event.id} className="action-icon-container">
                                    <FontAwesomeIcon
                                        onClick={() => initRemoveProcedure(event.Id)}
                                        icon={faMinusCircle}
                                        color={COLORS.palette.red}
                                        size="xs"
                                    />
                                </div>
                            )
                        })}
                    </div>
                ),
                onClick: () => {
                    return
                },
                tooltip: t('common.hide'),
            })),
    ]

    const formatData = (data: any) => {
        const parentArray = data.map((event: DeviceEvent) => {
            return {
                id: event.device.id,
                status: event.device.currentState,
                maintenanceMode: event.device.maintenanceMode,
                deviceCode: event.device.code,
                deviceName: `${event.device.brand.name} ${event.device.deviceModel.model} - ${event.device.name}`,
                eventsNumber: event.events,
                openedEvents: event.openedEvents,
                device: {
                    ...event.device,
                    openedEvents: event.openedEvents,
                    maxAlarmLevel:
                        event.openedEvents && event.openedEvents
                            ? event.openedEvents && event.openedEvents.length
                                ? Math.max(...event.openedEvents.map((openEvent: any) => openEvent.level))
                                : 0
                            : undefined,
                },
            }
        })
        return parentArray
    }

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData(0, '', '', true)
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData(currentPage, '', '', true)
        }
    }, [props.reloads])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'device-events'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += '&hidden=false'
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        } else {
                            {
                                url += '&sort_by=date_start&order_by=desc'
                            }
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: formatData(response.data.data),
                                page: query.page,
                                totalCount: handleDataCount(response.data.count),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 5,
                    rowStyle: (rowData) => {
                        return {
                            backgroundColor: rowData.parentId ? '#fafafa' : '#fff',
                        }
                    },
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                    navigate(`${ROUTE_NAMES.TODAY_ALARM}?page=${pageIndex}`)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
})
TodayAlarmTable.displayName = 'TodayAlarmTable'

export default TodayAlarmTable
