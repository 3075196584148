import React, { useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import DeleteModal from '../Modals/DeleteModal'
import { DeviceAvailableCommand } from '../../types/data/alarm'
import { CommandModelGrant } from '../../utils/enum/CommandModelGrant'
import AvailableCommandModal from '../Modals/AvailableCommandModal'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { COLORS } from '../../styles/constants'

export interface DeviceAvailableCommandTable extends DeviceAvailableCommand {
    id: number
}

interface Props {
    data: DeviceAvailableCommandTable[]
    filtered: DeviceAvailableCommandTable[]
    updateData: (vars: DeviceAvailableCommandTable[]) => void
    viewOnly?: boolean
}

const AvailableCommandsTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedCommand, setSelectedCommand] = useState<DeviceAvailableCommandTable | null>(null)
    const [removeModal, setRemoveModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [openInViewMode, setOpenInViewMode] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.command'),
                field: 'command',
            },
            {
                title: t('fields.oid'),
                field: 'oid',
            },
            {
                title: t('fields.name'),
                field: 'name',
                render: (rowData: DeviceAvailableCommandTable) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() => {
                            setEditModal(true)
                            setSelectedCommand(rowData)
                            setOpenInViewMode(true)
                        }}
                    >
                        {rowData.name}
                    </span>
                ),
            },
            {
                title: t('fields.value'),
                field: 'value',
                render: (rowData: DeviceAvailableCommand) => (
                    <span>
                        {rowData.userDefined
                            ? t('fields.commandValueOptions.userDefined')
                            : rowData.valueMap && rowData.valueMap !== ''
                            ? t('fields.commandValueOptions.mapped')
                            : rowData.value}
                    </span>
                ),
            },
            {
                title: t('fields.requiredGrants'),
                field: 'alarmLevels',
                render: (rowData: DeviceAvailableCommandTable) => (
                    <p>{t(`userRoles.${CommandModelGrant[rowData.requiredGrant]}`)}</p>
                ),
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        if (props.viewOnly) {
            return []
        }
        const actions = [
            (rowData: DeviceAvailableCommandTable) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setEditModal(true)
                    setSelectedCommand(rowData)
                },
                tooltip: t('common.edit'),
            }),
            (rowData: DeviceAvailableCommandTable) => ({
                icon: () => <FontAwesomeIcon icon={faClone} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setSelectedCommand({
                        ...rowData,
                        id: -1,
                    })
                    setEditModal(true)
                },
                tooltip: t('common.duplicate'),
            }),
            (rowData: DeviceAvailableCommandTable) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => {
                    setRemoveModal(true)
                    setSelectedCommand(rowData)
                },
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {editModal && selectedCommand ? (
                <AvailableCommandModal
                    command={selectedCommand}
                    onClose={() => {
                        setEditModal(false)
                        setSelectedCommand(null)
                        setOpenInViewMode(false)
                    }}
                    viewMode={openInViewMode ? true : undefined}
                    isNew={selectedCommand.id === -1}
                    onConfirm={(commandEdited) => {
                        const newArray = props.data
                            .filter((v) => v.id !== commandEdited.id)
                            .map((v) => {
                                delete v.id
                                return v
                            })
                        const edited = commandEdited
                        delete commandEdited.id
                        props.updateData([...newArray, edited])
                        setSelectedCommand(null)
                        setEditModal(false)
                    }}
                />
            ) : null}
            {removeModal && selectedCommand ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.commandDeleteConfirmMessage', { command: selectedCommand.name })}
                    onDelete={() => {
                        const newArray = props.data
                            .filter((v) => v.id !== selectedCommand.id)
                            .map((v) => {
                                delete v.id
                                return v
                            })
                        props.updateData(newArray)
                        setSelectedCommand(null)
                        setRemoveModal(false)
                    }}
                    onClose={() => {
                        setSelectedCommand(null)
                        setRemoveModal(false)
                    }}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                data={props.filtered}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 99,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default AvailableCommandsTable
