import React, { useState } from 'react'
import Button from '../../Components/Inputs/Button'
import TextInput from '../../Components/Inputs/TextInput'
import Footer from '../../Components/StaticComponents/Footer'
import '../../styles/pages/main.sass'
import '../../styles/pages/remotecontrol.sass'
import InfoLabel from '../../Components/Viewers/DataLabelViewer'
import EmittenteIcon from '../../resources/media/images/icons/emittente.png'
import BrandIcon from '../../resources/media/images/icons/brand.png'
import ModelIcon from '../../resources/media/images/icons/modello.png'
import SerialIcon from '../../resources/media/images/icons/serial.png'
import LatitudeIcon from '../../resources/media/images/icons/latitudine.png'
import LongitudeIcon from '../../resources/media/images/icons/longitudine.png'
import IPIcon from '../../resources/media/images/icons/ip.png'
import DoorIcon from '../../resources/media/images/icons/porta.png'
import PowerIcon from '../../resources/media/images/icons/potenza.png'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import { faLongArrowAltRight, faMinusCircle, faRedo } from '@fortawesome/free-solid-svg-icons'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const SendCommandPage: React.FC = () => {
    const [televisionBroadcaster, setTelevisionBroadcaster] = useState<SelectOptionValue | null>({
        label: 'Tutti',
        value: 'all',
    })
    const [region, setRegion] = useState<SelectOptionValue | null>({ label: 'Tutti', value: 'all' })
    const [comand, setComand] = useState<SelectOptionValue | null>({ label: 'INFO', value: 'info' })
    const [remoteControlCode, setRemoteControlCode] = useState<string>('')
    const [name, setName] = useState<string>('')

    const { t } = useTranslation()

    const data = useSelector<Reducers, ReducerData>((state) => state.data)

    return (
        <PageContainer>
            <div className="headerSection">Invia comando</div>
            <div className="body">
                <div className="sendRemoteColumns">
                    <div className="sendRemoteColumnsMainContainer">
                        <SelectInput
                            label="Emittente"
                            value={televisionBroadcaster}
                            onValueChange={(value) => setTelevisionBroadcaster(value)}
                            options={[
                                { label: 'Tutti', value: 'all' },
                                { label: 'Brodcast Electronics', value: 'be' },
                            ]}
                            width={'100%'}
                        />
                        <SelectInput
                            label="Regioni"
                            value={region}
                            onValueChange={(value) => setRegion(value)}
                            options={[
                                { label: 'Tutti', value: 'all' },
                                ...data.regions.map((region) => {
                                    return {
                                        label: region.Descrizione,
                                        value: region.ID,
                                    }
                                }),
                            ]}
                        />
                        <TextInput
                            label="Codice Telecontrollo*"
                            value={remoteControlCode}
                            onChangeText={(value: string) => setRemoteControlCode(value)}
                            withButton
                            button={
                                <Button
                                    label={t('common.update')}
                                    backgroundColor="#e10915"
                                    textColor="#fff"
                                    icon={faRedo}
                                    iconColor="#fff"
                                    onPress={null}
                                />
                            }
                        />
                        <TextInput label="Nome*" value={name} onChangeText={(value: string) => setName(value)} />
                        <SelectInput
                            label="Comando"
                            value={comand}
                            onValueChange={(value) => setComand(value)}
                            options={[
                                { label: 'INFO', value: 'info' },
                                { label: 'REBOOT', value: 'reboot' },
                            ]}
                        />
                        <div
                            style={{
                                padding: '20px 0',
                            }}
                        >
                            <Button
                                label="Invia"
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faLongArrowAltRight}
                                iconColor="#fff"
                                onPress={null}
                                margin="0px 10px 0px 0px"
                            />
                            <Button
                                label="Annulla"
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                icon={faMinusCircle}
                                onPress={null}
                            />
                        </div>
                    </div>
                    <div className="sendRemoteSecondContainer">
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: 16,
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Caratteristiche telecontrollo
                        </span>
                        <div
                            style={{
                                padding: 10,
                            }}
                        >
                            <InfoLabel label="Emittente" value={null} icon={EmittenteIcon} />
                            <InfoLabel label="Marca" value={null} icon={BrandIcon} />
                            <InfoLabel label="Modello" value={null} icon={ModelIcon} />
                            <InfoLabel label="Numero Seriale" value={null} icon={SerialIcon} />
                            <InfoLabel label="Latitudine" value={null} icon={LatitudeIcon} />
                            <InfoLabel label="Longitudine" value={null} icon={LongitudeIcon} />
                            <InfoLabel label="Indirizzo IP" value={null} icon={IPIcon} />
                            <InfoLabel label="Porta IP" value={null} icon={DoorIcon} />
                            <InfoLabel label="Potenza" value={null} icon={PowerIcon} />
                            <InfoLabel label="Codice Postazione" value={null} />
                            <InfoLabel label="Postazione" value={null} />
                            <InfoLabel label="Canale / Frequenza" value={null} />
                            <InfoLabel label="Note" value={null} />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        borderTop: '1px solid #e2e2e2',
                        paddingTop: 20,
                    }}
                >
                    <span className="titleIntro">Ultimi comandi inviati</span>
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default SendCommandPage
