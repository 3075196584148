import React, { useEffect, useState } from 'react'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import '../../styles/pages/device.sass'
import { useTranslation } from 'react-i18next'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import TextInput from '../../Components/Inputs/TextInput'
import DeviceUsersTable from '../../Components/Tables/DeviceUsersTable'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { User } from '../../types/data/user'
import CustomAxios from '../../customComponents/customAxios'

interface Props {
    device: any
}

const DeviceUsersSection: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [filterQuery, setFilterQuery] = useState('')
    const [toggleReset, setToggleReset] = useState(undefined)
    const [availableUsers, setAvailableUsers] = useState<User[]>([])
    const [userToAssociate, setUserToAssociate] = useState<SelectOptionValue | null>({
        label: t('fields.chooseUser'),
        value: '',
    })

    const getAvailableUsers = async () => {
        try {
            const users: User[] = await CustomAxios.get('users').then((response) => response.data.data)
            const alreadyAssociatedUsers = await CustomAxios.get(`users-devices?device=${props.device.ID}`).then(
                (response) => response.data.data
            )
            const associatedUsersIds = alreadyAssociatedUsers.map((user) => user.idUser)
            const filteredUsers =
                associatedUsersIds.length > 0 ? users.filter((user) => !associatedUsersIds.includes(user.ID)) : users
            setAvailableUsers(
                filteredUsers.filter((user) => user.networks.some((network) => network.ID === props.device.idNetwork))
            )
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getAvailableUsers()
    }, [])

    const associateDeviceWithUser = async () => {
        try {
            const newAssociatedUserData = {
                idUser: userToAssociate.value,
                idDevice: props.device.ID,
                emailEnabled: true,
                smsEnabled: false,
                minAlarmLevelNotification: 1,
            }
            await CustomAxios.post('users-devices', newAssociatedUserData)
            void getAvailableUsers()
            setToggleReset(!toggleReset)
            setUserToAssociate({ label: t('fields.user'), value: '' })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="sectionContent">
                {user.idSysGrant === 0 && (
                    <div className="fast-command-row">
                        <span>{t('fields.associateNewUser')}:</span>
                        <SelectInput
                            value={userToAssociate}
                            onValueChange={(value) => setUserToAssociate(value)}
                            options={availableUsers.map((user: User) => {
                                return { label: `${user.firstName} ${user.lastName}`, value: user.ID.toString() }
                            })}
                            width={'100%'}
                        />
                        <button
                            disabled={userToAssociate.value === ''}
                            className="standard-confirm-button"
                            onClick={() => void associateDeviceWithUser()}
                        >
                            {t('common.associate')}
                        </button>
                    </div>
                )}
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextInput
                            placeholder={t('loginPage.username')}
                            label=""
                            value={filterQuery}
                            onChangeText={(value) => setFilterQuery(value)}
                        />
                    </div>
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 0 0 25px"
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                </div>
                <DeviceUsersTable searchQuery={filterQuery} reloads={toggleReset} idDevice={props.device.ID} />
            </div>
        </>
    )
}

export default DeviceUsersSection
