import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import { NetworkGroup } from '../../types/data/system'
import { Scheduler } from '../../types/data/alarm'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { singleScheduler } from '../../utils/api-constants'
import AlertModal from '../Modals/AlertModal'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    currentGroup: NetworkGroup
    filterParams?: any
    reloads?: number
    viewOnly?: boolean
}

const GroupSchedulersTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedElementToRemove, setSelectedElementToRemove] = useState(-1)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const deleteMessage = t('systemMessages.removeElementFromList')
    const dispatch = useDispatch()

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData()
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData()
        }
    }, [props.reloads])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const initRemoveProcedure = (schedulerId: number) => {
        setOpenAlertModal(true)
        setSelectedElementToRemove(schedulerId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.delete(singleScheduler(selectedElementToRemove))
            exitRemoveProcedure()
            resetData(currentPage)
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedElementToRemove(-1)
    }

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.active'),
                field: 'enabled',
                render: (rowData: Scheduler) => {
                    return (
                        <div>
                            <span className={`dotAlert ${rowData.enabled === 1 ? 'enabledDot' : 'regularDot'}`} />
                        </div>
                    )
                },
            },
            {
                title: t('fields.command'),
                field: 'command',
            },
            {
                title: t('fields.creator'),
                field: 'id_user',
                render: (rowData: any) => {
                    return (
                        <div>
                            <span>{`${rowData.user.firstName} ${rowData.user.lastName}`}</span>
                        </div>
                    )
                },
            },
            {
                title: t('fields.associatedTo'),
                field: 'association',
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.idDevice !== null && rowData.idDevice !== -1 ? (
                                <div className="tableElement">{rowData.device.name}</div>
                            ) : rowData.idNetworkGroup !== null && rowData.idNetworkGroup !== -1 ? (
                                <div
                                    onClick={() => navigate(`/${ROUTE_NAMES.GROUPS}/${rowData?.idNetworkGroup}`)}
                                    className="tableElement table-link-value colorRed link"
                                >
                                    {rowData.networkGroup.name}
                                </div>
                            ) : null}
                        </>
                    )
                },
            },
            {
                title: t('fields.executions'),
                field: 'last_execution_date',
                render: (rowData: any) => {
                    return (
                        <>
                            <div className="table-event-value-block">
                                {rowData.lastExecutionDate !== null && (
                                    <span>
                                        {t('fields.variable')}:{' '}
                                        <b>{moment(rowData.lastExecutionDate).format('DD/MM/YYYY HH:mm:ss')}</b>
                                    </span>
                                )}
                                {rowData.nextExecutionDate !== null && (
                                    <span>
                                        {t('fields.threshold')}:{' '}
                                        <b>{moment(rowData.nextExecutionDate).format('DD/MM/YYYY HH:mm:ss')}</b>
                                    </span>
                                )}
                            </div>
                        </>
                    )
                },
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: Scheduler) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}/${rowData.ID}`)
                },
                tooltip: t('common.edit'),
            }),
            (rowData: any) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => initRemoveProcedure(rowData.ID),
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'schedulers'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += `&group=${props.currentGroup.id}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 99,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default GroupSchedulersTable
