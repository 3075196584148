import React, { useCallback } from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'

interface Props {
    jsonData: string
    closeLabel?: string
    onClose: () => void
}

const JsonDetailModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.close')
    const emptyJsonData = t('systemMessages.emptyJsonDataMessage')

    const parseJsonData = useCallback(() => {
        const jsonCopy = props.jsonData.split(';')
        jsonCopy.pop()
        if (jsonCopy.length > 0) {
            return (
                <ul className="json-data-list-element">
                    {jsonCopy.map((element) => {
                        const [name, value] = element.split(':')
                        if (name === '' && value === '') return
                        return (
                            <li key={element}>
                                <p className="json-data-list-element__attribute-name">{`${name}:`}</p>
                                {value}
                            </li>
                        )
                    })}
                </ul>
            )
        }
        return emptyJsonData
    }, [props.jsonData])

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="modal json-detail-modal medium-modal">
                <p className="modal--message">{parseJsonData()}</p>
                <div className="modal--actions-container">
                    <button className="close-action" onClick={() => props.onClose()}>
                        {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default JsonDetailModal
