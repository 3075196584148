import React, { useEffect, useRef, useState } from 'react'
import * as calendarLocales from 'date-fns/locale'
import { Reducers, ReducerUser } from '../types/reducers'
import { useSelector } from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { handleDropdownClick } from '../utils/functions'

interface Props {
    minSelectableDate?: string
    noMaxSelectableDate?: boolean
    onDateChange: (newDate: string) => void
    disabled?: boolean
    initialValue: string
    initialDate?: Date
    reset: boolean
    dateChangePrefix?: string
}

interface SelectedDateObject {
    date: Date | null
    displayValue: string
}

const Datepicker: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [calendarIsOpen, setCalendarIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState<SelectedDateObject>({
        date: props.initialDate ? props.initialDate : null,
        displayValue: props.initialValue,
    })
    const datepickerRef = useRef(null)
    const calendarTriggerRef = useRef(null)

    useEffect(() => {
        window.addEventListener('click', (e) => {
            setCalendarIsOpen(handleDropdownClick(e, calendarIsOpen, calendarTriggerRef, datepickerRef))
        })
        return () => {
            window.removeEventListener('click', (e) => {
                setCalendarIsOpen(handleDropdownClick(e, calendarIsOpen, calendarTriggerRef, datepickerRef))
            })
        }
    }, [])

    const resetDateValue = () => {
        setSelectedDate({ date: props.initialDate ? props.initialDate : null, displayValue: props.initialValue })
    }

    useEffect(() => {
        if (props.minSelectableDate && selectedDate.date !== null) {
            if (moment(selectedDate.date).isBefore(moment(props.minSelectableDate))) {
                resetDateValue()
                props.onDateChange('')
            }
        }
    }, [props.minSelectableDate])

    useEffect(() => {
        resetDateValue()
    }, [props.reset, props.initialValue])

    const handleSelectedDateChanged = (newDate: Date) => {
        const displayDatePrefix = props.dateChangePrefix ? props.dateChangePrefix : ''
        setSelectedDate({ date: newDate, displayValue: displayDatePrefix + moment(newDate).format('DD/MM/YYYY') })
        setCalendarIsOpen(false)
        if (newDate !== null) {
            props.onDateChange(moment(newDate).format('YYYY-MM-DD HH:mm:ss'))
        }
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <div className="input-rows">
                    <div
                        ref={calendarTriggerRef}
                        className={`calendar-trigger-button ${
                            props.disabled ? 'calendar-trigger-button-disabled' : ''
                        }`}
                        onClick={() => {
                            if (!props.disabled) {
                                setCalendarIsOpen(!calendarIsOpen)
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faCalendar} />
                    </div>
                    <p className="date-range-label">{selectedDate.displayValue}</p>
                </div>
                <div ref={datepickerRef}>
                    <ReactDatePicker
                        minDate={
                            props.minSelectableDate && props.minSelectableDate !== ''
                                ? moment(props.minSelectableDate).toDate()
                                : null
                        }
                        maxDate={props.noMaxSelectableDate ? null : moment().toDate()}
                        locale={calendarLocales[user.language.split('-')[0]]}
                        open={calendarIsOpen}
                        selected={selectedDate.date}
                        onChange={(date: Date) => handleSelectedDateChanged(date)}
                        onSelect={(date: Date) => handleSelectedDateChanged(date)}
                    />
                </div>
            </div>
        </>
    )
}

export default Datepicker
