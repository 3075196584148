import { faArrowLeft, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Button from '../../Components/Inputs/Button'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import AlertModal from '../../Components/Modals/AlertModal'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/pages/main.sass'
import { Device, Scheduler } from '../../types/data/alarm'
import { AvailableCommand, NetworkGroup } from '../../types/data/system'
import { getDevices, getSchedulers, singleScheduler } from '../../utils/api-constants'
import { ROUTE_NAMES } from '../../utils/routes'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import CronWidget from '../../Components/CronWidget'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import Datepicker from '../../Components/Datepicker'
import DeleteModal from '../../Components/Modals/DeleteModal'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import Loader from '../../Components/StaticComponents/Loader'
import { ToastError } from '../../utils/toast'

const defaultScheduler: Scheduler = {
    ID: -1,
    idUser: -1,
    idDevice: null,
    command: '',
    dateFrom: '',
    dateTo: '',
    enabled: 0,
    sendNotification: 0,
    creationDate: '',
    lastExecutionDate: null,
    nextExecutionDate: null,
    idNetworkGroup: -1,
    idEditor: null,
    schedule: '* * * * *',
    device: null,
    networkGroup: null,
}

interface PlannedCommandDetailPageState {
    state: {
        isView?: boolean
        deviceId?: number
        fromDevicePage?: boolean
        idGroup?: number
    }
}

const PlannedCommandDetail: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const { id } = useParams<{ id: string }>()
    const { state }: PlannedCommandDetailPageState = useLocation()
    const { t } = useTranslation()
    const [scheduler, setScheduler] = useState<Scheduler>(defaultScheduler)
    const [schedulerCronValue, setSchedulerCronValue] = useState('')
    const [isPreviewMode, setIsPreviewMode] = useState(true)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstSchedulerStartDate, setFirstSchedulerStartDate] = useState('')
    const [firstSchedulerEndDate, setFirstSchedulerEndDate] = useState('')
    const [schedulerStartDate, setSchedulerStartDate] = useState('')
    const [schedulerEndDate, setSchedulerEndDate] = useState('')
    const [schedulerGroup, setSchedulerGroup] = useState<SelectOptionValue | null>({
        label: t('fields.group'),
        value: '',
    })
    const [schedulerDevice, setSchedulerDevice] = useState<SelectOptionValue | null>({
        label: t('fields.device'),
        value: '',
    })
    const [schedulerSelectedCommand, setSchedulerSelectedCommand] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [networkGroupsList, setNetworkGroupsList] = useState<NetworkGroup[]>([])
    const [devicesList, setDevicesList] = useState<Device[]>([])
    const [availableCommands, setAvailableCommands] = useState<AvailableCommand[]>([])
    const navigate = useNavigate()

    const getNetworkGroups = useCallback(async () => {
        try {
            const networkGroups: NetworkGroup[] = await CustomAxios.get('network-groups').then((res) => {
                return res.data.data
            })
            setNetworkGroupsList(networkGroups)
        } catch (error) {
            console.error(error)
            setNetworkGroupsList([])
        }
    }, [])

    const getAllDevices = useCallback(async () => {
        try {
            const devices: Device[] = await CustomAxios.get(getDevices()).then((res) => {
                return res.data.data
            })
            setDevicesList(devices)
        } catch (error) {
            console.error(error)
            setNetworkGroupsList([])
        }
    }, [])

    const getScheduler = useCallback(async (id: string) => {
        try {
            setLoading(true)
            const schedulers: Scheduler[] = await CustomAxios.get(getSchedulers()).then((res) => {
                return res.data.data
            })
            const selectedScheduler: Scheduler = schedulers.find(
                (scheduler: Scheduler) => scheduler.ID === parseInt(id, 10)
            )
            if (selectedScheduler && selectedScheduler !== null) {
                setScheduler(selectedScheduler)
                if (selectedScheduler.device !== undefined && selectedScheduler.device !== null) {
                    void getAvailableCommands(`?device=${selectedScheduler.device.ID}`)
                } else if (selectedScheduler.networkGroup !== undefined && selectedScheduler.networkGroup !== null) {
                    void getAvailableCommands(`?group=${selectedScheduler.networkGroup.id}`)
                }
            }
            setLoading(false)
        } catch (error) {
            console.error(error)
            setScheduler(defaultScheduler)
            setLoading(false)
        }
    }, [])

    const setSchedulerInfo = useMemo(() => {
        if (scheduler !== null && scheduler) {
            if (scheduler.idDevice !== -1 && devicesList.length > 0) {
                const selectedDevice = devicesList.find((device: Device) => device.ID === scheduler.idDevice)
                if (selectedDevice !== undefined) {
                    setSchedulerDevice({ label: selectedDevice.name, value: selectedDevice.ID.toString() })
                }
            }
            if (scheduler.idNetworkGroup !== -1 && networkGroupsList.length > 0) {
                const selectedNetworkGroup = networkGroupsList.find(
                    (group: NetworkGroup) => group.id === scheduler.idNetworkGroup
                )
                if (selectedNetworkGroup !== undefined) {
                    setSchedulerGroup({ label: selectedNetworkGroup.name, value: selectedNetworkGroup.id.toString() })
                }
            }
            if (scheduler.dateFrom !== null && scheduler.dateFrom !== '') {
                setSchedulerStartDate(scheduler.dateFrom)
            }
            if (scheduler.dateTo !== null && scheduler.dateTo !== '') {
                setSchedulerEndDate(scheduler.dateTo)
            }
        }
    }, [networkGroupsList, devicesList, scheduler])

    useEffect(() => {
        if (id !== undefined && id !== 'new-scheduler') {
            const parsedEndDate = scheduler.dateTo !== null && scheduler.dateTo !== '' ? scheduler.dateTo : ''
            setFirstSchedulerStartDate(scheduler.dateFrom)
            setFirstSchedulerEndDate(parsedEndDate)
        }
    }, [scheduler])

    useEffect(() => {
        void getNetworkGroups()
        void getAllDevices()
        void getScheduler(id)
    }, [user])

    useEffect(() => {
        if (id !== undefined && id !== 'new-scheduler') {
            setSchedulerInfo
            if (user.idSysGrant === 0) {
                setIsPreviewMode(state && state.isView)
            } else {
                setIsPreviewMode(true)
            }
        } else {
            setIsPreviewMode(false)
            if (state !== null && state.deviceId !== undefined && devicesList.length > 0) {
                const deviceFromLocation: Device = devicesList.find((device: Device) => device.ID === state.deviceId)
                if (deviceFromLocation !== undefined) {
                    setSchedulerDevice({ label: deviceFromLocation.name, value: deviceFromLocation.ID.toString() })
                    setScheduler({ ...defaultScheduler, idDevice: state.deviceId })
                    void getAvailableCommands(`?device=${state.deviceId}`)
                }
            }
            if (state !== null && state.idGroup !== undefined && networkGroupsList.length > 0) {
                const networkGroupFromLocation: NetworkGroup = networkGroupsList.find(
                    (networkGroup: NetworkGroup) => networkGroup.id === state.idGroup
                )
                if (networkGroupFromLocation !== undefined) {
                    setSchedulerGroup({
                        label: networkGroupFromLocation.name,
                        value: networkGroupFromLocation.id.toString(),
                    })
                    setScheduler({ ...defaultScheduler, idNetworkGroup: state.idGroup })
                    void getAvailableCommands(`?group=${state.idGroup}`)
                }
            } else setScheduler(defaultScheduler)
        }
    }, [id, setSchedulerInfo, devicesList, networkGroupsList, state])

    const schedulerDataIsCorrect = useMemo(() => {
        if (scheduler !== null && scheduler) {
            return (scheduler.idNetworkGroup !== -1 || scheduler.idDevice !== -1) && scheduler.command !== ''
        }
        return false
    }, [scheduler])

    const saveScheduler = async () => {
        try {
            const schedulerData = {
                idDevice: undefined,
                idNetworkGroup: undefined,
                command: scheduler.command,
                dateFrom:
                    schedulerStartDate.length > 0
                        ? moment(schedulerStartDate).format('YYYY-MM-DD 00:00:00')
                        : moment().format('YYYY-MM-DD 00:00:00'),
                dateTo: schedulerEndDate.length > 0 ? moment(schedulerEndDate).format('YYYY-MM-DD 00:00:00') : null,
                enabled: scheduler.enabled,
                sendNotification: scheduler.sendNotification,
                idUser: scheduler.idEditor !== -1 && scheduler.idEditor !== null ? scheduler.idEditor : user.ID,
                schedule: schedulerCronValue,
            }
            if (scheduler.idDevice !== -1) {
                schedulerData.idDevice = scheduler.idDevice
            }
            if (state !== null && state.deviceId !== undefined) {
                schedulerData.idDevice = state.deviceId
            }
            if (scheduler.idNetworkGroup !== -1) {
                schedulerData.idNetworkGroup = scheduler.idNetworkGroup
            }

            if (id !== undefined && id !== 'new-scheduler') {
                await CustomAxios.put(singleScheduler(parseInt(id, 10)), schedulerData)
            } else {
                await CustomAxios.post(getSchedulers(), schedulerData)
            }
            if (state !== null && state.fromDevicePage) {
                navigate(`/${ROUTE_NAMES.DEVICE}/${state.deviceId}`)
            } else {
                navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}?page=0`)
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const getAvailableCommands = async (searchParameters: string) => {
        try {
            const commands: AvailableCommand[] = await CustomAxios.get(`available-commands${searchParameters}`).then(
                (response) => response.data.data
            )
            setAvailableCommands(commands.filter((command) => command.requiredGrant === user.idSysGrant))
        } catch (error) {
            console.error(error)
        }
    }

    const handleCommandSelection = (value: SelectOptionValue) => {
        setSchedulerSelectedCommand(value)
        setScheduler({ ...scheduler, command: value.value })
    }

    useEffect(() => {
        if (scheduler.command !== '') {
            const command = availableCommands.find((i) => i.command === scheduler.command)
            if (command) {
                setSchedulerSelectedCommand({ label: command.name, value: command.command })
                return
            }
        }
        setSchedulerSelectedCommand({ label: t('fields.command'), value: '' })
    }, [availableCommands])

    const setSchedulerGroupAssociation = (selectedGroupOption: any) => {
        setScheduler({ ...scheduler, idDevice: -1, idNetworkGroup: parseInt(selectedGroupOption.value, 10) })
        setSchedulerGroup(selectedGroupOption)
        setSchedulerDevice({ label: t('fields.device'), value: '' })
        void getAvailableCommands(`?group=${selectedGroupOption.value}`)
    }

    const setSchedulerDeviceAssociation = (selectedDeviceOption: any) => {
        setScheduler({ ...scheduler, idNetworkGroup: -1, idDevice: parseInt(selectedDeviceOption.value, 10) })
        setSchedulerDevice(selectedDeviceOption)
        setSchedulerGroup({ label: t('fields.group'), value: '' })
        void getAvailableCommands(`?device=${selectedDeviceOption.value}`)
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`schedulers/${id}`)
            navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const initExitProcedure = () => {
        if (!isPreviewMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}?page=0`)
        }
    }

    const getSelectElements = useMemo(() => {
        if (networkGroupsList.length > 0 && devicesList.length > 0) {
            return (
                <>
                    {state !== null && state.deviceId !== undefined ? (
                        <>
                            <SelectInput
                                required
                                label={t('fields.device')}
                                disabled
                                value={schedulerDevice}
                                onValueChange={(value) => setSchedulerDeviceAssociation(value)}
                                options={devicesList.map((device: Device) => {
                                    return { label: device.name, value: device.ID.toString() }
                                })}
                                width={'100%'}
                            />
                        </>
                    ) : state !== null && state.idGroup !== undefined ? (
                        <>
                            <SelectInput
                                required
                                label={t('fields.group')}
                                disabled
                                value={schedulerGroup}
                                onValueChange={(value) => setSchedulerGroupAssociation(value)}
                                options={networkGroupsList.map((networkGroup: NetworkGroup) => {
                                    return { label: networkGroup.name, value: networkGroup.id.toString() }
                                })}
                                width={'100%'}
                            />
                        </>
                    ) : (
                        <>
                            <SelectInput
                                required
                                label={t('fields.group')}
                                disabled={isPreviewMode || (id !== undefined && id !== 'new-scheduler')}
                                value={schedulerGroup}
                                onValueChange={(value) => setSchedulerGroupAssociation(value)}
                                options={networkGroupsList.map((networkGroup: NetworkGroup) => {
                                    return { label: networkGroup.name, value: networkGroup.id.toString() }
                                })}
                                width={'100%'}
                            />
                            <SelectInput
                                required
                                label={t('fields.device')}
                                disabled={isPreviewMode || (id !== undefined && id !== 'new-scheduler')}
                                value={schedulerDevice}
                                onValueChange={(value) => setSchedulerDeviceAssociation(value)}
                                options={devicesList.map((device: Device) => {
                                    return { label: device.name, value: device.ID.toString() }
                                })}
                                width={'100%'}
                            />
                        </>
                    )}
                </>
            )
        }
        return ''
    }, [networkGroupsList, devicesList, schedulerGroup, schedulerDevice, isPreviewMode])

    const availableCommandSelect = useMemo(() => {
        return (
            <SelectInput
                required
                label={t('fields.command')}
                disabled={isPreviewMode || (schedulerGroup.value === '' && schedulerDevice.value === '')}
                value={schedulerSelectedCommand}
                onValueChange={(value) => handleCommandSelection(value)}
                options={availableCommands.map((command: AvailableCommand) => {
                    return { label: command.name, value: command.command.toString() }
                })}
                width={'100%'}
            />
        )
    }, [availableCommands, isPreviewMode, schedulerSelectedCommand, schedulerGroup, schedulerDevice])

    return (
        <PageContainer>
            {showAlertModal && (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={() => void confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('fields.scheduler')}
                                </span>
                                {` / ${scheduler.ID === -1 ? t('fields.newScheduler') : scheduler.ID}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.schedulerDetail" />
                        </div>
                        <div className="header-right-actions">
                            {isPreviewMode ? (
                                <>
                                    {user.idSysGrant === 0 && (
                                        <Button
                                            label={t('common.edit')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => setIsPreviewMode(false)}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button
                                        label={t('common.back')}
                                        backgroundColor="#efefef"
                                        textColor="#3f3f3f"
                                        margin="0 0 0 25px"
                                        icon={faArrowLeft}
                                        onPress={() => setShowAlertModal(true)}
                                    />
                                    {id !== undefined && id !== 'new-scheduler' && (
                                        <Button
                                            label={t('common.delete')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faTrash}
                                            onPress={() => initDeleteProcedure()}
                                        />
                                    )}
                                    <Button
                                        isDisabled={!schedulerDataIsCorrect}
                                        label={t('common.save')}
                                        backgroundColor="#e10915"
                                        textColor="#ffffff"
                                        margin="0 0 0 25px"
                                        iconColor="#ffffff"
                                        icon={faPen}
                                        onPress={() => void saveScheduler()}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="scheduler-details-grid">
                        <div className="filter-column">
                            <h3 className="section-title">{t('fields.schedulerInfo')}</h3>
                            <p>{t('systemMessages.exlusiveSchedulerAssociationMessage')}</p>
                            <div className="input-rows">{getSelectElements}</div>
                            <div className="input-rows">{availableCommandSelect}</div>
                            <div className="input-rows">
                                <CheckboxInput
                                    disabled={isPreviewMode}
                                    label={t('fields.enabled')}
                                    value={scheduler.enabled === 1}
                                    onChangeValue={(value: boolean) =>
                                        setScheduler({ ...scheduler, enabled: value ? 1 : 0 })
                                    }
                                />
                                <CheckboxInput
                                    disabled={isPreviewMode}
                                    label={t('fields.sendNotifications')}
                                    value={scheduler.sendNotification === 1}
                                    onChangeValue={(value: boolean) =>
                                        setScheduler({ ...scheduler, sendNotification: value ? 1 : 0 })
                                    }
                                />
                            </div>
                        </div>
                        <div className="filter-column">
                            <h3 className="section-title">{t('fields.validity')}</h3>
                            <div className="calendar-selection-container">
                                <Datepicker
                                    minSelectableDate={moment().toISOString()}
                                    noMaxSelectableDate
                                    disabled={isPreviewMode || user.idSysGrant !== 0}
                                    reset
                                    initialValue={
                                        firstSchedulerStartDate !== ''
                                            ? `${t('fields.start')}: ${moment(firstSchedulerStartDate).format(
                                                  'DD/MM/YYYY'
                                              )}`
                                            : `${t('fields.start')}: ${moment().format('DD/MM/YYYY')}`
                                    }
                                    initialDate={moment().toDate()}
                                    dateChangePrefix={`${t('fields.start')}: `}
                                    onDateChange={(newDate) => setSchedulerStartDate(newDate)}
                                />
                                <Datepicker
                                    minSelectableDate={
                                        schedulerStartDate !== ''
                                            ? moment(schedulerStartDate).toISOString()
                                            : moment().toISOString()
                                    }
                                    noMaxSelectableDate
                                    disabled={isPreviewMode || user.idSysGrant !== 0}
                                    reset
                                    initialValue={
                                        firstSchedulerEndDate !== ''
                                            ? `${t('fields.end')}: ${moment(firstSchedulerEndDate).format(
                                                  'DD/MM/YYYY'
                                              )}`
                                            : t('fields.selectEndOfInterval')
                                    }
                                    dateChangePrefix={`${t('fields.end')}: `}
                                    onDateChange={(newDate) => setSchedulerEndDate(newDate)}
                                />
                            </div>
                        </div>
                        <div className="full-width-column filter-column">
                            <h3 className="section-title">{t('fields.periodicity')}</h3>
                            <CronWidget
                                disabled={isPreviewMode}
                                initialValue={scheduler.schedule}
                                onValueChange={(value: string) => setSchedulerCronValue(value)}
                            />
                        </div>
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default PlannedCommandDetail
