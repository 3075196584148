import React, { CSSProperties } from 'react'
import '../../styles/pages/main.sass'

interface Props {
    style?: CSSProperties
    type?: string
    label: string
    value: string
    onChangeText?: (value: string) => void
    onlyVisible?: boolean
    withButton?: boolean
    isTextarea?: boolean
    button?: React.ReactElement
    width?: number | string
    isPassword?: boolean
    labelText?: string
    small?: boolean
    autocomplete?: boolean
    maxLength?: number
    isValidInput?: boolean
}

const TextField: React.FC<Props> = (props) => {
    if (props.isTextarea) {
        return (
            <div className="selectedInput">
                {props.labelText && <div className="labelInput label-input-no-margin">{props.labelText}</div>}
                <div className="selectFieldContainer">
                    <textarea
                        autoComplete={props.autocomplete ? 'true' : 'new-password'}
                        className="text-field"
                        placeholder={props.label}
                        disabled={props.onlyVisible}
                        style={{
                            position: 'relative',
                            minWidth: props.width ? props.width : 200,
                            minHeight: 80,
                            borderRadius: 6,
                            padding: 16,
                            width: props.width ? props.width : 'inherit',
                            border: '1px solid rgba(0, 0, 0, .24)',
                        }}
                        value={props.value}
                        onChange={(e) => {
                            if (props.onChangeText) {
                                props.onChangeText(e.target.value)
                            }
                        }}
                        maxLength={props.maxLength}
                    />
                </div>
            </div>
        )
    }

    if (props.withButton) {
        return (
            <div className="selectedInput">
                {props.labelText && <div className="labelInput label-input-no-margin">{props.labelText}</div>}
                <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                    <div className="selectFieldContainer">
                        <input
                            type="text"
                            autoComplete={props.autocomplete ? 'true' : 'new-password'}
                            className="inputField"
                            disabled={props.onlyVisible}
                            style={{
                                minWidth: props.small && props.width ? props.width : 200,
                                width: props.width ? props.width : 'inherit',
                            }}
                            value={props.value}
                            onChange={(e) => {
                                if (props.onChangeText) {
                                    props.onChangeText(e.target.value)
                                }
                            }}
                            maxLength={props.maxLength}
                        />
                    </div>
                    <div style={{ marginLeft: 20 }}>{props.button}</div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ minWidth: props.small && props.width ? props.width : '125px' }} className="selectedInput">
            {props.labelText && <div className="labelInput label-input-no-margin">{props.labelText}</div>}
            <div className="selectFieldContainer">
                <input
                    min={0}
                    autoComplete={props.autocomplete ? 'true' : 'new-password'}
                    className="text-field"
                    placeholder={props.label}
                    disabled={props.onlyVisible}
                    type={props.type ? props.type : props.isPassword ? 'password' : 'text'}
                    style={{
                        ...props.style,
                        minWidth: props.small && props.width ? props.width : 200,
                        width: props.width ? props.width : 'inherit',
                        color: props.isValidInput === undefined || props.isValidInput === true ? 'black' : 'red',
                    }}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChangeText) {
                            props.onChangeText(e.target.value)
                        }
                    }}
                    maxLength={props.maxLength}
                />
            </div>
        </div>
    )
}

export default TextField
