/* eslint-disable indent */
import { DashboardMainInfo } from './../../types/data/system'
import { getAPIInfo, getCommunicationProtocolTypes } from './../../utils/api-constants'
import CustomAxios from '../../customComponents/customAxios'
import { DeviceEvent, DeviceType } from '../../types/data/alarm'
import { UserDataTable } from '../../types/data/user'
import {
    setAPIVersion,
    setBrands,
    setCommunicationProtocolTypes,
    setConfigurations,
    setDeviceTypes,
    setGeniusData,
    setModels,
    setNetworkGroups,
    setNetworks,
    setTodayAlarms,
    setUsers,
} from './data'
import {
    setFetchAlarmTypesLoaderStatus,
    setFetchBrandsLoaderStatus,
    setFetchMapAlarmsLoaderStatus,
    setFetchNationsLoaderStatus,
    setFetchRegionsLoaderStatus,
    setFetchTodayAlarmsLoaderStatus,
    setFetchUserLoaderStatus,
    setFetchUsersLoaderStatus,
} from './graphics'
import { Brand, Model, Network, NetworkGroup } from '../../types/data/system'
import { getAllUsers, getDeviceEvents, getOrCreateDeviceModel } from '../../utils/api-constants'
import { User } from '../../types/data/user'
import { CommunicationProtocolType } from '../../types/data/common'
import { setManagedNetworks, setUserInfo } from './user'
import { ConfigParameters, Reducers } from '../../types/reducers'
import i18next from 'i18next'
import { setAvailableDashboards } from './analytics'
import axios from 'axios'
import { config } from '../../config/config'

export const mathValidation = async (expression: string): Promise<string> => {
    try {
        const response: string = await CustomAxios.post('/system/mathEval', { expression }).then(
            (response) => response.data
        )
        return response
    } catch (e) {
        console.error(e)
    }
}

export async function getCustomerInformations(): Promise<string> {
    const configurations = await CustomAxios.get('configurations').then((response) => response.data.data)
    const sub = configurations.find((c) => c.key === 'subscription')
    if (sub) {
        const subscriptionData = JSON.parse(sub.jsonData)
        return subscriptionData.customer
    }
    return ''
}
export async function createCustomerSession(customer_id: string) {
    const data = JSON.stringify({
        customer_id,
        return_url: `https://${window.location.hostname}`,
    })

    const response = await axios({
        method: 'POST',
        url: `${config.stripe.paymentGateway}/create-customer-portal-session`,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        data,
    })

    return response.data
}

export const getAPIVersion =
    (params: any = null): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const { version } = await CustomAxios.get(getAPIInfo(), params).then((response) => response.data)

            dispatch(setAPIVersion(version))
        } catch (e) {
            console.error('getAPIVersion error: ', e)
        }
    }

export const getGeniusData =
    (params: any = null): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const response = await CustomAxios.get('/genius/system?aiEventsHistory=true', params).then(
                (response) => response.data
            )
            dispatch(setGeniusData(response))
        } catch (e) {
            console.error('getAPIVersion error: ', e)
        }
    }

export const getLoggedUserInfo =
    (): any =>
    async (dispatch: (action: any) => void, getState: () => Reducers): Promise<void> => {
        try {
            await dispatch(setFetchUserLoaderStatus(true))

            const user: User = await CustomAxios.get(`/users/user/${getState().user.ID}`).then(
                (response) => response.data
            )

            await i18next.changeLanguage(user.language)
            dispatch(setUserInfo(user))

            await dispatch(setFetchUserLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchUserLoaderStatus(false))
            console.error('fetch logged user info error: ', e)
        }
    }

export const getDashboardsInfo =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const dashboards: DashboardMainInfo[] = await CustomAxios.get('dashboards').then(
                (response) => response.data.data
            )
            dispatch(setAvailableDashboards(dashboards))
        } catch (e) {
            console.error(e)
        }
    }
export const getConfigurationsInfo =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const ConfigParamsFromDB: ConfigParameters[] = await CustomAxios.get('configurations').then(
                (response) => response.data.data
            )
            dispatch(setConfigurations(ConfigParamsFromDB))
        } catch (e) {
            console.error(e)
        }
    }

export const setUserManagedNetworks =
    (userId: number): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const networkAssociations: any[] = await CustomAxios.get(`network-user-association?user=${userId}`).then(
                (response) => response.data.data
            )
            const parsedAssociations = networkAssociations.filter((networkAssociation) => {
                if (networkAssociation.idGrant === 1) {
                    return networkAssociation.network.ID
                }
                return false
            })
            dispatch(setManagedNetworks(parsedAssociations.map((association) => association.network.ID)))
        } catch (e) {
            console.error(e)
        }
    }

export const getTodayAlarms =
    (params: any = null): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchTodayAlarmsLoaderStatus(true))
            const todayAlarms: DeviceEvent[] = await CustomAxios.get(getDeviceEvents(), params).then(
                (response) => response.data.data
            )
            dispatch(setTodayAlarms(todayAlarms))

            await dispatch(setFetchTodayAlarmsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchTodayAlarmsLoaderStatus(false))
            console.error('fetch today alarms error: ', e)
        }
    }

export const getAlarmTypes =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchAlarmTypesLoaderStatus(true))
            // TODO
            // const alarmTypes: AlarmType[] = await CustomAxios.post('/api/Allarmi2/Tipologia').then((response) =>
            //    checkApiData(response.data, dispatch, [], 'Data')
            // )
            // dispatch(setAlarmTypes(alarmTypes))

            await dispatch(setFetchAlarmTypesLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchAlarmTypesLoaderStatus(false))
            console.error('fetch alarm types error: ', e)
        }
    }

export const getMapAlarms =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchMapAlarmsLoaderStatus(true))
            // TODO
            // const mapAlarms: any[] = await CustomAxios.post('/api/Allarmi2/Mappa').then((response) => checkApiData(response.data, dispatch, [], 'Data'))

            // dispatch(setMapAlarms(mapAlarms))

            await dispatch(setFetchMapAlarmsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchMapAlarmsLoaderStatus(false))
            console.error('fetch map alarms error: ', e)
        }
    }

export const getRegions =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchRegionsLoaderStatus(true))
            // TODO
            // const regions: Region[] = await CustomAxios.post('/api/Categorie').then((response) => checkApiData(response.data, dispatch, [], 'Data'))

            // dispatch(setRegions(regions))

            await dispatch(setFetchRegionsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchRegionsLoaderStatus(false))
            console.error('fetch regions error: ', e)
        }
    }

export const getNations =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchNationsLoaderStatus(true))
            await dispatch(setFetchNationsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchNationsLoaderStatus(false))
            console.error('fetch nations error: ', e)
        }
    }

export const getUsers =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchUsersLoaderStatus(true))
            const users: User[] = await CustomAxios.get(getAllUsers()).then((response) => response.data.data)
            dispatch(setUsers(users))

            await dispatch(setFetchUsersLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchUsersLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const getBrands =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const brands: Brand[] = await CustomAxios.get('brands').then((response) => response.data.data)

            dispatch(setBrands(brands))

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const getModels =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const models: Model[] = await CustomAxios.get(getOrCreateDeviceModel()).then(
                (response) => response.data.data
            )

            dispatch(setModels(models))

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const getDeviceTypes =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const deviceTypes: DeviceType[] = await CustomAxios.get('device-types').then(
                (response) => response.data.data
            )

            dispatch(setDeviceTypes(deviceTypes))

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch brands error: ', e)
        }
    }

export const getNetworks =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const networks: Network[] = await CustomAxios.get('networks').then((response) => response.data.data)
            dispatch(setNetworks(networks))
        } catch (e) {
            console.error('fetch networks error: ', e)
        }
    }

export const getNetworkGroups =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const networkGroups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            dispatch(setNetworkGroups(networkGroups))
        } catch (e) {
            console.error('fetch networks error: ', e)
        }
    }

export const getAllCommunicationProtocolTypes =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            const protocolTypes: CommunicationProtocolType[] = await CustomAxios.get(
                getCommunicationProtocolTypes()
            ).then((response) => response.data.data)
            dispatch(setCommunicationProtocolTypes(protocolTypes))
        } catch (e) {
            console.error('fetch networks error: ', e)
        }
    }

export const updateDeviceType =
    (deviceType: DeviceType): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            await CustomAxios.put(`device-types/${deviceType.ID}`, {
                name: deviceType.name,
                description: deviceType.description,
                note: deviceType.note,
            }).then((response) => response.data.data)

            dispatch(getDeviceTypes())

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const createDeviceType =
    (deviceType: DeviceType): any =>
    async (dispatch: (action: any) => void): Promise<DeviceType> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const newId = await CustomAxios.post('device-types', {
                name: deviceType.name,
                description: deviceType.description,
                note: deviceType.note,
            }).then((response) => response.data.insertId)
            const deviceTypes: DeviceType[] = await CustomAxios.get('device-types').then(
                (response) => response.data.data
            )

            dispatch(setDeviceTypes(deviceTypes))
            const foundType = deviceTypes.find((d) => d.ID === newId)

            await dispatch(setFetchBrandsLoaderStatus(false))
            if (foundType) {
                return foundType
            }
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const updateBrand =
    (brand: Brand): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            await CustomAxios.put(`brands/${brand.ID}`, {
                name: brand.name,
                description: brand.description,
                logo: brand.logo,
            }).then((response) => response.data.data)

            dispatch(getBrands())

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const updateNetwork =
    (network: Network): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            await CustomAxios.put(`networks/network/${network.ID}`, {
                name: network.name,
                description: network.description,
                logo: network.logo,
                banner: network.banner,
            }).then((response) => response.data.data)

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const updateGroup =
    (group: NetworkGroup): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            await CustomAxios.put(`network-groups/${group.id}`, {
                name: group.name,
                description: group.description,
                logo: group.logo,
                idNetwork: group.idNetwork,
            }).then((response) => response.data.data)

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const updateUser =
    (user: UserDataTable): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            await CustomAxios.put(`users/user/${user.ID}`, {
                firstName: user.firstName,
                lastName: user.lastName,
                idSysGrant: user.idSysGrant,
                phone: user.phone,
                email: user.email,
                language: user.language,
            }).then((response) => response.data.data)

            await dispatch(setFetchBrandsLoaderStatus(false))
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const createBrand =
    (brand: Brand): any =>
    async (dispatch: (action: any) => void): Promise<Brand> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const newId = await CustomAxios.post('brands', {
                name: brand.name,
                description: brand.description,
                logo: brand.logo,
            }).then((response) => response.data.insertId)
            const brands: Brand[] = await CustomAxios.get('brands').then((response) => response.data.data)

            dispatch(setBrands(brands))
            const foundBrand = brands.find((d) => d.ID === newId)

            await dispatch(setFetchBrandsLoaderStatus(false))
            if (foundBrand) {
                return foundBrand
            }
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const createNetwork =
    (network: Network): any =>
    async (dispatch: (action: any) => void): Promise<Brand> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const newId = await CustomAxios.post('networks/network', {
                name: network.name,
                description: network.description,
                logo: network.logo,
                banner: network.banner,
            }).then((response) => response.data.insertId)
            const networks: Network[] = await CustomAxios.get('networks').then((response) => response.data.data)

            const foundNetwork = networks.find((d) => d.ID === newId)

            await dispatch(setFetchBrandsLoaderStatus(false))
            if (foundNetwork) {
                return foundNetwork
            }
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const createGroup =
    (group: NetworkGroup): any =>
    async (dispatch: (action: any) => void): Promise<NetworkGroup> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const newId = await CustomAxios.post('network-groups', {
                name: group.name,
                description: group.description,
                logo: group.logo,
                idNetwork: group.idNetwork,
            }).then((response) => response.data.insertId)
            const groups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )

            const foundGroup = groups.find((d) => d.id === newId)
            await dispatch(setFetchBrandsLoaderStatus(false))
            if (foundGroup) {
                return foundGroup
            }
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }

export const createUser =
    (user: UserDataTable): any =>
    async (dispatch: (action: any) => void): Promise<UserDataTable> => {
        try {
            await dispatch(setFetchBrandsLoaderStatus(true))
            const newId = await CustomAxios.post('users/user', {
                firstName: user.firstName,
                lastName: user.lastName,
                idSysGrant: user.idSysGrant,
                phone: user.phone,
                email: user.email,
                language: user.language,
                password: user.password,
                username: user.username,
            }).then((response) => response.data.insertId)

            await dispatch(setFetchBrandsLoaderStatus(false))
            return newId
        } catch (e) {
            await dispatch(setFetchBrandsLoaderStatus(false))
            console.error('fetch users error: ', e)
        }
    }
