import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { COLORS } from '../../styles/constants'

interface Props {
    label: string
    textColor: string
    backgroundColor: string
    onPress: () => void
    icon?: any
    iconSize?: number
    iconColor?: string
    isDisabled?: boolean
    margin?: string
    borderColor?: string
}

const Button: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                color: `${props.textColor}`,
                border: `1px solid ${props.borderColor ? props.borderColor : props.backgroundColor}`,
                backgroundColor: `${props.backgroundColor}`,
                opacity: props.isDisabled ? 0.5 : 1,
                lineHeight: 'normal',
                userSelect: 'none',
                whiteSpace: 'pre',
                alignItems: 'center',
                textRendering: 'auto',
                letterSpacing: 'normal',
                wordSpacing: 'normal',
                textTransform: 'none',
                textIndent: 0,
                textShadow: 'none',
                display: 'inline-block',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                height: 'auto',
                fontSize: 13,
                textAlign: 'left',
                padding: '8px 16px',
                margin: props.margin ? props.margin : 0,
                borderRadius: 24,
                cursor: 'pointer'
            }}
            onClick={() => {
                if (!props.isDisabled) {
                    props.onPress()
                }
            }}
        >
            {props.icon && (
                <FontAwesomeIcon
                    icon={props.icon}
                    style={{
                        fontSize: props.iconSize ? props.iconSize : 16,
                        color: props.iconColor ? props.iconColor : COLORS.palette.red,
                        marginRight: 10
                    }}
                />
            )}
            {props.label}
        </div>
    )
}

export default Button
