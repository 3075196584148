import React from 'react'
import '../styles/components/tabs.sass'
import Button from './Inputs/Button'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { createCustomerSession, getCustomerInformations } from '../store/actions/requests'

interface Props {
    text: string
}

const SubscriptionBanner: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 200,
                overflow: 'hidden',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '20px',
                borderTop: '4px solid red',
            }}
        >
            <div
                style={{
                    backgroundColor: 'rgba(0,0,0,.07)',
                    top: -4,
                    position: 'absolute',
                    bottom: -4,
                    left: 0,
                    right: 0,
                    zIndex: 0,
                    backdropFilter: 'blur(3.5px)',
                    WebkitBackdropFilter: 'blur(3.5px)',
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                    gap: '1%',
                    width: '100%',
                }}
            >
                <p style={{ marginBottom: 0 }}>{props.text}</p>
                <Button
                    label={'Renew'}
                    backgroundColor="#efefef"
                    textColor="#3f3f3f"
                    icon={faRedo}
                    onPress={async () => {
                        const customer = await getCustomerInformations()
                        const customerPortal = await createCustomerSession(customer)
                        window.open(customerPortal.value, '_self', 'noopener,noreferrer')
                    }}
                />
            </div>
        </div>
    )
}

export default SubscriptionBanner
