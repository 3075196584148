import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../../types/reducers'
import { createNetwork, updateNetwork } from '../../../store/actions/requests'
import PageContainer from '../../../Components/StaticComponents/PageContainer'
import Footer from '../../../Components/StaticComponents/Footer'
import { faArrowLeft, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../Components/Inputs/TextField'
import Button from '../../../Components/Inputs/Button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/routes'
import { Network } from '../../../types/data/system'
import LogoDefault from '../../../resources/media/images/whitelabel/login-header.png'
import UploadButton from '../../../Components/Inputs/UploadButton'
import CustomAxios from '../../../customComponents/customAxios'
import AlertModal from '../../../Components/Modals/AlertModal'
import NetworkUserAssocTab from '../../../Components/Tabs/NetworkUserAssocTab'
import NetworkGroupAssocTab from '../../../Components/Tabs/NetworkGroupAssocTab'
import CustomAxiosImages from '../../../customComponents/customAxiosImages'
import DeleteModal from '../../../Components/Modals/DeleteModal'
import Tabs from '../../../Components/Tabs'
import { ToastError, ToastSuccess } from '../../../utils/toast'
import PageHeaderCustom from '../../../customComponents/PageHeaderCustom'
import Loader from '../../../Components/StaticComponents/Loader'
import { useTranslation } from 'react-i18next'
import { config } from '../../../config/config'

interface NetworkDetailPageState {
    state: {
        isView?: boolean
    }
}

const NetworkDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const networkSubmenuVoices = [
        {
            label: t('fields.associatedGroups'),
            value: 'associatedGroups',
        },
        {
            label: t('fields.associatedUsers'),
            value: 'associatedUsers',
        },
    ]

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [network, setNetwork] = useState<Network>({
        ID: -1,
        name: '',
        logo: '',
        banner: '',
        description: '',
    })
    const [selectedTab, setSelectedTab] = useState<string>(networkSubmenuVoices[0].value)
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logoImg = useRef(null)
    const bannerImg = useRef(null)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { state }: NetworkDetailPageState = useLocation()
    const [editMode, setEditMode] = useState(!(state && state.isView))

    useEffect(() => {
        if (user.idSysGrant !== 0 && user.managedNetworks.length === 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
        if (id !== undefined && id !== 'new-network') {
            void getNetwork(id)
        } else {
            setNetwork({
                ID: -1,
                name: '',
                logo: '',
                banner: '',
                description: '',
            })
        }
    }, [id, user])

    const getNetwork = async (id: string) => {
        try {
            setLoading(true)
            const baseUrl = config.api.baseURL
            const network: Network = await CustomAxios.get(`networks/${id}`).then((res) => {
                return res.data
            })
            setNetwork({
                ...network,
                logo: network.logo !== null && network.logo !== '' ? `${baseUrl}/${network.logo}` : '',
                banner: network.banner !== null && network.banner !== '' ? `${baseUrl}/${network.banner}` : '',
            })
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCreateBrand = async () => {
        try {
            const newNetwork = await dispatch(createNetwork({ ...network, logo: '' }))
            if (newNetwork) {
                if (network.logo) {
                    await handleUpdateNetwork(
                        {
                            ...newNetwork,
                            logo: network.logo,
                        },
                        t('actionsMessages.successNetworkCreate')
                    )
                    navigate(`/${ROUTE_NAMES.NETWORKS}/${newNetwork.ID}`)
                } else {
                    navigate(`/${ROUTE_NAMES.NETWORKS}/${newNetwork.ID}`)
                }
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const handleUpdateNetwork = async (
        network: Network,
        toastMessage: string = t('actionsMessages.successNetworkUpdate')
    ) => {
        try {
            const baseUrl = config.api.baseURL
            const logo = await uploadImageLogo(network)
            const banner = await uploadImageBanner(network)
            const networkToSave = {
                ...network,
                description: network.description !== null ? network.description : '',
                logo: logo !== null ? logo.toString().replace(baseUrl, '') : '',
                banner: banner !== null ? banner.toString().replace(baseUrl, '') : '',
            }
            dispatch(updateNetwork(networkToSave))
            ToastSuccess(toastMessage)
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const uploadImageLogo = async (network: Network) => {
        if (network.logo instanceof File) {
            const data = new FormData()
            data.append('table', 'networks')
            data.append('object_id', `${network.ID.toString()}-logo`)
            data.append('image', network.logo)
            const newLogo = await CustomAxiosImages.post('images', data, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((res) => {
                return res.data.url
            })
            return newLogo
        }
        return network.logo
    }

    const uploadImageBanner = async (network: Network) => {
        if (network.logo instanceof File) {
            const data = new FormData()
            data.append('table', 'networks')
            data.append('object_id', `${network.ID.toString()}-banner`)
            data.append('image', network.logo)
            const newLogo = await CustomAxiosImages.post('images', data, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((res) => {
                return res.data.url
            })
            return newLogo
        }
        return network.logo
    }

    const initExitProcedure = () => {
        if (editMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.NETWORKS}?page=0`)
        }
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`networks/network/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.NETWORKS}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const handleNewLogoUpload = (file: File) => {
        const fr = new FileReader()
        fr.onload = function () {
            logoImg.current.src = fr.result
        }
        fr.readAsDataURL(file)
    }

    const handleNewBannerUpload = (file: File) => {
        const fr = new FileReader()
        fr.onload = function () {
            bannerImg.current.src = fr.result
        }
        fr.readAsDataURL(file)
    }

    const handleSendNetworkData = () => {
        if (network.ID !== -1) {
            void handleUpdateNetwork(network)
        } else {
            void handleCreateBrand()
        }
    }

    const networkDataIsCorrect = useMemo(() => {
        return network.name !== ''
    }, [network])

    const renderCurrentTab = () => {
        switch (selectedTab) {
            case 'associatedGroups':
                return <NetworkGroupAssocTab currentNetwork={network} viewOnly={!editMode} />
            case 'associatedUsers':
                return <NetworkUserAssocTab currentNetwork={network} viewOnly={!editMode} />
            default:
                return <NetworkGroupAssocTab currentNetwork={network} viewOnly={!editMode} />
        }
    }

    const renderTables = () => {
        if (network.ID !== -1) {
            return (
                <div style={{ marginTop: '25px' }}>
                    <Tabs tabs={networkSubmenuVoices} onSelectedTabChange={(newTab) => setSelectedTab(newTab)} />
                    {renderCurrentTab()}
                </div>
            )
        }
        return (
            <p style={{ fontSize: 16, fontStyle: 'italic', margin: '10px 20px' }}>
                {t('systemMessages.createNetworkFirstMessage')}
            </p>
        )
    }

    return (
        <PageContainer>
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.NETWORKS}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={() => void confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('routes.network')}
                                </span>{' '}
                                {`/ ${network.ID === -1 ? t('fields.newNetwork') : network.name}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.networkDetail" />
                        </div>
                        {(user.idSysGrant === 0 ||
                            user.managedNetworks.find((networkId) => networkId === network.ID)) && (
                            <div className="header-right-actions">
                                {!editMode ? (
                                    <Button
                                        label={t('common.update')}
                                        backgroundColor="#EA0B2A"
                                        textColor="#fff"
                                        iconColor="#fff"
                                        margin="0"
                                        icon={faPen}
                                        onPress={() => setEditMode(true)}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            label={t('common.back')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faArrowLeft}
                                            onPress={() => initExitProcedure()}
                                        />
                                        {id !== undefined && id !== 'new-group' && (
                                            <Button
                                                label={t('common.delete')}
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                margin="0 0 0 25px"
                                                icon={faTrash}
                                                onPress={() => initDeleteProcedure()}
                                            />
                                        )}
                                        <Button
                                            isDisabled={!networkDataIsCorrect}
                                            label={t('common.save')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => handleSendNetworkData()}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="sectionContent">
                        <div className="editContainer advanced-search-wrapper">
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.name')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.name')}
                                        value={network.name}
                                        onChangeText={(value: string) => {
                                            setNetwork({
                                                ...network,
                                                name: value,
                                            })
                                        }}
                                    />
                                </div>
                                <h3>{t('fields.description')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.description')}
                                        isTextarea
                                        value={network.description}
                                        onChangeText={(value: string) => {
                                            setNetwork({
                                                ...network,
                                                description: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.banner')}</h3>
                                <div className="input-rows" style={{ position: 'relative' }}>
                                    <div className="imgUploadContainer">
                                        {editMode ? (
                                            <div style={{ position: 'absolute', top: -10, right: -10 }}>
                                                <UploadButton
                                                    acceptedData="image/png, image/jpeg"
                                                    onChange={(f) => {
                                                        setNetwork({
                                                            ...network,
                                                            banner: f,
                                                        })
                                                        handleNewBannerUpload(f)
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <img
                                            alt=""
                                            src={
                                                network.banner && typeof network.banner === 'string'
                                                    ? network.banner
                                                    : LogoDefault
                                            }
                                            className="logoBrand"
                                            ref={bannerImg}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.logo')}</h3>
                                <div className="input-rows" style={{ position: 'relative' }}>
                                    <div className="imgUploadContainer">
                                        {editMode ? (
                                            <div style={{ position: 'absolute', top: -10, right: -10 }}>
                                                <UploadButton
                                                    onChange={(f) => {
                                                        setNetwork({
                                                            ...network,
                                                            logo: f,
                                                        })
                                                        handleNewLogoUpload(f)
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <img
                                            alt=""
                                            src={
                                                network.logo && typeof network.logo === 'string'
                                                    ? network.logo
                                                    : LogoDefault
                                            }
                                            className="logoBrand"
                                            ref={logoImg}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderTables()}
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default NetworkDetailPage
