import i18next from 'i18next'
import { UserAccessTokenData } from '../../types/data/user'
import { getAPIVersion, getDashboardsInfo, getLoggedUserInfo } from './requests'
import { logoutUser, setTokenData } from './user'

export const logUser =
    (tokenData: UserAccessTokenData): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            localStorage.setItem('access_token', tokenData.token)
            await i18next.changeLanguage(tokenData.language.toLowerCase())
            dispatch(setTokenData(tokenData))

            await dispatch(fetchAllData())
        } catch (e) {
            console.error('log user error: ', e)
        }
    }

export const fetchAllData =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            await dispatch(getAPIVersion())
            await dispatch(getLoggedUserInfo())
            await dispatch(getDashboardsInfo())
        } catch (e) {
            console.error('fetch alla data error: ', e)
        }
    }

export const logoutExpiredUser =
    (): any =>
    async (dispatch: (action: any) => void): Promise<void> => {
        try {
            dispatch(logoutUser())
        } catch (e) {
            console.error('fetch alla data error: ', e)
        }
    }
