import React, { useState } from 'react'
import '../styles/pages/loginPage.sass'
import BackgroundImage from '../resources/media/images/whitelabel/login-background.jpg'
import LogoImage from '../resources/media/images/whitelabel/login-header.png'
import CustomAxios from '../customComponents/customAxios'
import { useDispatch } from 'react-redux'
import { logUser } from '../store/actions/groupedActions'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../utils/routes'

const ValidateOTPPage: React.FC = () => {
    const [otpCode, setOTPCode] = useState<string>('')
    const [loginErrorFlag, setLoginErrorFlag] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation()

    const { t } = useTranslation()

    const validateOTP = async () => {
        try {
            const validation = await CustomAxios.post('/auth/validate-otp/', {
                token: otpCode,
                user_id: state.ID,
            }).then((response) => response.data)

            if (validation.otpInfo.otpValid === true) {
                dispatch(logUser(validation))
                navigate(`${ROUTE_NAMES.TODAY_ALARM}?page=0`)
            } else {
                setLoginErrorFlag(false)
            }
        } catch (e) {
            console.error('Login error: ', e)
            setLoginErrorFlag(true)
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${BackgroundImage})`,
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <form
                className="containerLogin"
                onSubmit={(event) => {
                    event.preventDefault()
                    void validateOTP()
                }}
            >
                <img alt="" className="logoLogin" src={LogoImage} />
                <div className="inputLoginContainer">
                    <input
                        className="inputLogin"
                        value={otpCode}
                        onChange={(e) => setOTPCode(e.target.value)}
                        type="text"
                        placeholder={'OTP CODE'}
                        autoComplete="off"
                    />
                </div>

                {loginErrorFlag && <p className="error-message">{'Wrong OTP CODE'}</p>}
                <input
                    type="submit"
                    className="inputButton"
                    style={{
                        marginBottom: 20,
                        width: '21vw',
                    }}
                    value={t('loginPage.loginButton').toUpperCase()}
                    onClick={() => void validateOTP()}
                />
                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.application')}
                </p>
                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.copyright', { year: new Date().getFullYear() })}
                </p>
            </form>
        </div>
    )
}

export default ValidateOTPPage
