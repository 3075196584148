import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeviceDefaultVars } from '../../types/data/alarm'
import Button from '../Inputs/Button'
import TextField from '../Inputs/TextField'
import DefaultVarModal from '../Modals/DefaultVarModal'
import DefaultVarsTable, { DefaultVarTable } from '../Tables/DefaultVarsTable'

interface ExtendedDeviceDefaultVars extends DeviceDefaultVars {
    idDevice?: number
    idEditor?: number
}

interface Props {
    defaultVars: ExtendedDeviceDefaultVars[]
    updateModel: (vars: ExtendedDeviceDefaultVars[], newOrEditedVar?: ExtendedDeviceDefaultVars) => void
    viewOnly?: boolean
    deviceId?: number
    isModelSection?: boolean
}

const DefaultVarsTab: React.FC<Props> = (props) => {
    const [varAsQuerySearch, setVarAsQuerySearch] = useState('')
    const [newVarsType, setNewVarsType] = useState<DefaultVarTable[]>([])
    const [selectedVar, setSelectedVar] = useState<DefaultVarTable | null>(null)
    const newVarTableVoid: any = {
        id: -1,
        oid: '',
        name: '',
        valMax: '',
        valMin: '',
        description: '',
        eventEnabled: false,
        alarmLevelMax: 0,
        alarmLevelMin: 0,
    }
    const { t } = useTranslation()

    useEffect(() => {
        const newArray = props.defaultVars.map((a, index) => {
            return {
                ...a,
                id: index,
            }
        })
        setNewVarsType(newArray)
    }, [props.defaultVars])

    const filteredVars = useMemo(() => {
        if (varAsQuerySearch) {
            return newVarsType.filter((v) => v.name.toLowerCase().includes(varAsQuerySearch.toLowerCase()))
        }
        return newVarsType
    }, [newVarsType, varAsQuerySearch])

    return (
        <div style={{ margin: '30px 0' }}>
            {selectedVar ? (
                <DefaultVarModal
                    preSetEnabled={false}
                    isModelVar={props.isModelSection}
                    defaultVar={selectedVar}
                    isNew
                    onClose={() => setSelectedVar(null)}
                    onConfirm={(newVar) => {
                        if (newVar.oid && newVar.name) {
                            const newVariable = newVar
                            delete newVar.id
                            props.updateModel([newVariable, ...props.defaultVars], newVariable)
                            setSelectedVar(null)
                        }
                    }}
                />
            ) : null}
            <div style={{ margin: 20 }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField
                            label={t('searches.searchByName')}
                            value={varAsQuerySearch}
                            onChangeText={(value: string) => setVarAsQuerySearch(value)}
                        />
                    </div>
                    <Button
                        label={t('common.addVariable')}
                        backgroundColor="#EA0B2A"
                        textColor="#fff"
                        iconColor="#fff"
                        margin="0 0 0 25px"
                        icon={faPlus}
                        onPress={() => {
                            if (props.deviceId) {
                                newVarTableVoid.idDevice = props.deviceId
                            }
                            setSelectedVar(newVarTableVoid)
                        }}
                    />
                </div>
            </div>
            <DefaultVarsTable
                data={newVarsType}
                filtered={filteredVars}
                updateData={(vars, edited) => {
                    props.updateModel(vars, edited)
                }}
                isModelSection={props.isModelSection}
            />
        </div>
    )
}

export default DefaultVarsTab
