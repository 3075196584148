import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { DeviceAvailableCommand } from '../../types/data/alarm'
import Button from '../Inputs/Button'
import TextField from '../Inputs/TextField'
import AvailableCommandModal from '../Modals/AvailableCommandModal'
import AvailableCommandsTable, { DeviceAvailableCommandTable } from '../Tables/AvailableCommandsTable'
import { useTranslation } from 'react-i18next'

interface Props {
    availableCommands: DeviceAvailableCommand[]
    updateModel: (vars: DeviceAvailableCommand[]) => void
    viewOnly?: boolean
}

const AvailableCommandsTab: React.FC<Props> = (props) => {
    const [varAsQuerySearch, setVarAsQuerySearch] = useState('')
    const [newCommandsType, setNewCommandsType] = useState<DeviceAvailableCommandTable[]>([])
    const [selectedCommand, setSelectedCommand] = useState<DeviceAvailableCommandTable | null>(null)
    const newCommandTableVoid = {
        id: -1,
        name: '',
        command: '',
        requiredGrant: 0
    }
    const { t } = useTranslation()

    useEffect(() => {
        const newArray = props.availableCommands.map((a, i) => {
            return {
                ...a,
                id: i
            }
        })
        setNewCommandsType(newArray)
    }, [props.availableCommands])

    const commandsFiltered = useMemo(() => {
        if (varAsQuerySearch) {
            return newCommandsType.filter((v) => v.name.toLowerCase().includes(varAsQuerySearch.toLowerCase()))
        }
        return newCommandsType
    }, [newCommandsType, varAsQuerySearch])

    return (
        <div style={{ margin: '30px 0' }}>
            {selectedCommand ? (
                <AvailableCommandModal
                    command={selectedCommand}
                    isNew
                    onClose={() => setSelectedCommand(null)}
                    onConfirm={(command) => {
                        if (command.name && command.command) {
                            const newCommand = command
                            delete command.id
                            props.updateModel([newCommand, ...props.availableCommands])
                            setSelectedCommand(null)
                        }
                    }}
                />
            ) : null}
            <div style={{ margin: 20 }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField label={t('searches.searchByName')} value={varAsQuerySearch} onChangeText={(value: string) => setVarAsQuerySearch(value)} />
                    </div>
                    <Button
                        label={t('common.addCommand')}
                        backgroundColor="#EA0B2A"
                        textColor="#fff"
                        iconColor="#fff"
                        margin="0 0 0 25px"
                        icon={faPlus}
                        onPress={() => setSelectedCommand(newCommandTableVoid)}
                    />
                </div>
            </div>
            <AvailableCommandsTable
                data={newCommandsType}
                filtered={commandsFiltered}
                updateData={(commands) => {
                    props.updateModel(commands)
                }}
            />
        </div>
    )
}

export default AvailableCommandsTab
