import { faCircleInfo, faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/components/modals.sass'
import { CommandModelGrant } from '../../utils/enum/CommandModelGrant'
import Button from '../Inputs/Button'
import SelectInput from '../Inputs/SelectInput'
import TextField from '../Inputs/TextField'
import { DeviceAvailableCommandTable } from '../Tables/AvailableCommandsTable'
import AlertModal from './AlertModal'
import { Grid, Paper, Tooltip, Button as MUIButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { COLORS } from '../../styles/constants'

interface Props {
    command: DeviceAvailableCommandTable
    isNew?: boolean
    viewMode?: boolean
    onClose: () => void
    onConfirm: (edited: DeviceAvailableCommandTable) => void
}

const AvailableCommandModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const [command, setCommand] = useState(props.command)
    const [valueOption, setValueOption] = useState(1)
    const [valueMap, setValueMap] = useState([])
    const [isView, setIsView] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)

    useEffect(() => {
        setCommand(props.command)

        if (props.command.value !== '') {
            setValueOption(1)
        }

        if (props.command.valueMap && props.command.valueMap !== '') {
            setValueOption(3)
            const splitted = props.command.valueMap.replaceAll('{', '').replaceAll('}', '').split(',')
            const items = []
            splitted.forEach((i) => {
                const key = i.substring(0, i.indexOf('('))
                const value = i.replaceAll(')', '').slice(i.indexOf('(') + 1)
                items.push({ key, value })
            })
            setValueMap(items)
        }
        if (props.command.userDefined) {
            setValueOption(2)
        }
        if (props.viewMode) {
            setIsView(props.viewMode)
        }
    }, [props.command, props.viewMode])

    const dataIsNotCorrect = useMemo(() => {
        return command.name === '' || command.command === ''
    }, [command])

    const updatevalueMap = (index: number, parameterToEdit: string, newValue: any) => {
        setValueMap(
            valueMap.map((th, i) => {
                if (i !== index) return th
                return { ...th, [parameterToEdit]: newValue }
            })
        )
    }

    const saveCommand = () => {
        if (!command.name || !command.oid) {
            return
        }

        command.userDefined = valueOption === 2 ? 1 : 0

        const valueMapItems = []
        valueMap.map((i) => {
            return valueMapItems.push(`${i.key}(${i.value})`)
        })
        if (valueMapItems.length > 0) {
            command.valueMap = `{${valueMapItems.join(',')}}`
        } else {
            command.valueMap = ''
        }

        props.onConfirm(command)
    }

    const isValueOptionValid = (item) => {
        return item.key !== undefined && item.key !== '' && item.value !== undefined && item.value !== ''
    }
    const changeValueOption = (index) => {
        if (index !== 2) {
            command.userDefined = 0
        }
        if (index !== 3) {
            setValueMap([])
        }
        setValueOption(index)
    }

    return (
        <div className="alert-modal-wrapper">
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => props.onClose()}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            <div
                className="dark-cover"
                onClick={() => {
                    if (!isView) {
                        setShowAlertModal(true)
                    } else {
                        props.onClose()
                    }
                }}
            />
            <div className="modal edit">
                <div className="headerSection addNewSection">
                    <h2>
                        <span className="titleLink" style={{ color: '#52565A', opacity: 0.5 }}>
                            {t('fields.availableCommand')}
                        </span>{' '}
                        - {props.isNew ? t('fields.newCommand') : props.command.name}
                    </h2>
                    <div>
                        {isView ? (
                            <Button
                                label={t('common.update')}
                                backgroundColor="#EA0B2A"
                                textColor="#fff"
                                iconColor="#fff"
                                margin="0 25px 0 0"
                                icon={faPen}
                                onPress={() => setIsView(false)}
                            />
                        ) : (
                            <Button
                                label={props.isNew ? t('common.add') : t('common.save')}
                                backgroundColor="#EA0B2A"
                                textColor="#fff"
                                iconColor="#fff"
                                margin="0 25px 0 0"
                                isDisabled={dataIsNotCorrect}
                                icon={props.isNew ? faPlus : faPen}
                                onPress={async () => {
                                    if (command.name && command.command) {
                                        saveCommand()
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="advanced-search-wrapper" style={{ gridTemplateColumns: 'repeat(4,1fr)' }}>
                    <div className="filter-column">
                        <div className="variable-field-label-container">
                            <h3>{t('fields.oid')}</h3>
                            <label className="required-asterisk">*</label>
                        </div>
                        <div className="input-rows">
                            <TextField
                                onlyVisible={isView}
                                label="OID"
                                value={command.oid}
                                onChangeText={(value: string) => {
                                    setCommand({ ...command, oid: value.startsWith('.') ? value : `.${value}` })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <div className="variable-field-label-container">
                            <h3>{t('fields.name')}</h3>
                            <label className="required-asterisk">*</label>
                        </div>
                        <div className="input-rows">
                            <TextField
                                onlyVisible={isView}
                                label={t('fields.commandName')}
                                value={command.name}
                                onChangeText={(value: string) => setCommand({ ...command, name: value })}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <div className="variable-field-label-container">
                            <h3>{t('fields.command')}</h3>
                            <label className="required-asterisk">*</label>
                        </div>
                        <div className="input-rows">
                            <TextField
                                onlyVisible={isView}
                                label={t('fields.command')}
                                value={command.command}
                                onChangeText={(value: string) => setCommand({ ...command, command: value })}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <h3>{t('fields.requiredRole')}</h3>
                        <div className="input-rows">
                            <SelectInput
                                disabled={isView}
                                options={CommandModelGrant.map((l, i) => {
                                    return { value: i.toString(), label: t(`userRoles.${l}`) }
                                })}
                                value={
                                    command.requiredGrant !== -1
                                        ? {
                                              value: command.requiredGrant.toString(),
                                              label: t(`userRoles.${CommandModelGrant[command.requiredGrant]}`),
                                          }
                                        : null
                                }
                                placeholder={t('fields.requiredRole')}
                                onValueChange={(v) => {
                                    setCommand({
                                        ...command,
                                        requiredGrant: parseInt(v.value, 10),
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <div className="variable-field-label-container">
                            <h3>{t('fields.commandWarningMessage')}</h3>
                        </div>
                        <div className="input-rows">
                            <TextField
                                isTextarea
                                onlyVisible={isView}
                                label={t('fields.commandWarningMessagePlaceholder')}
                                value={command.userMessage}
                                onChangeText={(value: string) => {
                                    setCommand({ ...command, userMessage: value })
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                            <h3>{t('fields.commandValueOption')}</h3>
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>{t('fields.commandValueOptionHint')}</div>
                                }
                                style={{ paddingBottom: 4 }}
                            >
                                <div>
                                    <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                                </div>
                            </Tooltip>
                        </div>
                        <ToggleButtonGroup
                            color="primary"
                            value={valueOption}
                            exclusive
                            onChange={(_, value) => {
                                changeValueOption(parseInt(value, 10))
                            }}
                            aria-label="Platform"
                        >
                            <ToggleButton disabled={isView} style={{ fontSize: '10px' }} value={1}>
                                {t('fields.commandValueOptions.fixed')}
                            </ToggleButton>
                            <ToggleButton disabled={isView} style={{ fontSize: '10px' }} value={2}>
                                {t('fields.commandValueOptions.userDefined')}
                            </ToggleButton>
                            <ToggleButton disabled={isView} style={{ fontSize: '10px' }} value={3}>
                                {t('fields.commandValueOptions.mapped')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {valueOption === 1 && (
                        <div className="filter-column">
                            <h3>{t('fields.value')}</h3>
                            <div className="input-rows">
                                <TextField
                                    onlyVisible={isView}
                                    label="Value"
                                    value={command.value}
                                    onChangeText={(value: string) => setCommand({ ...command, value })}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {valueOption === 3 && (
                    <div style={{ width: '100%', marginTop: 18, marginBottom: 40, backgroundColor: '#f8f8f8' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: 20,
                            }}
                        >
                            <h3>{t('fields.availableCommandValue')}</h3>
                            <Button
                                label={t('common.add')}
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                iconColor="#e10915"
                                margin="0 0 0 220px"
                                isDisabled={isView}
                                icon={faPlus}
                                onPress={() => {
                                    setValueMap((valueMap) => [...valueMap, { key: '', value: '' }])
                                }}
                            />
                        </div>

                        <Grid container spacing={4} style={{ padding: 20 }}>
                            {valueMap.map((i, index) => (
                                <Grid item xs={2} key={i}>
                                    <Paper
                                        style={{
                                            padding: '10px',
                                            border: !isValueOptionValid(i) ? '1px outset #ff00006e' : '',
                                        }}
                                    >
                                        {isValueOptionValid(i)}
                                        <div>
                                            <p style={{ fontSize: '8px' }}>{t('fields.label')}</p>
                                            <TextField
                                                width="150px"
                                                small
                                                onlyVisible={isView}
                                                label="Label"
                                                value={i.key}
                                                onChangeText={(value: string) => updatevalueMap(index, 'key', value)}
                                            />
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '8px' }}>{t('fields.value')}</p>
                                            <TextField
                                                width="150px"
                                                small
                                                onlyVisible={isView}
                                                label="Value"
                                                value={i.value}
                                                onChangeText={(value: string) => updatevalueMap(index, 'value', value)}
                                            />
                                        </div>

                                        <MUIButton
                                            variant="text"
                                            size="small"
                                            style={{ fontSize: '10px' }}
                                            disabled={isView}
                                            onClick={() => {
                                                const temp = valueMap.filter((item) => i.key !== item.key)
                                                setValueMap(temp)
                                            }}
                                        >
                                            Delete
                                        </MUIButton>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AvailableCommandModal
