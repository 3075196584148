import React, { useEffect, useState } from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'
import { AvailableCommand } from '../../types/data/system'
import SelectInput, { SelectOptionValue } from '../Inputs/SelectInput'
import { useSelector } from 'react-redux'
import { ReducerUser, Reducers } from '../../types/reducers'
import TextField from '../Inputs/TextField'

interface Props {
    commands?: AvailableCommand[]
    onClose: (commandInfo?: { value: string; command: string }) => void
}

const SendCommandModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedCommandOption, setSelectedCommandOption] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [commandValueOption, setCommandValueOption] = useState(1)
    const [commandValueMap, setCommandValueMap] = useState([])
    const [commandCustomValue, setCommandCustomValue] = useState('')
    const [commandValue, setCommandValue] = useState<SelectOptionValue | null>({
        label: t('fields.commandValuePlaceholder'),
        value: '',
    })
    const [warningMessage, setWarningMessage] = useState('')

    useEffect(() => {
        const command = props.commands.find((i) => i.name === selectedCommandOption.label)

        if (!command) {
            return
        }
        setWarningMessage(command.userMessage)
        if (command.userDefined) {
            setCommandValueOption(2)
        } else if (command.valueMap && command.valueMap !== '') {
            setCommandValueOption(3)
            const splitted = command.valueMap.replaceAll('{', '').replaceAll('}', '').split(',')
            const items = []
            splitted.forEach((i) => {
                const label = i.substring(0, i.indexOf('('))
                const value = i.replaceAll(')', '').slice(i.indexOf('(') + 1)
                items.push({ label, value })
            })
            setCommandValueMap(items)
        } else {
            setCommandValueOption(1)
        }
    }, [selectedCommandOption])

    const sendCommand = () => {
        const command = props.commands.find((i) => i.name === selectedCommandOption.label)
        let value = ''
        if (command.userDefined) {
            value = commandCustomValue
        } else if (command.valueMap && command.valueMap !== '') {
            value = commandValue.value
        } else {
            value = command.value
        }
        props.onClose({ command: command.command, value })
    }

    return (
        <div className="alert-modal-wrapper">
            <div
                className="dark-cover"
                onClick={() => {
                    props.onClose()
                }}
            />
            <div className="modal" style={{ width: '30%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 22,
                        width: '100%',
                        alignItems: 'flex-start',
                    }}
                >
                    <h3>{t('fields.sendNewCommand')}</h3>

                    <div style={{ display: 'flex', gap: 12 }}>
                        <div>
                            <p>{t('fields.commandSelectionPlaceholder')} </p>
                            <SelectInput
                                value={selectedCommandOption}
                                onValueChange={(value) => {
                                    setSelectedCommandOption(value)
                                    setCommandValue({ label: t('fields.commandValuePlaceholder'), value: '' })
                                }}
                                options={props.commands.map((command: AvailableCommand) => {
                                    if (command.requiredGrant === user.idSysGrant) {
                                        return { label: command.name, value: command.command.toString() }
                                    }
                                    return
                                })}
                            />
                        </div>

                        {commandValueOption === 1 ? null : commandValueOption === 3 ? (
                            <div>
                                <p>{t('fields.commandValuePlaceholder')} </p>
                                <SelectInput
                                    value={commandValue}
                                    onValueChange={(value) => setCommandValue(value)}
                                    options={commandValueMap.map((value) => {
                                        return { label: value.label, value: value.value }
                                    })}
                                />
                            </div>
                        ) : (
                            <div>
                                <p>{t('fields.commandValueCustomPlaceholder')} </p>
                                <TextField
                                    label="Value"
                                    value={commandCustomValue}
                                    onChangeText={(value) => setCommandCustomValue(value)}
                                />
                            </div>
                        )}
                    </div>
                    <p style={{ color: 'red' }}>{warningMessage}</p>
                    <button
                        disabled={selectedCommandOption.value === ''}
                        className="standard-confirm-button"
                        onClick={() => {
                            sendCommand()
                        }}
                    >
                        {t('common.send')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SendCommandModal
