import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Network } from '../../types/data/system'
import { Reducers, ReducerUser } from '../../types/reducers'
import Button from '../Inputs/Button'
import TextField from '../Inputs/TextField'
import NetworkUserAssocTable from '../Tables/NetworkUserAssocTable'

interface Props {
    currentNetwork: Network
    viewOnly?: boolean
}

const NetworkUserAssocTab: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [userNameQuery, setUserNameQuery] = useState('')
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: '0 0 0 25px' }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField
                            label={t('searches.searchByName')}
                            value={userNameQuery}
                            onChangeText={(value: string) => setUserNameQuery(value)}
                        />
                    </div>
                    {(user.idSysGrant === 0 ||
                        user.managedNetworks.find((networkId) => networkId === props.currentNetwork.ID)) && (
                        <Button
                            label={t('common.addUserAssociation')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 0 0 25px"
                            icon={faPlus}
                            onPress={() => {
                                setShowModal(true)
                            }}
                        />
                    )}
                </div>
            </div>
            <NetworkUserAssocTable
                currentNetwork={props.currentNetwork}
                searchQuery={userNameQuery}
                showModal={showModal}
                hideModal={() => setShowModal(false)}
            />
        </div>
    )
}

export default NetworkUserAssocTab
