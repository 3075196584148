import React, { useEffect, useMemo, useState } from 'react'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import '../../styles/pages/device.sass'
import UsersActivitiesTable from '../../Components/Tables/UsersActivitiesTable'
import { faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { useTranslation } from 'react-i18next'
import { User } from '../../types/data/user'
import CustomAxios from '../../customComponents/customAxios'
import { ActivityTypes } from '../../utils/enum/UserActivityTypes'
import Datepicker from '../../Components/Datepicker'

interface Props {
    device: any
}

const activities = [
    {
        id: 0,
        label: ActivityTypes.EDIT,
        value: ActivityTypes.EDIT,
    },
    {
        id: 1,
        label: ActivityTypes.CREATE,
        value: ActivityTypes.CREATE,
    },
    {
        id: 2,
        label: ActivityTypes.DELETE,
        value: ActivityTypes.DELETE,
    },
]

const UsersActivitiesSection: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const [filterSelectedUser, setFilterSelectedUser] = useState<SelectOptionValue | null>({
        label: t('fields.user'),
        value: '',
    })
    const [filterSelectedActivity, setFilterSelectedActivity] = useState<SelectOptionValue | null>({
        label: t('fields.activity'),
        value: '',
    })
    const [availableUsers, setAvailableUsers] = useState<User[]>([])
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [toggleReset, setToggleReset] = useState(undefined)
    const [filterParameters, setFilterParameters] = useState<any | null>(
        props.device !== undefined ? { record: props.device.ID, table: 'devices' } : null
    )

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const getFilterParameters = () => {
        const filterParameters: any = {
            record: props.device !== undefined ? props.device.ID : -1,
            table: 'devices',
            from: filterStartDate,
            to: filterEndDate,
            user: filterSelectedUser.value === '' ? -1 : parseInt(filterSelectedUser.value, 10),
            activity: filterSelectedActivity.value,
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const getDetailedFilteredEvents = () => {
        setFilterParameters(getFilterParameters())
    }

    const fetchUsers = async () => {
        try {
            const users = await CustomAxios.get('users').then((response) => response.data.data)
            setAvailableUsers(users)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchUsers()
    }, [])

    const deviceFilterIsSet = useMemo(() => {
        if (filterParameters !== null) {
            const keys = Object.keys(filterParameters)
            return keys.length === 2 && keys[0] === 'record' && keys[1] === 'table'
        }
        return false
    }, [filterParameters])

    const filterParametersChanged = useMemo(() => {
        return (
            filterSelectedUser.value !== '' ||
            filterEndDate !== '' ||
            filterStartDate !== '' ||
            filterSelectedActivity.value !== ''
        )
    }, [filterSelectedUser, filterStartDate, filterEndDate, filterSelectedActivity])

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setFilterSelectedUser({ label: t('fields.user'), value: '' })
        setFilterSelectedActivity({ label: t('fields.activity'), value: '' })
        setToggleReset(!toggleReset)
        setFilterParameters(props.device !== undefined ? { record: props.device.ID, table: 'devices' } : null)
    }

    return (
        <>
            <div className="sectionContent">
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterUsers')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        icon={faSlidersH}
                        onPress={() => {
                            setFilterPanelIsVisible(!filterPanelIsVisible)
                        }}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                </div>
                <div>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.actionName')}</h3>
                                    <div className="input-rows">
                                        <SelectInput
                                            value={filterSelectedUser}
                                            onValueChange={(value) => setFilterSelectedUser(value)}
                                            options={availableUsers.map((user: User) => {
                                                return {
                                                    label: `${user.firstName} ${user.lastName}`,
                                                    value: user.ID.toString(),
                                                }
                                            })}
                                            width={'100%'}
                                        />
                                        <SelectInput
                                            value={filterSelectedActivity}
                                            onValueChange={(value) => setFilterSelectedActivity(value)}
                                            options={activities.map((activity: any) => {
                                                return { label: activity.label, value: activity.value }
                                            })}
                                            width={'100%'}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('calendar.dateInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null || deviceFilterIsSet}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getDetailedFilteredEvents()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <UsersActivitiesTable reloads={toggleReset} filterParameters={filterParameters} />
            </div>
        </>
    )
}

export default UsersActivitiesSection
