import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_NAMES } from '../../utils/routes'
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from '../Inputs/TextInput'
import { statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { COLORS } from '../../styles/constants'
import { ThresholdLevel, ThresholdRule } from '../../types/data/alarm'

interface Props {
    items: any[]
}

const MapDevicesList: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const [items, setItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [query, setQuery] = useState('')

    const orderItemsByStatus = (items) => {
        const maintenanceMode = items.filter((item) => item.options.maintenanceMode)

        const withEvents = items
            .filter(
                (item) =>
                    !maintenanceMode.includes(item) && item.options.openedEvents && item.options.openedEvents.length > 0
            )
            .sort((a, b) => {
                if (a.options.maxAlarmLevel === undefined) {
                    a.options.maxAlarmLevel = -1
                }
                if (b.options.maxAlarmLevel === undefined) {
                    b.options.maxAlarmLevel = -1
                }
                if (a.options.maxAlarmLevel > b.options.maxAlarmLevel) {
                    return -1
                }
                if (a.options.maxAlarmLevel < b.options.maxAlarmLevel) {
                    return 1
                }
                return 0
            })
        const notCommunicating = items.filter(
            (item) => !maintenanceMode.includes(item) && !withEvents.includes(item) && item.options.deviceStatus === 1
        )
        const deviceWithNoConstraint = items.filter(
            (item) => !maintenanceMode.includes(item) && !withEvents.includes(item) && !notCommunicating.includes(item)
        )

        return [...maintenanceMode, ...withEvents, ...deviceWithNoConstraint, ...notCommunicating]
    }

    useEffect(() => {
        const orderedItems = orderItemsByStatus(props.items)
        setItems(orderedItems)
        if (query && query.length > 2) {
            setFilteredItems(
                orderedItems.filter((item) => item.options.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
            )
        } else {
            setFilteredItems(orderedItems)
        }
    }, [props.items])

    useEffect(() => {
        if (query && query.length > 2) {
            filterItems(query)
        } else {
            setFilteredItems(items)
        }
    }, [query])

    const filterItems = (query: string) => {
        const filtered = filteredItems.filter((item) =>
            item.options.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
        setFilteredItems(filtered)
    }

    const getThresholdRule = (rule: ThresholdRule) => {
        switch (rule) {
            case 'higher':
                return t('thresholdRules.higherString')
            case 'not equal':
                return t('thresholdRules.notEqual')
            case 'lower':
            default:
                return t('thresholdRules.lower')
        }
    }

    const getThresholdLevel = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return t('alarmLevels.low')
            case 1:
                return t('alarmLevels.medium')
            case 2:
            default:
                return t('alarmLevels.high')
        }
    }

    const getOpenEventString = (deviceEvent: any): string => {
        if (deviceEvent.eventString) return deviceEvent.eventString
        const rule = deviceEvent.operator || 'higher'
        return t('thresholdEventDefaultMessage.fullPhrase', {
            level: getThresholdLevel(deviceEvent.level),
            variableName: deviceEvent.variable,
            measuredValue: deviceEvent.startValue,
            thresholdLevel: getThresholdRule(rule),
            thresholdValue: deviceEvent.referenceValue,
        })
    }

    return (
        <>
            <TableContainer style={{ width: '40%' }}>
                <div style={{ padding: '2%' }}>
                    <TextInput
                        placeholder={t('searches.searchByDeviceName')}
                        label=""
                        value={query}
                        onChangeText={(newValue: string) => setQuery(newValue)}
                    />
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('fields.status')}</TableCell>
                            <TableCell>{t('fields.device')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredItems.map((row) => {
                            return (
                                <React.Fragment key={`${row.options.deviceId + Math.random()}`}>
                                    <TableRow>
                                        <TableCell>
                                            {row.options.maintenanceMode ? (
                                                <FontAwesomeIcon
                                                    icon={faToolbox}
                                                    color={COLORS.palette.darkBlue}
                                                    style={{ width: '20px', height: '20px', marginLeft: '7px' }}
                                                />
                                            ) : (
                                                <div className="dot-container">
                                                    <div className="dot-tooltip">
                                                        {t(statusLabelForDevice(row.options))}
                                                    </div>
                                                    <span className={statusPinStyleForDevice(row.options)} />
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 16,
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                <a
                                                    className="colorRed tableElement table-link-value"
                                                    href={`/${ROUTE_NAMES.DEVICE}/${row.options.deviceId}`}
                                                >
                                                    {row.options.name}
                                                </a>

                                                {row.options.openedEvents && row.options.openedEvents.length > 0 && (
                                                    <Chip
                                                        style={{ maxWidth: '100%' }}
                                                        variant="default"
                                                        component={() => (
                                                            <span
                                                                style={{
                                                                    backgroundColor: COLORS.palette.grey,
                                                                    color: 'white',
                                                                    padding: 8,
                                                                    borderRadius: 8,
                                                                    fontSize: '.9em',
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                {getOpenEventString(
                                                                    row.options.openedEvents &&
                                                                        row.options.openedEvents.length > 0 &&
                                                                        row.options.openedEvents[0]
                                                                )}
                                                            </span>
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MapDevicesList
