import React, { useCallback, useEffect, useMemo, useState } from 'react'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import '../../styles/pages/device.sass'
import 'leaflet/dist/leaflet.css'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import TextInput from '../../Components/Inputs/TextInput'
import { Device, DeviceType } from '../../types/data/alarm'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { Brand, Model, Network, NetworkGroup } from '../../types/data/system'
import CustomAxios from '../../customComponents/customAxios'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import TextField from '../../Components/Inputs/TextField'
import GeneratedMap from '../../Components/LeafletMap'
import { CommunicationProtocolType } from '../../types/data/common'
import { LatLngTuple } from 'leaflet'
import { editDevice, getCommunicationProtocolTypes, getDevices } from '../../utils/api-constants'
import { deepCopy, statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { ToastError } from '../../utils/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import Loader from '../../Components/StaticComponents/Loader'
import Button from '../../Components/Inputs/Button'
import { faChevronDown, faChevronUp, faCircleQuestion, faMinus, faPlus, faRss } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../../Components/Modals/AlertModal'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RealtimeModal from '../../Components/Modals/RealtimeModal'
import { COLORS } from '../../styles/constants'
import { Typography } from 'antd'

export interface DeviceInfoData {
    brand: number
    model: number
    networkGroup: number
    network: number
    type: number
    deviceInfo: any[]
}

interface DeviceInfoParameter {
    name: string
    type: string
    required: boolean
    maxLength: number
    value?: any
}

interface Props {
    device: any
    groupId?: number
    isPreviewMode: boolean
    checkDataIsCorrect: (flag: boolean) => void
    sendIsLoading: (flag: boolean) => void
    reloadDevice: () => void
    saveDevice: boolean
}

const DeviceInfoSection: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { configurations } = useSelector<Reducers, ReducerData>((state) => state.data)
    const { t } = useTranslation()
    const [device, setDevice] = useState(props.device)
    const [realtimeModalIsVisible, setRealtimeModalIsVisible] = useState(false)
    const [deviceDataToSave, setDeviceDataToSave] = useState<Device>()
    const [deviceNetwork, setDeviceNetwork] = useState<SelectOptionValue | null>({
        label: t('routes.network'),
        value: '',
    })
    const [deviceType, setDeviceType] = useState<SelectOptionValue | null>({ label: t('fields.deviceType'), value: '' })
    const [deviceGroup, setDeviceGroup] = useState<SelectOptionValue | null>({ label: t('fields.group'), value: '' })
    const [deviceBrand, setDeviceBrand] = useState<SelectOptionValue | null>({ label: t('routes.brand'), value: '' })
    const [deviceModel, setDeviceModel] = useState<SelectOptionValue | null>({ label: t('fields.model'), value: '' })
    const [loading, setLoading] = useState(false)
    // const [availableMasterDevices, setAvailableMasterDevices] = useState<Device[]>([])
    const [availableProtocols, setAvailableProtocols] = useState<CommunicationProtocolType[]>([])
    const [availableNetworks, setAvailableNetworks] = useState<Network[]>([])
    const [allNetworkGroups, setAllNetworkGroups] = useState<NetworkGroup[]>([])
    const [availableBrands, setAvailableBrands] = useState<Brand[]>([])
    const [availableDeviceTypes, setAvailableDeviceTypes] = useState<DeviceType[]>([])
    const [allModels, setAllModels] = useState<Model[]>([])
    const [coordinatesFromMap, setCoordinatesFromMap] = useState<LatLngTuple>([0, 0])
    // const [selectedMasterDevice, setSelectedMasterDevice] = useState<SelectOptionValue | null>({
    //     label: t('fields.master') + t('common.optionalSuffix'),
    //     value: ''
    // })
    const [selectedProtocolType, setSelectedProtocolType] = useState<SelectOptionValue | null>({
        label: t('fields.protocol'),
        value: '',
    })
    const [selectedSnmpVersion, setSelectedSnmpVersion] = useState<SelectOptionValue | null>({
        label: t('fields.snmpVersion'),
        value: '',
    })
    const navigate = useNavigate()
    const [parameters, setDeviceParameters] = useState<DeviceInfoParameter[]>([])
    const [isPreviewMode, setIsPreviewMode] = useState(props.isPreviewMode)
    const [collapsedSectionsStatus, setCollapsedSectionsStatus] = useState<boolean[]>([true, true, true])
    const [deviceModelWasChanged, setDeviceModelWasChanged] = useState(false)
    const [newModelAlertIsVisible, setNewModelAlertIsVisible] = useState(false)
    const [deviceManipulationLoaderIsVisible, setDeviceManipulationLoaderIsVisible] = useState(false)
    const [externalLinks, setExternalLinks] = useState<{ title: string; url: string }[]>([])

    const coordinatesRegex = /^(([-+]?)([\d]{1,3})((\.)(\d+))?)$/

    const availableSnmpVersions = [
        {
            label: 'v3',
            value: 'v3',
        },
        {
            label: 'v2c',
            value: 'v2c',
        },
        {
            label: 'v1',
            value: 'v1',
        },
    ]

    useEffect(() => {
        configurations.map((param) => {
            if (param.key === 'gsm_enabled') {
                void getCommunicationProtocols(param.value === 'true')
            }
            return
        })
    }, [configurations])

    const getCommunicationProtocols = async (gsmEnabled: boolean) => {
        try {
            const protocols: CommunicationProtocolType[] = await CustomAxios.get(getCommunicationProtocolTypes()).then(
                (response) => response.data.data
            )
            setAvailableProtocols(gsmEnabled ? protocols : protocols.filter((protocol) => protocol.ID !== 2))
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableNetworks = async () => {
        try {
            const networks: Network[] = await CustomAxios.get('networks').then((response) => response.data.data)
            if (user.idSysGrant !== 0) {
                setAvailableNetworks(
                    networks.filter((networkFromDB) => user.managedNetworks.includes(networkFromDB.ID))
                )
            } else {
                setAvailableNetworks(networks)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableNetworkGroups = async () => {
        try {
            const groups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            setAllNetworkGroups(groups)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableBrands = async () => {
        try {
            const brands: Brand[] = await CustomAxios.get('brands').then((response) => response.data.data)
            setAvailableBrands(brands)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableDeviceTypes = async () => {
        try {
            const deviceTypes: DeviceType[] = await CustomAxios.get('device-types').then(
                (response) => response.data.data
            )
            setAvailableDeviceTypes(deviceTypes)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableModels = async () => {
        try {
            const allModels: Model[] = await CustomAxios.get('device-models').then((response) => response.data.data)
            setAllModels(allModels)
        } catch (error) {
            console.error(error)
        }
    }

    const getGeneralData = async () => {
        try {
            setLoading(true)
            // await getAvailableMasterDevices()
            await getAvailableNetworks()
            await getAvailableNetworkGroups()
            await getAvailableBrands()
            await getAvailableDeviceTypes()
            await getAvailableModels()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    useEffect(() => {
        void getGeneralData()
        if (props.device.ID === 'new-device' || props.device.ID === -1) {
            setCollapsedSectionsStatus([false, false, false])
        }
    }, [])

    useEffect(() => {
        const deviceSnmpVersion = device.snmpVersion !== null ? device.snmpVersion : 'v2c'
        setSelectedSnmpVersion(availableSnmpVersions.find((version) => version.value === deviceSnmpVersion))
        if (device.idCommunicationProtocol !== -1) {
            const currentProtocolType = availableProtocols.find((e) => e.ID === device.idCommunicationProtocol) || {
                name: t('fields.protocol'),
                ID: '',
            }
            setSelectedProtocolType({ label: currentProtocolType.name, value: currentProtocolType.ID.toString() })
        } else {
            if (availableProtocols && availableProtocols.length > 0) {
                setSelectedProtocolType({
                    label: availableProtocols[0].name,
                    value: availableProtocols[0].ID.toString(),
                })
            }
        }
    }, [device, availableProtocols])

    useEffect(() => {
        setExternalLinks(device.externalLinks ? device.externalLinks : [])
    }, [device.externalLinks])

    const loadParametersSchema = (modelParameters: DeviceInfoParameter[] = []) => {
        const modelParams = JSON.parse(JSON.stringify(modelParameters))

        if (device.deviceInfo && device.deviceInfo.length > 0) {
            modelParams.forEach((parameter) => {
                const deviceInfoParam = device.deviceInfo.find((i) => i.name === parameter.name)
                if (deviceInfoParam) {
                    parameter.value = deviceInfoParam.value
                }
            })
        }
        setDeviceParameters(modelParams)
    }

    const loadModelParameters = useCallback(async () => {
        try {
            const parametersToAppend: Model = await CustomAxios.get(`device-models/${deviceModel.value}`).then(
                (response) => response.data
            )
            loadParametersSchema(parametersToAppend.modelSchema.length ? parametersToAppend.modelSchema : [])
        } catch (error) {
            console.error(error)
        }
    }, [deviceModel])

    useEffect(() => {
        if (
            device.ID !== -1 &&
            !loading &&
            deviceModel.value !== '' &&
            parseInt(deviceModel.value, 10) !== device.idDeviceModel
        ) {
            setDeviceModelWasChanged(true)
        }
    }, [deviceModel, device, loading])

    useEffect(() => {
        setDevice(props.device)
    }, [props.device])

    useEffect(() => {
        void loadModelParameters()
    }, [loadModelParameters])

    const checkDeviceValidity = useMemo(() => {
        return device !== undefined && device.idDeviceModel !== -1
    }, [device])

    useEffect(() => {
        setIsPreviewMode(props.isPreviewMode)
    }, [props.isPreviewMode])

    useEffect(() => {
        if (checkDeviceValidity) {
            let selectedDeviceGroup: NetworkGroup = null
            let selectedDeviceBrand: Brand = null
            let selectedDeviceType: DeviceType = null
            let selectedDeviceModel: Model = null
            let selectedDeviceNetwork: Network = null
            if (device.idNetwork !== null) {
                selectedDeviceNetwork = availableNetworks.find((network) => network.ID === device.idNetwork)
                if (selectedDeviceNetwork) {
                    setDeviceNetwork({ label: selectedDeviceNetwork.name, value: selectedDeviceNetwork.ID.toString() })
                }
            }
            if (device.idGroup !== null) {
                selectedDeviceGroup = allNetworkGroups.find(
                    (networkGroup) => networkGroup.id === parseInt(device.idGroup, 10)
                )
                if (selectedDeviceGroup) {
                    setDeviceGroup({ label: selectedDeviceGroup.name, value: selectedDeviceGroup.id.toString() })
                }
            }
            if (device.idDeviceModel !== null) {
                selectedDeviceModel = allModels.find((model) => model.ID === device.idDeviceModel)
                if (selectedDeviceModel) {
                    selectedDeviceBrand = availableBrands.find((brand) => brand.ID === selectedDeviceModel.idBrand)
                    selectedDeviceType = availableDeviceTypes.find(
                        (deviceType) => deviceType.ID === selectedDeviceModel.idDeviceType
                    )
                    if (selectedDeviceBrand && selectedDeviceType) {
                        setDeviceBrand({ label: selectedDeviceBrand.name, value: selectedDeviceBrand.ID.toString() })
                        setDeviceType({ label: selectedDeviceType.name, value: selectedDeviceType.ID.toString() })
                        setDeviceModel({ label: selectedDeviceModel.model, value: selectedDeviceModel.ID.toString() })
                    }
                }
            }
            loadParametersSchema()
        }
    }, [device, availableNetworks, allNetworkGroups, availableBrands, availableDeviceTypes, allModels])

    useEffect(() => {
        if (props.groupId && allNetworkGroups.length > 0) {
            const groupFromProps = allNetworkGroups.find(
                (networkGroup: NetworkGroup) => networkGroup.id === props.groupId
            )
            if (groupFromProps !== undefined) {
                const networkFromProps = availableNetworks.find((network) => network.ID === groupFromProps.idNetwork)
                if (networkFromProps !== undefined) {
                    setDeviceNetwork({ label: networkFromProps.name, value: networkFromProps.ID.toString() })

                    setTimeout(
                        () => setDeviceGroup({ label: groupFromProps.name, value: groupFromProps.id.toString() }),
                        100
                    )
                }
            }
        }
    }, [props.groupId, allNetworkGroups, availableNetworks])

    const connectionTestAvailable = () => {
        return deviceBrand.value !== '' && deviceModel.value !== '' && device.snmpPort !== '' && device.ipAddress !== ''
    }

    const requiredParametersAreCorrect = useMemo(() => {
        if (parameters.length === 0) return true
        return parameters.every((param) => {
            if (param.required) {
                return param.required && param.value !== undefined && param.value !== ''
            }
            return true
        })
    }, [parameters])

    const updateParameterValue = (newVal: string, parameter: DeviceInfoParameter) => {
        const updatedParameters = parameters.map((param) => {
            if (param.name === parameter.name && newVal.length <= param.maxLength) {
                return {
                    ...param,
                    value: newVal,
                }
            }
            return param
        })
        setDeviceParameters(updatedParameters)
    }

    const availableModels = useMemo(() => {
        if (deviceBrand.value !== '') {
            return allModels.filter((model: Model) => model.idBrand.toString() === deviceBrand.value)
        }
        return []
    }, [deviceBrand])

    const availableNetworkGroups = useMemo(() => {
        if (deviceNetwork.value !== '') {
            return allNetworkGroups.filter(
                (networkGroup: NetworkGroup) => networkGroup.idNetwork.toString() === deviceNetwork.value
            )
        }
        return []
    }, [deviceNetwork])

    useEffect(() => {
        if (device.ID !== -1) {
            return
        }
        setDeviceGroup({ label: t('fields.group'), value: '' })
    }, [deviceNetwork])

    useEffect(() => {
        if (device.ID !== -1) {
            return
        }
        setDeviceModel({ label: t('fields.model'), value: '' })
        setDeviceParameters([])
    }, [deviceBrand])

    const setLatitudeValue = (valueToSet: string) => {
        const parsedValue = parseInt(valueToSet, 10)
        if (
            (coordinatesRegex.test(valueToSet) ||
                valueToSet === '' ||
                valueToSet === '-' ||
                (valueToSet.charAt(valueToSet.length - 1) === '.' && valueToSet.split('.').length < 3)) &&
            parsedValue < 84 &&
            parsedValue > -84
        ) {
            setDevice({ ...device, coordinates: { ...device.coordinates, latitude: valueToSet } })
        }
    }

    const setLongitudeValue = (valueToSet: string) => {
        const parsedValue = parseInt(valueToSet, 10)
        if (
            (coordinatesRegex.test(valueToSet) ||
                valueToSet === '' ||
                valueToSet === '-' ||
                (valueToSet.charAt(valueToSet.length - 1) === '.' && valueToSet.split('.').length < 3)) &&
            parsedValue < 180 &&
            parsedValue > -180
        ) {
            setDevice({ ...device, coordinates: { ...device.coordinates, longitude: valueToSet } })
        }
    }

    useEffect(() => {
        if (coordinatesFromMap.every((coordinate) => coordinate !== 0)) {
            setDevice({
                ...device,
                coordinates: {
                    latitude: coordinatesFromMap[0].toString(),
                    longitude: coordinatesFromMap[1].toString(),
                },
            })
        }
    }, [coordinatesFromMap])

    const updateDeviceCoordinates = useCallback(
        (newCoordinates) => {
            if (!isPreviewMode) {
                setCoordinatesFromMap([newCoordinates[0].toString(), newCoordinates[1].toString()])
            }
        },
        [isPreviewMode]
    )

    const handleChangeProtocolType = (newProtocol) => {
        setDevice({
            ...device,
            idCommunicationProtocol: newProtocol.value !== '' ? parseInt(newProtocol.value, 10) : -1,
        })
        setSelectedProtocolType(newProtocol)
    }

    const deviceInfoDataIsCorrect = useMemo(() => {
        return (
            deviceBrand.value !== '' &&
            // deviceGroup.value !== '' &&
            deviceType.value !== '' &&
            deviceModel.value !== '' &&
            deviceNetwork.value !== '' &&
            requiredParametersAreCorrect
        )
    }, [deviceBrand, deviceType, deviceModel, deviceNetwork, requiredParametersAreCorrect])

    const deviceDataIsCorrect = useMemo(() => {
        let communicationIsCorrect = true
        if (device.idCommunicationProtocol === 2) {
            communicationIsCorrect = device.phoneNumber !== '' && device.phoneNumber !== null
        } else {
            communicationIsCorrect = device.ipAddress !== '' && device.snmpPort !== ''
        }
        return (
            device.name !== '' &&
            device.serialNo !== '' &&
            device.idCommunicationProtocol !== -1 &&
            communicationIsCorrect &&
            deviceInfoDataIsCorrect
        )
    }, [
        deviceInfoDataIsCorrect,
        device.idCommunicationProtocol,
        device.phoneNumber,
        device.ipAddress,
        device.snmpPort,
        device.name,
        device.code,
        device.serialNo,
    ])

    useEffect(() => {
        props.checkDataIsCorrect(deviceDataIsCorrect)
    }, [deviceDataIsCorrect])

    useEffect(() => {
        if (props.saveDevice !== undefined) {
            void saveDevice()
        }
    }, [props.saveDevice])

    const duplicateAndDeactivateCurrentDevice = async () => {
        try {
            setDeviceManipulationLoaderIsVisible(true)
            const deviceCopy = deepCopy(deviceDataToSave)
            await CustomAxios.put(editDevice(device.ID), {
                ...deviceDataToSave,
                automaticCheck: 0,
                idGroup: null,
                name: `${deviceDataToSave.name} - [INACTIVE] - ${new Date().toDateString()}`,
                coordinates: deviceDataToSave.coordinates,
            })
            delete deviceCopy.ID
            const newDeviceId = await CustomAxios.post(getDevices(), { ...deviceCopy }).then(
                (response) => response.data.insertId
            )
            navigate(`/${ROUTE_NAMES.DEVICE}/${newDeviceId}`, { state: { isView: true } })
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
        setDeviceManipulationLoaderIsVisible(false)
        props.sendIsLoading(false)
    }

    const overwriteDeviceData = async () => {
        try {
            setDeviceManipulationLoaderIsVisible(true)
            await CustomAxios.put(editDevice(device.ID), { ...deviceDataToSave, eraseMetrics: true })
            navigate(`/${ROUTE_NAMES.DEVICE}/${device.ID}`, { state: { isView: true } })
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
        setDeviceManipulationLoaderIsVisible(false)
        props.sendIsLoading(false)
        props.reloadDevice()
    }

    const saveDevice = async () => {
        const deviceCopy = deepCopy(device)
        try {
            props.sendIsLoading(true)
            delete (deviceCopy as any).deviceModel
            delete (deviceCopy as any).master
            const coordinatesAreValid =
                device.coordinates !== null && device.coordinates.latitude !== '' && device.coordinates.longitude !== ''
            const deviceData = {
                ...deviceCopy,
                idDeviceModel: parseInt(deviceModel.value, 10),
                idGroup: deviceGroup.value ? parseInt(deviceGroup.value, 10) : null,
                idNetwork: parseInt(deviceNetwork.value, 10),
                deviceInfo: parameters.map((param) => (!param.value ? { ...param, value: '' } : param)),
                coordinates: coordinatesAreValid
                    ? {
                          latitude: parseFloat(deviceCopy.coordinates.latitude),
                          longitude: parseFloat(deviceCopy.coordinates.longitude),
                      }
                    : null,
                idCommunicationProtocol:
                    selectedProtocolType.value !== '' ? parseInt(selectedProtocolType.value, 10) : -1,
                snmpPort: parseInt(deviceCopy.snmpPort, 10),
                idEditor: user.ID,
                snmpVersion: selectedSnmpVersion.value,
                externalLinks,
            }
            setDeviceDataToSave(deviceData)
            if (deviceCopy.ID === -1) {
                delete deviceData.ID
                const newDeviceId = await CustomAxios.post(getDevices(), deviceData).then(
                    (response) => response.data.insertId
                )
                navigate(`/${ROUTE_NAMES.DEVICE}/${newDeviceId}`, { state: { isView: true } })
            } else {
                if (deviceModelWasChanged) {
                    setNewModelAlertIsVisible(true)
                    return
                }
                await CustomAxios.put(editDevice(deviceCopy.ID), deviceData)
                navigate(`/${ROUTE_NAMES.DEVICE}/${deviceCopy.ID}`, { state: { isView: true } })
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError' + 'onennenenene'))
        }
        props.sendIsLoading(false)
        props.reloadDevice()
    }

    const getParametersMap = useMemo(() => {
        if (parameters.length === 0) {
            return <p className="empty-device-tree-message">{t('systemMessages.noParamsForDeviceMessage')}</p>
        }
        return (
            <>
                {parameters.map((parameter) => {
                    return (
                        <div key={parameter.name} className="parameter-element">
                            {parameter.name !== '' && (
                                <div className="parameter-element--label">
                                    <span>{parameter.name}</span>
                                    {parameter.required && <label className="required-asterisk">*</label>}
                                </div>
                            )}
                            <TextInput
                                onlyVisible={isPreviewMode}
                                label=""
                                value={parameter.value !== undefined ? parameter.value : ''}
                                onChangeText={(newVal) => updateParameterValue(newVal, parameter)}
                            />
                        </div>
                    )
                })}
            </>
        )
    }, [parameters, isPreviewMode])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="sectionContent no-left-right-padding">
                    {realtimeModalIsVisible && (
                        <RealtimeModal
                            ip={device.ipAddress}
                            port={device.snmpPort}
                            model={deviceModel.value}
                            communityRead={device.snmpCommunityRead}
                            snmpVersion={selectedSnmpVersion.value}
                            basicCheck
                            onClose={() => {
                                setRealtimeModalIsVisible(false)
                            }}
                        />
                    )}
                    {newModelAlertIsVisible && (
                        <AlertModal
                            width="50%"
                            exitButton
                            isLoading={deviceManipulationLoaderIsVisible}
                            confirmLabel={t('fields.overwriteData')}
                            closeLabel={t('fields.duplicateDevice')}
                            alertMessage={t('systemMessages.overwriteDeviceOrCreateNewOne')}
                            onConfirm={() => void overwriteDeviceData()}
                            onClose={() => void duplicateAndDeactivateCurrentDevice()}
                            onExit={() => {
                                setNewModelAlertIsVisible(false)
                                props.sendIsLoading(false)
                            }}
                        />
                    )}
                    <div className="device-main-info-wrapper">
                        <div className="device-main-info-wrapper--column">
                            <div className="device-main-info-wrapper-header">
                                <h3 className="section-title">
                                    {t('fields.deviceAnagraphics')}
                                    {device.ID !== -1 && (
                                        <div className="device-status-container">
                                            <div className={statusPinStyleForDevice(device)} />
                                            <label>{t(statusLabelForDevice(device))}</label>
                                        </div>
                                    )}
                                </h3>
                                <Button
                                    label={collapsedSectionsStatus[0] ? t('common.expand') : t('common.contract')}
                                    backgroundColor="#efefef"
                                    textColor="#3f3f3f"
                                    margin="0"
                                    iconColor="#e10915"
                                    icon={collapsedSectionsStatus[0] ? faChevronDown : faChevronUp}
                                    onPress={() =>
                                        setCollapsedSectionsStatus([
                                            !collapsedSectionsStatus[0],
                                            collapsedSectionsStatus[1],
                                            collapsedSectionsStatus[2],
                                        ])
                                    }
                                />
                            </div>
                            {!collapsedSectionsStatus[0] && (
                                <div className="device-main-info-wrapper--column--section">
                                    <div className="device-main-info-wrapper--column--section__input-group">
                                        <TextInput
                                            required
                                            onlyVisible={isPreviewMode}
                                            label={t('fields.name')}
                                            placeholder={t('fields.name')}
                                            value={device.name}
                                            onChangeText={(value) => setDevice({ ...device, name: value })}
                                        />
                                        <TextInput
                                            required
                                            onlyVisible={isPreviewMode}
                                            label={t('fields.serialNumber')}
                                            placeholder={t('fields.serialNumber')}
                                            value={device.serialNo}
                                            onChangeText={(value) => setDevice({ ...device, serialNo: value })}
                                        />
                                        <TextInput
                                            onlyVisible={isPreviewMode}
                                            label={t('fields.deviceCode')}
                                            placeholder={t('fields.deviceCode')}
                                            value={device.code}
                                            onChangeText={(value) => setDevice({ ...device, code: value })}
                                        />
                                    </div>
                                    <div className="device-main-info-wrapper--column--section__input-group">
                                        <CheckboxInput
                                            disabled={isPreviewMode}
                                            value={device.automaticCheck === 1}
                                            label={t('fields.activateAutomaticChecks')}
                                            onChangeValue={(value) =>
                                                setDevice({ ...device, automaticCheck: value ? 1 : 0 })
                                            }
                                        />
                                        <CheckboxInput
                                            disabled={isPreviewMode}
                                            value={device.maintenanceMode === 1}
                                            label={t('fields.maintenanceActive')}
                                            onChangeValue={(value) =>
                                                setDevice({ ...device, maintenanceMode: value ? 1 : 0 })
                                            }
                                        />
                                        {/* {device.ID === -1 ? (
                                            <SelectInput
                                                disabled={isPreviewMode}
                                                value={selectedMasterDevice}
                                                onValueChange={(value) => setSelectedMasterDevice(value)}
                                                options={[
                                                    { label: t('fields.noMasterDevice'), value: '' },
                                                    ...availableMasterDevices.map((device: Device) => {
                                                        return { label: device.name, value: device.ID.toString() }
                                                    })
                                                ]}
                                                width={'100%'}
                                            />
                                        ) : device.idMaster !== null ? (
                                            <p className="input-group-label">
                                                <b>{t('fields.master')}</b>: {device.master.name}
                                            </p>
                                        ) : null} */}
                                    </div>
                                    <div
                                        style={{
                                            marginTop: 24,
                                            marginBottom: 12,
                                            width: 'auto',
                                            gap: 12,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 'auto',
                                            }}
                                        >
                                            <Typography>{t('fields.externalLinks')}</Typography>
                                            {!isPreviewMode && (
                                                <Button
                                                    label={t('common.add')}
                                                    backgroundColor="#efefef"
                                                    textColor="#3f3f3f"
                                                    iconColor="#e10915"
                                                    margin="0 0 0 8px"
                                                    isDisabled={isPreviewMode}
                                                    icon={faPlus}
                                                    onPress={() => {
                                                        setExternalLinks([...externalLinks, { title: '', url: '' }])
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {externalLinks &&
                                            externalLinks.map((link, index) => (
                                                <div
                                                    style={{ display: 'flex', gap: 12, alignItems: 'flex-end' }}
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`${index}-externalink`}
                                                >
                                                    {isPreviewMode ? (
                                                        <div
                                                            style={{
                                                                padding: '8px 12px',
                                                                backgroundColor: 'white',
                                                                borderRadius: 12,
                                                                border: '1px solid #efefef',
                                                            }}
                                                        >
                                                            <a
                                                                style={{ color: 'blue', textDecoration: 'underline' }}
                                                                href={link.url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {link.title}
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <TextField
                                                                onlyVisible={isPreviewMode}
                                                                width="calc(100% - 50px)"
                                                                labelText={t('fields.externalLinkTitle')}
                                                                label={t('fields.externalLinkTitle')}
                                                                value={link.title}
                                                                onChangeText={(value) => {
                                                                    const externalLinksCopy = [...externalLinks]
                                                                    externalLinksCopy[index].title = value
                                                                    setExternalLinks(externalLinksCopy)
                                                                }}
                                                            />
                                                            <TextField
                                                                onlyVisible={isPreviewMode}
                                                                width="calc(100% - 50px)"
                                                                labelText={t('fields.externalLinkURL')}
                                                                label={t('fields.externalLinkURL')}
                                                                value={link.url}
                                                                onChangeText={(value) => {
                                                                    const externalLinksCopy = [...externalLinks]
                                                                    externalLinksCopy[index].url = value
                                                                    setExternalLinks(externalLinksCopy)
                                                                }}
                                                            />
                                                            <Button
                                                                label={t('common.remove')}
                                                                backgroundColor="#efefef"
                                                                textColor="#3f3f3f"
                                                                iconColor="#e10915"
                                                                margin="8px"
                                                                isDisabled={isPreviewMode}
                                                                icon={faMinus}
                                                                onPress={() => {
                                                                    setExternalLinks(
                                                                        externalLinks.filter((l) => l.url !== link.url)
                                                                    )
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                    <div className="device-main-info-wrapper--column--section__input-group">
                                        <TextField
                                            onlyVisible={isPreviewMode}
                                            isTextarea
                                            width="calc(100% - 32px)"
                                            labelText={t('fields.notes')}
                                            label={t('fields.notes')}
                                            value={device.note}
                                            onChangeText={(value) => setDevice({ ...device, note: value })}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="device-main-info-wrapper--column">
                            <div className="device-main-info-wrapper-header">
                                <h3 className="section-title">
                                    {t('fields.communication')}
                                    <div style={{ display: 'flex', gap: 12, alignItems: 'center', marginLeft: 12 }}>
                                        {selectedProtocolType.value !== '2' && (
                                            <>
                                                {' '}
                                                <Button
                                                    isDisabled={!connectionTestAvailable()}
                                                    label={t('fields.testConnectionButton')}
                                                    backgroundColor="#efefef"
                                                    textColor="#3f3f3f"
                                                    margin="0"
                                                    icon={faRss}
                                                    onPress={() => {
                                                        setRealtimeModalIsVisible(true)
                                                    }}
                                                />
                                                {!connectionTestAvailable() && (
                                                    <Tooltip
                                                        title={
                                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                                {t('fields.testConnectionHint')}
                                                            </div>
                                                        }
                                                        style={{ paddingBottom: 4 }}
                                                    >
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faCircleQuestion}
                                                                color={COLORS.palette.darkGrey}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </h3>
                                <Button
                                    label={collapsedSectionsStatus[1] ? t('common.expand') : t('common.contract')}
                                    backgroundColor="#efefef"
                                    textColor="#3f3f3f"
                                    margin="0"
                                    iconColor="#e10915"
                                    icon={collapsedSectionsStatus[1] ? faChevronDown : faChevronUp}
                                    onPress={() =>
                                        setCollapsedSectionsStatus([
                                            collapsedSectionsStatus[0],
                                            !collapsedSectionsStatus[1],
                                            collapsedSectionsStatus[2],
                                        ])
                                    }
                                />
                            </div>
                            {!collapsedSectionsStatus[1] && (
                                <>
                                    <div className="device-main-info-wrapper--column--section__input-group group-larger-first-element">
                                        <SelectInput
                                            required
                                            label={t('fields.protocol')}
                                            disabled={isPreviewMode}
                                            value={selectedProtocolType}
                                            onValueChange={(value) => handleChangeProtocolType(value)}
                                            options={availableProtocols.map(
                                                (communicationProtocolType: CommunicationProtocolType) => {
                                                    return {
                                                        label: communicationProtocolType.name,
                                                        value: communicationProtocolType.ID.toString(),
                                                    }
                                                }
                                            )}
                                            width={'100%'}
                                        />
                                        {selectedProtocolType.value !== '2' ? (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div className="input-row" style={{ gap: '16px' }}>
                                                    <SelectInput
                                                        label={t('fields.snmpVersion')}
                                                        disabled={isPreviewMode}
                                                        value={selectedSnmpVersion}
                                                        onValueChange={(value) => setSelectedSnmpVersion(value)}
                                                        options={availableSnmpVersions.map((version) => {
                                                            return { label: version.label, value: version.value }
                                                        })}
                                                        width={'100%'}
                                                    />
                                                    <TextInput
                                                        required
                                                        onlyVisible={isPreviewMode}
                                                        label={t('fields.address')}
                                                        smallInput
                                                        width="125px"
                                                        placeholder={t('fields.address')}
                                                        value={device.ipAddress}
                                                        onChangeText={(value) =>
                                                            setDevice({ ...device, ipAddress: value })
                                                        }
                                                    />
                                                    <TextInput
                                                        required
                                                        onlyVisible={isPreviewMode}
                                                        label={t('fields.snmpPort')}
                                                        smallInput
                                                        width="125px"
                                                        placeholder={t('fields.snmpPort')}
                                                        value={device.snmpPort.toString()}
                                                        onChangeText={(value) =>
                                                            setDevice({ ...device, snmpPort: value })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <TextInput
                                                    required
                                                    onlyVisible={isPreviewMode}
                                                    label={t('fields.simNumber')}
                                                    smallInput
                                                    width="125px"
                                                    placeholder={t('fields.simNumber')}
                                                    value={device.phoneNumber}
                                                    onChangeText={(value) =>
                                                        setDevice({ ...device, phoneNumber: value })
                                                    }
                                                />
                                                <div className="empty-input-space" />
                                            </>
                                        )}
                                    </div>
                                    {selectedProtocolType.value !== '2' && (
                                        <>
                                            <div className="snmp-community-grants-container">
                                                <span className="input-group-label">
                                                    {t('fields.snmpCommunityGrant')}
                                                </span>
                                                <TextInput
                                                    onlyVisible={isPreviewMode}
                                                    label={t('fields.read')}
                                                    smallInput
                                                    width="125px"
                                                    placeholder={t('fields.read')}
                                                    value={device.snmpCommunityRead}
                                                    onChangeText={(value) =>
                                                        setDevice({ ...device, snmpCommunityRead: value })
                                                    }
                                                />
                                                <TextInput
                                                    onlyVisible={isPreviewMode}
                                                    label={t('fields.write')}
                                                    smallInput
                                                    width="125px"
                                                    placeholder={t('fields.write')}
                                                    value={device.snmpCommunityWrite}
                                                    onChangeText={(value) =>
                                                        setDevice({ ...device, snmpCommunityWrite: value })
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="device-main-info-wrapper--column">
                            <div className="device-main-info-wrapper-header">
                                <h3 className="section-title">{t('fields.localization')}</h3>
                                <Button
                                    label={collapsedSectionsStatus[2] ? t('common.expand') : t('common.contract')}
                                    backgroundColor="#efefef"
                                    textColor="#3f3f3f"
                                    margin="0"
                                    iconColor="#e10915"
                                    icon={collapsedSectionsStatus[2] ? faChevronDown : faChevronUp}
                                    onPress={() =>
                                        setCollapsedSectionsStatus([
                                            collapsedSectionsStatus[0],
                                            collapsedSectionsStatus[1],
                                            !collapsedSectionsStatus[2],
                                        ])
                                    }
                                />
                            </div>
                            {!collapsedSectionsStatus[2] && (
                                <>
                                    <p>{t('systemMessages.deviceLocationCoordinatesUpdate')}</p>
                                    <div className="device-main-info-wrapper--column--section__input-group row-with-invisible-space">
                                        <TextInput
                                            onlyVisible={isPreviewMode}
                                            smallInput
                                            width="125px"
                                            label={t('fields.latitude')}
                                            placeholder={t('fields.latitude')}
                                            value={device.coordinates.latitude.toString()}
                                            onChangeText={(value) => setLatitudeValue(value)}
                                        />
                                        <TextInput
                                            onlyVisible={isPreviewMode}
                                            smallInput
                                            width="125px"
                                            label={t('fields.longitude')}
                                            placeholder={t('fields.longitude')}
                                            value={device.coordinates.longitude.toString()}
                                            onChangeText={(value) => setLongitudeValue(value)}
                                        />
                                    </div>
                                    <div className="device-small-map">
                                        <GeneratedMap
                                            onDeviceMarkerChangePosition={(newCoordinates) =>
                                                updateDeviceCoordinates(newCoordinates)
                                            }
                                            mapEvents={[]}
                                            deviceMarker={[
                                                !Number.isNaN(parseFloat(device.coordinates.latitude))
                                                    ? parseFloat(device.coordinates.latitude)
                                                    : 0,
                                                !Number.isNaN(parseFloat(device.coordinates.longitude))
                                                    ? parseFloat(device.coordinates.longitude)
                                                    : 0,
                                            ]}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="device-main-info-wrapper">
                        <div className="localization-section device-main-info-wrapper--column">
                            <h3 className="section-title">{t('fields.deviceInfo')}</h3>
                            <div className="input-select-row">
                                <div className="input-select-row--subsection">
                                    {/* {device !== undefined && device.ID !== -1 && device.idDeviceModel !== null ? (
                                        <>
                                            <div className="form-medium-label">
                                                {deviceType.label} - {deviceBrand.label}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                                <span className="form-medium-label">
                                                    {t('fields.model')}: {deviceModel.label}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <> */}
                                    <SelectInput
                                        required
                                        styles={['device-page-select']}
                                        label={t('fields.deviceType')}
                                        value={deviceType}
                                        onValueChange={(value) => setDeviceType(value)}
                                        disabled={isPreviewMode}
                                        options={availableDeviceTypes.map((deviceType: DeviceType) => {
                                            return { label: deviceType.name, value: deviceType.ID.toString() }
                                        })}
                                        width={'100%'}
                                    />
                                    <SelectInput
                                        required
                                        styles={['device-page-select']}
                                        label={t('routes.brand')}
                                        value={deviceBrand}
                                        onValueChange={(value) => {
                                            setDeviceBrand(value)
                                            setDeviceModel({ label: t('fields.model'), value: '' })
                                        }}
                                        disabled={isPreviewMode}
                                        options={availableBrands
                                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                                            .map((brand: Brand) => {
                                                return { label: brand.name, value: brand.ID.toString() }
                                            })}
                                        width={'100%'}
                                    />
                                    <SelectInput
                                        required
                                        styles={['device-page-select']}
                                        label={t('fields.model')}
                                        disabled={deviceBrand.value === '' || deviceType.value === '' || isPreviewMode}
                                        value={deviceModel}
                                        onValueChange={(value) => setDeviceModel(value)}
                                        options={availableModels
                                            .sort((a, b) => (a.model > b.model ? 1 : -1))
                                            .map((model: Model) => {
                                                return { label: model.model, value: model.ID.toString() }
                                            })}
                                        width={'100%'}
                                    />

                                    {/* </>
                                    )} */}
                                </div>
                                <div className="input-select-row--subsection">
                                    {/* {device !== undefined && device.ID !== -1 ? (
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                            <span className="form-medium-label">
                                                {t('routes.network')}: {deviceNetwork.label}
                                            </span>
                                            <span className="form-medium-label">
                                                {t('fields.group')}: {deviceGroup.label}
                                            </span>
                                        </div>
                                    ) : (
                                        <> */}
                                    <SelectInput
                                        required
                                        styles={['device-page-select']}
                                        label={t('routes.network')}
                                        disabled={isPreviewMode}
                                        value={deviceNetwork}
                                        onValueChange={(value) => {
                                            setDeviceNetwork(value)
                                        }}
                                        options={availableNetworks.map((network: Network) => {
                                            return { label: network.name, value: network.ID.toString() }
                                        })}
                                        width={'100%'}
                                    />
                                    <SelectInput
                                        styles={['device-page-select']}
                                        label={t('fields.group')}
                                        disabled={deviceNetwork.value === '' || isPreviewMode}
                                        value={deviceGroup}
                                        onValueChange={(value) => setDeviceGroup(value)}
                                        options={availableNetworkGroups.map((networkGroup: NetworkGroup) => {
                                            return { label: networkGroup.name, value: networkGroup.id.toString() }
                                        })}
                                        width={'100%'}
                                    />
                                    {/* </>
                                    )} */}
                                </div>
                            </div>
                            {parameters.length > 0 && (
                                <>
                                    <h3 className="section-title">{t('fields.parameters')}</h3>
                                    <div className="dynamic-parameters-section">{getParametersMap}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DeviceInfoSection
