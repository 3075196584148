import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from './common'

export const usePageApi = (apiToCall: () => any, timerToUpdate?: number, isThunkAction = true): [number, Date] => {
    const [timer, setTimer] = useState<number>(timerToUpdate)
    const [lastUpdate, setLastUpdate] = useState<Date>(new Date())

    const dispatch = useDispatch()

    useEffect(() => {
        if (isThunkAction) {
            dispatch(apiToCall())
        } else {
            apiToCall()
        }
        return () => setTimer(timerToUpdate)
    }, [])

    useInterval(() => {
        if (!timerToUpdate) return
        if (timer === 1) {
            if (isThunkAction) {
                dispatch(apiToCall())
            } else {
                apiToCall()
            }
            setTimer(timerToUpdate)
            setLastUpdate(new Date())
        } else {
            setTimer(timer - 1)
        }
    }, 1000)

    return [timer, lastUpdate]
}
