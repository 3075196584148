import React from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'
import Loader from '../StaticComponents/Loader'

interface Props {
    alertMessage: string
    closeLabel?: string
    confirmLabel?: string
    exitLabel?: string
    singleButton?: boolean
    isLoading?: boolean
    width?: string
    exitButton?: boolean
    onClose: () => void
    onConfirm: () => void
    onExit?: () => void
}

const AlertModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.cancel')
    const defaultConfirmLabel = t('common.confirm')

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div style={{ maxWidth: props.width || '100%' }} className="modal alert-modal">
                {props.isLoading ? (
                    <div style={{ width: '300px', maxHeight: '300px', display: 'flex', alignItems: 'center' }}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <p className="modal--message">{props.alertMessage}</p>
                        <div className="modal--actions-container">
                            {!props.singleButton ? (
                                <>
                                    <button className="confirm-action" onClick={() => props.onConfirm()}>
                                        {props.confirmLabel ? props.confirmLabel : defaultConfirmLabel}
                                    </button>
                                    <button className="close-action" onClick={() => props.onClose()}>
                                        {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                                    </button>
                                </>
                            ) : (
                                <button className="confirm-action" onClick={() => props.onConfirm()}>
                                    {props.confirmLabel ? props.confirmLabel : defaultConfirmLabel}
                                </button>
                            )}
                            {props.exitButton && (
                                <button className="close-action" onClick={() => (props.onExit ? props.onExit() : null)}>
                                    {props.exitLabel ? props.exitLabel : defaultCloseLabel}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AlertModal
