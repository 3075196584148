import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Network } from '../../types/data/system'
import { Reducers, ReducerUser } from '../../types/reducers'
import { ROUTE_NAMES } from '../../utils/routes'
import Button from '../Inputs/Button'
import TextField from '../Inputs/TextField'
import NetworkGroupAssocTable from '../Tables/NetworkGroupAssocTable'

interface Props {
    currentNetwork: Network
    viewOnly?: boolean
}

const NetworkGroupAssocTab: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [networkGroupName, setNetworkGroupName] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: '0 0 0 25px' }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField
                            label={t('searches.searchByName')}
                            value={networkGroupName}
                            onChangeText={(value: string) => setNetworkGroupName(value)}
                        />
                    </div>
                    {(user.idSysGrant === 0 ||
                        user.managedNetworks.find((networkId) => networkId === props.currentNetwork.ID)) && (
                        <Button
                            label={t('common.addGroupAssociation')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 0 0 25px"
                            icon={faPlus}
                            onPress={() => {
                                navigate(`/${ROUTE_NAMES.GROUPS}/new-group`, {
                                    state: {
                                        group: {
                                            id: -1,
                                            name: '',
                                            description: '',
                                            logo: '',
                                            idNetwork: props.currentNetwork.ID,
                                        },
                                    },
                                })
                            }}
                        />
                    )}
                </div>
            </div>
            <NetworkGroupAssocTable currentNetwork={props.currentNetwork} searchQuery={networkGroupName} />
        </div>
    )
}

export default NetworkGroupAssocTab
