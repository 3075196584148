import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { usePrevious } from '../../utils/hooks/common'
import { Network, NetworkGroup } from '../../types/data/system'
import { Reducers, ReducerUser } from '../../types/reducers'
import LogoDefault from '../../resources/media/images/whitelabel/login-header.png'
import CustomAxiosImages from '../../customComponents/customAxiosImages'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { config } from '../../config/config'
import { COLORS } from '../../styles/constants'

interface Props {
    currentNetwork: Network
    searchQuery: string
    viewOnly?: boolean
}

const NetworkGroupAssocTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedGroup, setSelectedGroup] = useState<NetworkGroup | null>(null)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const previousQuery = usePrevious(props.searchQuery)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const removeHandler = async (group: NetworkGroup) => {
        try {
            await CustomAxios.delete(`network-groups/${group.id}`)
            if (group.logo) {
                const data = new FormData()
                data.append('table', 'groups')
                data.append('object_id', `${group.id.toString()}`)
                await CustomAxiosImages.delete('images', {
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
                    data,
                })
            }
            resetData(currentPage)
            setSelectedGroup(null)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData()
        }
    }, [props.searchQuery])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const baseUrl = config.api.baseURL
        const columns = [
            {
                title: '',
                field: 'logo',
                render: (rowData: NetworkGroup) => (
                    <div className="table-element-logo-container">
                        <img
                            src={
                                rowData.logo && typeof rowData.logo === 'string'
                                    ? `${baseUrl}/${rowData.logo}`
                                    : LogoDefault
                            }
                            alt={rowData.name}
                        />
                    </div>
                ),
            },
            {
                title: t('fields.name'),
                field: 'name',
                render: (rowData: NetworkGroup) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() => {
                            navigate(`/${ROUTE_NAMES.GROUPS}/${rowData.id}`, { state: { isView: true } })
                        }}
                    >
                        {rowData.name}
                    </span>
                ),
            },
            {
                title: t('fields.description'),
                field: 'description',
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        if (props.viewOnly) {
            return
        }
        const actions = [
            (rowData: NetworkGroup) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    navigate(`/${ROUTE_NAMES.GROUPS}/${rowData.id}`, { state: { group: rowData } })
                },
                tooltip: t('common.edit'),
            }),
            (rowData: NetworkGroup) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => setSelectedGroup(rowData),
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [props.viewOnly])

    return (
        <>
            {selectedGroup ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.groupDeleteConfirmMessage', { group: selectedGroup.name })}
                    onClose={() => setSelectedGroup(null)}
                    onDelete={() => void removeHandler(selectedGroup)}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'network-groups'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += `&network=${props.currentNetwork.ID}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&query=${props.searchQuery}`
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default NetworkGroupAssocTable
