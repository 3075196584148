import { Skeleton } from '@material-ui/lab'
import React from 'react'

export const SkeletonBoxItem: React.FC = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton variant="text" width={210} />
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton variant="rect" width={210} height={60} />
            <Skeleton variant="rect" width={210} height={60} />
        </div>
    )
}
export const SkeletonRowsItem: React.FC = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton width={500} />
            <Skeleton width={500} animation="wave" />
            <Skeleton width={500} animation={false} />
        </div>
    )
}
const SkeletonTemplate: React.FC = () => {
    return (
        <>
            {Array.from(new Array(2)).map((item) => (
                <>
                    <div key={item} style={{ display: 'flex', justifyContent: 'center', gap: '2%', marginTop: '2%' }}>
                        <SkeletonBoxItem />
                        <SkeletonBoxItem />
                        <SkeletonBoxItem />
                    </div>
                    <div
                        key={item}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '2%',
                            marginTop: '2%',
                        }}
                    >
                        <SkeletonRowsItem />
                    </div>
                </>
            ))}
        </>
    )
}

export default SkeletonTemplate
