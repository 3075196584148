import React, { useState } from 'react'
import Button from '../../Components/Inputs/Button'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import TextInput from '../../Components/Inputs/TextInput'
import DateInput from '../../Components/Inputs/DateInput'
import Footer from '../../Components/StaticComponents/Footer'
import '../../styles/pages/main.sass'
import '../../styles/pages/remotecontrol.sass'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import { faLongArrowAltRight, faMinusCircle, faRedo } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../types/reducers'
import { useTranslation } from 'react-i18next'

const PowerHistoryPage: React.FC = () => {
    const [televisionBroadcaster, setTelevisionBroadcaster] = useState<SelectOptionValue | null>({
        label: 'Tutti',
        value: 'all',
    })
    const [region, setRegion] = useState<SelectOptionValue | null>({ label: 'Tutti', value: 'all' })
    const [remoteControlCode, setRemoteControlCode] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [fromDate, setFromDate] = useState<string>('')
    const [toDate, setToDate] = useState<string>('')
    const [powerType, setPowerType] = useState<SelectOptionValue | null>({ label: 'Potenza diretta', value: 'direct' })

    const { t } = useTranslation()

    const data = useSelector<Reducers, ReducerData>((state) => state.data)

    return (
        <PageContainer>
            <div className="headerSection">Storico potenze</div>
            <div className="body">
                <div className="sendRemoteColumns">
                    <div className="sendRemoteColumnsMainContainer" style={{ borderRightWidth: 0 }}>
                        <SelectInput
                            label="Emittente"
                            value={televisionBroadcaster}
                            onValueChange={(value) => setTelevisionBroadcaster(value)}
                            options={[
                                { label: 'Tutti', value: 'all' },
                                { label: 'Brodcast Electronics', value: 'be' },
                            ]}
                            width={'100%'}
                        />
                        <SelectInput
                            label="Regioni"
                            value={region}
                            onValueChange={(value) => setRegion(value)}
                            options={[
                                { label: 'Tutti', value: 'all' },
                                ...data.regions.map((region) => {
                                    return {
                                        label: region.Descrizione,
                                        value: region.ID,
                                    }
                                }),
                            ]}
                        />
                        <TextInput
                            label="Codice Telecontrollo*"
                            value={remoteControlCode}
                            onChangeText={(value: string) => setRemoteControlCode(value)}
                            withButton
                            button={
                                <Button
                                    label={t('common.update')}
                                    backgroundColor="#e10915"
                                    textColor="#fff"
                                    icon={faRedo}
                                    iconColor="#fff"
                                    onPress={null}
                                />
                            }
                        />
                        <TextInput label="Nome*" value={name} onChangeText={(value: string) => setName(value)} />
                        <DateInput label="Dal" value={fromDate} onChangeText={(value: string) => setFromDate(value)} />
                        <DateInput label="Al" value={toDate} onChangeText={(value: string) => setToDate(value)} />
                        <SelectInput
                            label="Tipologia potenza"
                            value={powerType}
                            onValueChange={(value) => setPowerType(value)}
                            options={[
                                { label: 'Potenza diretta', value: 'direct' },
                                { label: 'Potenza rifless', value: 'reflect' },
                            ]}
                        />
                        <div style={{ padding: '20px 0' }}>
                            <Button
                                label="Cerca"
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faLongArrowAltRight}
                                iconColor="#fff"
                                onPress={null}
                                margin="0px 10px 0px 0px"
                            />
                            <Button
                                label="Annulla"
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                icon={faMinusCircle}
                                onPress={null}
                            />
                        </div>
                    </div>
                    <div className="sendRemoteSecondContainer" />
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default PowerHistoryPage
