import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'

interface MenuItem {
    name: string
    slug: string
}

interface Props {
    title: string
    isCollapsed: boolean
    onClick?: () => void
    menuItems: MenuItem[]
}

const Collapsible: React.FC<Props> = (props) => {
    const singleItem = (name: string, menuItems: MenuItem[]) => {
        return (
            <li className="nav-item">
                <NavLink
                    to={menuItems[0].slug}
                    className={({ isActive }) => `main navigateLink${isActive ? ' active' : ''}`}
                >
                    <span className="item">{name}</span>
                </NavLink>
            </li>
        )
    }
    const multiItems = (name: string, menuItems: MenuItem[]) => {
        return (
            <li className={props.isCollapsed ? 'nav-item collapsed' : 'nav-item'}>
                <a className="main" onClick={() => props.onClick()}>
                    <span className="item">{name}</span>
                    <FontAwesomeIcon
                        icon={faPlus}
                        style={{ fontSize: 12, transform: props.isCollapsed ? 'rotate(0deg)' : 'rotate(45deg)' }}
                    />
                </a>
                <ul className={props.isCollapsed ? 'submenu hide' : 'submenu show'}>
                    {menuItems.map((item) => {
                        return (
                            <NavLink
                                key={`m${item.name}`}
                                to={item.slug}
                                className={({ isActive }) => {
                                    return `navigateLink${isActive ? ' active' : ''}`
                                }}
                                end
                            >
                                <span className="menu-item-text">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
            </li>
        )
    }

    if (props.menuItems.length > 1) {
        return multiItems(props.title, props.menuItems)
    }
    return singleItem(props.title, props.menuItems)
}

export default Collapsible
