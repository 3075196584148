import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import '../../styles/pages/main.sass'
import { Reducers, ReducerUser } from '../../types/reducers'
import '../../styles/pages/alarms.sass'
import { faRedo, faSlidersH, faTerminal } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { AvailableCommand, Model } from '../../types/data/system'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { User } from '../../types/data/user'
import CommandsHistoryTable from '../../Components/Tables/CommandsHistoryTable'
import CustomAxios from '../../customComponents/customAxios'
import Datepicker from '../../Components/Datepicker'
import { ToastError, ToastSuccess } from '../../utils/toast'
import SendCommandModal from '../../Components/Modals/SendCommandModal'
import Loader from '../../Components/StaticComponents/Loader'

interface Props {
    device: any
}

const DeviceCommandsHistory: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()

    const [schedulerCreator, setSchedulerCreator] = useState<SelectOptionValue | null>({
        label: t('fields.user'),
        value: '',
    })
    const [commandModalOpen, setCommandModalOpen] = useState(false)
    const [selectedCommand, setSelectedCommand] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [availableCommands, setAvailableCommands] = useState<AvailableCommand[]>([])
    const [allModels, setAllModels] = useState<Model[]>([])
    const [allUsers, setAllUsers] = useState<User[]>([])
    const [loading, setLoading] = useState(false)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [toggleReset, setToggleReset] = useState(undefined)
    const [filterParameters, setFilterParameters] = useState<any | null>(
        props.device !== undefined && props.device !== null ? { id_device: props.device.ID } : null
    )

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setSchedulerCreator({ label: t('fields.user'), value: '' })
        setSelectedCommand({ label: t('fields.command'), value: '' })
        setToggleReset(!toggleReset)
        setFilterParameters(props.device !== undefined && props.device !== null ? { id_device: props.device.ID } : null)
    }

    const getFilterParameters = () => {
        const filterParameters: any = {
            id_device: props.device !== undefined && props.device !== null ? props.device.ID : -1,
            from: filterStartDate,
            to: filterEndDate,
            user: schedulerCreator.value === '' ? -1 : parseInt(schedulerCreator.value, 10),
            command: selectedCommand.value === '' ? -1 : parseInt(selectedCommand.value, 10),
        }

        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (attributeValue === '' || attributeValue === null || attributeValue === -1) {
                delete filterParameters[key]
            }
        })

        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const getFilteredCommands = () => {
        setFilterParameters(getFilterParameters())
    }

    const deviceFilterIsSet = useMemo(() => {
        if (filterParameters !== null) {
            const keys = Object.keys(filterParameters)
            return keys.length === 1 && keys[0] === 'id_device'
        }
        return false
    }, [filterParameters])

    const filterParametersChanged = useMemo(() => {
        return (
            selectedCommand.value !== '' ||
            filterEndDate !== '' ||
            filterStartDate !== '' ||
            schedulerCreator.value !== ''
        )
    }, [selectedCommand, filterStartDate, filterEndDate, schedulerCreator])

    const getAvailableModels = async () => {
        try {
            const models: Model[] = await CustomAxios.get('device-models').then((response) => response.data.data)
            setAllModels(models)
        } catch (error) {
            console.error(error)
        }
    }

    const getDetailedModel = async (modelId: number) => {
        try {
            const model: Model = await CustomAxios.get(`device-models/${modelId}`).then((response) => response.data)
            setAvailableCommands(
                model.availableCommands.filter((command: AvailableCommand) => user.idSysGrant >= command.requiredGrant)
            )
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableUsers = async () => {
        try {
            const users: User[] = await CustomAxios.get('users').then((response) => response.data.data)
            setAllUsers(users)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getAvailableModels()
        void getAvailableUsers()
        getFilteredCommands()
    }, [])

    useEffect(() => {
        if (props.device !== undefined && props.device !== null && allModels.length > 0) {
            const deviceModel = allModels.find((model: Model) => model.ID === props.device.idDeviceModel)
            if (deviceModel === undefined) return
            void getDetailedModel(deviceModel.ID)
        }
    }, [props.device, allModels])

    const sendNewCommandToDevice = async (commandInfo: { command: string; value: string }) => {
        try {
            setLoading(true)
            const commandData = {
                idDevice: props.device.ID,
                command: commandInfo.command,
                value: commandInfo.value ?? ' ',
            }

            await CustomAxios.post('commands/snmp', commandData)
            setToggleReset(!toggleReset)
            ToastSuccess(t('actionsMessages.successSendCommandToDevice'))
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
        setLoading(false)
    }

    return (
        <>
            <div className="sectionContent">
                {loading && <Loader />}
                {commandModalOpen && (
                    <SendCommandModal
                        commands={availableCommands}
                        onClose={(command) => {
                            if (command) {
                                void sendNewCommandToDevice(command)
                            }
                            setCommandModalOpen(false)
                        }}
                    />
                )}

                <div className="header-right-actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        label={t('fields.sendNewCommand')}
                        backgroundColor="#e10915"
                        textColor="#ffffff"
                        margin="0 25px 0 0"
                        iconColor="#ffffff"
                        icon={faTerminal}
                        onPress={() => {
                            setCommandModalOpen(true)
                        }}
                    />
                    <div>
                        <Button
                            label={t('fields.filterEvents')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin="0 25px 0 0"
                            icon={faSlidersH}
                            borderColor={filterPanelIsVisible ? '#212121' : undefined}
                            onPress={() => {
                                setFilterPanelIsVisible(!filterPanelIsVisible)
                            }}
                        />
                        <Button
                            label={t('common.update')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin="0"
                            icon={faRedo}
                            onPress={() => setToggleReset(!toggleReset)}
                        />
                    </div>
                </div>
                <div>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.commandInfo')}</h3>
                                    <div className="input-rows">
                                        <SelectInput
                                            value={schedulerCreator}
                                            onValueChange={(value) => setSchedulerCreator(value)}
                                            options={allUsers.map((user: User) => {
                                                return {
                                                    label: `${user.firstName} ${user.lastName}`,
                                                    value: user.ID.toString(),
                                                }
                                            })}
                                            width={'100%'}
                                        />
                                        <SelectInput
                                            value={selectedCommand}
                                            onValueChange={(value) => setSelectedCommand(value)}
                                            options={availableCommands.map((command: AvailableCommand) => {
                                                if (command.requiredGrant === user.idSysGrant) {
                                                    return { label: command.name, value: command.command.toString() }
                                                }
                                                return
                                            })}
                                            width={'100%'}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('fields.searchInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null || deviceFilterIsSet}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getFilteredCommands()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <CommandsHistoryTable filterParams={filterParameters} reloads={toggleReset} />
            </div>
        </>
    )
}

export default DeviceCommandsHistory
