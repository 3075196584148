import React, { useEffect, useMemo, useRef, useState } from 'react'
import PageContainer from '../../../../Components/StaticComponents/PageContainer'
import Footer from '../../../../Components/StaticComponents/Footer'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../../Components/Inputs/Button'
import CustomAxios from '../../../../customComponents/customAxios'
import TextField from '../../../../Components/Inputs/TextField'
import PageHeaderCustom from '../../../../customComponents/PageHeaderCustom'
import { useTranslation } from 'react-i18next'
import { ROUTE_NAMES } from '../../../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { ReducerData, Reducers, ReducerUser } from '../../../../types/reducers'
import { useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'
import UploadButton from '../../../../Components/Inputs/UploadButton'
import LogoDefault from '../../../../resources/media/images/whitelabel/login-header.png'
import CustomAxiosImages from '../../../../customComponents/customAxiosImages'
import { config } from '../../../../config/config'
import { setConfigurations } from '../../../../store/actions/data'
import { useDispatch } from 'react-redux'
import '../../../../styles/components/staticComponents.sass'
import { isValidString } from '../../../../utils/functions'
import CheckboxInput from '../../../../Components/Inputs/CheckboxInput'

interface MailServerParameters {
    host: string
    port: string
    username: string
    password: string
    from: string
}

interface GSMParameters {
    pollingInterval: number
    host: string
    port: string
}
interface GeniusParameters {
    key: string
}
interface AspectParameters {
    logo: string | null | File
    title: string
}

interface EraserParameters {
    status: boolean
    days: number
}

const defaultMailServerConfig: MailServerParameters = {
    host: '',
    port: '',
    username: '',
    password: '',
    from: '',
}

const defaultGSMConfig: GSMParameters = {
    host: '',
    port: '',
    pollingInterval: 30,
}
const defaultGeniusConfig: GeniusParameters = {
    key: '',
}

const defaultAspectParameters: AspectParameters = {
    logo: '',
    title: 'TX Control',
}

const defaultEraserParameters: EraserParameters = {
    status: false,
    days: -1,
}

const ConfigurationPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { configurations } = useSelector<Reducers, ReducerData>((state) => state.data)
    const [aspectParams, setAspectParams] = useState<AspectParameters>(defaultAspectParameters)
    const [mailServerParams, setMailServerParams] = useState<MailServerParameters>(null)
    const [GSMParams, setGSMParams] = useState<GSMParameters>(null)
    const [GSMEnabled, setGSMEnabled] = useState(false)
    const [GeniusEnabled, setGeniusEnabled] = useState(false)
    const [GeniusParams, setGeniusParams] = useState<GeniusParameters>(defaultGeniusConfig)
    const [EraserParams, setEraserParams] = useState<EraserParameters>(defaultEraserParameters)
    const [isPreviewMode, setIsPreviewMode] = useState(true)
    const logoImg = useRef(null)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleNewUpload = (file: File) => {
        const fr = new FileReader()
        fr.onload = function () {
            logoImg.current.src = fr.result
        }
        fr.readAsDataURL(file)
    }

    useEffect(() => {
        const remoteAspectParams: AspectParameters = defaultAspectParameters
        const remoteMailServerParams: MailServerParameters = defaultMailServerConfig
        const remoteGSMParams: GSMParameters = defaultGSMConfig
        const remoteGeniusParams: GeniusParameters = defaultGeniusConfig
        const remoteEraserParams: EraserParameters = defaultEraserParameters

        configurations.map((param) => {
            if (param.key === 'gsm_enabled') {
                setGSMEnabled(param.value === 'true')
            }

            if (param.key === 'genius_enabled') {
                setGeniusEnabled(param.value === 'true')
            }

            if (param.key === 'service_measures_eraser_status') {
                remoteEraserParams.status = param.value === 'true'
            }
            if (param.key === 'service_measures_eraser_days') {
                remoteEraserParams.days = parseInt(param.value, 10)
            }

            const [group, key] = param.key.split('.')
            if (!key) return
            const formattedKey = key.replace(/(_\w)|(-\w)/g, (k) => k[1].toUpperCase())

            if (group === 'mail') {
                if (formattedKey === 'host') {
                    const [host, port] = param.value.split(':')
                    remoteMailServerParams.host = host
                    remoteMailServerParams.port = port
                }
                if (formattedKey === 'from') {
                    remoteMailServerParams.from = param.value
                }
                if (formattedKey === 'username') {
                    remoteMailServerParams.username = param.value
                }
                if (formattedKey === 'password') {
                    remoteMailServerParams.password = param.value
                }
            } else if (group === 'sms') {
                if (formattedKey === 'serverIp') {
                    remoteGSMParams.host = param.value
                }
                if (formattedKey === 'serverPort') {
                    remoteGSMParams.port = param.value
                }
                if (formattedKey === 'pollingInterval') {
                    remoteGSMParams.pollingInterval = parseInt(param.value, 10)
                }
            } else if (group === 'aspect') {
                if (formattedKey === 'logo') {
                    remoteAspectParams.logo = param.value
                }
                if (formattedKey === 'title') {
                    remoteAspectParams.title = param.value
                }
            } else if (group === 'genius') {
                if (formattedKey === 'key') {
                    remoteGeniusParams.key = param.value
                }
            }
            return null
        })

        setMailServerParams(remoteMailServerParams)
        setGSMParams(remoteGSMParams)
        setAspectParams(remoteAspectParams)
        setEraserParams(remoteEraserParams)
    }, [configurations])

    const saveConfigurations = async () => {
        try {
            // Mail Server Params
            const mailServerParamsData = [
                {
                    key: 'host',
                    value: `${mailServerParams.host}:${mailServerParams.port}`,
                },
                {
                    key: 'from',
                    value: `${mailServerParams.from}`,
                },
                {
                    key: 'username',
                    value: `${mailServerParams.username}`,
                },
            ]

            if (
                mailServerParams.password !== undefined &&
                mailServerParams.password !== null &&
                mailServerParams.password.length > 3
            ) {
                mailServerParamsData.push({
                    key: 'password',
                    value: mailServerParams.password ? `${mailServerParams.password}` : '',
                })
            }

            await Promise.all(
                mailServerParamsData.map(async (paramToSend) => {
                    await CustomAxios.put(`configurations/mail.${paramToSend.key}`, { value: paramToSend.value })
                })
            )

            // GSM Server Params
            const GSMParamsData = [
                {
                    key: 'server_ip',
                    value: `${GSMParams.host}`,
                },
                {
                    key: 'server_port',
                    value: `${GSMParams.port}`,
                },
                {
                    key: 'polling_interval',
                    value: GSMParams.pollingInterval ?? 30,
                },
            ]
            await Promise.all(
                GSMParamsData.map(async (paramToSend) => {
                    await CustomAxios.put(`configurations/sms.${paramToSend.key}`, { value: paramToSend.value })
                })
            )

            const logo = await uploadImage()
            const AspectParamsData = [
                {
                    key: 'logo',
                    value: logo !== null ? logo.toString().replace(config.api.baseURL, '') : '',
                },
                {
                    key: 'title',
                    value: `${aspectParams.title}`,
                },
            ]

            await Promise.all(
                AspectParamsData.map(async (paramToSend) => {
                    await CustomAxios.put(`configurations/aspect.${paramToSend.key}`, { value: paramToSend.value })
                })
            )

            // Erasers Params
            const EraserParamsData = [
                {
                    key: 'status',
                    value: `${EraserParams.status}`,
                },
                {
                    key: 'days',
                    value: `${EraserParams.days}`,
                },
            ]
            await Promise.all(
                EraserParamsData.map(async (paramToSend) => {
                    await CustomAxios.put(`configurations/service_measures_eraser_${paramToSend.key}`, {
                        value: paramToSend.value,
                    })
                })
            )

            // Genius Params
            const GeniusParamsData = [
                {
                    key: 'key',
                    value: `${GeniusParams.key}`,
                },
            ]
            await Promise.all(
                GeniusParamsData.map(async (paramToSend) => {
                    await CustomAxios.put(`configurations/genius.${paramToSend.key}`, { value: paramToSend.value })
                })
            )

            setIsPreviewMode(true)
            const configurations = await CustomAxios.get('configurations').then((response) => response.data.data)
            dispatch(setConfigurations(configurations))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (user.idSysGrant !== 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
    }, [])

    const uploadImage = async () => {
        if (aspectParams.logo instanceof File) {
            const data = new FormData()
            data.append('table', 'system')
            data.append('object_id', '1')
            data.append('image', aspectParams.logo)
            const newLogo = await CustomAxiosImages.post('images', data, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((res) => {
                return res.data.url
            })
            return newLogo
        }
        return aspectParams.logo
    }

    const allParamsAreCorrect = useMemo(() => {
        if (GeniusParams.key && !isValidString(GeniusParams.key)) {
            return false
        }

        return true
    }, [mailServerParams, GSMParams, GeniusParams])

    const renderAspectParams = useMemo(() => {
        return (
            <>
                <div className="header-section-info-text">
                    <h2 style={{ marginTop: '32px' }}>{t('systemConfig.aspectConfig.header')}</h2>
                </div>
                <div className="input-row">
                    <TextField
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.aspectConfig.title')}
                        label={t('systemConfig.aspectConfig.title')}
                        value={aspectParams.title ?? 'TXControl'}
                        onChangeText={(value: string) => setAspectParams({ ...aspectParams, title: value })}
                    />
                    <div
                        className="input-rows"
                        style={{
                            width: '20%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 0,
                        }}
                    >
                        <p>Logo</p>
                        <div className="logo" style={{ marginTop: 0 }}>
                            {!isPreviewMode ? (
                                <div style={{ position: 'absolute', top: -10, right: -10, zIndex: 9999 }}>
                                    <UploadButton
                                        acceptedData="image/png, image/jpeg"
                                        onChange={(f) => {
                                            handleNewUpload(f)
                                            setAspectParams({ ...aspectParams, logo: f })
                                        }}
                                    />
                                </div>
                            ) : null}
                            <img
                                src={
                                    aspectParams.logo && typeof aspectParams.logo === 'string'
                                        ? `${config.api.baseURL}/${aspectParams.logo}`
                                        : LogoDefault
                                }
                                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                ref={logoImg}
                                alt="brand logo"
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }, [aspectParams.title, aspectParams.logo, isPreviewMode])

    const renderMailServerParams = useMemo(() => {
        if (!mailServerParams) return null
        return (
            <>
                <div className="input-row">
                    <TextField
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.mailServerConfig.host')}
                        label={t('systemConfig.mailServerConfig.host')}
                        value={mailServerParams.host}
                        onChangeText={(value: string) => setMailServerParams({ ...mailServerParams, host: value })}
                    />
                    <TextField
                        small
                        width={50}
                        type="number"
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.mailServerConfig.port')}
                        label={t('systemConfig.mailServerConfig.port')}
                        value={mailServerParams.port}
                        onChangeText={(value: string) => setMailServerParams({ ...mailServerParams, port: value })}
                    />
                </div>
                <div className="input-row">
                    <TextField
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.mailServerConfig.user')}
                        label={t('systemConfig.mailServerConfig.user')}
                        value={mailServerParams.username}
                        onChangeText={(value: string) => setMailServerParams({ ...mailServerParams, username: value })}
                    />
                    <TextField
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.mailServerConfig.pass')}
                        label={t('systemConfig.mailServerConfig.pass')}
                        value={mailServerParams.password}
                        isPassword
                        onChangeText={(value: string) => setMailServerParams({ ...mailServerParams, password: value })}
                    />
                </div>
                <TextField
                    onlyVisible={isPreviewMode}
                    labelText={t('systemConfig.mailServerConfig.from')}
                    label={t('systemConfig.mailServerConfig.from')}
                    value={mailServerParams.from}
                    onChangeText={(value: string) => setMailServerParams({ ...mailServerParams, from: value })}
                />
            </>
        )
    }, [
        mailServerParams?.from,
        mailServerParams?.host,
        mailServerParams?.password,
        mailServerParams?.port,
        mailServerParams?.username,
        isPreviewMode,
    ])

    const renderGSMParams = useMemo(() => {
        if (!GSMEnabled) return null
        return (
            <>
                <div className="header-section-info-text">
                    <h2 style={{ marginTop: '32px' }}>{'Gateway SMS'}</h2>
                </div>
                <div className="input-row">
                    <TextField
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.GSMConfig.host')}
                        label={t('systemConfig.GSMConfig.host')}
                        value={GSMParams.host}
                        onChangeText={(value: string) => setGSMParams({ ...GSMParams, host: value })}
                    />
                    <TextField
                        small
                        width={50}
                        type="number"
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.GSMConfig.port')}
                        label={t('systemConfig.GSMConfig.port')}
                        value={GSMParams.port}
                        onChangeText={(value: string) => setGSMParams({ ...GSMParams, port: value })}
                    />
                </div>
                <div className="input-row">
                    <TextField
                        small
                        width={50}
                        onlyVisible={isPreviewMode}
                        type="number"
                        labelText={t('systemConfig.GSMConfig.polling')}
                        label={t('systemConfig.GSMConfig.polling')}
                        value={GSMParams.pollingInterval.toString()}
                        onChangeText={(value: string) =>
                            setGSMParams({ ...GSMParams, pollingInterval: parseInt(value, 10) })
                        }
                    />
                </div>
            </>
        )
    }, [GSMParams?.host, GSMParams?.pollingInterval, GSMParams?.port, isPreviewMode, GSMEnabled])

    const renderGeniusParams = useMemo(() => {
        if (!GeniusEnabled) return null
        return (
            <>
                <div className="header-section-info-text">
                    <h2 style={{ marginTop: '32px' }}>{'Genius'}</h2>
                </div>
                <div className="input-row">
                    <TextField
                        isValidInput={isValidString(GeniusParams.key)}
                        onlyVisible={isPreviewMode}
                        labelText={t('systemConfig.GeniusConfig.description')}
                        label={t('systemConfig.GeniusConfig.key')}
                        isPassword
                        value={GeniusParams.key}
                        onChangeText={(value: string) => setGeniusParams({ ...GeniusParams, key: value })}
                    />
                </div>
            </>
        )
    }, [GeniusParams?.key, GeniusEnabled, isPreviewMode])

    const renderEraserParams = useMemo(() => {
        return (
            <>
                <div className="header-section-info-text">
                    <h2 style={{ marginTop: '32px' }}>{t('systemConfig.EraserConfig.title')}</h2>
                </div>
                <div
                    className="input-row"
                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                >
                    <CheckboxInput
                        disabled={isPreviewMode}
                        label={
                            EraserParams.status
                                ? t('systemConfig.EraserConfig.active')
                                : t('systemConfig.EraserConfig.disabled')
                        }
                        value={EraserParams.status}
                        onChangeValue={(value: boolean) => {
                            console.log(value)

                            setEraserParams({ ...EraserParams, status: value })
                        }}
                    />
                </div>
                {EraserParams.status && (
                    <>
                        <span>{t('systemConfig.EraserConfig.preDays')} </span>
                        <TextField
                            label=" "
                            width={30}
                            onlyVisible={isPreviewMode}
                            value={EraserParams.days.toString()}
                            onChangeText={(value: string) =>
                                setEraserParams({ ...EraserParams, days: parseInt(value, 10) })
                            }
                        />
                    </>
                )}
            </>
        )
    }, [EraserParams?.status, EraserParams.days, isPreviewMode])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.configurations" descriptionKey="pageDescription.configurations" />
                <div className="header-right-actions">
                    <div className="header-rapid-search">
                        {isPreviewMode ? (
                            <Button
                                label={t('common.edit')}
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                margin="0 0 0 25px"
                                icon={faPen}
                                onPress={() => setIsPreviewMode(false)}
                            />
                        ) : (
                            <Button
                                isDisabled={!allParamsAreCorrect}
                                label={t('common.save')}
                                backgroundColor="#EA0B2A"
                                textColor="#fff"
                                iconColor="#fff"
                                margin="0 0 0 25px"
                                icon={faPen}
                                onPress={async () => saveConfigurations()}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="sectionContent">
                {renderAspectParams}
                <div className="header-section-info-text">
                    <h2 style={{ marginTop: '32px' }}>{t('fields.email')}</h2>
                </div>
                {renderMailServerParams}
                <Divider style={{ marginTop: 36 }} />
                {renderGSMParams}
                <Divider style={{ marginTop: 36 }} />
                {renderEraserParams}
                <Divider style={{ marginTop: 36 }} />
                {renderGeniusParams}

                <Footer />
            </div>
        </PageContainer>
    )
}

export default ConfigurationPage
