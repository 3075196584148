export const getAPIInfo = (): string => {
    return 'about'
}

export const getDeviceEvents = (): string => {
    return 'device-events'
}

export const getDetailedEvents = (): string => {
    return 'device-events/detailed'
}

export const getDevices = (): string => {
    return 'devices'
}

export const editDevice = (deviceId: number): string => {
    return `devices/${deviceId}`
}

export const getDeviceDetails = (): string => {
    return 'device/'
}

export const getUserActivities = (): string => {
    return 'user-activities'
}

export const getDeviceTree = (deviceId: number): string => {
    return `devices-tree?master=${deviceId}`
}

export const getCommunicationProtocolTypes = (): string => {
    return 'communication-protocol-types'
}

export const getAllCountries = (): string => {
    return 'zones/countries'
}

export const getAllUsers = (): string => {
    return 'users'
}

export const getSchedulers = (): string => {
    return 'schedulers'
}

export const singleScheduler = (schedulerId: number): string => {
    return `schedulers/${schedulerId}`
}

export const getCommandsHistory = (): string => {
    return 'commands-history'
}

export const singleDeviceEvent = (eventId: number): string => {
    return `device-events/${eventId}`
}

export const getOrCreateDeviceModel = (): string => {
    return 'device-models'
}

export const getOrCreateBrands = (): string => {
    return 'brands'
}

export const sendNewCommand = (): string => {
    return 'commands/new-station-command'
}
