import { DATA_ACTIONS } from '../actions/data'
import { ReducerData, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerData = {
    configurations: [],
    genius: undefined,
    apiVersion: '',
    todayAlarms: [],
    mapAlarms: [],
    alarmTypes: [],
    regions: [],
    nations: [],
    users: [],
    brands: [],
    models: [],
    deviceTypes: [],
    networks: [],
    networkGroups: [],
    communicationProtocolTypes: [],
}

const reducerState: (state: ReducerData, action: ReduxActionData<any, DATA_ACTIONS>) => ReducerData = (
    state,
    action: ReduxActionData<any, DATA_ACTIONS>
) => {
    state = state || defaultValue
    switch (action.type) {
        case DATA_ACTIONS.SET_CONFIGURATIONS:
            return {
                ...state,
                configurations: action.payload,
            }
        case DATA_ACTIONS.SET_GENIUS_DATA:
            return {
                ...state,
                geniusData: action.payload,
            }
        case DATA_ACTIONS.SET_API_VERSION:
            return {
                ...state,
                apiVersion: action.payload,
            }
        case DATA_ACTIONS.SET_TODAY_ALARMS:
            return {
                ...state,
                todayAlarms: action.payload,
            }
        case DATA_ACTIONS.SET_MAP_ALARMS:
            return {
                ...state,
                mapAlarms: action.payload,
            }
        case DATA_ACTIONS.SET_ALARM_TYPES:
            return {
                ...state,
                alarmTypes: action.payload,
            }
        case DATA_ACTIONS.SET_REGIONS:
            return {
                ...state,
                regions: action.payload,
            }
        case DATA_ACTIONS.SET_NATIONS:
            return {
                ...state,
                nations: action.payload,
            }
        case DATA_ACTIONS.SET_USERS:
            return {
                ...state,
                users: action.payload,
            }
        case DATA_ACTIONS.SET_BRANDS:
            return {
                ...state,
                brands: action.payload,
            }
        case DATA_ACTIONS.SET_MODELS:
            return {
                ...state,
                models: action.payload,
            }
        case DATA_ACTIONS.SET_NETWORKS:
            return {
                ...state,
                networks: action.payload,
            }
        case DATA_ACTIONS.SET_NETWORK_GROUPS:
            return {
                ...state,
                networkGroups: action.payload,
            }
        case DATA_ACTIONS.SET_DEVICE_TYPES:
            return {
                ...state,
                deviceTypes: action.payload,
            }
        case DATA_ACTIONS.SET_COMMUNICATION_PROTOCOL_TYPES:
            return {
                ...state,
                communicationProtocolTypes: action.payload,
            }
        default:
            return state
    }
}

export default reducerState
