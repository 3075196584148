import React, { useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { usePrevious } from '../../utils/hooks/common'
import { Network } from '../../types/data/system'
import { UserDataTable } from '../../types/data/user'
import NetworkUserAssocModal from '../Modals/NetworkUserAssocModal'
import { NetworkGrant } from '../../utils/enum/NetworkGrant'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

export interface NetworkUserAssoc {
    idGrant: number
    network: Network | null
    tableData: { id: number }
    user: UserDataTable | null
}

interface Props {
    currentNetwork: Network
    searchQuery: string
    showModal: boolean
    hideModal: () => void
    viewOnly?: boolean
}

const NetworkUserAssocTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedAssoc, setSelectedAssoc] = useState<NetworkUserAssoc | null>(null)
    const [usersAssociation, setUsersAssociation] = useState<NetworkUserAssoc[]>([])
    const tableRef = React.useRef<any>()
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const navigate = useNavigate()
    const previousQuery = usePrevious(props.searchQuery)
    const [showEdit, setShowEdit] = useState(false)
    const [showRemove, setShowRemove] = useState(false)
    const newAssociation: NetworkUserAssoc = {
        idGrant: 1,
        user: null,
        network: props.currentNetwork,
        tableData: { id: -1 },
    }
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const removeHandler = async (idNetwork: number, idUser: number) => {
        try {
            const body = {
                id_network: idNetwork,
                id_user: idUser,
            }
            await CustomAxios.delete('network-user-association/', { params: body })
            await fetchUsersAssociation()
            setShowRemove(false)
            setSelectedAssoc(null)
        } catch (error) {
            console.error(error)
        }
    }

    const editHandler = async (newAssoc: NetworkUserAssoc) => {
        try {
            const body = {
                idNetwork: newAssoc.network.ID,
                idUser: newAssoc.user.ID,
                idGrant: newAssoc.idGrant,
            }
            await CustomAxios.put('network-user-association', body)
            await fetchUsersAssociation()
            setShowEdit(false)
            setSelectedAssoc(null)
        } catch (error) {
            console.error(error)
        }
    }

    const createHandler = async (newAssoc: NetworkUserAssoc) => {
        try {
            const body = {
                idNetwork: newAssoc.network.ID,
                idUser: newAssoc.user.ID,
                idGrant: newAssoc.idGrant,
            }
            await CustomAxios.post('network-user-association', body)
            await fetchUsersAssociation()
            props.hideModal()
            setSelectedAssoc(null)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (props.showModal) {
            setSelectedAssoc(newAssociation)
        }
    }, [props.showModal])

    useEffect(() => {
        void fetchUsersAssociation()
    }, [props.currentNetwork])

    const fetchUsersAssociation = async () => {
        const usersAssoc = await CustomAxios.get(`network-user-association?network=${props.currentNetwork.ID}`).then(
            (res) => {
                return res.data.data
            }
        )
        setUsersAssociation(usersAssoc)
    }

    const filtered = useMemo(() => {
        if (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2)) {
            return usersAssociation.filter(
                (u) =>
                    u.user.firstName.toLocaleLowerCase().includes(props.searchQuery.toLocaleLowerCase()) ||
                    u.user.lastName.toLocaleLowerCase().includes(props.searchQuery.toLocaleLowerCase())
            )
        }
        return usersAssociation
    }, [usersAssociation, props.searchQuery])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.user'),
                field: 'user',
                render: (rowData: NetworkUserAssoc) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() => navigate(`/${ROUTE_NAMES.USERS}/${rowData?.user.ID}`)}
                    >
                        {rowData.user.firstName} {rowData.user.lastName}
                    </span>
                ),
            },
            {
                title: t('fields.grant'),
                field: 'idGrant',
                render: (rowData: NetworkUserAssoc) => (
                    <span>{t(`userRoles.${NetworkGrant[rowData.idGrant - 1]}`)}</span>
                ),
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        if (props.viewOnly) {
            return []
        }
        const actions = [
            (rowData: NetworkUserAssoc) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setShowEdit(true)
                    setSelectedAssoc(rowData)
                },
                tooltip: t('common.edit'),
            }),
            (rowData: NetworkUserAssoc) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => {
                    setShowRemove(true)
                    setSelectedAssoc(rowData)
                },
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [props.viewOnly])

    return (
        <>
            {showRemove && selectedAssoc && selectedAssoc.user ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.userNetworkAssociationDeleteConfirmMessage', {
                        user: `${selectedAssoc.user.firstName} ${selectedAssoc.user.lastName}`,
                    })}
                    onClose={() => {
                        setShowRemove(false)
                        setSelectedAssoc(null)
                    }}
                    onDelete={() => void removeHandler(props.currentNetwork.ID, selectedAssoc.user.ID)}
                />
            ) : null}
            {(props.showModal && selectedAssoc) || (selectedAssoc && showEdit) ? (
                <NetworkUserAssocModal
                    assoc={selectedAssoc}
                    associated={usersAssociation}
                    onClose={() => {
                        setShowEdit(false)
                        props.hideModal()
                        setSelectedAssoc(null)
                    }}
                    onConfirm={(newAssoc) => {
                        if (props.showModal) {
                            void createHandler(newAssoc)
                        } else {
                            void editHandler(newAssoc)
                        }
                    }}
                    isNew={props.showModal}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={filtered.filter((user) => user.user.hide !== 1)}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    setPageSize(pageSize)
                    dispatch(setUserDefaultTablePageSize(pageSize))
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default NetworkUserAssocTable
