import { faArrowLeft, faEye, faFileDownload, faFileImport, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Button from '../../../../Components/Inputs/Button'
import SelectInput from '../../../../Components/Inputs/SelectInput'
import TextField from '../../../../Components/Inputs/TextField'
import AlertModal from '../../../../Components/Modals/AlertModal'
import DeleteModal from '../../../../Components/Modals/DeleteModal'
import Footer from '../../../../Components/StaticComponents/Footer'
import PageContainer from '../../../../Components/StaticComponents/PageContainer'
import Tabs from '../../../../Components/Tabs'
import AvailableCommandsTab from '../../../../Components/Tabs/AvailableCommandsTab'
import DefaultVarsTab from '../../../../Components/Tabs/DefaultVarsTab'
import CustomAxios from '../../../../customComponents/customAxios'
import { DeviceModel, DeviceType } from '../../../../types/data/alarm'
import { Brand } from '../../../../types/data/system'
import { Reducers, ReducerUser } from '../../../../types/reducers'
import { deepCopy } from '../../../../utils/functions'
import { ROUTE_NAMES } from '../../../../utils/routes'
import { ToastError, ToastSuccess, ToastWarning } from '../../../../utils/toast'
import PageHeaderCustom from '../../../../customComponents/PageHeaderCustom'
import Loader from '../../../../Components/StaticComponents/Loader'
import { useTranslation } from 'react-i18next'
import ChangesDisplayModal from '../../../../Components/Modals/ChangesDisplayModal'
import FileImportModal from '../../../../Components/Modals/FileImportModal'
import ModelDevicesUpdateModal from '../../../../Components/Modals/ModelDevicesUpdateModal'

interface ModelDetailPageState {
    state: {
        idBrand?: number
        modelDuplicated?: DeviceModel
        isView?: boolean
    }
}

const ModelDetailPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [availableDeviceTypes, setAvailableDeviceTypes] = useState<DeviceType[]>([])
    const navigate = useNavigate()
    const { state }: ModelDetailPageState = useLocation()
    const { id } = useParams<{ id: string }>()
    const [model, setModel] = useState<DeviceModel>({
        ID: -1,
        availableCommands: [],
        defaultVars: [],
        idBrand: state ? state.idBrand : -1,
        idDeviceType: -1,
        model: '',
        mib: '',
        idDashboard: null,
        modelSchema: [],
    })
    const { t } = useTranslation()
    const modelSubMenuVoices = [
        {
            label: t('fields.defaultVariables'),
            value: 'defaultVariables',
        },
        {
            label: t('fields.availableCommands'),
            value: 'availableCommands',
        },
    ]
    const [selectedTab, setSelectedTab] = useState<string>(modelSubMenuVoices[0].value)
    const [targetRoute, setTargetRoute] = useState('')
    const [showDevicesModal, setShowDevicesModal] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [showJsonModal, setShowJsonModal] = useState(false)
    const [modelBrand, setModelBrand] = useState<Brand | null>(null)
    const [editMode, setEditMode] = useState(!(state && state.isView))

    const fetchModel = async () => {
        try {
            setLoading(true)
            const model: DeviceModel = await CustomAxios.get(`device-models/${id}`).then((response) => {
                return response.data
            })
            setModel(model)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const getBrandOfModal = async () => {
        try {
            const brands: Brand[] = await CustomAxios.get('brands').then((response) => response.data.data)
            const selectedBrand: Brand = brands.find((brand) => brand.ID === state.idBrand)
            if (selectedBrand) {
                setModelBrand(selectedBrand)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableDeviceTypes = async () => {
        try {
            const deviceTypes: DeviceType[] = await CustomAxios.get('device-types').then(
                (response) => response.data.data
            )
            setAvailableDeviceTypes(deviceTypes)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getAvailableDeviceTypes()
    }, [])

    useEffect(() => {
        if (user.idSysGrant !== 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
        if (id !== undefined && id !== 'new-model') {
            void fetchModel()
            void getBrandOfModal()
        } else {
            if (state && state.modelDuplicated && state.modelDuplicated !== null) {
                const modelDuplicated = state.modelDuplicated
                setModel({
                    ID: -1,
                    availableCommands: modelDuplicated.availableCommands,
                    defaultVars: modelDuplicated.defaultVars,
                    idBrand: modelDuplicated.idBrand,
                    idDeviceType: modelDuplicated.idDeviceType,
                    model: modelDuplicated.model,
                    mib: modelDuplicated.mib,
                    idDashboard: modelDuplicated.idDashboard,
                    modelSchema: modelDuplicated.modelSchema,
                })
            } else {
                setModel({
                    ID: -1,
                    availableCommands: [],
                    defaultVars: [],
                    idBrand: state ? state.idBrand : -1,
                    idDeviceType: -1,
                    model: '',
                    mib: '',
                    idDashboard: null,
                    modelSchema: [],
                })
            }
        }
    }, [id, user])

    const handleUpdateModal = async (deviceIDs: number[]) => {
        try {
            const modelCopy = deepCopy(model)
            if (model.ID !== -1) {
                let modelCopySchema = []
                if (modelCopy.modelSchema.length > 0) {
                    modelCopySchema = modelCopy.modelSchema.map((schemaValue) => {
                        if (schemaValue.value === undefined) {
                            return { ...schemaValue, value: '' }
                        }
                        return schemaValue
                    })
                }
                delete modelCopy.brand
                delete modelCopy.deviceType
                const defaultVarsCopy = modelCopy.defaultVars.map((defaultVar) => {
                    delete (defaultVar as any).tableData
                    return defaultVar
                })
                if (deviceIDs.length > 0) {
                    ToastWarning(t('fields.modelDevicesUpdateToastText'))
                }

                await CustomAxios.patch(`device-models/${model.ID}`, {
                    ...modelCopy,
                    modelSchema: modelCopySchema,
                    defaultVars: defaultVarsCopy,
                    devicesToUpdate: deviceIDs,
                })
            }
            ToastSuccess(t('actionsMessages.successModelUpdate'))
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const handleCreateModel = async () => {
        try {
            const modelCopy = JSON.parse(JSON.stringify(model))
            delete modelCopy.brand
            delete modelCopy.ID
            delete modelCopy.deviceType
            delete modelCopy.idDashboard
            const newModel = await CustomAxios.post('device-models', modelCopy).then((data) => {
                return data.data.insertId
            })
            if (newModel) {
                navigate(`/${ROUTE_NAMES.MODELS}/${newModel}`, { state: { idBrand: model.idBrand } })
            }
            ToastSuccess(t('actionsMessages.successModelCreate'))
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const initExitProcedure = (route: string) => {
        if (editMode) {
            setShowAlertModal(true)
            setTargetRoute(route)
        } else {
            navigate(route)
        }
    }

    const handleSendUserData = (devicesIDs?: number[]) => {
        if (id !== undefined && id !== 'new-model') {
            void handleUpdateModal(devicesIDs)
        } else {
            void handleCreateModel()
        }
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`device-models/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.BRANDS}/${model.idBrand}`)
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const userDataIsCorrect = useMemo(() => {
        return model.model !== '' && model.idDeviceType !== -1
    }, [model])

    const downloadModelDriver = async () => {
        try {
            await CustomAxios.post(
                `create-driver/${model.ID}`,
                {},
                {
                    headers: { 'Response-Type': 'blob' },
                }
            ).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${model.model !== '' ? model.model : 'model'}.trx`)
                document.body.appendChild(link)
                link.click()
            })
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const renderCurrentTab = useCallback(() => {
        switch (selectedTab) {
            case 'defaultVariables':
                return (
                    <DefaultVarsTab
                        isModelSection
                        defaultVars={model.defaultVars ? model.defaultVars : []}
                        viewOnly={!editMode}
                        updateModel={async (vars) => {
                            setModel({ ...model, defaultVars: vars })
                            let defaultVarsCopy = deepCopy(vars)
                            defaultVarsCopy = defaultVarsCopy.map((defaultVar) => {
                                delete (defaultVar as any).tableData
                                return defaultVar
                            })
                            const modelCopy = deepCopy(model)
                            delete modelCopy.brand
                            await CustomAxios.patch(`device-models/${model.ID}`, {
                                ...modelCopy,
                                defaultVars: defaultVarsCopy,
                            })
                        }}
                    />
                )
            case 'availableCommands':
                return (
                    <AvailableCommandsTab
                        availableCommands={model.availableCommands ? model.availableCommands : []}
                        viewOnly={!editMode}
                        updateModel={async (commands) => {
                            const modelCopy = deepCopy(model)
                            let commandsCopy = deepCopy(commands)
                            commandsCopy = commandsCopy.map((singleCommand) => {
                                delete (singleCommand as any).tableData
                                return singleCommand
                            })
                            setModel({ ...model, availableCommands: commandsCopy })
                            delete modelCopy.brand
                            delete modelCopy.deviceType
                            await CustomAxios.patch(`device-models/${model.ID}`, {
                                ...modelCopy,
                                availableCommands: commandsCopy,
                            })
                        }}
                    />
                )
            default:
                return (
                    <DefaultVarsTab
                        defaultVars={model.defaultVars ? model.defaultVars : []}
                        viewOnly={!editMode}
                        updateModel={async (vars) => {
                            setModel({ ...model, defaultVars: vars })
                            let defaultVarsCopy = deepCopy(vars)
                            defaultVarsCopy = defaultVarsCopy.map((defaultVar) => {
                                delete (defaultVar as any).tableData
                                return defaultVar
                            })
                            const modelCopy = deepCopy(model)
                            delete modelCopy.brand
                            await CustomAxios.patch(`device-models/${model.ID}`, {
                                ...modelCopy,
                                defaultVars: defaultVarsCopy,
                            })
                        }}
                    />
                )
        }
    }, [model, selectedTab])

    const renderTables = () => {
        if (model.ID !== -1) {
            return (
                <div style={{ marginTop: '25px' }}>
                    <Tabs tabs={modelSubMenuVoices} onSelectedTabChange={(newTab) => setSelectedTab(newTab)} />
                    {renderCurrentTab()}
                </div>
            )
        }
        return (
            <p style={{ fontSize: 16, fontStyle: 'italic', margin: '10px 20px' }}>
                {t('systemMessages.createModelFirstMessage')}
            </p>
        )
    }

    return (
        <>
            {showDevicesModal && (
                <ModelDevicesUpdateModal
                    currentModel={model}
                    onClose={() => setShowDevicesModal(false)}
                    onConfirm={(deviceIDs) => handleSendUserData(deviceIDs)}
                />
            )}
            {showImportModal && (
                <FileImportModal
                    isUpdate
                    validExtensions=".trx"
                    validatorEndpointAPI="process-driver-file"
                    endpointAPI={`device-models/${model.ID}`}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            <PageContainer>
                {showAlertModal ? (
                    <AlertModal
                        alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                        onConfirm={() => navigate(targetRoute)}
                        onClose={() => setShowAlertModal(false)}
                    />
                ) : null}
                {showDeleteModal && (
                    <DeleteModal
                        deleteMessage={t('systemMessages.removeElementFromList')}
                        onClose={() => cancelDeleteProcedure()}
                        onDelete={() => void confirmDeleteElement()}
                    />
                )}
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {showJsonModal && (
                            <ChangesDisplayModal
                                jsonObjectPre={JSON.stringify(model.mib)}
                                displaySingleObject
                                onClose={() => setShowJsonModal(false)}
                            />
                        )}
                        <div className="headerSection addNewSection">
                            <div className="header-section-info-text">
                                <h2>
                                    <span
                                        className="breadcrumb-index"
                                        onClick={() => initExitProcedure(`/${ROUTE_NAMES.BRANDS}?page=0`)}
                                    >
                                        {t('routes.brand')}
                                    </span>
                                    {modelBrand !== null && ' / '}
                                    <span
                                        className="breadcrumb-index"
                                        onClick={() => initExitProcedure(`/${ROUTE_NAMES.BRANDS}/${model.idBrand}`)}
                                    >
                                        {modelBrand !== null ? `${modelBrand.name}` : ''}
                                    </span>{' '}
                                    {`/ ${model.ID === -1 ? t('fields.newModel') : model.model}`}
                                </h2>
                                <PageHeaderCustom descriptionKey="pageDescription.modelDetail" />
                            </div>
                            <div className="header-right-actions">
                                <Button
                                    label={t('common.updateFromFile')}
                                    backgroundColor="#EA0B2A"
                                    textColor="#fff"
                                    iconColor="#fff"
                                    margin="0 32px 0 0"
                                    icon={faFileImport}
                                    onPress={() => setShowImportModal(true)}
                                />
                                <Button
                                    label={t('common.downloadModel')}
                                    backgroundColor="#EA0B2A"
                                    textColor="#fff"
                                    iconColor="#fff"
                                    margin="0 32px 0 0"
                                    icon={faFileDownload}
                                    onPress={() => void downloadModelDriver()}
                                />
                                {!editMode ? (
                                    <Button
                                        label={t('common.update')}
                                        backgroundColor="#EA0B2A"
                                        textColor="#fff"
                                        iconColor="#fff"
                                        margin="0"
                                        icon={faPen}
                                        onPress={() => setEditMode(true)}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            label={t('common.back')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0"
                                            icon={faArrowLeft}
                                            onPress={() => initExitProcedure(`/${ROUTE_NAMES.BRANDS}/${model.idBrand}`)}
                                        />
                                        {id !== undefined && id !== 'new-model' && (
                                            <Button
                                                label={t('common.delete')}
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                margin="0 0 0 25px"
                                                icon={faTrash}
                                                onPress={() => initDeleteProcedure()}
                                            />
                                        )}
                                        <Button
                                            isDisabled={!userDataIsCorrect}
                                            label={t('common.save')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => {
                                                if (model.ID !== -1) {
                                                    setShowDevicesModal(true)
                                                } else {
                                                    void handleCreateModel()
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="sectionContent">
                            <div className="editContainer advanced-search-wrapper">
                                <div className="filter-column">
                                    <div className="variable-field-label-container">
                                        <h3>{t('fields.name')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.name')}
                                            value={model.model}
                                            onChangeText={(value: string) => {
                                                setModel({
                                                    ...model,
                                                    model: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <div className="variable-field-label-container">
                                        <h3>{t('fields.deviceType')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <SelectInput
                                            disabled={!editMode}
                                            options={availableDeviceTypes.map((d) => {
                                                return {
                                                    value: d.ID.toString(),
                                                    label: d.name,
                                                }
                                            })}
                                            value={
                                                model.idDeviceType !== -1
                                                    ? {
                                                          value:
                                                              availableDeviceTypes
                                                                  .find((d) => d.ID === model.idDeviceType)
                                                                  ?.ID.toString() || '',
                                                          label:
                                                              availableDeviceTypes.find(
                                                                  (d) => d.ID === model.idDeviceType
                                                              )?.name || '',
                                                      }
                                                    : null
                                            }
                                            onValueChange={(value) => {
                                                if (value) {
                                                    setModel({
                                                        ...model,
                                                        idDeviceType: parseInt(value.value, 10),
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {model.ID !== -1 && model.mib && (
                                    <div className="filter-column">
                                        <h3>{t('fields.mib')}</h3>
                                        <div className="input-rows">
                                            <Button
                                                label={t('common.view')}
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                iconColor="#3f3f3f"
                                                margin="0"
                                                icon={faEye}
                                                onPress={() => setShowJsonModal(true)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {renderTables()}
                        </div>
                    </>
                )}
                <Footer />
            </PageContainer>
        </>
    )
}

export default ModelDetailPage
