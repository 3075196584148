export const it = {
    everyText: 'ogni',
    emptyMonths: 'ogni mese',
    emptyMonthDays: 'ogni giorno del mese',
    emptyMonthDaysShort: 'giorno del mese',
    emptyWeekDays: 'ogni giorno della settimana',
    emptyWeekDaysShort: 'giorno della settimana',
    emptyHours: 'ogni ora',
    emptyMinutes: 'ogni minuto',
    emptyMinutesForHourPeriod: 'ogni',
    yearOption: 'anno',
    monthOption: 'mese',
    weekOption: 'settimana',
    dayOption: 'giorno',
    hourOption: 'ora',
    minuteOption: 'minuto',
    rebootOption: 'reboot',
    prefixPeriod: 'Ogni',
    prefixMonths: 'in',
    prefixMonthDays: 'il',
    prefixWeekDays: 'il',
    prefixWeekDaysForMonthAndYearPeriod: 'e',
    prefixHours: 'alle',
    prefixMinutes: ':',
    prefixMinutesForHourPeriod: 'at',
    suffixMinutesForHourPeriod: 'minuti',
    errorInvalidCron: 'Cron non valido',
    clearButtonText: 'Resetta',
    weekDays: [
      // Order is important, the index will be used as value
      'Domenica', // Sunday must always be first, it's "0"
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
    ],
    months: [
      // Order is important, the index will be used as value
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    // Order is important, the index will be used as value
    altWeekDays: [
      'DOM', // Sunday must always be first, it's "0"
      'LUN',
      'MAR',
      'MER',
      'GIO',
      'VEN',
      'SAB',
    ],
    // Order is important, the index will be used as value
    altMonths: [
      'GEN',
      'FEB',
      'MAR',
      'APR',
      'MAG',
      'GIU',
      'LUG',
      'AGO',
      'SET',
      'OTT',
      'NOV',
      'DIC',
    ],
}

export const en = {
  everyText: 'every',
  emptyMonths: 'every month',
  emptyMonthDays: 'every day of the month',
  emptyMonthDaysShort: 'day of the month',
  emptyWeekDays: 'every day of the week',
  emptyWeekDaysShort: 'day of the week',
  emptyHours: 'every hour',
  emptyMinutes: 'every minute',
  emptyMinutesForHourPeriod: 'every',
  yearOption: 'year',
  monthOption: 'month',
  weekOption: 'week',
  dayOption: 'day',
  hourOption: 'hour',
  minuteOption: 'minute',
  rebootOption: 'reboot',
  prefixPeriod: 'Every',
  prefixMonths: 'in',
  prefixMonthDays: 'on',
  prefixWeekDays: 'on',
  prefixWeekDaysForMonthAndYearPeriod: 'and',
  prefixHours: 'at',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: 'at',
  suffixMinutesForHourPeriod: 'minute(s)',
  errorInvalidCron: 'Invalid cron expression',
  clearButtonText: 'Clear',
  weekDays: [
    // Order is important, the index will be used as value
    'Sunday', // Sunday must always be first, it's "0"
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  months: [
    // Order is important, the index will be used as value
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    'SUN', // Sunday must always be first, it's "0"
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ],
  // Order is important, the index will be used as value
  altMonths: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
}