import { AlarmType, DeviceEvent, DeviceType } from '../../types/data/alarm'
import { CommunicationProtocolType, Country, Region } from '../../types/data/common'
import { Brand, Model, Network, NetworkGroup } from '../../types/data/system'
import { User } from '../../types/data/user'
import { ConfigParameters, ReduxAction } from '../../types/reducers'

export enum DATA_ACTIONS {
    SET_CONFIGURATIONS = 'dataAction/setConfigurations',
    SET_GENIUS_DATA = 'dataAction/setGeniusData',
    SET_API_VERSION = 'dataAction/setApiVersion',
    SET_TODAY_ALARMS = 'dataAction/setTodayAlarms',
    SET_MAP_ALARMS = 'dataAction/setMapAlarms',
    SET_ALARM_TYPES = 'dataAction/setAlarmTypes',
    SET_REGIONS = 'dataAction/setRegions',
    SET_NATIONS = 'dataAction/setNations',
    SET_USERS = 'dataAction/setUsers',
    SET_BRANDS = 'dataAction/setBrands',
    SET_MODELS = 'dataAction/setModels',
    SET_DEVICE_TYPES = 'dataActions/setDeviceTypes',
    SET_NETWORKS = 'dataActions/setNetworks',
    SET_NETWORK_GROUPS = 'dataActions/setNetworkGroups',
    SET_COMMUNICATION_PROTOCOL_TYPES = 'dataActions/setCommunicationProtocolTypes',
}

export const setConfigurations: ReduxAction<ConfigParameters[], DATA_ACTIONS> = (configurationsData) => {
    return {
        type: DATA_ACTIONS.SET_CONFIGURATIONS,
        payload: configurationsData,
    }
}

export const setGeniusData: ReduxAction<string, DATA_ACTIONS> = (geniusData) => {
    return {
        type: DATA_ACTIONS.SET_GENIUS_DATA,
        payload: geniusData,
    }
}

export const setAPIVersion: ReduxAction<string, DATA_ACTIONS> = (apiVersion) => {
    return {
        type: DATA_ACTIONS.SET_API_VERSION,
        payload: apiVersion,
    }
}

export const setTodayAlarms: ReduxAction<DeviceEvent[], DATA_ACTIONS> = (alarms) => {
    return {
        type: DATA_ACTIONS.SET_TODAY_ALARMS,
        payload: alarms,
    }
}

export const setMapAlarms: ReduxAction<any[], DATA_ACTIONS> = (alarms) => {
    return {
        type: DATA_ACTIONS.SET_MAP_ALARMS,
        payload: alarms,
    }
}

export const setAlarmTypes: ReduxAction<AlarmType[], DATA_ACTIONS> = (types) => {
    return {
        type: DATA_ACTIONS.SET_ALARM_TYPES,
        payload: types,
    }
}

export const setRegions: ReduxAction<Region[], DATA_ACTIONS> = (regions) => {
    return {
        type: DATA_ACTIONS.SET_REGIONS,
        payload: regions,
    }
}

export const setNations: ReduxAction<Country[], DATA_ACTIONS> = (nations) => {
    return {
        type: DATA_ACTIONS.SET_NATIONS,
        payload: nations,
    }
}

export const setUsers: ReduxAction<User[], DATA_ACTIONS> = (user) => {
    return {
        type: DATA_ACTIONS.SET_USERS,
        payload: user,
    }
}

export const setBrands: ReduxAction<Brand[], DATA_ACTIONS> = (brands) => {
    return {
        type: DATA_ACTIONS.SET_BRANDS,
        payload: brands,
    }
}

export const setModels: ReduxAction<Model[], DATA_ACTIONS> = (models) => {
    return {
        type: DATA_ACTIONS.SET_MODELS,
        payload: models,
    }
}

export const setDeviceTypes: ReduxAction<DeviceType[], DATA_ACTIONS> = (types) => {
    return {
        type: DATA_ACTIONS.SET_DEVICE_TYPES,
        payload: types,
    }
}

export const setNetworks: ReduxAction<Network[], DATA_ACTIONS> = (networks) => {
    return {
        type: DATA_ACTIONS.SET_NETWORKS,
        payload: networks,
    }
}

export const setNetworkGroups: ReduxAction<NetworkGroup[], DATA_ACTIONS> = (networkGroups) => {
    return {
        type: DATA_ACTIONS.SET_NETWORK_GROUPS,
        payload: networkGroups,
    }
}

export const setCommunicationProtocolTypes: ReduxAction<CommunicationProtocolType[], DATA_ACTIONS> = (
    communicationProtocolTypes
) => {
    return {
        type: DATA_ACTIONS.SET_COMMUNICATION_PROTOCOL_TYPES,
        payload: communicationProtocolTypes,
    }
}
