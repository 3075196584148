import {
    faChevronDown,
    faChevronUp,
    faCircleInfo,
    faGreaterThan,
    faLessThan,
    faMinus,
    faMinusCircle,
    faNotEqual,
    faPen,
    faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/components/modals.sass'
import { Threshold, ThresholdLevel, ThresholdRule, ThresholdType } from '../../types/data/alarm'
import { Reducers, ReducerUser } from '../../types/reducers'
import { editDevice } from '../../utils/api-constants'
import { AlarmLevels, ThresholdRules } from '../../utils/enum/AlarmLevels'
import { deepCopy } from '../../utils/functions'
import Button from '../Inputs/Button'
import CheckboxInput from '../Inputs/CheckboxInput'
import SelectInput from '../Inputs/SelectInput'
import TextField from '../Inputs/TextField'
import { DefaultVarTable } from '../Tables/DefaultVarsTable'
import AlertModal from './AlertModal'
import { mathValidation } from '../../store/actions/requests'
import { COLORS } from '../../styles/constants'
import { ReservedVariableNames } from '../../utils/constants'

interface Props {
    defaultVar: DefaultVarTable
    isNew?: boolean
    viewMode?: boolean
    isModelVar?: boolean
    preSetEnabled: boolean
    onClose: () => void
    onConfirm: (edited: DefaultVarTable) => void
}

const DefaultNetworkAvailabilityThreshold: Threshold = {
    id: moment().unix().toString(),
    value: 1,
    absolute_value: 1,
    rule: ThresholdRule.notEqual,
    level: ThresholdLevel.high,
    type: ThresholdType.absolute,
    enabled: true,
}

const DefaultVarModal: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const { t } = useTranslation()
    const [variable, setVariable] = useState(props.defaultVar)
    const [isView, setIsView] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [conversionValue, setConversionValue] = useState('')
    const [mathEvaluation, setMathEvaluation] = useState('')
    const [variableThresholds, setVariableThresholds] = useState<Threshold[]>([])
    const [deviceModalRefValue, setDeviceModalRefValue] = useState(0)
    const [availableValuesFromMap, setAvailableValuesFromMap] = useState<{ label: string; value: string }[] | null>(
        null
    )
    const [warningMessage, setWarningMessage] = useState<string[]>([])

    const { id } = useParams<{ id: string }>()
    const [isNetwokAvailability, setIsNetwokAvailability] = useState(false)

    const dataIsNotCorrect = () => {
        if (isNetwokAvailability) return false

        return (
            variable.oid === '' ||
            variable.name === '' ||
            ReservedVariableNames.indexOf(variable.name) !== -1 ||
            variable.oid.startsWith('.')
        )
    }

    useEffect(() => {
        const messages: string[] = []

        if (ReservedVariableNames.indexOf(variable.name) !== -1) {
            if (variable.name === 'networkAvailability') {
                setIsNetwokAvailability(true)
            } else {
                messages.push(t('systemMessages.reservedVariableName'))
            }
        }
        if (variable.oid && variable.oid.startsWith('.')) {
            messages.push(t('systemMessages.variableOIDBadString'))
        }

        setWarningMessage(messages)
    }, [variable.name, variable.oid])

    const mathEval = async () => {
        if (!conversionValue) return

        if (Number.isNaN(parseInt(conversionValue, 10))) {
            const evaluation = await mathValidation(conversionValue.replaceAll('x', '10'))
            setMathEvaluation(`${conversionValue} = ${evaluation}`)
            return
        }
        if (parseInt(conversionValue, 10) === 0) {
            setMathEvaluation('10/1 = 1')
            return
        }
        setMathEvaluation(`10/${conversionValue} = ${10 / parseInt(conversionValue, 10)}`)
    }

    useEffect(() => {
        void mathEval()
    }, [conversionValue])

    const getDeviceObject = async () => {
        try {
            if (!props.isModelVar) {
                const device = await CustomAxios.get(editDevice(parseInt(id, 10))).then((res) => {
                    return res.data
                })

                const foundModelSchemaRefValue = device.deviceInfo.find((info) => info.name === variable.modelSchemaRef)
                if (foundModelSchemaRefValue) {
                    setDeviceModalRefValue(parseInt(foundModelSchemaRefValue ?? '0', 10))
                }
            } else {
                setDeviceModalRefValue(0)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const formatVariableValueMap = () => {
        if (!variable.valueMap) return
        const valueMap = variable.valueMap
            .slice(1, -1)
            .split(',')
            .map((val) => {
                const [label, value] = val.slice(0, -1).split('(')
                return { label, value: value.replace(')', '') }
            })

        setAvailableValuesFromMap(
            valueMap.filter(
                (vM, i) => valueMap.findIndex((valueMapElement) => valueMapElement.label === vM.label) === i
            )
        )
    }

    useEffect(() => {
        if (!props.defaultVar) return
        if (props.defaultVar.ID) {
            setVariable(props.defaultVar)
        } else {
            setVariable({ ...props.defaultVar, infoReport: 1 })
        }
        if (props.defaultVar.thresholds) setVariableThresholds([...props.defaultVar.thresholds])
        setConversionValue(props.defaultVar.conversionValue ? props.defaultVar.conversionValue.toString() : '')
        if (props.viewMode) {
            setIsView(props.viewMode)
        }
        formatVariableValueMap()
        void getDeviceObject()
    }, [props.defaultVar, props.viewMode])

    const parseVariableParams = () => {
        let variableCopy: DefaultVarTable = deepCopy(variable)
        variableCopy = {
            ...variableCopy,
            description: variableCopy.description && variableCopy.description !== null ? variableCopy.description : '',
            infoReport: variableCopy.infoReport && variableCopy.infoReport === 1 ? 1 : 0,
            unitOfMeasure: variableCopy.unitOfMeasure ? variableCopy.unitOfMeasure : '',
            writeCommand: variableCopy.writeCommand ? variableCopy.writeCommand : '',
            conversionValue,
            thresholds: variableThresholds,
        }

        props.onConfirm(variableCopy)
    }

    const filterAvailableThresholdTypes = () => {
        let allowedTypes = []
        if (availableValuesFromMap !== null && variable.modelSchemaRef) {
            allowedTypes = [ThresholdType.absolute]
        } else if (availableValuesFromMap !== null && !variable.modelSchemaRef) {
            allowedTypes = [ThresholdType.absolute]
        } else if (!availableValuesFromMap && !variable.modelSchemaRef) {
            allowedTypes = [ThresholdType.absolute]
        } else if (variable.modelSchemaRef && !availableValuesFromMap) {
            if (props.isModelVar) {
                allowedTypes = [ThresholdType.percentage]
            } else {
                allowedTypes = [ThresholdType.absolute, ThresholdType.percentage]
            }
        }
        return allowedTypes
    }

    const addEmptyThreshold = () => {
        const emptyThreshold: Threshold = {
            id: moment().unix().toString(),
            value: 0,
            absolute_value: 0,
            rule: ThresholdRule.lower,
            level: ThresholdLevel.low,
            type: filterAvailableThresholdTypes()[0],
            enabled: true,
        }
        setVariableThresholds([...variableThresholds, emptyThreshold])
    }

    const removeSelectedThreshold = (thresholdIndex: number) => {
        const currentThresholds = variableThresholds.filter((_th, index) => thresholdIndex !== index)
        setVariableThresholds(currentThresholds)
    }

    const updateThreshold = (thresholdIndex: number, parameterToEdit: string, newValue: any) => {
        setVariableThresholds(
            variableThresholds.map((th, i) => {
                if (i !== thresholdIndex) return th
                return { ...th, [parameterToEdit]: newValue }
            })
        )
    }

    const updateAbsoluteValue = (thresholdIndex: number, updatedParameter: string, newParameterValue: any) => {
        const thresholdToEdit = deepCopy(variableThresholds[thresholdIndex])
        let newType = thresholdToEdit.type
        let newValue = thresholdToEdit.value
        if (updatedParameter === 'type') newType = newParameterValue
        if (updatedParameter === 'value') newValue = newParameterValue
        const calculatedValue =
            newType === ThresholdType.percentage && deviceModalRefValue > 0
                ? (newValue * deviceModalRefValue) / 100
                : newValue
        thresholdToEdit.absolute_value = calculatedValue
        thresholdToEdit.value = newValue
        thresholdToEdit.type = newType
        setVariableThresholds(
            variableThresholds.map((th, i) => {
                if (i !== thresholdIndex) return th
                return thresholdToEdit
            })
        )
    }

    const getLevelOptionLabel = (levelIndex: number) => {
        switch (AlarmLevels[levelIndex]) {
            case 'high':
                return (
                    <div className="simple-row">
                        <FontAwesomeIcon icon={faChevronUp} color={COLORS.events.high} />
                        High
                    </div>
                )
            case 'medium':
                return (
                    <div className="simple-row">
                        <FontAwesomeIcon icon={faMinus} color={COLORS.events.medium} />
                        Medium
                    </div>
                )
            case 'low':
            default:
                return (
                    <div className="simple-row">
                        <FontAwesomeIcon icon={faChevronDown} color={COLORS.events.low} />
                        Low
                    </div>
                )
        }
    }

    const getRuleIcon = (rule: ThresholdRule) => {
        switch (rule) {
            case ThresholdRule.notEqual:
                return faNotEqual
            case ThresholdRule.higher:
                return faGreaterThan
            case ThresholdRule.lower:
            default:
                return faLessThan
        }
    }

    const getLevelClass = (level: ThresholdLevel) => {
        switch (level) {
            case ThresholdLevel.high:
                return 'high'
            case ThresholdLevel.medium:
                return 'medium'
            case ThresholdLevel.low:
            default:
                return 'low'
        }
    }

    const foundValueFromMap = (absVal: number) => {
        const valToCheck = absVal.toString()
        const foundValFromMap =
            availableValuesFromMap.find((availableVal) => availableVal.value === valToCheck) || undefined
        if (foundValFromMap) {
            return { value: foundValFromMap.value, label: foundValFromMap.label }
        }
        return { value: availableValuesFromMap[0].value, label: availableValuesFromMap[0].label }
    }

    return (
        <div className="alert-modal-wrapper">
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => props.onClose()}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            <div
                className="dark-cover"
                onClick={() => {
                    if (!isView) {
                        setShowAlertModal(true)
                    } else {
                        props.onClose()
                    }
                }}
            />
            <div className="modal edit" style={{ width: 'auto' }}>
                <div className="headerSection addNewSection">
                    <h2>
                        <span className="titleLink" style={{ color: '#52565A', opacity: 0.5 }}>
                            {t('fields.variable')}
                        </span>{' '}
                        - {props.isNew ? t('fields.newVariable') : props.defaultVar.name}
                    </h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        {isView ? (
                            <Button
                                label={t('common.update')}
                                backgroundColor="#EA0B2A"
                                textColor="#fff"
                                iconColor="#fff"
                                margin="0 25px 0 0"
                                icon={faPen}
                                onPress={() => setIsView(false)}
                            />
                        ) : (
                            <>
                                <Button
                                    label={props.isNew ? t('common.add') : t('common.save')}
                                    backgroundColor="#EA0B2A"
                                    textColor="#fff"
                                    iconColor="#fff"
                                    margin="0 25px 0 0"
                                    isDisabled={dataIsNotCorrect()}
                                    icon={props.isNew ? faPlus : faPen}
                                    onPress={async () => {
                                        if (isNetwokAvailability || (variable.oid && variable.name)) {
                                            parseVariableParams()
                                        }
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        marginTop: 8,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    {warningMessage.map((message) => (
                                        <p key={message} className="system-warning-text">
                                            {message}
                                        </p>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!isNetwokAvailability ? (
                    <div className="advanced-search-wrapper new-var-wrapper">
                        <div className="elements-row three-elements-row">
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.OidVariable')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <TextField
                                    onlyVisible={isView}
                                    label={t('fields.OidVariable')}
                                    value={variable.oid}
                                    onChangeText={(value: string) => setVariable({ ...variable, oid: value })}
                                />
                            </div>
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.name')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <TextField
                                    onlyVisible={isView}
                                    label={t('fields.name')}
                                    value={variable.name}
                                    onChangeText={(value: string) => setVariable({ ...variable, name: value })}
                                />
                            </div>
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.customName')}</h3>
                                </div>
                                <TextField
                                    onlyVisible={isView}
                                    label={t('fields.customName')}
                                    value={variable.customName}
                                    onChangeText={(value: string) => setVariable({ ...variable, customName: value })}
                                />
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.description')}</h3>
                                <TextField
                                    onlyVisible={isView}
                                    label={t('fields.description')}
                                    isTextarea
                                    value={variable.description}
                                    onChangeText={(value: string) => setVariable({ ...variable, description: value })}
                                />
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.unitOfMeasure')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={isView}
                                        label={t('fields.unitOfMeasure')}
                                        value={variable.unitOfMeasure}
                                        onChangeText={(value: string) =>
                                            setVariable({ ...variable, unitOfMeasure: value })
                                        }
                                    />
                                </div>
                                <CheckboxInput
                                    disabled={isView}
                                    label={t('fields.infoReport')}
                                    value={variable.infoReport === 1}
                                    onChangeValue={(value: boolean) =>
                                        setVariable({ ...variable, infoReport: value ? 1 : 0 })
                                    }
                                />
                            </div>

                            <div className="filter-column">
                                <div
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}
                                >
                                    <h3>{t('fields.conversionValue')}</h3>
                                    <Tooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                {t('fields.conversionValueHint')}
                                            </div>
                                        }
                                        style={{ paddingBottom: 4 }}
                                    >
                                        <div>
                                            <FontAwesomeIcon icon={faCircleInfo} color={COLORS.palette.darkGrey} />
                                        </div>
                                    </Tooltip>
                                </div>

                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={isView}
                                        label={'32 + 1.8 * x'}
                                        value={conversionValue}
                                        onChangeText={(value: string) => setConversionValue(value)}
                                    />
                                </div>
                                {conversionValue && (
                                    <>
                                        <Typography variant="caption">
                                            {t('fields.conversionValueHintCaption')}
                                        </Typography>
                                        <p>
                                            {mathEvaluation}
                                            {variable.unitOfMeasure}
                                        </p>
                                    </>
                                )}

                                {variable.modelSchemaRef && (
                                    <>
                                        <h3>{t('fields.modelSchemaRefValue')}</h3>
                                        <div className="input-rows">
                                            <p>{variable.modelSchemaRef}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {props.preSetEnabled && (
                            <div className="filter-column" style={{ marginBottom: 24 }}>
                                <h3>{t('fields.variablePreSetValue')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={isView}
                                        label={t('fields.variablePreSetValue')}
                                        value={variable.writeCommand}
                                        onChangeText={(value: string) =>
                                            setVariable({ ...variable, writeCommand: value })
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="elements-row three-elements-row">
                            <h2>{t('fields.alarmsThresholds')}</h2>
                            <div />
                            <Button
                                label={t('common.add')}
                                backgroundColor="#efefef"
                                textColor="#3f3f3f"
                                iconColor="#e10915"
                                margin="0 0 0 220px"
                                isDisabled={variableThresholds.length === 3 || isView}
                                icon={faPlus}
                                onPress={() => {
                                    addEmptyThreshold()
                                }}
                            />
                        </div>
                        {variableThresholds &&
                            variableThresholds.map((threshold, index) => (
                                <div key={threshold.id} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="threshold-row">
                                        <div className="filter-column">
                                            <span
                                                className={`dotAlert largeDot ${
                                                    threshold.enabled ? 'enabledDot' : 'regularDot'
                                                } ${getLevelClass(threshold.level)}-level-threshold-dot`}
                                            >
                                                <FontAwesomeIcon icon={getRuleIcon(threshold.rule)} />
                                            </span>
                                        </div>
                                        <div className="separator-vertical-line" />
                                        <div>{t('thresholdManagementParagraph.chechThatThe')}</div>
                                        {user.language !== 'en-GB' && (
                                            <div>{t('thresholdManagementParagraph.value')}</div>
                                        )}
                                        <div className="filter-column">
                                            <div className="input-rows">
                                                <SelectInput
                                                    width={'120px'}
                                                    isSmall
                                                    disabled={
                                                        isView ||
                                                        deviceModalRefValue === 0 ||
                                                        availableValuesFromMap !== null
                                                    }
                                                    options={filterAvailableThresholdTypes().map((l, i) => {
                                                        return {
                                                            value: i.toString(),
                                                            label: t(`fields.threshold_${l}`),
                                                        }
                                                    })}
                                                    value={{
                                                        value: threshold.type,
                                                        label: t(`fields.threshold_${threshold.type}`),
                                                    }}
                                                    placeholder={t('fields.type')}
                                                    onValueChange={(v) => {
                                                        const newType =
                                                            v.label ===
                                                            t(`fields.threshold_${ThresholdType.percentage}`)
                                                                ? ThresholdType.percentage
                                                                : ThresholdType.absolute
                                                        updateAbsoluteValue(index, 'type', newType)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {user.language === 'en-GB' && (
                                            <div>{t('thresholdManagementParagraph.value')}</div>
                                        )}
                                        <div>{t('thresholdManagementParagraph.is')}</div>
                                        <div className="filter-column">
                                            <div className="input-rows">
                                                <SelectInput
                                                    width={'120px'}
                                                    isSmall
                                                    disabled={isView}
                                                    options={ThresholdRules.map((l, i) => {
                                                        return { value: i.toString(), label: t(`thresholdRules.${l}`) }
                                                    })}
                                                    value={{
                                                        value: threshold.rule,
                                                        label: t(`thresholdRules.${threshold.rule}`),
                                                    }}
                                                    placeholder={t('fields.thresholdRule')}
                                                    onValueChange={(v) => {
                                                        const newType = ThresholdRules[v.value]
                                                        updateThreshold(index, 'rule', newType)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {t('thresholdManagementParagraph.than', {
                                                variant:
                                                    user.language === 'en-GB' ||
                                                    threshold.type !== ThresholdType.percentage
                                                        ? ''
                                                        : 'l',
                                            })}
                                        </div>
                                        {availableValuesFromMap ? (
                                            <div className="filter-column">
                                                <div className="input-rows">
                                                    <SelectInput
                                                        width={'140px'}
                                                        isSmall
                                                        disabled={isView}
                                                        options={availableValuesFromMap.map((l) => {
                                                            return { value: l.value, label: `${l.label} (${l.value})` }
                                                        })}
                                                        value={foundValueFromMap(threshold.value)}
                                                        placeholder={t('fields.value')}
                                                        onValueChange={(v) => {
                                                            updateAbsoluteValue(index, 'value', v.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="filter-column">
                                                <TextField
                                                    onlyVisible={isView}
                                                    width="40px"
                                                    small
                                                    label={t('fields.value')}
                                                    value={
                                                        !Number.isNaN(threshold.value) ? threshold.value.toString() : ''
                                                    }
                                                    onChangeText={(value: string) => {
                                                        updateAbsoluteValue(index, 'value', value)
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {!props.isModelVar && threshold.type === ThresholdType.percentage ? (
                                            <Tooltip title={threshold.absolute_value}>
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faCircleInfo}
                                                        color={COLORS.palette.darkGrey}
                                                    />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <div style={{ width: 0 }} />
                                        )}
                                        <div>{t('thresholdManagementParagraph.andCreateAnEventWith')}</div>
                                        {user.language !== 'en-GB' && (
                                            <div>{t('thresholdManagementParagraph.priority')}</div>
                                        )}
                                        <div className="filter-column">
                                            <div className="input-rows">
                                                <SelectInput
                                                    width={'145px'}
                                                    isSmall
                                                    disabled={isView}
                                                    options={AlarmLevels.map((_l, i) => {
                                                        return { value: i.toString(), label: getLevelOptionLabel(i) }
                                                    })}
                                                    value={{
                                                        value: threshold.level ? threshold.level.toString() : 'low',
                                                        label: getLevelOptionLabel(threshold.level),
                                                    }}
                                                    placeholder={t('fields.thresholdLevel')}
                                                    onValueChange={(v) => {
                                                        const newLevel = parseInt(v.value, 10)
                                                        updateThreshold(index, 'level', newLevel)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {user.language === 'en-GB' && (
                                            <div>{t('thresholdManagementParagraph.priority')}</div>
                                        )}
                                        <div className="separator-vertical-line" />
                                        <div>{t('fields.thresholdPre')}</div>
                                        <div className="filter-column">
                                            <div className="input-rows">
                                                <TextField
                                                    onlyVisible={isView}
                                                    width="20px"
                                                    small
                                                    label={'0'}
                                                    value={
                                                        threshold.delay && !Number.isNaN(threshold.delay)
                                                            ? threshold.delay.toString()
                                                            : ''
                                                    }
                                                    onChangeText={(value: string) => {
                                                        updateThreshold(index, 'delay', parseInt(value, 10))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>{t('fields.thresholdPost')}</div>
                                        <div className="separator-vertical-line" />
                                        <div className="filter-column">
                                            <CheckboxInput
                                                disabled={isView || Number.isNaN(threshold.value)}
                                                label={t('fields.enabled')}
                                                value={threshold.enabled}
                                                onChangeValue={(value: boolean) =>
                                                    updateThreshold(index, 'enabled', value)
                                                }
                                            />
                                        </div>
                                        <div className="filter-column">
                                            <Button
                                                label=""
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                iconColor="#e10915"
                                                margin="0"
                                                isDisabled={isView}
                                                icon={faMinusCircle}
                                                onPress={() => {
                                                    removeSelectedThreshold(index)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="threshold-custom-string-container">
                                            <Tooltip title={t('fields.customStringExplanation')}>
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faCircleInfo}
                                                        color={COLORS.palette.darkGrey}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <TextField
                                                onlyVisible={isView}
                                                width="100%"
                                                small
                                                label={t('fields.customString')}
                                                value={threshold.event_string || threshold.eventString || ''}
                                                onChangeText={(value: string) => {
                                                    updateThreshold(index, 'event_string', value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                ) : (
                    <>
                        <div className="input-rows">
                            <div className="filter-column">
                                <CheckboxInput
                                    disabled={isView}
                                    label={
                                        variableThresholds.length > 0
                                            ? t('fields.enableNetworkAvailability')
                                            : t('fields.disableNetworkAvailability')
                                    }
                                    value={variableThresholds.length > 0}
                                    onChangeValue={(value: boolean) => {
                                        if (value) {
                                            setVariableThresholds([DefaultNetworkAvailabilityThreshold])
                                        } else {
                                            setVariableThresholds([])
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {variableThresholds.length > 0 && (
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '12px' }}>
                                <div>{t('fields.thresholdPre')}</div>
                                <div style={{ width: '40px' }}>
                                    <TextField
                                        onlyVisible={isView}
                                        width="20px"
                                        small
                                        label={'0'}
                                        value={
                                            variableThresholds[0].delay && !Number.isNaN(variableThresholds[0].delay)
                                                ? variableThresholds[0].delay.toString()
                                                : ''
                                        }
                                        onChangeText={(value: string) => {
                                            updateThreshold(0, 'delay', parseInt(value, 10))
                                        }}
                                    />
                                </div>

                                <div>{t('fields.thresholdPost')}</div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default DefaultVarModal
