import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import CustomAxios from '../../customComponents/customAxios'
import { GeniusData } from '../../types/data/genius'
import { Chip, Paper, Typography } from '@material-ui/core'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import SkeletonTemplate, { SkeletonRowsItem } from '../../Components/StaticComponents/SkeletonTemplate'
import moment from 'moment'
import { ReducerData, ReducerUser, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { config } from '../../config/config'
const GeniusPage: React.FC = () => {
    const { t } = useTranslation()
    const { configurations } = useSelector<Reducers, ReducerData>((state) => state.data)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [geniusData, setGeniusData] = useState<GeniusData | undefined>()
    const [geniusAI, setGeniusAI] = useState<string | undefined>()
    const [isAIEnabled, setIsAIEnabled] = useState<boolean>(false)
    const [isAIConfigured, setIsAIConfigured] = useState<boolean>(false)

    const getData = useCallback(async (cache: boolean) => {
        setLoading(true)
        const response = await CustomAxios.get(`genius/data?cache=${cache}`).then((response) => response.data)

        response.report.powerAnalysis.totalSaving = 0
        response.report.powerAnalysis.devices.forEach((item) => {
            if (item.saving.saving) {
                response.report.powerAnalysis.totalSaving += item.saving.saving
            }
        })

        setGeniusData(response)
        setLoading(false)
    }, [])

    useEffect(() => {
        const geniusEnabled = configurations.find((param) => param.key === 'genius_enabled')
        const geniusKey = configurations.find((param) => param.key === 'genius.key')

        if (geniusEnabled) {
            setIsAIEnabled(geniusEnabled.value === 'true')
        }
        if (geniusKey) {
            setIsAIConfigured(geniusKey.value !== '')
        }

        void getData(true)
    }, [configurations])

    const loadAIData = async () => {
        if (!isAIEnabled || !isAIConfigured) return
        let userLang = 'english'
        if (user.language.includes('en')) {
            userLang = 'english'
        }
        if (user.language.includes('it')) {
            userLang = 'italian'
        }

        setGeniusAI(undefined)
        const responseAI = await fetch(
            `${config.api.baseURL}/genius/ai?aiEventsHistory=true&cache=false&locale=${userLang}`
        )
        const reader = responseAI.body.getReader()
        const decoder = new TextDecoder()
        const loopRunner = true

        while (loopRunner) {
            // Here we start reading the stream, until its done.
            const { value, done } = await reader.read()
            if (done) {
                break
            }
            const decodedChunk = decoder.decode(value, { stream: true })
            setGeniusAI((prevAnswer) => (prevAnswer ? prevAnswer + decodedChunk : decodedChunk)) // update state with new chunk
        }
    }
    useEffect(() => {
        void loadAIData()
    }, [isAIEnabled])

    return (
        <PageContainer>
            <div style={{ backgroundColor: '#EFEFEE' }}>
                <div className="headerSection row-header-section">
                    <div>{t('fields.genius')}</div>
                    {geniusData && !loading && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Chip label={t('genius.updateMessage') + moment(geniusData.elaboratedOn).format('HH:mm')} />
                        </div>
                    )}
                </div>
                {isAIEnabled && isAIConfigured && (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '2%',
                            marginLeft: '2%',
                            justifyContent: 'center',
                            gap: '2%',
                        }}
                    >
                        <Paper elevation={3} style={Styles.paperLarge}>
                            {geniusAI && geniusAI.length > 10 ? (
                                <Typography variant="overline" display="block" color="error" gutterBottom>
                                    {t('genius.aiResultTitle')}
                                </Typography>
                            ) : (
                                <Typography variant="overline" display="block" color="error" gutterBottom>
                                    {t('genius.aiResultProgress')}
                                </Typography>
                            )}
                            <div style={{ ...Styles.paperBody, alignItems: 'flex-start' }}>
                                {geniusAI ? (
                                    <div dangerouslySetInnerHTML={{ __html: geniusAI }} />
                                ) : (
                                    <SkeletonRowsItem />
                                )}
                            </div>
                        </Paper>
                    </div>
                )}
                {isAIEnabled && !isAIConfigured && (
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '2%',
                            marginLeft: '2%',
                            justifyContent: 'center',
                            gap: '2%',
                        }}
                    >
                        <Paper elevation={3} style={Styles.paperLarge}>
                            <div style={{ ...Styles.paperBody, alignItems: 'flex-start' }}>
                                <div>{t('fields.genius')}</div>
                                <Typography variant="overline" display="block" color="error" gutterBottom>
                                    {t('genius.configurationHint')}
                                </Typography>
                            </div>
                        </Paper>
                    </div>
                )}

                {loading ? (
                    <SkeletonTemplate />
                ) : (
                    geniusData && (
                        <>
                            <Typography
                                variant="overline"
                                display="block"
                                color="error"
                                gutterBottom
                                style={{ ...Styles.headerTitle, marginTop: '3%' }}
                            >
                                {t('genius.newtworkQualitySection.title')}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: '2%',
                                    marginLeft: '2%',
                                    justifyContent: 'center',
                                    gap: '2%',
                                }}
                            >
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.newtworkQualitySection.registeredDevices')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <Typography variant="h1" display="block" color="error" gutterBottom>
                                            {geniusData.devices.items}
                                        </Typography>
                                    </div>
                                </Paper>
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.newtworkQualitySection.notReachableDevices')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <Typography variant="h1" display="block" color="error" gutterBottom>
                                            {geniusData.devices.unreachables}
                                        </Typography>
                                        <div style={{ width: 70, height: 70 }}>
                                            <CircularProgressbar
                                                value={
                                                    (geniusData.devices.unreachables / geniusData.devices.items) * 100
                                                }
                                                text={`${(
                                                    (geniusData.devices.unreachables / geniusData.devices.items) *
                                                    100
                                                ).toFixed(1)}%`}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.newtworkQualitySection.alarmedDevices')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <Typography variant="h1" display="block" color="error" gutterBottom>
                                            {geniusData.devices.alarmed}
                                        </Typography>
                                        <Typography variant="h6" display="block" gutterBottom>
                                            <div style={{ width: 70, height: 70 }}>
                                                <CircularProgressbar
                                                    value={
                                                        (geniusData.devices.alarmed / geniusData.devices.items) * 100
                                                    }
                                                    text={`${(
                                                        (geniusData.devices.alarmed / geniusData.devices.items) *
                                                        100
                                                    ).toFixed(1)}%`}
                                                />
                                            </div>
                                        </Typography>
                                    </div>
                                </Paper>
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.newtworkQualitySection.inMaintenanceMode')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <Typography variant="h1" display="block" color="error" gutterBottom>
                                            {geniusData.devices.maintenance}
                                        </Typography>
                                        <div style={{ width: 70, height: 70 }}>
                                            <CircularProgressbar
                                                value={
                                                    (geniusData.devices.maintenance / geniusData.devices.items) * 100
                                                }
                                                text={`${(
                                                    (geniusData.devices.maintenance / geniusData.devices.items) *
                                                    100
                                                ).toFixed(1)}%`}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                            </div>

                            <Typography
                                variant="overline"
                                display="block"
                                color="error"
                                gutterBottom
                                style={{ ...Styles.headerTitle, marginTop: '3%' }}
                            >
                                {t('genius.instanceOptimization.title')}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: '2%',
                                    marginLeft: '2%',
                                    justifyContent: 'center',
                                    gap: '2%',
                                }}
                            >
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.instanceOptimization.optimizationLevel')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <div style={{ width: 200, height: 200 }}>
                                            <CircularProgressbar
                                                value={geniusData.report.percentage}
                                                text={`${geniusData.report.percentage.toFixed(0)}%`}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={3} style={{ ...Styles.paper, width: '55%' }}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.instanceOptimization.suggestedOptimizations')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        {geniusData.report.optimizations.map((item) => (
                                            <div
                                                key={item.description[user.language.split('-')[0]]}
                                                style={Styles.optimizationList}
                                            >
                                                <div style={{ width: 50, height: 50 }}>
                                                    <CircularProgressbar value={item.value} text={`${item.value}%`} />
                                                </div>
                                                <Typography
                                                    variant="caption"
                                                    key={item.description[user.language.split('-')[0]]}
                                                >
                                                    {item.description[user.language.split('-')[0]]}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </Paper>
                            </div>
                            {/* 
                            <Typography variant="overline" display="block" color="error" gutterBottom style={{ ...Styles.headerTitle, marginTop: '3%' }}>
                                {t('genius.energyOptimization.title')}
                            </Typography>
                            <Paper elevation={3} style={{ ...Styles.paper, margin: '0 auto', width: '25%' }}>
                                <Typography variant="overline" display="block" color="error" gutterBottom>
                                    {t('genius.energyOptimization.optimizationLevel')}
                                </Typography>
                                <div style={Styles.paperBody}>
                                    <div style={{ width: 200, height: 200 }}>
                                        <CircularProgressbar
                                            value={
                                                ((geniusData.devices.items - geniusData.report.powerAnalysis.devices.length) / geniusData.devices.items) * 100
                                            }
                                            text={`${(
                                                ((geniusData.devices.items - geniusData.report.powerAnalysis.devices.length) / geniusData.devices.items) *
                                                100
                                            ).toFixed(1)}%`}
                                        />
                                    </div>
                                </div>
                            </Paper>
                            <div style={{ display: 'flex', marginTop: '2%', marginLeft: '2%', justifyContent: 'center', gap: '2%' }}>
                                <Paper elevation={3} style={Styles.paper}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        Elenos Indium {t('genius.energyOptimization.optimizable')}
                                    </Typography>
                                    <div style={Styles.paperBody}>
                                        <Typography variant="h1" display="block" color="error" gutterBottom>
                                            {geniusData.report.powerAnalysis.devices.length}
                                        </Typography>
                                        <div style={{ width: 70, height: 70 }}>
                                            <CircularProgressbar
                                                value={(geniusData.report.powerAnalysis.devices.length / geniusData.devices.items) * 100}
                                                text={`${((geniusData.report.powerAnalysis.devices.length / geniusData.devices.items) * 100).toFixed(1)}%`}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={3} style={{ ...Styles.paper, width: '55%' }}>
                                    <Typography variant="overline" display="block" color="error" gutterBottom>
                                        {t('genius.energyOptimization.suggestedOptimizations')}
                                    </Typography>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        {t('genius.energyOptimization.description')}
                                    </Typography>
                                    <Typography variant="h3" color="error" gutterBottom>
                                        {Math.round((geniusData.report.powerAnalysis.totalSaving / 1000) * 0.3 * 24 * 365)} €
                                    </Typography>
                                    <div style={{ ...Styles.paperBodyScroll, marginTop: '4%' }}>
                                        {geniusData.report.powerAnalysis.devices.map((item) => (
                                            <div key={item.idDevice} style={Styles.optimizationList}>
                                                <div style={{ width: 50, height: 50 }}>
                                                    <CircularProgressbar
                                                        value={(item.maxPower / item.nominalPower) * 100}
                                                        text={`${(item.maxPower / item.nominalPower) * 100}%`}
                                                    />
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="subtitle1" key={item.idDevice}>
                                                        [{item.maxPower}W / {item.nominalPower}W] {item.deviceName}
                                                    </Typography>
                                                    {item.variation && (
                                                        <>
                                                            <Typography variant="subtitle2" key={item.idDevice} style={{ fontStyle: 'italic' }}>
                                                                {t('genius.energyOptimization.reccomendedModel')}
                                                                <Typography variant="button" key={item.idDevice} style={{ fontWeight: 'bold' }}>
                                                                    {item.variation.name}
                                                                </Typography>
                                                            </Typography>
                                                            {item.saving && item.saving.saving && (
                                                                <>
                                                                    <Typography variant="body2" key={item.idDevice} style={{ fontStyle: 'italic' }}>
                                                                        {t('genius.energyOptimization.estimatedSaving')} {item.saving.saving} Wh |{' '}
                                                                        {t('genius.energyOptimization.estimatedAnnualSaving')}
                                                                        {Math.round((item.saving.saving / 1000) * 0.3 * 24 * 365)} €
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Paper>
                            </div> */}
                        </>
                    )
                )}
                <Footer />
            </div>
        </PageContainer>
    )
}

const Styles = {
    headerTitle: { 'width': '100%', 'text-align': 'center' },
    paper: {
        'width': '20%',
        'minHeight': '300px',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'flex-direction': 'column',
        'padding': '1%',
        'overflow': 'hidden',
    },
    paperBody: {
        'width': '80%',
        'minHeight': '300px',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'gap': '1%',
        'flex-direction': 'column',
    },
    paperBodyScroll: {
        'width': '80%',
        'height': '300px',
        'display': 'flex',
        'justifyContent': 'flex-start',
        'alignItems': 'center',
        'gap': '1%',
        'flex-direction': 'column',
        'overflow-y': 'auto',
    },
    optimizationList: { display: 'flex', alignItems: 'center', width: '100%', gap: '2%', marginBottom: '2%' },
    paperLarge: {
        'width': '80%',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'flex-direction': 'column',
        'padding': '1%',
    },
}

export default GeniusPage
