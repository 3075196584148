import React, { useEffect, useState } from 'react'
import CustomAxios from '../../customComponents/customAxios'
import { Device } from '../../types/data/alarm'
import SelectInput, { SelectOptionValue } from '../Inputs/SelectInput'
import TextField from '../Inputs/TextField'
import UserDevicesNotificationsTable from '../Tables/UserDevicesNotificationsTable'
import { useTranslation } from 'react-i18next'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    currentUser: any
}

const UserAssociatedDevices: React.FC<Props> = (props) => {
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [deviceName, setDeviceName] = useState('')
    const [toggleReloads, setToggleReloads] = useState(undefined)
    const [availableDevices, setAvailableDevices] = useState<Device[]>([])
    const { t } = useTranslation()
    const [deviceToAssociate, setDeviceToAssociate] = useState<SelectOptionValue | null>({
        label: t('fields.chooseDevice'),
        value: '',
    })

    const associateNewDevice = async () => {
        try {
            const newDeviceAssociationData = {
                idUser: props.currentUser.ID,
                idDevice: deviceToAssociate.value,
                emailEnabled: true,
                smsEnabled: false,
                minAlarmLevelNotification: 1,
            }
            await CustomAxios.post('users-devices', newDeviceAssociationData)
            setToggleReloads(!toggleReloads)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchDevices = async () => {
        try {
            const devices: Device[] = await CustomAxios.get('devices').then((response) => response.data.data)
            const userDevices = await CustomAxios.get(`users-devices?user=${loggedUser.ID}`).then(
                (response) => response.data.data
            )
            setAvailableDevices(
                devices.filter((device) => userDevices.every((userDevice) => userDevice.device.ID !== device.ID))
            )
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchDevices()
    }, [])

    return (
        <div style={{ margin: '30px 0 0' }}>
            {(loggedUser.idSysGrant === 0 ||
                loggedUser.managedNetworks.find((networkId) =>
                    props.currentUser.networks.find((network) => network.ID === networkId)
                )) && (
                <div className="fast-command-row">
                    <span>{t('fields.associateNewDevice')}:</span>
                    <SelectInput
                        value={deviceToAssociate}
                        onValueChange={(value) => setDeviceToAssociate(value)}
                        options={availableDevices.map((device: Device) => {
                            return { label: device.name, value: device.ID.toString() }
                        })}
                        width={'100%'}
                    />
                    <button
                        disabled={deviceToAssociate.value === ''}
                        className="standard-confirm-button"
                        onClick={() => void associateNewDevice()}
                    >
                        {t('common.associate')}
                    </button>
                </div>
            )}
            <div style={{ margin: '0 25px 0 0' }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField
                            label={t('searches.searchByName')}
                            value={deviceName}
                            onChangeText={(value: string) => setDeviceName(value)}
                        />
                    </div>
                </div>
            </div>
            <UserDevicesNotificationsTable
                searchQuery={deviceName}
                reloads={toggleReloads}
                idUser={props.currentUser.ID}
            />
        </div>
    )
}

export default UserAssociatedDevices
