import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import CustomAxios from '../../customComponents/customAxios'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import AlertModal from '../Modals/AlertModal'
import { singleScheduler } from '../../utils/api-constants'
import moment from 'moment'
import { useTablePagination } from '../../utils/hooks/common'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    filterParams?: any
    reloads?: boolean
    onFinish?: () => void
}

const SchedulerTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const { t } = useTranslation()

    const [selectedElementToRemove, setSelectedElementToRemove] = useState(-1)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const deleteMessage = t('systemMessages.removeElementFromList')
    const dispatch = useDispatch()

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const columns = [
        {
            title: t('fields.active'),
            field: 'enabled',
            render: (rowData: any) => {
                return (
                    <div>
                        <span className={`dotAlert ${rowData.enabled === 1 ? 'enabledDot' : 'regularDot'}`} />
                    </div>
                )
            },
        },
        {
            title: t('fields.command'),
            field: 'command',
        },
        {
            title: t('fields.creator'),
            field: 'id_user',
            render: (rowData: any) => {
                return (
                    <div
                        onClick={() =>
                            navigate(`/${ROUTE_NAMES.USERS}/${rowData?.user.ID}`, { state: { isView: true } })
                        }
                        className="tableElement table-link-value colorRed link"
                    >
                        <span>{`${rowData.user.firstName} ${rowData.user.lastName}`}</span>
                    </div>
                )
            },
        },
        {
            title: t('fields.associatedTo'),
            field: 'association',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.idDevice !== null && rowData.idDevice !== -1 ? (
                            <a
                                className="tableElement table-link-value colorRed link"
                                // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
                                href={`/${ROUTE_NAMES.DEVICE}/${rowData?.idDevice}`}
                            >
                                {rowData.device.name}
                            </a>
                        ) : rowData.idNetworkGroup !== null && rowData.idNetworkGroup !== -1 ? (
                            <>
                                {user.idSysGrant === 0 ? (
                                    <div
                                        onClick={() =>
                                            navigate(`/${ROUTE_NAMES.GROUPS}/${rowData?.idNetworkGroup}`, {
                                                state: { isView: true },
                                            })
                                        }
                                        className="tableElement table-link-value colorRed link"
                                    >
                                        {rowData.networkGroup.name}
                                    </div>
                                ) : (
                                    <div>{rowData.networkGroup.name}</div>
                                )}
                            </>
                        ) : null}
                    </>
                )
            },
        },
        {
            title: t('fields.executions'),
            field: 'last_execution_date',
            render: (rowData: any) => {
                return (
                    <>
                        <div className="table-event-value-block">
                            {rowData.lastExecutionDate !== null && (
                                <span>
                                    {t('fields.lastRun')}:{' '}
                                    <b>{moment(rowData.lastExecutionDate).format('DD/MM/YYYY HH:mm:ss')}</b>
                                </span>
                            )}
                            {rowData.nextExecutionDate !== null && (
                                <span>
                                    {t('fields.nextRun')}:{' '}
                                    <b>{moment(rowData.nextExecutionDate).format('DD/MM/YYYY HH:mm:ss')}</b>
                                </span>
                            )}
                        </div>
                    </>
                )
            },
        },
    ]

    const initRemoveProcedure = (schedulerId: number) => {
        setOpenAlertModal(true)
        setSelectedElementToRemove(schedulerId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.delete(singleScheduler(selectedElementToRemove))
            exitRemoveProcedure()
            resetData(currentPage, '', '', true)
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedElementToRemove(-1)
    }

    const actions = useMemo(() => {
        const actions = [
            (rowData: any) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}/${rowData.ID}`),
                tooltip: t('common.edit'),
            }),
            user.idSysGrant === 0 &&
                ((rowData: any) => ({
                    icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                    onClick: () => initRemoveProcedure(rowData.ID),
                    tooltip: t('common.remove'),
                })),
        ]
        return actions
    }, [])

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData(currentPage, '', '', true)
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData(currentPage, '', '', true)
        }
    }, [props.reloads])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'schedulers'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: handleDataCount(response.data.count),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                    navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}?page=${pageIndex}`)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 6,
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default SchedulerTable
