import React from 'react'

interface Props {
    label: string
    value: string | null
    icon?: string
}

const DataLabelViewer: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    paddingLeft: 70,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left 10px center',
                    backgroundImage: props.icon ? `url(${props.icon})` : '',
                    minWidth: 125,
                    color: '#acacac',
                    fontWeight: 400,
                    width: '10vw',
                }}
            >
                {props.label}
            </div>
            -{props.value !== null ? <div /> : null}
        </div>
    )
}

export default DataLabelViewer
