import React, { useEffect, useState } from 'react'
import '../styles/components/tabs.sass'

export interface TabElement {
    value: string
    label: string
}

interface Props {
    tabs: TabElement[]
    defaultSelectedTabIndex?: number
    onSelectedTabChange: (selectedTab: string) => void
}

const Tabs: React.FC<Props> = (props) => {
    const [selectedTabValue, setSelectedTabValue] = useState(
        props.tabs.length > 0
            ? props.defaultSelectedTabIndex
                ? props.tabs[props.defaultSelectedTabIndex].value
                : props.tabs[0].value
            : ''
    )

    useEffect(() => {
        props.onSelectedTabChange(selectedTabValue)
    }, [selectedTabValue])

    return (
        <>
            {props.tabs.length > 0 && (
                <div className="tabs-container">
                    {props.tabs.map((tab: TabElement) => {
                        return (
                            <div
                                key={tab.value}
                                onClick={() => setSelectedTabValue(tab.value)}
                                className={`tab ${tab.value === selectedTabValue ? 'selected-tab' : ''}`}
                            >
                                {tab.label}
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default Tabs
