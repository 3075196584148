import axios from 'axios'
import { config } from '../config/config'

const CustomAxiosImages = axios.create({
    baseURL: config.api.baseURL,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

export default CustomAxiosImages
