import React from 'react'
import ReactDOM from 'react-dom'
import Setup from './setup'
import './styles/index.sass'
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <React.StrictMode>
        <Setup />
    </React.StrictMode>,
    document.getElementById('root')
)
