import React from 'react'
import '../../styles/pages/main.sass'

interface Props {
    label: string
    value: string
    required?: boolean
    maxLength?: number
    onChangeText?: (value: string) => void
    onlyVisible?: boolean
    withButton?: boolean
    button?: React.ReactElement
    width?: number | string
    placeholder?: string
    smallInput?: boolean
}

const TextInput: React.FC<Props> = (props) => {
    const defaultInputMaxLength = 524288

    if (props.withButton) {
        return (
            <div className="selectedInput">
                {props.label !== '' && (
                    <div className="labelInput label-input-no-margin">
                        {props.label}
                        {props.required && <label className="required-asterisk">*</label>}
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                    <div className="selectFieldContainer">
                        <input
                            maxLength={props.maxLength ? props.maxLength : defaultInputMaxLength}
                            required={props.required ? props.required : false}
                            placeholder={props.placeholder ? props.placeholder : ''}
                            className="inputField"
                            disabled={props.onlyVisible ? props.onlyVisible : false}
                            style={{
                                minWidth: props.smallInput ? 125 : 200,
                                width: props.width ? props.width : 'inherit',
                            }}
                            value={props.value}
                            onChange={(e) => {
                                if (props.onChangeText) {
                                    props.onChangeText(e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: 20 }}>{props.button}</div>
                </div>
            </div>
        )
    }

    return (
        <div
            className="selectedInput"
            style={{
                minWidth: 125,
                flexDirection: 'column',
            }}
        >
            {props.label !== '' && (
                <div className="labelInput label-input-no-margin">
                    {props.label}
                    {props.required && <label className="required-asterisk">*</label>}
                </div>
            )}
            <div className="selectFieldContainer">
                <input
                    placeholder={props.placeholder ? props.placeholder : ''}
                    className="inputField"
                    disabled={props.onlyVisible}
                    style={{
                        padding: '12px 16px',
                        minWidth: props.smallInput ? 125 : 200,
                        width: props.width ? props.width : 'inherit',
                    }}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChangeText) {
                            props.onChangeText(e.target.value)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default TextInput
