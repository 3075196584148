import React, { useState } from 'react'
import './OnboardingModal.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Chip } from '@material-ui/core'

interface OnboardingModalProps {
    lightMode?: boolean
    onComplete: () => void
    onClose: () => void
}

const OnboardingModal: React.FC<OnboardingModalProps> = (props) => {
    const [selectedStepIndex, setSelectedStepIndex] = useState(0)
    const { t } = useTranslation()
    const onboardingSteps = [
        {
            id: 0,
            text: t('quickhelp.1.title'),
            caption: t('quickhelp.1.body'),
            image: require('../../../resources/gifs/tutorial-1.gif'),
            isVideo: false,
            video: null,
            newFeature: false,
        },
        {
            id: 1,
            text: t('quickhelp.2.title'),
            caption: t('quickhelp.2.body'),
            image: require('../../../resources/gifs/tutorial-2.gif'),
        },
        {
            id: 2,
            text: t('quickhelp.3.title'),
            caption: t('quickhelp.3.body'),
            image: require('../../../resources/gifs/tutorial-3.gif'),
        },
        {
            id: 3,
            text: t('quickhelp.4.title'),
            caption: t('quickhelp.4.body'),
            image: require('../../../resources/gifs/tutorial-4.gif'),
        },
        // {
        //     id: 4,
        //     text: t('quickhelp.5.title'),
        //     caption: t('quickhelp.5.body'),
        //     image: require('../../../resources/gifs/tutorial-5.gif'),
        //     newFeature: true
        // }
    ]

    return (
        <div className="onboarding-modal">
            <div className="dark-cover" />
            <div className="onboarding-modal-panel light-modal-panel">
                <div className="onboarding-modal-panel__header">
                    <div className="icon-button" onClick={() => props.onClose()}>
                        <FontAwesomeIcon className="close-icon" icon={faTimes} />
                    </div>
                    <span>TXControl Quick Help</span>
                    {selectedStepIndex + 1 < onboardingSteps.length ? (
                        <div className="icon-button" onClick={() => setSelectedStepIndex(selectedStepIndex + 1)}>
                            <FontAwesomeIcon className="close-icon" icon={faChevronRight} />
                        </div>
                    ) : (
                        <div className="icon-button" onClick={() => props.onComplete()}>
                            <FontAwesomeIcon className="close-icon" icon={faCheck} />
                        </div>
                    )}
                </div>
                <div className="onboarding-modal-panel__body">
                    <div className="onboarding-modal-panel__body__image-container">
                        {onboardingSteps[selectedStepIndex].isVideo ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video controls width="100%" loop>
                                <source src={onboardingSteps[selectedStepIndex].video} type="video/mp4" />
                            </video>
                        ) : (
                            <img src={onboardingSteps[selectedStepIndex].image} alt="Peperoni onboarding" />
                        )}
                    </div>
                    {onboardingSteps[selectedStepIndex].newFeature && (
                        <Chip color="secondary" label="New Feature" style={{ marginBottom: '2%' }} />
                    )}
                    <div className="onboarding-modal-panel__body__text-container">
                        {onboardingSteps[selectedStepIndex].text}
                    </div>
                    <div className="onboarding-modal-panel__body__caption-container">
                        {onboardingSteps[selectedStepIndex].caption}
                    </div>
                </div>
                <div className="onboarding-modal-panel__footer">
                    {onboardingSteps.map((step) => (
                        <div
                            key={step.id}
                            onClick={() => setSelectedStepIndex(step.id)}
                            className={`step-dot ${step.id === selectedStepIndex ? 'selected-dot' : ''}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OnboardingModal
