import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import '../../styles/pages/main.sass'
import { Reducers, ReducerUser } from '../../types/reducers'
import '../../styles/pages/alarms.sass'
import { faPlus, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { AvailableCommand, Model } from '../../types/data/system'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import DeviceSchedulersTable from '../../Components/Tables/DeviceSchedulersTable'
import { User } from '../../types/data/user'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import CustomAxios from '../../customComponents/customAxios'
import Datepicker from '../../Components/Datepicker'
import AlertModal from '../../Components/Modals/AlertModal'

interface Props {
    device: any
}

export interface Command {
    ID: number
    dateSent: string
    command: string
    idDevice: number
    idUser: number
    dateReply: string | null
    idCommunicationProtocol: number
    responseMessage: string
}

const DeviceSchedulersSection: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()

    const [schedulerCreator, setSchedulerCreator] = useState<SelectOptionValue | null>({
        label: t('fields.user'),
        value: '',
    })
    const [schedulerFilterCommand, setSchedulerFilterCommand] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [availableCommands, setAvailableCommands] = useState<AvailableCommand[]>([])
    const [availableUsers, setAvailableUsers] = useState<User[]>([])
    const [toggleReset, setToggleReset] = useState(undefined)
    const [searchThroughEnabledSchedulers, setSearchThroughEnabledSchedulers] = useState(false)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [alertModalIsVisible, setAlertModalIsVisible] = useState(false)
    const navigate = useNavigate()
    const [filterParameters, setFilterParameters] = useState<any | null>(
        props.device !== undefined ? { device: props.device.ID } : null
    )

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const getFilterParameters = () => {
        const filterParameters: any = {
            device: props.device.ID,
            command: schedulerFilterCommand.label === t('fields.command') ? '' : schedulerFilterCommand.value,
            from: filterStartDate,
            to: filterEndDate,
            enabled: searchThroughEnabledSchedulers ? true : undefined,
            user: schedulerCreator.value === '' ? -1 : parseInt(schedulerCreator.value, 10),
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const getDetailedFilteredEvents = () => {
        setFilterParameters(getFilterParameters())
    }

    const getDeviceSchedulerInfo = async () => {
        try {
            const model: Model = await CustomAxios.get(`device-models/${props.device.idDeviceModel}`).then(
                (response) => response.data
            )
            setAvailableCommands(model.availableCommands)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchUsers = async () => {
        try {
            const fetchedUsers: User[] = await CustomAxios.get('users').then((response) => response.data.data)
            setAvailableUsers(fetchedUsers)
        } catch (error) {
            console.error(error)
        }
    }

    const deviceFilterIsSet = useMemo(() => {
        if (filterParameters !== null) {
            const keys = Object.keys(filterParameters)
            return keys.length === 1 && keys[0] === 'device'
        }
        return false
    }, [filterParameters])

    const filterParametersChanged = useMemo(() => {
        return (
            schedulerFilterCommand.value !== '' ||
            filterEndDate !== '' ||
            filterStartDate !== '' ||
            searchThroughEnabledSchedulers ||
            schedulerCreator.value !== ''
        )
    }, [schedulerFilterCommand, filterStartDate, filterEndDate, searchThroughEnabledSchedulers, schedulerCreator])

    const getInitialData = async () => {
        try {
            await fetchUsers()
            await getDeviceSchedulerInfo()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void getInitialData()
    }, [])

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setSchedulerCreator({ label: t('fields.user'), value: '' })
        setSchedulerFilterCommand({ label: t('fields.command'), value: '' })
        setSearchThroughEnabledSchedulers(false)
        setFilterParameters(props.device !== undefined ? { device: props.device.ID } : null)
        setToggleReset(!toggleReset)
    }

    return (
        <>
            {alertModalIsVisible && (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onClose={() => setAlertModalIsVisible(false)}
                    onConfirm={() =>
                        navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}/new-scheduler`, {
                            state: { fromDevicePage: true, deviceId: props.device.ID },
                        })
                    }
                />
            )}
            <div className="sectionContent">
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterScheduler')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin={user.idSysGrant === 0 ? '0 25px 0 0' : '0'}
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                    {user.idSysGrant === 0 && (
                        <Button
                            label={t('common.addScheduler')}
                            backgroundColor="#e10915"
                            textColor="#fff"
                            icon={faPlus}
                            iconColor="#fff"
                            onPress={() => setAlertModalIsVisible(true)}
                            margin="0"
                        />
                    )}
                </div>
                <div style={{ marginTop: '25px' }}>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.schedulerInfo')}</h3>
                                    <SelectInput
                                        value={schedulerFilterCommand}
                                        onValueChange={(value) => setSchedulerFilterCommand(value)}
                                        options={availableCommands.map((command: AvailableCommand) => {
                                            if (command.requiredGrant === user.idSysGrant) {
                                                return { label: command.name, value: command.command }
                                            }
                                            return null
                                        })}
                                        width={'100%'}
                                    />
                                </div>
                                <div className="filter-column column-without-title">
                                    <SelectInput
                                        value={schedulerCreator}
                                        onValueChange={(value) => setSchedulerCreator(value)}
                                        options={availableUsers.map((user: User) => {
                                            return {
                                                label: `${user.firstName} ${user.lastName}`,
                                                value: user.ID.toString(),
                                            }
                                        })}
                                        width={'100%'}
                                    />
                                    <CheckboxInput
                                        label={t('fields.showOnlyEnabledSchedulers')}
                                        value={searchThroughEnabledSchedulers}
                                        onChangeValue={(value: boolean) => setSearchThroughEnabledSchedulers(value)}
                                    />
                                </div>
                                <div className="filter-column">
                                    <h3>{t('fields.searchInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null || deviceFilterIsSet}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getDetailedFilteredEvents()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <DeviceSchedulersTable reloads={toggleReset} device={props.device.ID} filterParams={filterParameters} />
            </div>
        </>
    )
}

export default DeviceSchedulersSection
