import { ReducerGraphics, ReduxActionData } from '../../types/reducers'
import { GRAPHICS_ACTIONS } from '../actions/graphics'

const defaultValue: ReducerGraphics = {
    fetchUserLoaderStatus: false,
    fetchTodayAlarmsLoaderStatus: false,
    fetchMapAlarmsLoaderStatus: false,
    fetchAlarmTypesLoaderStatus: false,
    fetchRegionsLoaderStatus: false,
    fetchNationsLoaderStatus: false,
    fetchUsersLoaderStatus: false,
    collapsedMenu: true,
    showQuickHelp: false,
}

const reducerState: (state: ReducerGraphics, action: ReduxActionData<any, GRAPHICS_ACTIONS>) => ReducerGraphics = (
    state,
    action: ReduxActionData<any, GRAPHICS_ACTIONS>
) => {
    state = state || defaultValue
    switch (action.type) {
        case GRAPHICS_ACTIONS.SET_FETCH_USER_LOADER_STATUS:
            return {
                ...state,
                fetchUserLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_TODAY_ALARMS_LOADER_STATUS:
            return {
                ...state,
                fetchTodayAlarmsLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_MAP_ALARMS_LOADER_STATUS:
            return {
                ...state,
                fetchMapAlarmsLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_ALARM_TYPES_LOADER_STATUS:
            return {
                ...state,
                fetchAlarmTypesLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_REGIONS_LOADER_STATUS:
            return {
                ...state,
                fetchRegionsLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_NATIONS_LOADER_STATUS:
            return {
                ...state,
                fetchNationsLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_FETCH_USERS_LOADER_STATUS:
            return {
                ...state,
                fetchUsersLoaderStatus: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_COLLAPSED_MENU:
            return {
                ...state,
                collapsedMenu: action.payload,
            }
        case GRAPHICS_ACTIONS.SET_SHOW_QUICK_HELP:
            return {
                ...state,
                showQuickHelp: action.payload,
            }
        default:
            return state
    }
}

export default reducerState
