import React, { useEffect, useState } from 'react'
import PageContainer from '../../../Components/StaticComponents/PageContainer'
import Footer from '../../../Components/StaticComponents/Footer'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../Components/Inputs/TextField'
import Button from '../../../Components/Inputs/Button'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/routes'
import NetworksTable from '../../../Components/Tables/NetworksTable'
import PageHeaderCustom from '../../../customComponents/PageHeaderCustom'
import { useTranslation } from 'react-i18next'
import { Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector } from 'react-redux'

const NetworksPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [deviceNameAsQuerySearch, setDeviceNameAsQuerySearch] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        if (user.idSysGrant !== 0 && user.managedNetworks.length === 0) { navigate(`${ROUTE_NAMES.TODAY_ALARM}`) }
    }, [user])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.network" descriptionKey="pageDescription.networks" />
                <div className="header-right-actions">
                    <div className="header-rapid-search">
                        <TextField
                            label={t('searches.searchByName')}
                            value={deviceNameAsQuerySearch}
                            onChangeText={(value: string) => setDeviceNameAsQuerySearch(value)}
                        />
                    </div>
                    {user.idSysGrant === 0 && (
                        <Button
                            label={t('common.addNetwork')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 0 0 25px"
                            icon={faPlus}
                            onPress={() => navigate(`/${ROUTE_NAMES.NETWORKS}/new-network`)}
                        />
                    )}
                </div>
            </div>
            <div className="sectionContent">
                <NetworksTable searchQuery={deviceNameAsQuerySearch} />
                <Footer />
            </div>
        </PageContainer>
    )
}

export default NetworksPage
