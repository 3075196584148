import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/components/modals.sass'
import { UserDataTable } from '../../types/data/user'
import { NetworkGrant } from '../../utils/enum/NetworkGrant'
import Button from '../Inputs/Button'
import SelectInput from '../Inputs/SelectInput'
import { NetworkUserAssoc } from '../Tables/NetworkUserAssocTable'
import AlertModal from './AlertModal'

interface Props {
    assoc: NetworkUserAssoc
    isNew?: boolean
    associated: NetworkUserAssoc[]
    onClose: () => void
    onConfirm: (edited: NetworkUserAssoc) => void
}

const NetworkUserAssocModal: React.FC<Props> = (props) => {
    const [assoc, setAssoc] = useState(props.assoc)
    const [userList, setUserList] = useState<UserDataTable[]>([])
    const [showAlertModal, setShowAlertModal] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setAssoc(props.assoc)
        void fetchUsers()
    }, [props.assoc, props.associated])

    const fetchUsers = async () => {
        const users = await CustomAxios.get('users').then((res) => {
            return res.data.data
        })
        if (props.associated.length > 0) {
            setUserList(users.filter((u) => props.associated.find((us) => us.user.ID !== u.ID) !== undefined))
        } else {
            setUserList(users)
        }
    }

    return (
        <div className="alert-modal-wrapper">
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => props.onClose()}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            <div
                className="dark-cover"
                onClick={() => {
                    setShowAlertModal(true)
                }}
            />
            <div className="modal edit">
                <div className="headerSection addNewSection">
                    <h2>
                        <span className="titleLink" style={{ color: '#52565A', opacity: 0.5 }}>
                            {t('fields.networkUserAssociation')}
                        </span>{' '}
                        -{' '}
                        {props.isNew
                            ? t('fields.newAssociation')
                            : `${t('fields.user')}: ${props.assoc.user.firstName} ${props.assoc.user.lastName}`}
                    </h2>
                    <div>
                        <Button
                            label={props.isNew ? t('common.add') : t('common.update')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 25px 0 0"
                            isDisabled={!assoc.user || !assoc.idGrant}
                            icon={props.isNew ? faPlus : faPen}
                            onPress={async () => {
                                if (assoc.user && assoc.idGrant) {
                                    props.onConfirm(assoc)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="advanced-search-wrapper">
                    <div className="filter-column">
                        <h3>{t('fields.user')}</h3>
                        <div className="input-rows">
                            <SelectInput
                                options={userList.map((l) => {
                                    return { value: l.ID.toString(), label: `${l.firstName} ${l.lastName}` }
                                })}
                                disabled={!props.isNew}
                                value={
                                    assoc.user
                                        ? {
                                              value: assoc.user.ID.toString(),
                                              label: `${assoc.user.firstName} ${assoc.user.lastName}`,
                                          }
                                        : null
                                }
                                placeholder={t('fields.user')}
                                onValueChange={(v) => {
                                    const userFound = userList.find((u) => u.ID.toString() === v.value)
                                    if (userFound) {
                                        setAssoc({
                                            ...assoc,
                                            user: userFound,
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <h3>{t('fields.userGrants')}</h3>
                        <div className="input-rows">
                            <SelectInput
                                options={NetworkGrant.map((l, i) => {
                                    return { value: (i + 1).toString(), label: t(`userRoles.${l}`) }
                                })}
                                value={
                                    assoc.idGrant
                                        ? {
                                              value: assoc.idGrant.toString(),
                                              label: t(`userRoles.${NetworkGrant[assoc.idGrant - 1]}`),
                                          }
                                        : null
                                }
                                placeholder={t('fields.userGrants')}
                                onValueChange={(v) => {
                                    setAssoc({
                                        ...assoc,
                                        idGrant: parseInt(v.value, 10),
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetworkUserAssocModal
