import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/components/modals.sass'
import AlertModal from './AlertModal'
import ModelDevicesTable from '../Tables/ModelDevicesTable'
import { DeviceModel } from '../../types/data/alarm'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import Button from '../Inputs/Button'
import { faCancel, faPen } from '@fortawesome/free-solid-svg-icons'

interface Props {
    currentModel: DeviceModel
    onClose: () => void
    onConfirm: (devicesIDs: number[]) => void
}

const ModelDevicesUpdateModal: React.FC<Props> = (props) => {
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [selectedIDS, setSelectedIDs] = useState([])
    const { t } = useTranslation()

    return (
        <div className="alert-modal-wrapper">
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => props.onClose()}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            <div
                className="dark-cover"
                onClick={() => {
                    setShowAlertModal(true)
                }}
            />
            <div className="modal edit">
                <div
                    className="headerSection addNewSection"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <h2>
                        <span className="titleLink" style={{ color: '#52565A', opacity: 0.5 }}>
                            {t('fields.ModelDevices')}
                        </span>{' '}
                    </h2>
                    <div className="header-right-actions">
                        <Button
                            label={t('common.cancel')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin="0 0 0 25px"
                            icon={faCancel}
                            onPress={() => setShowAlertModal(true)}
                        />
                        <Button
                            label={t('common.update')}
                            backgroundColor="#e10915"
                            textColor="#ffffff"
                            margin="0 0 0 25px"
                            iconColor="#ffffff"
                            icon={faPen}
                            onPress={() => {
                                props.onConfirm(selectedIDS)
                                props.onClose()
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginLeft: '2%', marginBottom: '2%' }}>
                    <PageHeaderCustom descriptionKey="fields.ModelDevicesUpdateText" />
                </div>

                <div>
                    <ModelDevicesTable
                        currentModel={props.currentModel}
                        onDeviceSelection={setSelectedIDs}
                        onClose={props.onClose}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModelDevicesUpdateModal
