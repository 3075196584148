import React, { useEffect } from 'react'
import Button from '../../../Components/Inputs/Button'
import Footer from '../../../Components/StaticComponents/Footer'
import UsersTable from '../../../Components/Tables/UsersTable'
import '../../../styles/pages/main.sass'
import '../../../styles/pages/amministration.sass'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/routes'
import PageContainer from '../../../Components/StaticComponents/PageContainer'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import TextField from '../../../Components/Inputs/TextField'
import { Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector } from 'react-redux'
import PageHeaderCustom from '../../../customComponents/PageHeaderCustom'
import { useTranslation } from 'react-i18next'

const UsersPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [deviceNameAsQuerySearch, setDeviceNameAsQuerySearch] = useState('')
    const { t } = useTranslation()

    const navigate = useNavigate()

    useEffect(() => {
        if (user.idSysGrant !== 0 && user.managedNetworks.length === 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
    }, [user])

    return (
        <PageContainer>
            <div className="headerSection">
                <PageHeaderCustom titleKey="routes.users" descriptionKey="pageDescription.users" />
                <div className="header-right-actions">
                    <div className="header-rapid-search">
                        <TextField
                            label={t('searches.searchByName')}
                            value={deviceNameAsQuerySearch}
                            onChangeText={(value: string) => setDeviceNameAsQuerySearch(value)}
                        />
                    </div>
                    {user.idSysGrant === 0 && (
                        <Button
                            label={t('common.addUser')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 0 0 25px"
                            icon={faPlus}
                            onPress={() => navigate(`/${ROUTE_NAMES.USERS}/new-user`)}
                        />
                    )}
                </div>
            </div>
            <div className="sectionContent">
                <UsersTable searchQuery={deviceNameAsQuerySearch} />
            </div>
            <Footer />
        </PageContainer>
    )
}

export default UsersPage
