import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { useTranslation } from 'react-i18next'
import { usePrevious, useTablePagination } from '../../utils/hooks/common'
import { Reducers, ReducerUser } from '../../types/reducers'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { useDispatch } from 'react-redux'
import { COLORS } from '../../styles/constants'

export interface DeviceTypesTableTable {
    ID: number
    name: string
    description: string
    note: string
}

interface Props {
    searchQuery: string
    onFinish?: () => void
}

const DeviceTypesTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedType, setSelectedType] = useState<DeviceTypesTableTable | null>(null)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const previousQuery = usePrevious(props.searchQuery)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const removeHandler = async (id: number) => {
        try {
            await CustomAxios.delete(`device-types/${id}`)
            resetData(currentPage, '', '', true)
            setSelectedType(null)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData(currentPage, '', '', true)
        }
    }, [props.searchQuery])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.name'),
                field: 'name',
                render: (rowData: DeviceTypesTableTable) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() =>
                            navigate(`/${ROUTE_NAMES.DEVICE_TYPES}/${rowData?.ID}`, { state: { isView: true } })
                        }
                    >
                        {rowData.name}
                    </span>
                ),
            },
            {
                title: t('fields.description'),
                field: 'description',
            },
            {
                title: t('fields.notes'),
                field: 'note',
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: DeviceTypesTableTable) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => navigate(`/${ROUTE_NAMES.DEVICE_TYPES}/${rowData?.ID}`),
                tooltip: t('common.edit'),
            }),
            (rowData: DeviceTypesTableTable) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => setSelectedType(rowData),
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {selectedType ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList', { type: selectedType.name })}
                    onClose={() => setSelectedType(null)}
                    onDelete={() => void removeHandler(selectedType.ID)}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'device-types'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&query=${props.searchQuery}`
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: handleDataCount(response.data.count),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                    navigate(`/${ROUTE_NAMES.DEVICE_TYPES}?page=${pageIndex}`)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default DeviceTypesTable
