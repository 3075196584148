import React from 'react'
import '../styles/pages/loginPage.sass'
import BackgroundImage from '../resources/media/images/whitelabel/login-background.jpg'
import LogoImage from '../resources/media/images/whitelabel/login-header.png'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createCustomerSession, getCustomerInformations } from '../store/actions/requests'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { COLORS } from '../styles/constants'

const SubscriptionInvalidPage: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    flex: 1,
                    zIndex: -1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url(${BackgroundImage})`,
                    height: '100vh',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    filter: 'blur(8px)',
                }}
            />
            <div className="containerLogin">
                <img alt="" className="logoLogin" src={LogoImage} />
                <p>Your subscription is not active.</p>
                <p>Please check your subscription status and retry</p>
                <li className="header-list-item" style={{ marginBottom: '30px' }}>
                    <a
                        className="header-list-content"
                        onClick={async () => {
                            const customer = await getCustomerInformations()
                            const customerPortal = await createCustomerSession(customer)
                            window.open(customerPortal.value, '_self', 'noopener,noreferrer')
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCreditCard}
                            color={COLORS.palette.darkGrey}
                            style={{ marginRight: '14px', marginLeft: '3px' }}
                        />
                        {t('fields.billing')}
                    </a>
                </li>

                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.application')}
                </p>
                <p
                    className="footerLogin"
                    style={{
                        alignSelf: 'flex-start',
                        color: '#666',
                        fontSize: 10,
                        lineHeight: 'normal',
                    }}
                >
                    {t('footer.copyright', { year: new Date().getFullYear() })}
                </p>
            </div>
        </div>
    )
}
export default SubscriptionInvalidPage
