import { useDispatch, useSelector } from 'react-redux'
import { ReducerAnalytics, ReducerData, ReducerGraphics, Reducers, ReducerUser } from './types/reducers'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import TodayAlarmPage from './pages/AlarmSection/TodayAlarmPage'
import Header from './Components/StaticComponents/Header'
import Drawer from './Components/StaticComponents/Drawer'
import { ROUTE_NAMES } from './utils/routes'
import './styles/pages/main.sass'
import MapPage from './pages/AlarmSection/MapPage'
import { ToastContainer } from 'react-toastify'
import SendCommandPage from './pages/RemoteControls/SendCommandPage'
import PlannedCommandPage from './pages/RemoteControls/PlannedCommandPage'
import RemoteControlListPage from './pages/RemoteControls/RemoteControlListPage.tsx'
import PowerHistoryPage from './pages/RemoteControls/PowerHistoryPage'
import UsersPage from './pages/Amministation/Users/UsersPage'
import RemoteControlsPage from './pages/Amministation/RemoteControlsPage'
import ImportPage from './pages/Amministation/ImportPage'
import UserDetailPage from './pages/Amministation/Users/UserDetailPage'
import { useEffect, useState } from 'react'
import BrandsPage from './pages/Amministation/SystemAdministration/Brand/BrandsPage'
import BrandDetailPage from './pages/Amministation/SystemAdministration/Brand/BrandDetailPage'
import DeviceTypesPage from './pages/Amministation/SystemAdministration/DeviceTypes/DeviceTypesPage'
import { fetchAllData } from './store/actions/groupedActions'
import EventsHistory from './pages/AlarmSection/EventsHistory'
import DeviceTypeDetailPage from './pages/Amministation/SystemAdministration/DeviceTypes/DeviceTypeDetailPage'
import DevicePage from './pages/Device/DevicePage'
import PlannedCommandDetail from './pages/RemoteControls/PlannedCommandDetail'
import ModelDetailPage from './pages/Amministation/SystemAdministration/Model/ModelDetailPage'
import NetworksPage from './pages/Amministation/Networks/NetworksPage'
import NetworkDetailPage from './pages/Amministation/Networks/NetworkDetailPage'
import GroupsPage from './pages/Amministation/Groups/GroupsPage'
import GroupDetailPage from './pages/Amministation/Groups/GroupDetailPage'
import PersonalProfilePage from './pages/Configuration/PersonalProfilePage'
import 'react-toastify/dist/ReactToastify.css'
import ConfigurationPage from './pages/Amministation/SystemAdministration/Configuration/ConfigurationPage'
import DashboardPage from './pages/Analytics/DashboardPage'
import GeniusPage from './pages/Genius/GeniusPage'
import { setUserDefaultTablePageSize } from './store/actions/user'
import { DefaultTablePageSize } from './utils/enum/UserActivityTypes'
import { setUserManagedNetworks } from './store/actions/requests'
import SubscriptionInvalidPage from './pages/SubscriptionInvalidPage'
import CustomAxios from './customComponents/customAxios'
import SubscriptionBanner from './Components/SubscriptionBanner'
import moment from 'moment'
import ChangePasswordFirstAccessModal from './Components/Modals/ChangePasswordFirstAccessModal'
import ValidateOTPPage from './pages/ValidateOTPPage'
import { setConfigurations } from './store/actions/data'
import OnboardingModal from './Components/Modals/OnboardingModal/OnboardingModal'
import { setShowQuickHelp } from './store/actions/graphics'
import ChangelogPage from './pages/ChangelogPage'
import { InstanceTitle } from './utils/constants'

function App() {
    const { configurations } = useSelector<Reducers, ReducerData>((store) => store.data)
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const dashboards = useSelector<Reducers, ReducerAnalytics>((store) => store.analytics)
    const graphics = useSelector<Reducers, ReducerGraphics>((store) => store.graphics)

    const [subscriptionState, setSubscriptionState] = useState('active')
    const [showQuickHelp, setShowQuckHelp] = useState(false)
    const [trialExpire, setTrialExpire] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        const aspectTitle = configurations.find((c) => c.key === 'aspect.title')
        if (aspectTitle && aspectTitle.value !== '') {
            document.title = aspectTitle.value
        } else {
            document.title = InstanceTitle
        }
    }, [configurations])

    const getConfigurations = async () => {
        const configurations = await CustomAxios.get('configurations').then((response) => response.data.data)
        dispatch(setConfigurations(configurations))
        const sub = configurations.find((c) => c.key === 'subscription')
        if (sub) {
            const subscriptionData = JSON.parse(sub.jsonData)
            const status = subscriptionData.status

            setSubscriptionState(status)

            if (
                (status === 'canceled' || status === 'unpaid') &&
                location.pathname !== `/${ROUTE_NAMES.INVALID_SUBSCRIPTION}`
            ) {
                location.href = ROUTE_NAMES.INVALID_SUBSCRIPTION
            } else if (
                status !== 'canceled' &&
                status !== 'unpaid' &&
                location.pathname === `/${ROUTE_NAMES.INVALID_SUBSCRIPTION}`
            ) {
                location.href = ROUTE_NAMES.TODAY_ALARM
            }

            if (status === 'trialing') {
                const duration = moment
                    .unix(subscriptionData.trial_end)
                    .diff(moment.unix(subscriptionData.trial_start), 'days')
                setTrialExpire(duration)
            }
        }
        const quickhelp = configurations.find((c) => c.key === 'quickhelpshow')
        if (quickhelp.value === 'true') {
            await CustomAxios.put('configurations/quickhelpshow', { value: 'false' })
            setShowQuckHelp(true)
        } else {
            setShowQuckHelp(false)
        }
    }

    useEffect(() => {
        if (!user.token) return
        void getConfigurations()
        dispatch(setUserManagedNetworks(user.ID))
    }, [user.token, user.ID])

    useEffect(() => {
        if (!user.token) return
        dispatch(fetchAllData())
        dispatch(setUserDefaultTablePageSize(DefaultTablePageSize))
    }, [])

    return (
        <Router basename={process.env.PUBLIC_URL}>
            {subscriptionState === 'canceled' || subscriptionState === 'unpaid' ? (
                <Routes>
                    <Route path={`${ROUTE_NAMES.INVALID_SUBSCRIPTION}`} element={<SubscriptionInvalidPage />} />
                </Routes>
            ) : user.ID !== -1 ? (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        width: '100vw',
                        height: '100vh',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                >
                    <ToastContainer />
                    {subscriptionState === 'trialing' ? (
                        <SubscriptionBanner text={`Your trial will expire in ${trialExpire} days`} />
                    ) : null}
                    <Header />
                    {user.firstAccess && <ChangePasswordFirstAccessModal />}
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Drawer />
                        {showQuickHelp || graphics.showQuickHelp ? (
                            <OnboardingModal
                                onClose={() => {
                                    setShowQuckHelp(false)
                                    dispatch(setShowQuickHelp(false))
                                }}
                                onComplete={() => {
                                    setShowQuckHelp(false)
                                    dispatch(setShowQuickHelp(false))
                                }}
                            />
                        ) : null}
                        <Routes>
                            <Route path={`${ROUTE_NAMES.TODAY_ALARM}`} element={<TodayAlarmPage />} />
                            <Route path={`/${ROUTE_NAMES.GENIUS}`} element={<GeniusPage />} />
                            <Route path={`/${ROUTE_NAMES.MAP}`} element={<MapPage />} />
                            <Route path={`/${ROUTE_NAMES.SEND_COMMANDS}`} element={<SendCommandPage />} />
                            <Route path={`/${ROUTE_NAMES.PLANNED_COMMANDS}`} element={<PlannedCommandPage />} />
                            <Route path={`/${ROUTE_NAMES.PLANNED_COMMANDS}/:id`} element={<PlannedCommandDetail />} />
                            <Route path={`/${ROUTE_NAMES.LIST}`} element={<RemoteControlListPage />} />
                            <Route path={`/${ROUTE_NAMES.POWERS_HISTORY}`} element={<PowerHistoryPage />} />
                            <Route path={`/${ROUTE_NAMES.EVENTS_HISTORY}`} element={<EventsHistory />} />
                            <Route path={`/${ROUTE_NAMES.USERS}`} element={<UsersPage />} />
                            <Route path={`/${ROUTE_NAMES.BRANDS}`} element={<BrandsPage />} />
                            <Route path={`/${ROUTE_NAMES.BRANDS}/:id`} element={<BrandDetailPage />} />
                            <Route path={`/${ROUTE_NAMES.DEVICE_TYPES}`} element={<DeviceTypesPage />} />
                            <Route path={`/${ROUTE_NAMES.MODELS}/:id`} element={<ModelDetailPage />} />
                            <Route path={`/${ROUTE_NAMES.NETWORKS}`} element={<NetworksPage />} />
                            <Route path={`/${ROUTE_NAMES.NETWORKS}/:id`} element={<NetworkDetailPage />} />
                            <Route path={`/${ROUTE_NAMES.GROUPS}`} element={<GroupsPage />} />
                            <Route path={`/${ROUTE_NAMES.GROUPS}/:id`} element={<GroupDetailPage />} />
                            <Route path={`/${ROUTE_NAMES.DEVICE_TYPES}/:id`} element={<DeviceTypeDetailPage />} />
                            <Route path={`/${ROUTE_NAMES.USERS}/:id`} element={<UserDetailPage />} />
                            <Route
                                path={`/${ROUTE_NAMES.USERS}/${ROUTE_NAMES.NEW_USER}`}
                                element={<UserDetailPage />}
                            />
                            <Route path={`/${ROUTE_NAMES.REMOTE_CONTROLS}`} element={<RemoteControlsPage />} />
                            <Route path={`/${ROUTE_NAMES.IMPORT}`} element={<ImportPage />} />
                            <Route path={`/${ROUTE_NAMES.CHANGELOG}`} element={<ChangelogPage />} />
                            {dashboards.availableDashboards.map((dashboard) => {
                                return (
                                    <Route
                                        key={dashboard.ID}
                                        path={`/${ROUTE_NAMES.ANALYTICS_DASHBOARD}/${dashboard.ID}`}
                                        element={
                                            <DashboardPage dashboardName={dashboard.name} idDashboard={dashboard.ID} />
                                        }
                                    />
                                )
                            })}
                            <Route path={`/${ROUTE_NAMES.DEVICE}/:id`} element={<DevicePage />} />
                            <Route path={`/${ROUTE_NAMES.PROFILE}`} element={<PersonalProfilePage />} />
                            <Route path={`/${ROUTE_NAMES.CONFIGURATION}`} element={<ConfigurationPage />} />
                        </Routes>
                    </div>
                </div>
            ) : (
                <Routes>
                    <Route path={`${ROUTE_NAMES.LOGIN}`} element={<LoginPage />} />
                    <Route path={`${ROUTE_NAMES.VALIDATE_OTP}`} element={<ValidateOTPPage />} />
                </Routes>
            )}
        </Router>
    )
}

export default App
