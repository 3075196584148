import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import user from './user'
import graphics from './graphics'
import data from './data'
import analytics from './analytics'

const config = {
    key: 'root',
    storage,
    whitelist: ['user'],
    blacklist: ['data', 'graphics', 'analytics'],
}

// Group reducers
const rootReducer = combineReducers({
    user,
    graphics,
    data,
    analytics,
})

const persistedReducer = persistReducer(config, rootReducer)

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(persistedReducer)
const persistor = persistStore(store)

export { persistor, store }
