import { ReducerUser, ReduxActionData } from '../../types/reducers'
import { DefaultTablePageSize } from '../../utils/enum/UserActivityTypes'
import { USER_ACTIONS } from '../actions/user'

const defaultValue: ReducerUser = {
    // User info
    ID: -1,
    username: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    // Token data
    token: '',
    idSysGrant: 1,
    language: '',
    passExpire: '',
    idEditor: 0,
    networks: [],
    defaultTablePageSize: DefaultTablePageSize,
    managedNetworks: [],
    firstAccess: 0,
    hide: 0,
    otpInfo: null,
}

const reducerState: (state: ReducerUser, action: ReduxActionData<any, USER_ACTIONS>) => ReducerUser = (
    state,
    action: ReduxActionData<any, USER_ACTIONS>
) => {
    state = state || defaultValue
    switch (action.type) {
        case USER_ACTIONS.SET_USER_INFO:
            return {
                ...state,
                ...action.payload,
            }
        case USER_ACTIONS.SET_USER_DEFAULT_PAGE_SIZE:
            return {
                ...state,
                defaultTablePageSize: action.payload,
            }
        case USER_ACTIONS.SET_USER_MANAGED_NETWORKS:
            return {
                ...state,
                managedNetworks: action.payload,
            }
        case USER_ACTIONS.SET_TOKEN_DATA:
            return {
                ...state,
                ...action.payload,
            }
        case USER_ACTIONS.LOGOUT_USER:
            return defaultValue
        default:
            return state
    }
}

export default reducerState
