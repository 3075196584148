/* eslint-disable indent */
import React, { useState } from 'react'
import Collapsible from './Collapsible'
import '../../styles/components/staticComponents.sass'
import { ROUTE_NAMES } from '../../utils/routes'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerAnalytics, ReducerGraphics, Reducers, ReducerUser } from '../../types/reducers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { setCollapsedMenu } from '../../store/actions/graphics'
import LOGO22 from '../../resources/media/images/whitelabel/logo_22.jpg'
import { useTranslation } from 'react-i18next'
import { SystemDashboardName } from '../../utils/constants'

const Drawer: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dashboards = useSelector<Reducers, ReducerAnalytics>((store) => store.analytics)
    const [expandedElement, setExpandedElement] = useState<string>('events')

    const graphic = useSelector<Reducers, ReducerGraphics>((state) => state.graphics)
    const { t } = useTranslation()

    const dispatch = useDispatch()

    if (!graphic.collapsedMenu) {
        return (
            <div
                className="sidemenu collapsed"
                onClick={() => {
                    dispatch(setCollapsedMenu(true))
                }}
            >
                <div className="nav-item" style={{ borderBottomWidth: 0 }}>
                    <p
                        className="main navigateLink collapseMenu"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <span
                            className="item"
                            style={{
                                width: 25,
                                height: 25,
                                background: '#efefef',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 13,
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12 }} />
                        </span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="sidemenu">
            <div className="nav-item">
                <p
                    className="main navigateLink collapseMenu"
                    style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 0 }}
                >
                    <span
                        className="item collapseMenuButton"
                        onClick={() => {
                            dispatch(setCollapsedMenu(false))
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12 }} />
                    </span>
                </p>
            </div>
            {user.idSysGrant === 0 && (
                <Collapsible
                    title={t('routes.genius')}
                    isCollapsed={expandedElement !== 'genius'}
                    onClick={() => {
                        if (expandedElement === 'genius') {
                            setExpandedElement('')
                        } else {
                            setExpandedElement('genius')
                        }
                    }}
                    menuItems={[{ name: t('routes.genius'), slug: `${ROUTE_NAMES.GENIUS}` }]}
                />
            )}
            <Collapsible
                title={t('routes.map')}
                isCollapsed={expandedElement !== 'configurations'}
                onClick={() => {
                    if (expandedElement === 'configurations') {
                        setExpandedElement('')
                    } else {
                        setExpandedElement('configurations')
                    }
                }}
                menuItems={[{ name: t('routes.map'), slug: `/${ROUTE_NAMES.MAP}` }]}
            />
            <Collapsible
                title={t('routes.events')}
                isCollapsed={expandedElement !== 'events'}
                onClick={() => {
                    if (expandedElement === 'events') {
                        setExpandedElement('')
                    } else {
                        setExpandedElement('events')
                    }
                }}
                menuItems={[
                    { name: t('routes.eventsList'), slug: `${ROUTE_NAMES.TODAY_ALARM}?page=0` },
                    { name: t('routes.eventsHistory'), slug: `/${ROUTE_NAMES.EVENTS_HISTORY}?page=0` },
                ]}
            />
            <Collapsible
                title={t('routes.devices')}
                isCollapsed={expandedElement !== 'remote-controls'}
                onClick={() => {
                    if (expandedElement === 'remote-controls') {
                        setExpandedElement('')
                    } else {
                        setExpandedElement('remote-controls')
                    }
                }}
                menuItems={[
                    { name: t('routes.devices'), slug: `/${ROUTE_NAMES.LIST}?page=0` },
                    { name: t('routes.scheduledCommands'), slug: `/${ROUTE_NAMES.PLANNED_COMMANDS}?page=0` },
                ]}
            />
            <Collapsible
                title={t('routes.profile')}
                isCollapsed={expandedElement !== 'configurations'}
                onClick={() => {
                    if (expandedElement === 'configurations') {
                        setExpandedElement('')
                    } else {
                        setExpandedElement('configurations')
                    }
                }}
                menuItems={[{ name: t('routes.profile'), slug: `/${ROUTE_NAMES.PROFILE}` }]}
            />
            {(user.idSysGrant === 0 || user.managedNetworks.length > 0) && (
                <Collapsible
                    title={t('routes.administration')}
                    isCollapsed={expandedElement !== 'amministration'}
                    onClick={() => {
                        if (expandedElement === 'amministration') {
                            setExpandedElement('')
                        } else {
                            setExpandedElement('amministration')
                        }
                    }}
                    menuItems={[
                        // { name: t('routes.import'), slug: `/${ROUTE_NAMES.IMPORT}` },
                        { name: t('routes.users'), slug: `/${ROUTE_NAMES.USERS}?page=0` },
                        // { name: t('routes.devices'), slug: `/${ROUTE_NAMES.REMOTE_CONTROLS}` },
                        { name: t('routes.groups'), slug: `/${ROUTE_NAMES.GROUPS}?page=0` },
                        { name: t('routes.network'), slug: `/${ROUTE_NAMES.NETWORKS}?page=0` },
                    ]}
                />
            )}
            {user.idSysGrant === 0 && (
                <Collapsible
                    title={t('routes.system')}
                    isCollapsed={expandedElement !== 'system'}
                    onClick={() => {
                        if (expandedElement === 'system') {
                            setExpandedElement('')
                        } else {
                            setExpandedElement('system')
                        }
                    }}
                    menuItems={[
                        { name: t('routes.brand'), slug: `/${ROUTE_NAMES.BRANDS}?page=0` },
                        { name: t('routes.devicesTypes'), slug: `/${ROUTE_NAMES.DEVICE_TYPES}?page=0` },
                        { name: t('routes.configurations'), slug: `/${ROUTE_NAMES.CONFIGURATION}` },
                        ...dashboards.availableDashboards
                            .filter((dashboard) => dashboard.name === SystemDashboardName)
                            .map((dashboard) => {
                                return {
                                    name: dashboard.name,
                                    slug: `/${ROUTE_NAMES.ANALYTICS_DASHBOARD}/${dashboard.ID}`,
                                }
                            }),
                    ]}
                />
            )}
            {user.idSysGrant === 0 && dashboards.availableDashboards.length > 0 && (
                <Collapsible
                    title={t('routes.analytics')}
                    isCollapsed={expandedElement !== 'analytics'}
                    onClick={() => {
                        if (expandedElement === 'analytics') {
                            setExpandedElement('')
                        } else {
                            setExpandedElement('analytics')
                        }
                    }}
                    menuItems={
                        dashboards.availableDashboards.length > 0
                            ? dashboards.availableDashboards
                                  .filter((dashboard) => dashboard.name !== SystemDashboardName)
                                  .map((dashboard) => {
                                      return {
                                          name: dashboard.name,
                                          slug: `/${ROUTE_NAMES.ANALYTICS_DASHBOARD}/${dashboard.ID}`,
                                      }
                                  })
                            : []
                    }
                />
            )}
            <img alt="" src={LOGO22} style={{ position: 'absolute', bottom: '30vh', maxWidth: 250 - 40, zIndex: 90 }} />
        </div>
    )
}

export default Drawer
