import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { COLORS } from '../../styles/constants'

interface Props {
    acceptedData?: string
    onChange: (f: File) => void
}

const UploadButton: React.FC<Props> = (props) => {
    return (
        <div className="uploadButton">
            <input
                type="file"
                onChange={(event) => {
                    props.onChange(event.target.files[0])
                }}
                accept={props.acceptedData ? props.acceptedData : 'image/*'}
            />
            <FontAwesomeIcon icon={faUpload} style={{ fontSize: 16, color: COLORS.palette.darkGrey, cursor: 'pointer' }} />
        </div>
    )
}

export default UploadButton
