import { faPlus, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import { Brand, Model, NetworkGroup } from '../../types/data/system'
import { Reducers, ReducerUser } from '../../types/reducers'
import { getOrCreateBrands, getOrCreateDeviceModel } from '../../utils/api-constants'
import { ROUTE_NAMES } from '../../utils/routes'
import Button from '../Inputs/Button'
import SelectInput, { SelectOptionValue } from '../Inputs/SelectInput'
import TextInput from '../Inputs/TextInput'
import GroupDevicesTable from '../Tables/GroupDevicesTable'
import { useTranslation } from 'react-i18next'

interface Props {
    currentGroup: NetworkGroup
    viewOnly?: boolean
}

const GroupDevicesTab: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()
    const [selectedModel, setSelectedModel] = useState<SelectOptionValue | null>({
        label: t('fields.model'),
        value: '',
    })
    const [selectedBrand, setSelectedBrand] = useState<SelectOptionValue | null>({
        label: t('routes.brand'),
        value: '',
    })
    const [searchDeviceName, setSearchDeviceName] = useState<string>('')
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [toggleReset, setToggleReset] = useState(undefined)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)
    const [brands, setBrands] = useState<Brand[]>([])
    const [models, setModels] = useState<Model[]>([])
    const navigate = useNavigate()

    const fetchModelAndBrandData = async () => {
        try {
            const availableModels: Model[] = await CustomAxios.get(getOrCreateDeviceModel()).then(
                (response) => response.data.data
            )
            const availableBrands: Brand[] = await CustomAxios.get(getOrCreateBrands()).then(
                (response) => response.data.data
            )
            setBrands(availableBrands)
            setModels(availableModels)
        } catch (error) {
            console.error(error)
        }
    }

    const getFilterParameters = () => {
        const filterParameters: any = {
            name: searchDeviceName,
            brand: selectedBrand.value === '' ? '' : parseInt(selectedBrand.value, 10),
            device_model: selectedModel.value === '' ? '' : parseInt(selectedModel.value, 10),
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const filterParametersChanged = useMemo(() => {
        return searchDeviceName !== '' || selectedModel.value !== '' || selectedBrand.value !== ''
    }, [searchDeviceName, selectedModel, selectedBrand])

    const getFilteredSchedulers = () => {
        setFilterParameters(getFilterParameters())
    }

    useEffect(() => {
        void fetchModelAndBrandData()
    }, [])

    const resetFilterParameters = () => {
        setSearchDeviceName('')
        setSelectedBrand({ label: t('routes.brand'), value: '' })
        setSelectedModel({ label: t('fields.model'), value: '' })
        setFilterParameters(null)
        setToggleReset(!toggleReset)
    }

    const availableModels = useMemo(() => {
        setSelectedModel({ label: t('fields.model'), value: '' })
        if (models.length > 0 && selectedBrand.value !== '') {
            return models.filter((model: Model) => model.idBrand.toString() === selectedBrand.value)
        }
        return []
    }, [selectedBrand])

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: 20 }}>
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterDevices')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin={user.idSysGrant === 0 || user.managedNetworks.length > 0 ? '0 25px 0 0' : '0'}
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                    {(user.idSysGrant === 0 ||
                        user.managedNetworks.find((networkId) => networkId === props.currentGroup.idNetwork)) && (
                        <Button
                            label={t('common.addDevice')}
                            backgroundColor="#e10915"
                            textColor="#fff"
                            icon={faPlus}
                            iconColor="#fff"
                            onPress={() =>
                                navigate(`/${ROUTE_NAMES.DEVICE}/new-device`, {
                                    state: { groupId: props.currentGroup.id },
                                })
                            }
                            margin="0"
                        />
                    )}
                </div>
                <div className="sectionContent">
                    {filterPanelIsVisible && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="search-wrapper-body search-wrapper-single-row-container">
                                <TextInput
                                    label=""
                                    placeholder={t('fields.name')}
                                    value={searchDeviceName}
                                    onChangeText={(value: string) => setSearchDeviceName(value)}
                                    width={'100%'}
                                />
                                <SelectInput
                                    label=""
                                    value={selectedBrand}
                                    onValueChange={(value) => setSelectedBrand(value)}
                                    options={brands.map((brand: Brand) => {
                                        return { label: brand.name, value: brand.ID.toString() }
                                    })}
                                    width={'100%'}
                                />
                                <SelectInput
                                    label=""
                                    disabled={selectedBrand.value === ''}
                                    value={selectedModel}
                                    onValueChange={(value) => setSelectedModel(value)}
                                    options={availableModels.map((model: Model) => {
                                        return { label: model.model, value: model.ID.toString() }
                                    })}
                                    width={'100%'}
                                />
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getFilteredSchedulers()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <GroupDevicesTable
                    reloads={toggleReset}
                    filterParams={filterParameters}
                    currentGroup={props.currentGroup}
                />
            </div>
        </div>
    )
}

export default GroupDevicesTab
