import React, { useEffect, useRef, useState } from 'react'
import LogoImage from '../../resources/media/images/whitelabel/login-header.png'
import MenuIcon from '../../resources/media/images/icons/menu.png'
import AccountIcon from '../../resources/media/images/icons/account.png'
import LogoutIcon from '../../resources/media/images/icons/logout.png'
import '../../styles/components/staticComponents.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { ROUTE_NAMES } from '../../utils/routes'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { logoutUser } from '../../store/actions/user'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { handleDropdownClick } from '../../utils/functions'
import { createCustomerSession, getCustomerInformations } from '../../store/actions/requests'
import { COLORS } from '../../styles/constants'
import { config } from '../../config/config'

const Header: React.FC = () => {
    const { configurations } = useSelector<Reducers, ReducerData>((store) => store.data)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [menuVisible, setMenuVisible] = useState<boolean>(false)
    const [currentCustomer, setCurrentCustomer] = useState('')
    const [logo, setLogo] = useState(LogoImage)

    const navigate = useNavigate()
    const userMenuRef = useRef(null)
    const menuTriggerRef = useRef(null)

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const fetchCurrentCustomer = async () => {
        const customer = await getCustomerInformations()
        setCurrentCustomer(customer)
    }

    useEffect(() => {
        const aspectLogo = configurations.find((c) => c.key === 'aspect.logo')
        if (aspectLogo && aspectLogo.value !== '') {
            setLogo(`${config.api.baseURL}/${aspectLogo.value}`)
        }
    }, [configurations])

    useEffect(() => {
        void fetchCurrentCustomer()
    }, [])

    useEffect(() => {
        window.addEventListener('click', (e) => {
            setMenuVisible(handleDropdownClick(e, menuVisible, menuTriggerRef, userMenuRef))
        })
        return () => {
            window.removeEventListener('click', (e) => {
                setMenuVisible(handleDropdownClick(e, menuVisible, menuTriggerRef, userMenuRef))
            })
        }
    }, [])

    const getUserRoleLabel = () => {
        switch (user.idSysGrant) {
            case 0:
                return t('userRoles.superAdmin')
            default:
                return t('userRoles.user')
        }
    }

    return (
        <>
            <div className="header">
                <div className="leftHeader">
                    <img alt="" className="logo" src={logo} />
                </div>
                <div className="rightHeader">
                    <span className="usernameHeader">{t('header.userTitle', { fullName: `${user.firstName}` })}</span>
                    <div ref={menuTriggerRef} className="menuHeader">
                        <FontAwesomeIcon icon={faSortDown} color="#666" style={{ fontSize: 18 }} />
                        <img alt="" src={MenuIcon} />
                    </div>
                </div>
            </div>
            <div ref={userMenuRef}>
                {menuVisible && (
                    <div className="overlayHeader">
                        <ul>
                            <li
                                className="header-list-item"
                                style={{
                                    paddingLeft: 32,
                                }}
                            >
                                {getUserRoleLabel()}
                            </li>
                            {currentCustomer && (
                                <li className="header-list-item">
                                    <a
                                        className="header-list-content"
                                        onClick={async () => {
                                            const customerPortal = await createCustomerSession(currentCustomer)
                                            window.open(customerPortal.value, '_self', 'noopener,noreferrer')
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCreditCard}
                                            color={COLORS.palette.darkGrey}
                                            style={{ marginRight: '14px', marginLeft: '3px' }}
                                        />
                                        {t('fields.billing')}
                                    </a>
                                </li>
                            )}
                            <li className="header-list-item">
                                <NavLink
                                    className="header-list-content"
                                    to={`/${ROUTE_NAMES.PROFILE}`}
                                    onClick={() => setMenuVisible(false)}
                                >
                                    <>
                                        <img alt="" src={AccountIcon} />
                                        {t('routes.profile')}
                                    </>
                                </NavLink>
                            </li>
                            <li className="header-list-item">
                                <a
                                    className="header-list-content"
                                    onClick={() => {
                                        dispatch(logoutUser())
                                        localStorage.removeItem('access_token')
                                        navigate(`${ROUTE_NAMES.LOGIN}`)
                                    }}
                                >
                                    <img alt="" src={LogoutIcon} />
                                    {t('header.logout')}
                                </a>
                            </li>
                        </ul>
                        <div className="triangle" />
                    </div>
                )}
            </div>
        </>
    )
}

export default Header
