import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createDeviceType, updateDeviceType } from '../../../../store/actions/requests'
import PageContainer from '../../../../Components/StaticComponents/PageContainer'
import Footer from '../../../../Components/StaticComponents/Footer'
import { faArrowLeft, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../../Components/Inputs/TextField'
import Button from '../../../../Components/Inputs/Button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { DeviceType } from '../../../../types/data/alarm'
import { ROUTE_NAMES } from '../../../../utils/routes'
import CustomAxios from '../../../../customComponents/customAxios'
import AlertModal from '../../../../Components/Modals/AlertModal'
import DeleteModal from '../../../../Components/Modals/DeleteModal'
import { ToastError, ToastSuccess } from '../../../../utils/toast'
import { Reducers, ReducerUser } from '../../../../types/reducers'
import Loader from '../../../../Components/StaticComponents/Loader'
import PageHeaderCustom from '../../../../customComponents/PageHeaderCustom'
import { useTranslation } from 'react-i18next'

interface DeviceTypeDetailPageState {
    state: {
        isView?: boolean
    }
}

const DeviceTypeDetailPage: React.FC = () => {
    const [showAlertModal, setShowAlertModal] = useState(false)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [deviceType, setDeviceType] = useState<DeviceType>({
        ID: -1,
        name: '',
        description: '',
        note: '',
    })
    const { id } = useParams<{ id: string }>()
    const { state }: DeviceTypeDetailPageState = useLocation()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [editMode, setEditMode] = useState(!(state && state.isView))
    const { t } = useTranslation()

    useEffect(() => {
        if (user.idSysGrant !== 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
        if (id !== undefined && id !== 'new-device-type') {
            void getType(id)
        } else {
            setDeviceType({
                ID: -1,
                name: '',
                description: '',
                note: '',
            })
        }
    }, [id, user])

    const getType = async (id: string) => {
        try {
            setLoading(true)
            const deviceType: DeviceType = await CustomAxios.get(`device-types/${id}`).then((res) => {
                return res.data
            })
            setDeviceType(deviceType)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCreateDeviceType = async () => {
        try {
            const newDeviceType = await dispatch(createDeviceType(deviceType))
            if (newDeviceType) {
                navigate(`/${ROUTE_NAMES.DEVICE_TYPES}/${newDeviceType.ID}`)
                ToastSuccess(t('actionsMessages.successDeviceTypeCreate'))
            } else {
                ToastError(t('actionsMessages.genericError'))
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const handleUpdateDeviceType = async () => {
        try {
            dispatch(updateDeviceType(deviceType))
            ToastSuccess(t('actionsMessages.successDeviceTypeUpdate'))
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const deviceTypeDataIsCorrect = useMemo(() => {
        return deviceType.name !== '' && deviceType.description !== ''
    }, [deviceType])

    const handleSendUserData = () => {
        if (id !== undefined && id !== 'new-device-type') {
            void handleUpdateDeviceType()
        } else {
            void handleCreateDeviceType()
        }
    }

    const initExitProcedure = () => {
        if (editMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.DEVICE_TYPES}?page=0`)
        }
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`device-types/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.DEVICE_TYPES}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <PageContainer>
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.DEVICE_TYPES}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={() => void confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('routes.devicesTypes')}
                                </span>{' '}
                                {`/ ${deviceType.ID === -1 ? t('fields.newUser') : deviceType.name}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.deviceTypeDetail" />
                        </div>
                        <div className="header-right-actions">
                            {!editMode ? (
                                <Button
                                    label={t('common.update')}
                                    backgroundColor="#EA0B2A"
                                    textColor="#fff"
                                    iconColor="#fff"
                                    margin="0"
                                    icon={faPen}
                                    onPress={() => setEditMode(true)}
                                />
                            ) : (
                                <>
                                    <Button
                                        label={t('common.back')}
                                        backgroundColor="#efefef"
                                        textColor="#3f3f3f"
                                        margin="0 0 0 25px"
                                        icon={faArrowLeft}
                                        onPress={() => initExitProcedure()}
                                    />
                                    {id !== undefined && id !== 'new-device-type' && (
                                        <Button
                                            label={t('common.delete')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faTrash}
                                            onPress={() => initDeleteProcedure()}
                                        />
                                    )}
                                    <Button
                                        isDisabled={!deviceTypeDataIsCorrect}
                                        label={t('common.save')}
                                        backgroundColor="#e10915"
                                        textColor="#ffffff"
                                        margin="0 0 0 25px"
                                        iconColor="#ffffff"
                                        icon={faPen}
                                        onPress={() => handleSendUserData()}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="sectionContent">
                        <div className="editContainer advanced-search-wrapper">
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.name')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.name')}
                                        value={deviceType.name}
                                        onChangeText={(value: string) => {
                                            setDeviceType({
                                                ...deviceType,
                                                name: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.description')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.description')}
                                        isTextarea
                                        value={deviceType.description}
                                        onChangeText={(value: string) => {
                                            setDeviceType({
                                                ...deviceType,
                                                description: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.notes')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.notes')}
                                        isTextarea
                                        value={deviceType.note}
                                        onChangeText={(value: string) => {
                                            setDeviceType({
                                                ...deviceType,
                                                note: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default DeviceTypeDetailPage
