import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faCircleMinus, faMinus, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { OpenedEvent, ThresholdLevel, ThresholdRule } from '../../types/data/alarm'
import moment from 'moment'
import AlertModal from '../Modals/AlertModal'
import CustomAxios from '../../customComponents/customAxios'
import { singleDeviceEvent } from '../../utils/api-constants'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { ROUTE_NAMES } from '../../utils/routes'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { useTablePagination } from '../../utils/hooks/common'
import { useDispatch } from 'react-redux'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { Tooltip } from '@material-ui/core'
import { statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { COLORS } from '../../styles/constants'

export interface HistoryEventTableData {
    id: number
    status: number
    deviceCode: string
    deviceName: string
    maintenanceMode: number
    eventData: OpenedEvent
}

interface Props {
    filterParams?: any
    reloads?: boolean
    onFinish?: () => void
}

const EventsHistoryTable: React.FC<Props> = React.memo((props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [firstDataLoad, setFirstDataLoad] = useState(true)
    const [selectedEventIdToRemove, setSelectedEventIdToRemove] = useState(-1)
    const { t } = useTranslation()
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const deleteMessage = t('systemMessages.removeElementFromList')

    const currentPageFromURL = useTablePagination('page')

    useEffect(() => {
        handlePageDataUpdate()
    }, [])

    const handlePageDataUpdate = useCallback(() => {
        const currentPageValue = currentPageFromURL > 0 ? currentPageFromURL : 0
        if (currentPageValue * pageSize <= totalElements) {
            setCurrentPage(currentPageValue)
            resetData(currentPageValue)
        }
    }, [currentPageFromURL, totalElements, pageSize])

    const handleDataCount = (totCounter: number): number => {
        setTotalElements(totCounter)
        return totCounter
    }

    const initRemoveProcedure = (alarmId: number) => {
        setOpenAlertModal(true)
        setSelectedEventIdToRemove(alarmId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.patch(singleDeviceEvent(selectedEventIdToRemove), {
                hide: true,
                date_end: moment().format('YYYY-MM-DD HH:mm:ss'),
                forced_close_by: user.ID,
            })
            exitRemoveProcedure()
            resetData(currentPage, '', '', true)
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedEventIdToRemove(-1)
    }

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData(0, '', '', true)
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData(currentPage, '', '', true)
        }
    }, [props.reloads])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '', forceReload = false) => {
        if (page === currentPage && !forceReload) return
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const getThresholdRule = (rule: ThresholdRule) => {
        switch (rule) {
            case 'higher':
                return t('thresholdRules.higherString')
            case 'not equal':
                return t('thresholdRules.notEqual')
            case 'lower':
            default:
                return t('thresholdRules.lower')
        }
    }

    const getOpenEventString = (deviceEvent: any): string => {
        if (deviceEvent.eventString) return deviceEvent.eventString
        const rule = deviceEvent.operator || 'higher'
        return t('thresholdEventDefaultMessage.fullPhrase', {
            variableName: deviceEvent.variable,
            measuredValue: deviceEvent.startValue,
            thresholdLevel: getThresholdRule(rule),
            thresholdValue: deviceEvent.referenceValue,
        })
    }

    const getThresholdLevel = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return t('alarmLevels.low')
            case 1:
                return t('alarmLevels.medium')
            case 2:
            default:
                return t('alarmLevels.high')
        }
    }

    const getThresholdIcon = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return <FontAwesomeIcon icon={faChevronDown} color={COLORS.events.low} />
            case 1:
                return <FontAwesomeIcon icon={faMinus} color={COLORS.events.medium} />
            case 2:
            default:
                return <FontAwesomeIcon icon={faChevronUp} color={COLORS.events.high} />
        }
    }

    const columns = [
        {
            title: t('fields.status'),
            field: 'state',
            width: '1%',
            render: (rowData: any) => {
                return (
                    <>
                        {rowData.maintenanceMode ? (
                            <div className="dot-container">
                                <div style={{ minWidth: '102px' }} className="dot-tooltip">
                                    {t('fields.onMaintenanceMode')}
                                </div>
                                <FontAwesomeIcon
                                    icon={faToolbox}
                                    color={COLORS.palette.darkBlue}
                                    style={{ width: '20px', height: '20px', marginLeft: '7px' }}
                                />
                            </div>
                        ) : (
                            <div className="dot-container">
                                <div className="dot-tooltip">{t(statusLabelForDevice(rowData))}</div>
                                <span className={statusPinStyleForDevice(rowData)} />
                            </div>
                        )}
                    </>
                )
            },
        },
        {
            title: t('fields.priority'),
            field: 'level',
            width: '1%',
            render: (rowData: any) => {
                return (
                    <div className="small-cell">
                        <div className="input-row">
                            <Tooltip title={getThresholdLevel(rowData.level)}>
                                <div>{getThresholdIcon(rowData.level)}</div>
                            </Tooltip>
                        </div>
                    </div>
                )
            },
        },
        {
            title: location.pathname.split('/')[1] !== ROUTE_NAMES.DEVICE ? t('fields.device') : '',
            field: location.pathname.split('/')[1] !== ROUTE_NAMES.DEVICE ? 'device_name' : '',
            sorting: location.pathname.split('/')[1] !== ROUTE_NAMES.DEVICE,
            render: (rowData: any) => {
                if (location.pathname.split('/')[1] !== ROUTE_NAMES.DEVICE) {
                    return (
                        <a className="colorRed  table-link-value" href={`/${ROUTE_NAMES.DEVICE}/${rowData?.idDevice}`}>
                            {`${rowData.device.brand.name} - ${rowData.device.deviceModel.model} - ${rowData.device.name}`}
                        </a>
                    )
                }
            },
        },

        {
            title: t('fields.event'),
            field: 'variable',
            render: (rowData: any) => {
                return (
                    <div>
                        <div className="table-event-value-block">
                            <span>{getOpenEventString(rowData)}</span>
                        </div>
                    </div>
                )
            },
        },
        {
            title: t('fields.dateEvent'),
            field: 'date',
            render: (rowData: any) => {
                return (
                    <div>
                        <div className="table-event-value-block single-line-event-value-block" style={{ fontSize: 10 }}>
                            <span style={{ color: 'red' }}>
                                {t('fields.start')}: {moment(rowData.dateStart).format('DD/MM/YYYY - HH:mm:ss')}
                            </span>
                            {rowData.dateEnd !== undefined && rowData.dateEnd !== '' && rowData.dateEnd !== null && (
                                <span style={{ color: 'green' }}>
                                    {t('fields.end')}: {moment(rowData.dateEnd).format('DD/MM/YYYY - HH:mm:ss')}
                                </span>
                            )}
                        </div>
                    </div>
                )
            },
            cellStyle: {
                minWidth: 200,
                maxWidth: 200,
            },
        },
    ]

    const actions = useMemo(() => {
        const actions = [
            user.idSysGrant === 0 &&
                ((rowData: any) => {
                    if (rowData.status !== 1) {
                        return {
                            icon: () => <FontAwesomeIcon icon={faCircleMinus} color={COLORS.palette.red} size="xs" />,
                            onClick: () => initRemoveProcedure(rowData.Id),
                            tooltip: t('common.hide'),
                        }
                    }
                }),
        ]
        return actions
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'device-events/detailed'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += '&hidden=false'
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        } else {
                            url += '&sort_by=date_start&order_by=desc'
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: handleDataCount(response.data.count),
                            })
                            if (firstDataLoad && props.onFinish) {
                                props.onFinish()
                                setFirstDataLoad(false)
                            }
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 5,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)

                    if (props.filterParams && props.filterParams.device === undefined) {
                        navigate(`/${ROUTE_NAMES.EVENTS_HISTORY}?page=${pageIndex}`)
                    }
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
})
EventsHistoryTable.displayName = 'EventsHistoryTable'

export default EventsHistoryTable
