import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/components/modals.sass'
import { Network } from '../../types/data/system'
import { NetworkGrant } from '../../utils/enum/NetworkGrant'
import Button from '../Inputs/Button'
import SelectInput from '../Inputs/SelectInput'
import { NetworkUserAssoc } from '../Tables/NetworkUserAssocTable'
import AlertModal from './AlertModal'

interface Props {
    assoc: NetworkUserAssoc
    isNew?: boolean
    associated: NetworkUserAssoc[]
    onClose: () => void
    onConfirm: (edited: NetworkUserAssoc) => void
}

const UserNetworkAssocModal: React.FC<Props> = (props) => {
    const [assoc, setAssoc] = useState(props.assoc)
    const [networksList, setNetworksList] = useState<Network[]>([])
    const [showAlertModal, setShowAlertModal] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setAssoc(props.assoc)
        void fetchUsers()
    }, [props.assoc])

    const fetchUsers = async () => {
        const networks = await CustomAxios.get('networks').then((res) => {
            return res.data.data
        })
        setNetworksList(networks.filter((u) => props.associated.find((us) => us.network.ID === u.ID) === undefined))
    }

    return (
        <div className="alert-modal-wrapper">
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => props.onClose()}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            <div
                className="dark-cover"
                onClick={() => {
                    setShowAlertModal(true)
                }}
            />
            <div className="modal edit">
                <div className="headerSection addNewSection">
                    <h2>
                        <span className="titleLink" style={{ color: '#52565A', opacity: 0.5 }}>
                            {t('fields.userNetworkAssociation')}
                        </span>{' '}
                        -{' '}
                        {props.isNew
                            ? t('fields.newAssociation')
                            : `${t('routes.network')}: ${props.assoc.network.name}`}
                    </h2>
                    <div>
                        <Button
                            label={props.isNew ? t('common.add') : t('common.update')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 25px 0 0"
                            isDisabled={!assoc.network || !assoc.idGrant}
                            icon={props.isNew ? faPlus : faPen}
                            onPress={async () => {
                                if (assoc.network && assoc.idGrant) {
                                    props.onConfirm(assoc)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="advanced-search-wrapper" style={{ zIndex: 100 }}>
                    <div className="filter-column">
                        <h3>{t('routes.network')}</h3>
                        <div className="input-rows">
                            <SelectInput
                                options={networksList.map((l) => {
                                    return { value: l.ID.toString(), label: `${l.name}` }
                                })}
                                disabled={!props.isNew}
                                value={
                                    assoc.network
                                        ? {
                                              value: assoc.user.ID.toString(),
                                              label: `${assoc.network.name}`,
                                          }
                                        : null
                                }
                                placeholder={t('fields.selectNetwork')}
                                onValueChange={(v) => {
                                    const networkFind = networksList.find((u) => u.ID.toString() === v.value)
                                    if (networkFind) {
                                        setAssoc({
                                            ...assoc,
                                            network: networkFind,
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="filter-column">
                        <h3>{t('fields.userGrants')}</h3>
                        <div className="input-rows">
                            <SelectInput
                                options={NetworkGrant.map((l, i) => {
                                    return { value: (i + 1).toString(), label: t(`userRoles.${l}`) }
                                })}
                                value={
                                    assoc.idGrant
                                        ? {
                                              value: assoc.idGrant.toString(),
                                              label: t(`userRoles.${NetworkGrant[assoc.idGrant - 1]}`),
                                          }
                                        : null
                                }
                                placeholder={t('fields.userGrants')}
                                onValueChange={(v) => {
                                    setAssoc({
                                        ...assoc,
                                        idGrant: parseInt(v.value, 10),
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserNetworkAssocModal
