import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import 'leaflet/dist/leaflet.css'
import { useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../types/reducers'
import { Device, DeviceDefaultVars, DeviceToEdit } from '../../types/data/alarm'
import Button from '../../Components/Inputs/Button'
import { faArrowLeft, faPen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import Tabs from '../../Components/Tabs'
import DeviceInfoSection from './DeviceInfoSection'
import EventsHistorySection from './EventsHistorySection'
import DeviceSchedulersSection from './DeviceSchedulersSection'
import DeviceCommandsHistory from './DeviceCommandsHistory'
import CustomAxios from '../../customComponents/customAxios'
import DeviceTreeSection from './DeviceTreeSection'
import UsersActivitiesSection from './UsersActivitiesSection'
import AlertModal from '../../Components/Modals/AlertModal'
import { ROUTE_NAMES } from '../../utils/routes'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import DeviceUsersSection from './DeviceUsersSection'
import DefaultVarsTab from '../../Components/Tabs/DefaultVarsTab'
import { Model } from '../../types/data/system'
import DeleteModal from '../../Components/Modals/DeleteModal'
import DeviceRealtimeSection from './DeviceRealtimeSection'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import Loader from '../../Components/StaticComponents/Loader'
import { useTranslation } from 'react-i18next'
import { ToastInfo, ToastSuccess } from '../../utils/toast'
import { toast } from 'react-toastify'

const defaultDevice: DeviceToEdit = {
    ID: -1,
    code: '',
    name: '',
    note: '',
    serialNo: '',
    snmpPort: '',
    ipAddress: '',
    coordinates: {
        latitude: '',
        longitude: '',
    },
    deviceInfo: [],
    automaticCheck: 1,
    maintenanceMode: 0,
    snmpCommunityRead: '',
    snmpCommunityWrite: '',
    // default forced to SMS
    idCommunicationProtocol: 3,
    idNetwork: -1,
    idDeviceModel: -1,
    idMaster: null,
    idGroup: -1,
    idEditor: -1,
    phoneNumber: '',
    master: null,
    snmpVersion: null,
}

interface DeviceDetailPageState {
    state: {
        isView?: boolean
        groupId?: number
    }
}

const DevicePage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [device, setDevice] = useState<DeviceToEdit | null>(defaultDevice)
    const [deviceVariables, setDeviceVariables] = useState<DeviceDefaultVars[]>([])
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState('')
    const [showEmptyModelAlertModal, setShowEmptyModelAlertModal] = useState(false)
    const [showEmptyNetworkAlertModal, setShowEmptyNetworkAlertModal] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showUnauthorizedDeviceAlert, setShowUnauthorizedDeviceAlert] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dataIsCorrect, setDataIsCorrect] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sendSaveDeviceCommand, setSendSaveDeviceCommand] = useState(undefined)
    const [deviceModel, setDeviceModel] = useState<Model | null>(null)
    const [isPreviewMode, setIsPreviewMode] = useState(true)
    const [loading, setLoading] = useState(false)
    const { state }: DeviceDetailPageState = useLocation()
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    const tabs = useMemo(() => {
        const tabs = [
            {
                value: 'deviceInfo',
                label: t('fields.deviceInfo'),
            },
        ]
        if (id !== null && id !== undefined && id !== '' && id !== 'new-device') {
            tabs.push(
                {
                    value: 'realtime',
                    label: t('fields.metrics'),
                },
                {
                    value: 'deviceSchedulers',
                    label: t('fields.scheduler'),
                },
                {
                    value: 'eventsHistory',
                    label: t('routes.eventsHistory'),
                }
            )
            if (user.idSysGrant === 0) {
                tabs.push(
                    {
                        value: 'variables',
                        label: t('fields.variables'),
                    },
                    {
                        value: 'commandsHistory',
                        label: t('fields.commands'),
                    },
                    {
                        value: 'users',
                        label: t('routes.users'),
                    },
                    {
                        value: 'usersActivity',
                        label: t('fields.usersActivity'),
                    }
                )
                // if (device.idMaster === null) {
                //     tabs.push({
                //         value: 'childDevices',
                //         label: t('fields.connectedDevices')
                //     })
                // }
            }
        }
        return tabs
    }, [device, user, id])

    const getModelObject = async (singleDevice: Device) => {
        try {
            const model: Model = await CustomAxios.get(`device-models/${singleDevice.idDeviceModel}`).then(
                (response) => response.data
            )
            setDeviceModel(model)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchDeviceData = async (newId?: number) => {
        try {
            const deviceId = newId && newId !== -1 ? newId : id
            setLoading(true)
            const singleDevice: Device = await CustomAxios.get(`devices/${deviceId}`).then((response) => response.data)
            if (singleDevice) {
                setDevice({
                    ...singleDevice,
                    coordinates: {
                        latitude: singleDevice.coordinates !== null ? singleDevice.coordinates.latitude.toString() : '',
                        longitude:
                            singleDevice.coordinates !== null ? singleDevice.coordinates.longitude.toString() : '',
                    },
                    snmpPort: singleDevice.snmpPort !== null ? singleDevice.snmpPort.toString() : '',
                    snmpCommunityRead: singleDevice.snmpCommunityRead,
                    snmpCommunityWrite: singleDevice.snmpCommunityWrite,
                    note: singleDevice.note ? singleDevice.note : '',
                    maintenanceMode: singleDevice.maintenanceMode !== undefined ? singleDevice.maintenanceMode : 0,
                })
                const singleDeviceVars: DeviceDefaultVars[] = await CustomAxios.get(
                    `device-vars?id_device=${singleDevice.ID}`
                ).then((response) => {
                    return response.data.data.map((item) => {
                        return { ...item, thresholds: item.thresholds ? JSON.parse(item.thresholds) : null }
                    })
                })

                void getModelObject(singleDevice)
                setDeviceVariables(singleDeviceVars)
            }
            setLoading(false)
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                setShowUnauthorizedDeviceAlert(true)
            } else if (error.response && error.response.status === 404) {
                navigate(`/${ROUTE_NAMES.LIST}?page=0`)
            }

            console.error(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        setShowEmptyModelAlertModal(device.idDeviceModel === null)
        setShowEmptyNetworkAlertModal(device.idNetwork === null)
    }, [device.idDeviceModel, device.idNetwork])

    useEffect(() => {
        if (id !== null && id !== undefined && id !== '' && id !== 'new-device') {
            void fetchDeviceData()
        }
    }, [id])

    useEffect(() => {
        // if (activeTab === 'deviceInfo' && id !== null && id !== undefined && id !== '' && id !== 'new-device') fetchDeviceData()
    }, [activeTab, id])

    useEffect(() => {
        if (id !== null && id !== undefined && id !== '' && id !== 'new-device') {
            if (user.idSysGrant === 0) {
                setIsPreviewMode(true)
            } else {
                setIsPreviewMode(true)
            }
            setActiveTab(tabs.length > 0 ? tabs[1].value : '')
        } else {
            setIsPreviewMode(false)
            setActiveTab(tabs.length > 0 ? tabs[0].value : '')
        }
    }, [id, state, user.idSysGrant, device])

    const getDeviceVars = useCallback(() => {
        if (id !== null && id !== undefined && id !== '' && id !== 'new-device') {
            return deviceVariables || []
        }
        return deviceModel !== null && deviceModel.defaultVars && deviceVariables
            ? [...deviceModel.defaultVars, ...deviceVariables]
            : []
    }, [deviceModel, deviceVariables, id])

    const refreshDeviceVars = useCallback(async () => {
        const singleDeviceVars: DeviceDefaultVars[] = await CustomAxios.get(`device-vars?id_device=${device.ID}`).then(
            (response) => {
                return response.data.data.map((item) => {
                    return { ...item, thresholds: item.thresholds ? JSON.parse(item.thresholds) : null }
                })
            }
        )
        setDeviceVariables(singleDeviceVars)
        getDeviceVars()

        ToastSuccess(t('actionsMessages.successFetchData'))
        toast.dismiss(device.ID)
    }, [deviceVariables])

    const activeSubsection = useMemo(() => {
        switch (activeTab) {
            case 'deviceInfo':
                return (
                    <DeviceInfoSection
                        groupId={state && state.groupId !== null ? state.groupId : undefined}
                        isPreviewMode={isPreviewMode}
                        device={device}
                        saveDevice={sendSaveDeviceCommand}
                        checkDataIsCorrect={(value) => setDataIsCorrect(value)}
                        sendIsLoading={(flag) => setIsLoading(flag)}
                        reloadDevice={() => void fetchDeviceData()}
                    />
                )
            case 'eventsHistory':
                return <EventsHistorySection device={device} />
            case 'commandsHistory':
                return <DeviceCommandsHistory device={device} />
            case 'deviceSchedulers':
                return <DeviceSchedulersSection device={device} />
            case 'childDevices':
                return <DeviceTreeSection device={device} />
            case 'usersActivity':
                return <UsersActivitiesSection device={device} />
            case 'users':
                return <DeviceUsersSection device={device} />
            case 'realtime':
                return <DeviceRealtimeSection device={device} />
            case 'variables':
                return (
                    <DefaultVarsTab
                        deviceId={device.ID}
                        defaultVars={getDeviceVars()}
                        viewOnly={!isPreviewMode}
                        updateModel={async (vars, editedOrCreatedVar) => {
                            ToastInfo(device.ID, t('actionsMessages.updateLoading'))
                            const variable = editedOrCreatedVar
                            delete (variable as any).tableData
                            if (variable.ID) {
                                await CustomAxios.put(`device-vars/${variable.ID}`, variable)
                            } else {
                                await CustomAxios.post('device-vars', variable)
                            }
                            void refreshDeviceVars()
                        }}
                    />
                )
            default:
                return
        }
    }, [activeTab, device, isPreviewMode, deviceModel, state, deviceVariables, sendSaveDeviceCommand])

    const handleSelectedTabChange = (value: string) => {
        setActiveTab(value)
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`devices/${id}`)
            navigate(`/${ROUTE_NAMES.LIST}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const initExitProcedure = () => {
        if (!isPreviewMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.LIST}?page=0`)
        }
    }

    // TODO: migliorare gestione chiamata saveDevice() per
    // componente DeviceInfoSection
    useEffect(() => {
        if (sendSaveDeviceCommand) {
            setSendSaveDeviceCommand(undefined)
        }
    }, [sendSaveDeviceCommand])

    return (
        <PageContainer>
            {showUnauthorizedDeviceAlert && (
                <AlertModal
                    singleButton
                    confirmLabel={t('deviceStatus.ok')}
                    alertMessage={t('systemMessages.unauthorizedDeviceAlert')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.LIST}?page=0`)}
                    onClose={() => setShowUnauthorizedDeviceAlert(false)}
                />
            )}
            {showEmptyModelAlertModal && (
                <AlertModal
                    singleButton
                    confirmLabel={t('deviceStatus.ok')}
                    alertMessage={t('systemMessages.emptyModelAlert')}
                    onConfirm={() => setShowEmptyModelAlertModal(false)}
                    onClose={() => setShowEmptyModelAlertModal(false)}
                />
            )}
            {showEmptyNetworkAlertModal && (
                <AlertModal
                    singleButton
                    confirmLabel={t('deviceStatus.ok')}
                    alertMessage={t('systemMessages.emptyNetworkAlert')}
                    onConfirm={() => setShowEmptyNetworkAlertModal(false)}
                    onClose={() => setShowEmptyNetworkAlertModal(false)}
                />
            )}
            {showAlertModal && (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.LIST}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={() => void confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('fields.devices')}
                                </span>
                                {` / ${device.ID === -1 ? t('fields.newDevice') : device.name}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.deviceDetail" />
                        </div>
                        <div className="header-right-actions">
                            {isPreviewMode ? (
                                <>
                                    {(user.idSysGrant === 0 || user.managedNetworks.length > 0) && (
                                        <Button
                                            label={t('common.update')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => setIsPreviewMode(false)}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button
                                        label={t('common.back')}
                                        backgroundColor="#efefef"
                                        textColor="#3f3f3f"
                                        margin="0 0 0 25px"
                                        icon={faArrowLeft}
                                        onPress={() => setShowAlertModal(true)}
                                    />
                                    {user.idSysGrant === 0 && id !== undefined && id !== 'new-device' && (
                                        <Button
                                            label={t('common.delete')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faTrash}
                                            onPress={() => initDeleteProcedure()}
                                        />
                                    )}
                                    <Button
                                        isDisabled={!dataIsCorrect || isPreviewMode || isLoading}
                                        label={t('common.save')}
                                        backgroundColor="#e10915"
                                        textColor="#ffffff"
                                        margin="0 0 0 25px"
                                        iconColor="#ffffff"
                                        icon={faSave}
                                        onPress={() => setSendSaveDeviceCommand(!sendSaveDeviceCommand)}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="sectionContent">
                        <div>
                            <Tabs
                                tabs={tabs}
                                defaultSelectedTabIndex={
                                    id !== null && id !== undefined && id !== '' && id !== 'new-device' ? 1 : 0
                                }
                                onSelectedTabChange={(selectedTabValue) => handleSelectedTabChange(selectedTabValue)}
                            />
                        </div>
                        {activeSubsection}
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default DevicePage
