import React, { useMemo, useState } from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'
import TextInput from '../Inputs/TextInput'
import CustomAxios from '../../customComponents/customAxios'
import { useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useDispatch } from 'react-redux'
import { getLoggedUserInfo } from '../../store/actions/requests'

interface Props {
    onClose?: () => void
}

const ChangePasswordFirstAccessModal: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [showPassError, setShowPassError] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const defaultConfirmLabel = t('common.confirm')
    const dispatch = useDispatch()

    const updatePassword = async () => {
        try {
            setShowPassError(false)
            setLoading(true)
            const passwordData = {
                oldPassword,
                newPassword,
                confirmNewPassword: newPassword,
            }
            await CustomAxios.put('auth/update_password/', passwordData)
            await CustomAxios.put(`users/user/${user.ID}`, { firstAccess: 0 })
            await dispatch(getLoggedUserInfo())
            setOldPassword('')
            setNewPassword('')
            if (props.onClose) props.onClose()
        } catch (error) {
            console.error(error)
            setShowPassError(true)
        }
        setLoading(false)
    }

    const dataIsValid = useMemo(() => {
        return oldPassword !== '' && newPassword !== ''
    }, [oldPassword, newPassword])

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="modal alert-modal" style={{ maxWidth: '550px' }}>
                <h3>{t('fields.changePassword')}</h3>
                <p className="modal--message" style={{ textAlign: 'center' }}>
                    {t('systemMessages.changePasswordOnFirstLogin')}
                </p>
                {showPassError && <p className="error-message">{t('systemMessages.oldPasswordNotCorrectMessage')}</p>}
                <div className="input-row spaced-inputs" style={{ margin: '16px 0' }}>
                    <TextInput
                        value={oldPassword}
                        label=""
                        placeholder={t('fields.oldPassword')}
                        onChangeText={(newVal) => setOldPassword(newVal)}
                    />
                    <TextInput
                        value={newPassword}
                        label=""
                        placeholder={t('fields.newPassword')}
                        onChangeText={(newVal) => setNewPassword(newVal)}
                    />
                </div>
                <div className="modal--actions-container">
                    <button
                        className="confirm-action"
                        style={{ marginRight: 0 }}
                        disabled={!dataIsValid || loading}
                        onClick={() => void updatePassword()}
                    >
                        {defaultConfirmLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordFirstAccessModal
