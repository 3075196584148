import React from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'

interface Props {
    deleteMessage: string
    closeLabel?: string
    confirmLabel?: string
    onClose: () => void
    onDelete: () => void
}

const DeleteModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.cancel')
    const defaultDeleteLabel = t('common.delete')

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="modal delete-modal">
                <p className="modal--message">{props.deleteMessage}</p>
                <div className="modal--actions-container">
                    <button className="confirm-action" onClick={() => props.onDelete()}>
                        {props.confirmLabel ? props.confirmLabel : defaultDeleteLabel}
                    </button>
                    <button className="close-action" onClick={() => props.onClose()}>
                        {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal
