import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { ROUTE_NAMES } from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import CheckboxInput from '../Inputs/CheckboxInput'
import { usePrevious } from '../../utils/hooks/common'
import { MenuItem, Select } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../Modals/AlertModal'
import { User } from '../../types/data/user'
import { Device } from '../../types/data/alarm'
import { useDispatch } from 'react-redux'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AlarmLevels } from '../../utils/enum/AlarmLevels'
import { COLORS } from '../../styles/constants'

interface Props {
    searchQuery?: string
    idDevice?: number
    reloads?: boolean
}

interface UserDevice {
    ID: number
    idDevice: number
    idUser: number
    emailEnabled: number
    smsEnabled: number
    pushEnabled: number
    minAlarmLevelNotification: number
    user: User
    device: Device
}

const DeviceUsersTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { configurations } = useSelector<Reducers, ReducerData>((state) => state.data)
    const [selectedElementToRemove, setSelectedElementToRemove] = useState(-1)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [GSMEnabled, setGSMEnabled] = useState(false)
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const tableRef = React.useRef<any>()
    const previousQuery = usePrevious(props.searchQuery)
    const { t } = useTranslation()
    const deleteMessage = t('systemMessages.removeElementFromList')
    const dispatch = useDispatch()

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData()
        }
    }, [props.searchQuery])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData()
        }
    }, [props.reloads])

    useEffect(() => {
        configurations.map((param) => {
            if (param.key === 'gsm_enabled') {
                setGSMEnabled(param.value === 'true')
            }
            return
        })
    }, [configurations])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const updateUserSettings = async (recordId: number, newRecordData: any) => {
        try {
            delete newRecordData.tableData
            delete newRecordData.device
            delete newRecordData.user
            await CustomAxios.put(`users-devices/${recordId}`, newRecordData)
            resetData()
        } catch (error) {
            console.error(error)
        }
    }

    const columns = [
        {
            title: t('fields.name'),
            field: 'user_name',
            render: (rowData: UserDevice) => (
                <span
                    className="colorRed table-link-value"
                    onClick={() => navigate(`/${ROUTE_NAMES.USERS}/${rowData.idUser}`)}
                >
                    {rowData.user.firstName} {rowData.user.lastName}
                </span>
            ),
        },
        GSMEnabled
            ? {
                  title: t('fields.smsNotifications'),
                  field: 'sms_enabled',
                  render: (rowData: UserDevice) => (
                      <div className="table-cell-checkbox-container">
                          <CheckboxInput
                              label=""
                              value={rowData.smsEnabled === 1}
                              onChangeValue={async (value: boolean) =>
                                  updateUserSettings(rowData.ID, { ...rowData, smsEnabled: value ? 1 : 0 })
                              }
                          />
                      </div>
                  ),
              }
            : {},
        {
            title: t('fields.emailNotifications'),
            field: 'email_enabled',
            render: (rowData: UserDevice) => (
                <div className="table-cell-checkbox-container">
                    <CheckboxInput
                        label=""
                        value={rowData.emailEnabled === 1}
                        onChangeValue={async (value: boolean) =>
                            updateUserSettings(rowData.ID, { ...rowData, emailEnabled: value ? 1 : 0 })
                        }
                    />
                </div>
            ),
        },
        {
            title: t('fields.pushNotifications'),
            field: 'push_enabled',
            render: (rowData: UserDevice) => (
                <div className="table-cell-checkbox-container">
                    <CheckboxInput
                        label=""
                        value={rowData.pushEnabled === 1}
                        onChangeValue={async (value: boolean) =>
                            updateUserSettings(rowData.ID, { ...rowData, pushEnabled: value ? 1 : 0 })
                        }
                    />
                </div>
            ),
        },
        {
            title: t('fields.minLevelNotification'),
            field: 'min_alarm_level_notification',
            render: (rowData: UserDevice) => (
                <div>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowData.minAlarmLevelNotification}
                        label="Age"
                        onChange={async (e) =>
                            updateUserSettings(rowData.ID, { ...rowData, minAlarmLevelNotification: e.target.value })
                        }
                    >
                        <MenuItem value={0}>{t(`alarmLevels.${AlarmLevels[0]}`)}</MenuItem>
                        <MenuItem value={1}>{t(`alarmLevels.${AlarmLevels[1]}`)}</MenuItem>
                        <MenuItem value={2}>{t(`alarmLevels.${AlarmLevels[2]}`)}</MenuItem>
                    </Select>
                </div>
            ),
        },
    ]

    const initRemoveProcedure = (schedulerId: number) => {
        setOpenAlertModal(true)
        setSelectedElementToRemove(schedulerId)
    }

    const confirmRemoveEvent = async () => {
        try {
            await CustomAxios.delete(`users-devices/${selectedElementToRemove}`)
            exitRemoveProcedure()
            resetData(currentPage)
        } catch (error) {
            console.error(error)
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedElementToRemove(-1)
    }

    const actions = [
        (rowData: UserDevice) => ({
            icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
            onClick: () => initRemoveProcedure(rowData.ID),
            tooltip: t('common.remove'),
        }),
    ]

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={deleteMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={() => void confirmRemoveEvent()}
                />
            )}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                actions={actions}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'users-devices'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&query=${props.searchQuery}`
                        }
                        if (props.idDevice !== undefined) {
                            url += `&device=${props.idDevice}`
                        }
                        void CustomAxios.get(url).then((response) => {
                            const users = response.data.data.filter((user) => user.user.hide !== 1)
                            resolve({
                                data: users,
                                page: query.page,
                                totalCount: users.length,
                            })
                        })
                    })
                }
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 6,
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default DeviceUsersTable
