import React, { useEffect, useState } from 'react'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import '../../styles/pages/device.sass'
import 'leaflet/dist/leaflet.css'
import { Device } from '../../types/data/alarm'
import CustomAxios from '../../customComponents/customAxios'
import { getDeviceTree } from '../../utils/api-constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { ROUTE_NAMES } from '../../utils/routes'
import AlertModal from '../../Components/Modals/AlertModal'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import Loader from '../../Components/StaticComponents/Loader'
import { ToastError, ToastSuccess } from '../../utils/toast'

interface Props {
    device: any
}

const DeviceTreeSection: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [children, setChildren] = useState<Device[]>([])
    const [availableDevices, setAvailableDevices] = useState<Device[]>([])
    const { t } = useTranslation()
    const [selectedDeviceToAdd, setSelectedDeviceToAdd] = useState<SelectOptionValue | null>({
        label: t('fields.chooseDevice'),
        value: '',
    })
    const [selectedElementToRemove, setSelectedElementToRemove] = useState(-1)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const addChildMessage = t('systemMessages.removeChildFromDevice')

    const fetchDeviceTree = async () => {
        try {
            const treeData = await CustomAxios.get(getDeviceTree(props.device.ID)).then((response) => response.data)
            setChildren(treeData.children)
        } catch (error) {
            console.error(error)
        }
    }

    const fetchFreeDevices = async () => {
        try {
            const freeDevices = await CustomAxios.get('free-devices').then((response) => response.data.data)
            setAvailableDevices(freeDevices.filter((device) => device.ID !== props.device.ID))
        } catch (error) {
            console.error(error)
        }
    }

    const refreshData = async () => {
        try {
            setLoading(true)
            await fetchFreeDevices()
            await fetchDeviceTree()
            setLoading(false)
            ToastSuccess(t('actionsMessages.successFetchData'))
        } catch (error) {
            setLoading(false)
            console.error(error)

            ToastError(t('actionsMessages.genericError'))
        }
    }

    const initRemoveProcedure = (schedulerId: number) => {
        setOpenAlertModal(true)
        setSelectedElementToRemove(schedulerId)
    }

    const removeChild = async () => {
        try {
            await CustomAxios.patch(`devices/${selectedElementToRemove}`, { idMaster: null })
            void refreshData()
            exitRemoveProcedure()
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const exitRemoveProcedure = () => {
        setOpenAlertModal(false)
        setSelectedElementToRemove(-1)
    }

    const addChild = async () => {
        try {
            await CustomAxios.patch(`devices/${selectedDeviceToAdd.value}`, { idMaster: props.device.ID })
            void refreshData()
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    useEffect(() => {
        void refreshData()
    }, [])

    return (
        <>
            {openAlertModal && (
                <AlertModal
                    alertMessage={addChildMessage}
                    onClose={() => exitRemoveProcedure()}
                    onConfirm={async () => removeChild()}
                />
            )}
            <div className="sectionContent no-left-right-padding">
                {user.idSysGrant === 0 && (
                    <div className="fast-command-row">
                        <span>{t('fields.associateDevice')}:</span>
                        <SelectInput
                            disabled={availableDevices.length === 0}
                            value={selectedDeviceToAdd}
                            onValueChange={(value) => setSelectedDeviceToAdd(value)}
                            options={availableDevices.map((device: Device) => {
                                return { label: device.name, value: device.ID.toString() }
                            })}
                            width={'100%'}
                        />
                        <div className="standard-confirm-button" onClick={async () => addChild()}>
                            {t('common.associate')}
                        </div>
                    </div>
                )}
                {loading ? (
                    <>
                        <Loader />
                    </>
                ) : (
                    <>
                        {children.length > 0 ? (
                            <div className="device-tree-container">
                                <p className="master-device-label">{props.device.name}</p>
                                {children.map((childDevice) => {
                                    return (
                                        <div key={childDevice.ID} className="children-slot">
                                            <div
                                                className={`children-status-dot ${
                                                    childDevice.currentState ? 'normal-status' : 'alert-status'
                                                }`}
                                            />
                                            <a
                                                className="children-device-label table-link-value colorRed link"
                                                // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
                                                href={`/${ROUTE_NAMES.DEVICE}/${childDevice.ID}`}
                                            >
                                                {childDevice.name}
                                            </a>
                                            <div
                                                onClick={() => initRemoveProcedure(childDevice.ID)}
                                                className="remove-element-icon"
                                            >
                                                <FontAwesomeIcon icon={faMinusCircle} color="#e10915" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className="empty-device-tree-message">
                                {t('systemMessages.noDeviceAssociatedWithMessage', { device: props.device.name })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default DeviceTreeSection
