import React, { useMemo, useState } from 'react'
import '../../styles/pages/main.sass'
import '../../styles/pages/alarms.sass'
import { faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Components/Inputs/Button'
import TextInput from '../../Components/Inputs/TextInput'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import EventsHistoryTable from '../../Components/Tables/EventsHistoryTable'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import Datepicker from '../../Components/Datepicker'

interface Props {
    device: any
}

const EventsHistorySection: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const [querySearch, setQuerySearch] = useState('')
    const [toggleReset, setToggleReset] = useState(undefined)
    const [selectedThresholdFilter, setSelectedThresholdFilter] = useState(2)
    const [searchThroughOpenAlarms, setSearchThroughOpenAlarms] = useState(false)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    // eslint-disable-next-line max-len
    const [filterParameters, setFilterParameters] = useState<any | null>(
        props.device !== undefined ? { device: props.device.ID } : null
    )

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const thresholdFilters = [
        {
            id: 0,
            value: 'onlyBiggerFinalValues',
            label: t('fields.valueBiggerThanThreshold'),
        },
        {
            id: 1,
            value: 'onlySmallerFinalValues',
            label: t('fields.valueSmallerThanThreshold'),
        },
        {
            id: 2,
            value: 'all',
            label: t('fields.allCases'),
        },
    ]

    const getFilterParameters = () => {
        const filterParameters: any = {
            device: props.device !== undefined ? props.device.ID : null,
            query: querySearch,
            from: filterStartDate,
            to: filterEndDate,
            closed: !searchThroughOpenAlarms ? undefined : false,
            threshold: selectedThresholdFilter === 0 ? 'MAX' : selectedThresholdFilter === 1 ? 'MIN' : '',
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return {}
    }

    const getDetailedFilteredEvents = () => {
        setFilterParameters(getFilterParameters())
    }

    const filterParametersChanged = useMemo(() => {
        return (
            querySearch !== '' ||
            filterStartDate !== '' ||
            filterEndDate !== '' ||
            !Number.isNaN(selectedThresholdFilter) ||
            searchThroughOpenAlarms
        )
    }, [querySearch, filterStartDate, filterEndDate, selectedThresholdFilter, searchThroughOpenAlarms])

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setQuerySearch('')
        setSelectedThresholdFilter(2)
        setSearchThroughOpenAlarms(false)
        setFilterParameters(props.device !== undefined ? { device: props.device.ID } : null)
        setToggleReset(!toggleReset)
    }

    const deviceFilterIsSet = useMemo(() => {
        if (filterParameters !== null) {
            const keys = Object.keys(filterParameters)
            return keys.length === 1 && keys[0] === 'device'
        }
        return false
    }, [filterParameters])

    return (
        <div>
            <div className="sectionContent">
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterEvents')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0"
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                </div>
                <div style={{ marginTop: '25px' }}>
                    {filterPanelIsVisible && (
                        <div className="advanced-search-wrapper">
                            <div className="search-wrapper-body">
                                <div className="filter-column">
                                    <h3>{t('fields.variable')}</h3>
                                    <TextInput
                                        placeholder={t('fields.searchByVariable')}
                                        label=""
                                        value={querySearch}
                                        onChangeText={(newValue: string) => setQuerySearch(newValue)}
                                    />
                                </div>
                                <div className="filter-column">
                                    <h3>{t('routes.events')}</h3>
                                    <div className="input-rows">
                                        <RadioGroup
                                            name="radio-buttons-group"
                                            value={selectedThresholdFilter}
                                            onChange={(e) => setSelectedThresholdFilter(parseInt(e.target.value, 10))}
                                        >
                                            {thresholdFilters.map((filter) => {
                                                return (
                                                    <FormControlLabel
                                                        key={filter.id}
                                                        value={filter.id}
                                                        control={<Radio />}
                                                        label={filter.label}
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                    </div>
                                    <div className="input-rows checkbox-padding-removal">
                                        <CheckboxInput
                                            label={t('fields.showOnlyNotClosedEvents')}
                                            value={searchThroughOpenAlarms}
                                            onChangeValue={(value: boolean) => setSearchThroughOpenAlarms(value)}
                                        />
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <h3>{t('fields.searchInterval')}</h3>
                                    <div className="calendar-selection-container">
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectStartOfInterval')}
                                            onDateChange={(newDate) => setFilterStartDate(newDate)}
                                        />
                                        <Datepicker
                                            reset={toggleReset}
                                            initialValue={t('fields.selectEndOfInterval')}
                                            onDateChange={(newDate) => setFilterEndDate(newDate)}
                                            minSelectableDate={filterStartDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null || deviceFilterIsSet}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getDetailedFilteredEvents()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <EventsHistoryTable reloads={toggleReset} filterParams={filterParameters} />
            </div>
        </div>
    )
}

export default EventsHistorySection
