import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ChangesDisplayModal from '../Modals/ChangesDisplayModal'
import CustomAxios from '../../customComponents/customAxios'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    filterParameters?: any
    reloads?: boolean
}

const UsersActivitiesTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [jsonObjectPre, setJsonObjectPre] = useState('')
    const [jsonObjectPost, setJsonObjectPost] = useState('')
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const tableRef = React.useRef<any>()
    const [changesDisplayModalIsOpen, setChangesDisplayModalIsOpen] = useState(false)
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const navigate = useNavigate()

    const showActivityDetail = (pre: any, post: any) => {
        setJsonObjectPre(JSON.stringify(pre))
        setJsonObjectPost(JSON.stringify(post))
        setChangesDisplayModalIsOpen(true)
    }

    const resetModalData = () => {
        setChangesDisplayModalIsOpen(false)
        setJsonObjectPost('')
        setJsonObjectPre('')
    }

    const columns = [
        {
            title: t('fields.user'),
            field: 'user_name',
            render: (rowData: any) => {
                return (
                    <div>
                        <span
                            className="colorRed table-link-value"
                            onClick={() => navigate(`/${ROUTE_NAMES.USERS}/${rowData.user.ID}`)}
                        >
                            {rowData.user.firstName} {rowData.user.lastName}
                        </span>
                    </div>
                )
            },
        },
        {
            title: t('fields.activity'),
            field: 'activity',
        },
        {
            title: t('fields.date'),
            field: 'timestamp',
            render: (rowData: any) => {
                return (
                    <div>
                        <span>{moment(rowData.timestamp).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                )
            },
        },
    ]

    const actions = [
        (rowData: any) => ({
            icon: () => <FontAwesomeIcon icon={faInfoCircle} color={COLORS.palette.darkGrey} size="xs" />,
            onClick: () => showActivityDetail(rowData.preActivity, rowData.postActivity),
            tooltip: t('common.info'),
        }),
    ]

    useEffect(() => {
        if (tableRef.current && props.filterParameters) {
            resetData()
        }
    }, [props.filterParameters])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData()
        }
    }, [props.reloads])

    const resetData = useCallback((headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page: 0, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    return (
        <>
            {changesDisplayModalIsOpen && (
                <ChangesDisplayModal
                    jsonObjectPre={jsonObjectPre}
                    jsonObjectPost={jsonObjectPost}
                    onClose={() => resetModalData()}
                />
            )}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'user-activities'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParameters && props.filterParameters !== null) {
                            Object.keys(props.filterParameters).map((key: string) => {
                                url += `&${key}=${props.filterParameters[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            const userActions = response.data.data.filter((userAction) => userAction.user.hide !== 1)
                            resolve({
                                data: userActions,
                                page: query.page,
                                totalCount: userActions.length,
                            })
                        })
                    })
                }
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 5,
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default UsersActivitiesTable
