import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import JsonDetailModal from '../Modals/JsonDetailModal'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomAxios from '../../customComponents/customAxios'
import { ROUTE_NAMES } from '../../utils/routes'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    filterParams?: any
    reloads?: boolean
}

const CommandsHistoryTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const [jsonModalIsOpen, setJsonModalIsOpen] = useState(false)
    const [jsonDataToDisplay, setJsonDataToDisplay] = useState('')
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const showCommandReplyDetail = (data: string) => {
        setJsonModalIsOpen(true)
        setJsonDataToDisplay(data)
    }

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.command'),
                field: 'command',
            },
            {
                title: t('fields.creator'),
                field: 'user_name',
                render: (rowData: any) => {
                    return (
                        <div
                            onClick={() => navigate(`/${ROUTE_NAMES.USERS}/${rowData?.idUser}`)}
                            className="tableElement table-link-value colorRed link"
                        >
                            {rowData.user.firstName} {rowData.user.lastName}
                        </div>
                    )
                },
            },
            {
                title: t('fields.dateSent'),
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.dateSent !== null && (
                                <span>{moment(rowData.dateSent).format('DD/MM/YYYY - HH:mm:ss')}</span>
                            )}
                        </>
                    )
                },
                field: 'date_sent',
            },
            {
                title: t('fields.dateReply'),
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.dateReply !== null && (
                                <span>{moment(rowData.dateReply).format('DD/MM/YYYY - HH:mm:ss')}</span>
                            )}
                        </>
                    )
                },
                field: 'date_reply',
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: any) => {
                if (rowData.dateReply && rowData.responseMessage) {
                    return {
                        icon: () => <FontAwesomeIcon icon={faInfoCircle} color={COLORS.palette.darkGrey} size="xs" />,
                        onClick: () => showCommandReplyDetail(rowData.responseMessage),
                        tooltip: t('common.info'),
                    }
                }
            },
        ]
        return actions
    }, [])

    useEffect(() => {
        if (tableRef.current && props.filterParams && props.filterParams.idDevice) {
            resetData()
        }
    }, [props.filterParams])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData()
        }
    }, [props.reloads])

    const resetData = useCallback((headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page: 0, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    return (
        <>
            {jsonModalIsOpen && (
                <JsonDetailModal jsonData={jsonDataToDisplay} onClose={() => setJsonModalIsOpen(false)} />
            )}
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'commands-history'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            const commands = response.data.data.filter((command) => command.user.hide !== 1)
                            resolve({
                                data: commands,
                                page: query.page,
                                totalCount: commands.length,
                            })
                        })
                    })
                }
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    actionsColumnIndex: 4,
                    search: false,
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default CommandsHistoryTable
