import { FC, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { Button, Modal, Paper, TextField, Typography } from '@material-ui/core'
import CustomAxios from '../customComponents/customAxios'
import { useDispatch } from 'react-redux'
import { setTokenData } from '../store/actions/user'

type TwoFactorAuthProps = {
    otpauth_url: string
    base32: string
    user_id: string
    closeModal: () => void
}

const TwoFactorAuth: FC<TwoFactorAuthProps> = (props) => {
    const [qrcodeUrl, setqrCodeUrl] = useState('')
    const [mfacode, setmfacode] = useState('')
    const [error, setError] = useState('')
    const dispatch = useDispatch()

    const verifyOtp = async () => {
        try {
            const validation = await CustomAxios.post('/auth/verify-otp/', {
                token: mfacode,
                user_id: props.user_id,
            }).then((response) => response.data)
            dispatch(setTokenData(validation))

            props.closeModal()
        } catch (error: any) {
            const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString()
            setError(resMessage)
        }
    }

    useEffect(() => {
        QRCode.toDataURL(props.otpauth_url).then(setqrCodeUrl)
        setError('')
    }, [])

    return (
        <Modal open style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper style={{ width: '90%', maxWidth: '360px', position: 'fixed', zIndex: 99, padding: 16 }}>
                <Typography variant="h5" gutterBottom style={{ marginTop: '2%', marginLeft: '2%' }}>
                    Two-Factor Authentication (2FA)
                </Typography>

                <Typography
                    variant="caption"
                    gutterBottom
                    style={{ marginTop: '2%', marginLeft: '2%', color: '#ff4242', fontWeight: 'bold' }}
                >
                    Scan QR code
                </Typography>
                <Paper elevation={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={qrcodeUrl} alt="qrcode url" />
                </Paper>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant="caption"
                        gutterBottom
                        style={{ marginTop: '4%', marginLeft: '2%', color: '#ff4242', fontWeight: 'bold' }}
                    >
                        Or enter code into your authenticator app
                    </Typography>
                    <Typography variant="caption" gutterBottom style={{ marginLeft: '2%', fontWeight: 'bold' }}>
                        You can use Google Authenticator or Authy app, both for iOS and Android.
                    </Typography>
                    <Typography variant="caption" gutterBottom style={{ marginTop: '2%', marginLeft: '2%' }}>
                        SecretKey: {props.base32} (base32 Encoded)
                    </Typography>
                    <Typography
                        variant="caption"
                        gutterBottom
                        style={{ marginTop: '2%', marginLeft: '2%', color: '#ff4242', fontWeight: 'bold' }}
                    >
                        Verify code
                    </Typography>
                    <TextField
                        variant="outlined"
                        label=""
                        placeholder="Authentication code"
                        onChange={(e) => setmfacode(e.target.value)}
                        value={mfacode}
                    />
                </div>
                {error && (
                    <p
                        style={{
                            padding: 12,
                            borderRadius: 8,
                            border: '1px solid #ff4242',
                            color: '#ff4242',
                            marginTop: 16,
                            lineHeight: '100%',
                        }}
                    >
                        {error}
                    </p>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignContent: 'center',
                        flexDirection: 'row',
                        marginTop: 16,
                        gap: 16,
                    }}
                >
                    <Button variant="outlined" onClick={() => props.closeModal()}>
                        Close
                    </Button>
                    <Button style={{ background: '#ff4242' }} variant="contained" onClick={async () => verifyOtp()}>
                        Verify
                    </Button>
                </div>
            </Paper>
        </Modal>
    )
}

export default TwoFactorAuth
