import React, { useEffect, useMemo, useState } from 'react'
import { config } from '../../config/config'

interface Props {
    hide: boolean
    dashboardData: {
        uid: string
        slug: string
        start: string
        end: string
        deviceID: number
        nominalPower: any
        grafanaDeviceDashboard: any[]
    }
    selectedDashboard?: string
}

const GrafanaAdapter: React.FC<Props> = (props) => {
    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        setHidden(props.hide)
    }, [props.hide])

    useEffect(() => {
        setHidden(true)
        setTimeout(() => {
            setHidden(false)
        }, 3000)
    }, [props.selectedDashboard])

    const renderChartsElements = useMemo(() => {
        const grafanaBaseUrl = config.grafana.baseURL

        let panelURL = ''

        // se viene passato il prop selectedDashboard (nel caso di implementazione nella pagina del dettaglio dispositivo)
        if (props.selectedDashboard) {
            if (props.selectedDashboard === 'Default' && props.dashboardData.slug) {
                panelURL = `${grafanaBaseUrl}/d/${props.dashboardData.uid}/${
                    props.dashboardData.slug
                }?orgId=1&refresh=30s&theme=light&var-id_device=${props.dashboardData.deviceID}&var-maxPower=${
                    props.dashboardData.nominalPower && props.dashboardData.nominalPower.value
                }&from=${props.dashboardData.start}&to=${props.dashboardData.end}`
            }

            if (
                props.selectedDashboard === 'Custom' &&
                props.dashboardData.grafanaDeviceDashboard !== undefined &&
                props.dashboardData.grafanaDeviceDashboard.length > 0
            ) {
                panelURL = `${grafanaBaseUrl}/${props.dashboardData.grafanaDeviceDashboard[0].url.replace(
                    'grafana_frame/',
                    ''
                )}?orgId=1&refresh=30s&theme=light&var-id_device=${props.dashboardData.deviceID}&var-maxPower=${
                    props.dashboardData.nominalPower && props.dashboardData.nominalPower.value
                }&from=${props.dashboardData.start}&to=${props.dashboardData.end}`
            }
            // se mi trovo nella sezione delle dashboard generali
        } else {
            panelURL = `${grafanaBaseUrl}/d/${props.dashboardData.uid}/${
                props.dashboardData.slug
            }?orgId=1&refresh=30s&theme=light&var-id_device=${props.dashboardData.deviceID}&var-maxPower=${
                props.dashboardData.nominalPower && props.dashboardData.nominalPower.value
            }&from=${props.dashboardData.start}&to=${props.dashboardData.end}`
        }

        return (
            <div style={{ height: '1200px', width: '100%', padding: 0, opacity: hidden ? 0 : 1 }}>
                <iframe
                    title="stats"
                    id="stats"
                    src={panelURL}
                    width="100%"
                    style={{ minHeight: '100%', border: 0 }}
                    frameBorder="0"
                    scrolling="no"
                />
            </div>
        )
    }, [props.dashboardData, hidden])

    return (
        <>
            <div className="charts-wrapper">{renderChartsElements}</div>
        </>
    )
}

export default GrafanaAdapter
