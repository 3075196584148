import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../../types/reducers'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import { useDispatch } from 'react-redux'
import { setShowQuickHelp } from '../../store/actions/graphics'

const Footer: React.FC = () => {
    const { configurations } = useSelector<Reducers, ReducerData>((store) => store.data)
    const { t } = useTranslation()
    const data = useSelector<Reducers, ReducerData>((store) => store.data)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div
            style={{
                fontSize: 10,
                fontWeight: 300,
                padding: '50px 20px 20px',
            }}
        >
            {t('footer.release', { year: new Date().getFullYear() })} - <b>v{process.env.REACT_APP_VERSION}</b> || API:
            v<b>{data.apiVersion}</b>
            <span
                className=" table-link-value"
                style={{ marginLeft: '.5ch', cursor: 'pointer' }}
                onClick={() => navigate(`/${ROUTE_NAMES.CHANGELOG}`)}
            >
                <b>Changelog</b>
            </span>
            <span
                className=" table-link-value"
                style={{ marginLeft: '.5ch', cursor: 'pointer' }}
                onClick={() => dispatch(setShowQuickHelp(true))}
            >
                <b>Help</b>
            </span>
            <br />
            <br />
            {t('footer.trademark')}
            <br />
            {t('footer.application').replaceAll(
                '{{instanceTitle}}',
                configurations.find((c) => c.key === 'aspect.title')?.value || ''
            )}
            <br />
            <br />
            {t('footer.copyright', { year: new Date().getFullYear() })}
            <br />
            <div className="footer-link-container">
                <a href="https://22hbg.com/" rel="noreferrer" target="_blank">
                    22HBG
                </a>
                <a href="" rel="noreferrer" target="_blank">
                    Privacy Policy
                </a>
            </div>
        </div>
    )
}

export default Footer
