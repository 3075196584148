import React, { useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBolt,
    faClone,
    faGreaterThan,
    faLessThan,
    faMinusCircle,
    faNotEqual,
    faPen,
} from '@fortawesome/free-solid-svg-icons'
import DeleteModal from '../Modals/DeleteModal'
import { DeviceDefaultVars, ThresholdLevel, ThresholdRule, ThresholdType } from '../../types/data/alarm'
import DefaultVarModal from '../Modals/DefaultVarModal'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CustomAxios from '../../customComponents/customAxios'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { useDispatch } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import { COLORS } from '../../styles/constants'

export interface DefaultVarTable extends DeviceDefaultVars {
    id: number
}

interface Props {
    data: DefaultVarTable[]
    filtered: DefaultVarTable[]
    updateData: (vars: DeviceDefaultVars[], editedVar?: DeviceDefaultVars) => void
    viewOnly?: boolean
    isModelSection?: boolean
}

const DefaultVarsTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [selectedVar, setSelectedVar] = useState<DefaultVarTable | null>(null)
    const [removeModal, setRemoveModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [openInViewMode, setOpenInViewMode] = useState(false)
    const [preSetVariable, setPreSetVariable] = useState<DeviceDefaultVars | undefined>(undefined)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    useEffect(() => {
        setPreSetVariable(props.data.find((v) => v.writeCommand !== null && v.writeCommand !== ''))
    }, [props.data])
    const getLevelClass = (type: ThresholdLevel) => {
        switch (type) {
            case ThresholdLevel.high:
                return 'high'
            case ThresholdLevel.medium:
                return 'medium'
            case ThresholdLevel.low:
            default:
                return 'low'
        }
    }

    const getRuleIcon = (rule: ThresholdRule) => {
        switch (rule) {
            case ThresholdRule.notEqual:
                return faNotEqual
            case ThresholdRule.higher:
                return faGreaterThan
            case ThresholdRule.lower:
            default:
                return faLessThan
        }
    }

    const columns = [
        {
            title: t('fields.alarmsThresholds'),
            field: 'thresholds',
            render: (rowData: DeviceDefaultVars) => (
                <div
                    className="small-cell"
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}
                >
                    {rowData.writeCommand && (
                        <Tooltip key={rowData.oid} title={t('fields.variablePreSetValueTooltip')}>
                            <span>
                                <FontAwesomeIcon icon={faBolt} />
                            </span>
                        </Tooltip>
                    )}
                    {rowData.thresholds && typeof rowData.thresholds !== 'string' && rowData.thresholds.length ? (
                        rowData.thresholds.map((threshold) => {
                            return (
                                <Tooltip
                                    key={threshold.id}
                                    title={t('thresholdManagementParagraph.fullPhrase', {
                                        type: threshold.type,
                                        rule: threshold.rule,
                                        variant:
                                            threshold.type !== ThresholdType.percentage || user.language === 'en-GB'
                                                ? ''
                                                : 'l',
                                        value:
                                            threshold.type !== ThresholdType.percentage
                                                ? threshold.absolute_value || threshold.value
                                                : `${threshold.value}%`,
                                        level: getLevelClass(threshold.level),
                                    })}
                                >
                                    <span
                                        className={`dotAlert largeDot ${
                                            threshold.enabled ? 'enabledDot' : 'regularDot'
                                        } ${getLevelClass(threshold.level)}-level-threshold-dot`}
                                    >
                                        <FontAwesomeIcon icon={getRuleIcon(threshold.rule)} />
                                    </span>
                                </Tooltip>
                            )
                        })
                    ) : (
                        <div>
                            <span className="dotAlert regularDot" />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: t('fields.name'),
            field: 'name',
            width: '10%',
            render: (rowData: DefaultVarTable) => (
                <span
                    className="colorRed table-link-value"
                    onClick={() => {
                        setEditModal(true)
                        setSelectedVar(rowData)
                        setOpenInViewMode(true)
                    }}
                >
                    {rowData.customName ? rowData.customName : rowData.name}
                </span>
            ),
        },
        {
            title: t('fields.description'),
            field: 'description',
        },
    ]

    const actions = useMemo(() => {
        if (props.viewOnly) {
            return []
        }
        const actions = [
            (rowData: DefaultVarTable) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setEditModal(true)
                    setSelectedVar(rowData)
                },
                tooltip: t('common.edit'),
            }),
            (rowData: DefaultVarTable) => ({
                icon: () => <FontAwesomeIcon icon={faClone} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setSelectedVar({
                        ...rowData,
                        id: -1,
                    })
                    setEditModal(true)
                },
                tooltip: t('common.duplicate'),
            }),
            (rowData: DefaultVarTable) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => {
                    setRemoveModal(true)
                    setSelectedVar(rowData)
                },
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {editModal && selectedVar !== null ? (
                <DefaultVarModal
                    preSetEnabled={!preSetVariable || (preSetVariable && preSetVariable.ID === selectedVar.ID)}
                    isModelVar={props.isModelSection}
                    defaultVar={selectedVar}
                    onClose={() => {
                        setEditModal(false)
                        setSelectedVar(null)
                        setOpenInViewMode(false)
                    }}
                    viewMode={openInViewMode ? true : undefined}
                    isNew={selectedVar.id === -1}
                    onConfirm={(varEdited) => {
                        const newArray = props.data
                            .filter((v) => v.id !== varEdited.id)
                            .map((v) => {
                                delete v.id
                                return v
                            })
                        const edited = varEdited
                        delete varEdited.id
                        props.updateData([...newArray, edited], edited)
                        setSelectedVar(null)
                        setEditModal(false)
                    }}
                />
            ) : null}
            {removeModal && selectedVar !== null ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.commandDeleteConfirmMessage', { command: selectedVar.name })}
                    onDelete={async () => {
                        const newArray = props.data
                            .filter((v) => v.id !== selectedVar.id)
                            .map((v) => {
                                delete v.id
                                return v
                            })
                        if (selectedVar.ID) {
                            await CustomAxios.delete(`device-vars/${selectedVar.ID}`)
                        }
                        props.updateData(newArray)
                        setSelectedVar(null)
                        setRemoveModal(false)
                    }}
                    onClose={() => {
                        setSelectedVar(null)
                        setRemoveModal(false)
                    }}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                data={props.filtered}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 99,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default DefaultVarsTable
