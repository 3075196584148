import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePageApi } from '../../utils/hooks/usePageApi'
import { COLORS } from '../../styles/constants'

interface Props {
    callback: () => void
    time: number
    count: number
}

const Timer: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const [time, lastUpdate] = usePageApi(props.callback, props.time, false)
    return (
        <>
            <div className="nextUpdate">
                <FontAwesomeIcon
                    icon={faRedo}
                    style={{ color: COLORS.palette.darkGrey, marginRight: 8, fontSize: 14 }}
                />
                {t('common.nextUpdate')}
                <span className="timeToUpdate">{time}</span>
            </div>
            <span className="textIntro">
                {t('fields.mapSectionHeaderRecap', {
                    date: moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss'),
                    alarmsNumber: props.count.toString(),
                })}
            </span>
        </>
    )
}

export default Timer
