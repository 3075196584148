import React from 'react'

interface Props {
    height?: string
    isAbsolutePos?: boolean
    zIndex?: number
}

const Loader: React.FC<Props> = (props) => {
    return (
        <div style={{ height: props.height ? props.height : null, zIndex: props.zIndex || null }} className={`${props.isAbsolutePos ? 'absolute-loader-container' : 'loader-container'}`}>
            <div className="loader" />
        </div>
    )
}

export default Loader
