import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, updateUser } from '../../../store/actions/requests'
import PageContainer from '../../../Components/StaticComponents/PageContainer'
import Footer from '../../../Components/StaticComponents/Footer'
import { faArrowLeft, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../Components/Inputs/TextField'
import Button from '../../../Components/Inputs/Button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/routes'
import CustomAxios from '../../../customComponents/customAxios'
import AlertModal from '../../../Components/Modals/AlertModal'
import { UserDataTable } from '../../../types/data/user'
import SelectInput from '../../../Components/Inputs/SelectInput'
import { SystemGrant } from '../../../utils/enum/SystemGrant'
import UserNetworkAssocTab from '../../../Components/Tabs/UserNetworkAssocTab'
import { NetworkGrant } from '../../../utils/enum/NetworkGrant'
import UserLastOperationsTab from '../../../Components/Tabs/UserLastOperationsTab'
import DeleteModal from '../../../Components/Modals/DeleteModal'
import { Reducers, ReducerUser } from '../../../types/reducers'
import Tabs, { TabElement } from '../../../Components/Tabs'
import UserAssociatedDevices from '../../../Components/Tabs/UserAssociatedDevicesTab'
import { ToastError, ToastSuccess } from '../../../utils/toast'
import PageHeaderCustom from '../../../customComponents/PageHeaderCustom'
import Loader from '../../../Components/StaticComponents/Loader'
import { useTranslation } from 'react-i18next'

interface UserDetailPageState {
    state: {
        isView?: boolean
    }
}

const languageSelect = [
    {
        id: 'it-IT',
        label: 'Italiano',
    },
    {
        id: 'en-GB',
        label: 'English',
    },
]

const UserDetailPage: React.FC = () => {
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [user, setUser] = useState<UserDataTable>({
        ID: -1,
        username: '',
        idSysGrant: 1,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        language: 'en-GB',
        networks: [],
        hide: 0,
        tableData: { id: -1 },
    })
    const { t } = useTranslation()
    const userSubmenuVoices: TabElement[] = [
        {
            label: t('fields.associatedNetworks'),
            value: 'associatedNetworks',
        },
        {
            label: t('fields.activityHistory'),
            value: 'activityHistory',
        },
        {
            label: t('routes.associatedDevices'),
            value: 'associatedDevices',
        },
    ]
    const [selectedTab, setSelectedTab] = useState<string>(userSubmenuVoices[0].value)
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newUserPassword, setNewUserPassword] = useState('')
    const [repeatNewUserPassword, setRepeatNewUserPassword] = useState('')
    const { state }: UserDetailPageState = useLocation()
    const [editMode, setEditMode] = useState(!(state && state.isView))

    useEffect(() => {
        if (loggedUser.idSysGrant !== 0 && loggedUser.managedNetworks.length === 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
        if (id !== undefined && id !== 'new-user') {
            void getUser(id)
        } else {
            setUser({
                ID: -1,
                username: '',
                idSysGrant: 1,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                language: 'en-GB',
                networks: [],
                password: '',
                hide: 0,
                tableData: { id: -1 },
            })
        }
    }, [id, loggedUser])

    const getUser = async (id: string) => {
        try {
            setLoading(true)
            const user: UserDataTable = await CustomAxios.get(`users/user/${id}`).then((res) => {
                return res.data
            })
            setUser(user)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCreateUser = async () => {
        try {
            const newUserId = await dispatch(createUser(user))
            if (newUserId) {
                if (user.networks.length > 0) {
                    for (let i = 0; i < user.networks.length; i++) {
                        const body = {
                            idNetwork: user.networks[i].ID,
                            idUser: newUserId,
                            idGrant: user.networks[i].userGrant ? user.networks[i].userGrant : NetworkGrant[2],
                        }
                        await CustomAxios.post('network-user-association', body)
                    }
                }
                ToastSuccess(t('actionsMessages.successUserCreate'))
                navigate(`/${ROUTE_NAMES.USERS}/${newUserId}`)
            } else {
                ToastError(t('actionsMessages.genericError'))
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const updateUserPassword = async () => {
        try {
            await CustomAxios.put(`users/user/${id}`, { password: newUserPassword })
            setNewUserPassword('')
            setRepeatNewUserPassword('')
        } catch (error) {
            console.error(error)
        }
    }

    const handleUpdateUser = async () => {
        try {
            if (newUserPassword !== '') {
                void updateUserPassword()
            }
            dispatch(updateUser(user))
            setEditMode(false)
            ToastSuccess(t('actionsMessages.successUserUpdate'))
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const renderCurrentTab = useMemo(() => {
        switch (selectedTab) {
            case 'associatedNetworks':
                return (
                    <UserNetworkAssocTab
                        currentUser={user}
                        setNewNetworks={(networks) => {
                            setUser({ ...user, networks })
                        }}
                    />
                )
            case 'activityHistory':
                return <UserLastOperationsTab currentUser={user} />
            case 'associatedDevices':
                return <UserAssociatedDevices currentUser={user} />
            default:
                return (
                    <UserNetworkAssocTab
                        currentUser={user}
                        setNewNetworks={(networks) => {
                            setUser({ ...user, networks })
                        }}
                    />
                )
        }
    }, [editMode, selectedTab, user])

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`users/user/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.USERS}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const userDataIsCorrect = useMemo(() => {
        const changedPasswordValidity = newUserPassword !== '' ? newUserPassword === repeatNewUserPassword : true
        return (
            user.firstName !== '' &&
            user.lastName !== '' &&
            user.email !== '' &&
            user.phone !== '' &&
            user.hide !== 1 &&
            user.username !== '' &&
            user.password !== '' &&
            changedPasswordValidity
        )
    }, [user, newUserPassword, repeatNewUserPassword])

    const handleSendUserData = () => {
        if (id !== undefined && id !== 'new-user') {
            void handleUpdateUser()
        } else {
            void handleCreateUser()
        }
    }

    const initExitProcedure = () => {
        if (editMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.USERS}?page=0`)
        }
    }

    const renderTables = () => {
        if (id !== undefined && id !== 'new-user') {
            return (
                <div style={{ marginTop: '25px' }}>
                    <Tabs tabs={userSubmenuVoices} onSelectedTabChange={(newTab) => setSelectedTab(newTab)} />
                    {renderCurrentTab}
                </div>
            )
        }
        return (
            <p style={{ fontSize: 16, fontStyle: 'italic', margin: '10px 20px' }}>
                {t('systemMessages.createUserFirstMessage')}
            </p>
        )
    }

    return (
        <PageContainer>
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.USERS}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={() => void confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('routes.users')}
                                </span>{' '}
                                {`/ ${user.ID === -1 ? t('fields.newUser') : `${user.firstName} ${user.lastName}`}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.userDetail" />
                        </div>
                        {(loggedUser.idSysGrant === 0 ||
                            loggedUser.managedNetworks.find((networkId) =>
                                user.networks.find((network) => network.ID === networkId)
                            )) && (
                            <div className="header-right-actions">
                                {!editMode ? (
                                    <Button
                                        label={t('common.update')}
                                        backgroundColor="#EA0B2A"
                                        textColor="#fff"
                                        iconColor="#fff"
                                        margin="0"
                                        icon={faPen}
                                        onPress={() => setEditMode(true)}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            label={t('common.back')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faArrowLeft}
                                            onPress={() => initExitProcedure()}
                                        />
                                        {id !== undefined && id !== 'new-user' && (
                                            <Button
                                                label={t('common.delete')}
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                margin="0 0 0 25px"
                                                icon={faTrash}
                                                onPress={() => initDeleteProcedure()}
                                            />
                                        )}
                                        <Button
                                            isDisabled={!userDataIsCorrect}
                                            label={t('common.save')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => handleSendUserData()}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="sectionContent">
                        <div style={{ display: 'flex' }} className="editContainer advanced-search-wrapper">
                            <div style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} className="elements-row">
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.name')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.name')}
                                            value={user.firstName}
                                            onChangeText={(value: string) => {
                                                setUser({
                                                    ...user,
                                                    firstName: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.lastName')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.lastName')}
                                            value={user.lastName}
                                            onChangeText={(value: string) => {
                                                setUser({
                                                    ...user,
                                                    lastName: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <h3 style={{ marginBottom: 0 }}>{t('fields.userLocale')}</h3>
                                    <div className="input-rows">
                                        <SelectInput
                                            disabled={!editMode}
                                            options={languageSelect.map((l) => {
                                                return { value: l.id, label: l.label }
                                            })}
                                            value={
                                                user.language !== ''
                                                    ? {
                                                          value: user.language,
                                                          label: languageSelect.find((l) => l.id === user.language)
                                                              .label,
                                                      }
                                                    : null
                                            }
                                            placeholder={t('common.select')}
                                            onValueChange={(v) => {
                                                setUser({
                                                    ...user,
                                                    language: v.value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} className="elements-row">
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.email')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.email')}
                                            value={user.email}
                                            onChangeText={(value: string) => {
                                                setUser({
                                                    ...user,
                                                    email: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.phoneNumber')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.phoneNumber')}
                                            value={user.phone}
                                            maxLength={15}
                                            onChangeText={(value: string) => {
                                                setUser({
                                                    ...user,
                                                    phone: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.systemGrants')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <SelectInput
                                            disabled={!editMode}
                                            options={SystemGrant.map((l, i) => {
                                                return { value: i.toString(), label: t(`userRoles.${l}`) }
                                            })}
                                            value={
                                                user.hide !== 1
                                                    ? {
                                                          value: user.idSysGrant.toString(),
                                                          label:
                                                              user.idSysGrant > -1
                                                                  ? t(`userRoles.${SystemGrant[user.idSysGrant]}`)
                                                                  : t(`userRoles.${SystemGrant[1]}`),
                                                      }
                                                    : null
                                            }
                                            placeholder={t('common.select')}
                                            onValueChange={(v) => {
                                                setUser({
                                                    ...user,
                                                    idSysGrant: parseInt(v.value, 10),
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} className="elements-row">
                                <div className="column">
                                    <div className="variable-field-label-container">
                                        <h3 style={{ marginBottom: 0 }}>{t('fields.username')}</h3>
                                        <label className="required-asterisk">*</label>
                                    </div>
                                    <div className="input-rows">
                                        <TextField
                                            label={t('fields.username')}
                                            value={user.username}
                                            onlyVisible={(id !== undefined && id !== 'new-user') || !editMode}
                                            onChangeText={(value: string) => {
                                                setUser({
                                                    ...user,
                                                    username: value,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                {(id === undefined || id === 'new-user') && (
                                    <div className="column">
                                        <div className="variable-field-label-container">
                                            <h3 style={{ marginBottom: 0 }}>{t('loginPage.password')}</h3>
                                            <label className="required-asterisk">*</label>
                                        </div>
                                        <div className="input-rows">
                                            <TextField
                                                label={t('loginPage.password')}
                                                value={user.password}
                                                isPassword
                                                onlyVisible={(id !== undefined && id !== 'new-user') || !editMode}
                                                onChangeText={(value: string) => {
                                                    setUser({
                                                        ...user,
                                                        password: value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {id !== undefined && id !== 'new-user' && loggedUser.idSysGrant === 0 && (
                            <div className="single-line-input-row editContainer advanced-search-wrapper">
                                <div className="filter-column">
                                    <h3 style={{ marginBottom: 0 }}>{t('fields.changePassword')}</h3>
                                    <div className="input-rows">
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.newPassword')}
                                            isPassword
                                            value={newUserPassword}
                                            onChangeText={(value: string) => setNewUserPassword(value)}
                                        />
                                        <TextField
                                            onlyVisible={!editMode}
                                            label={t('fields.repeatNewPassword')}
                                            isPassword
                                            value={repeatNewUserPassword}
                                            onChangeText={(value: string) => setRepeatNewUserPassword(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {renderTables()}
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default UserDetailPage
