import React from 'react'
import '../../styles/pages/main.sass'

interface Props {
    label: string
    value: string
    onChangeText?: (value: string) => void
    onlyVisible?: boolean
    withButton?: boolean
    button?: React.ReactElement
    width?: number | string
}

const DateInput: React.FC<Props> = (props) => {
    if (props.withButton) {
        return (
            <div className="selectedInput">
                <div className="labelInput">{props.label}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                    <div className="selectFieldContainer">
                        <input
                            className="inputField"
                            disabled={!!props.onlyVisible}
                            style={{
                                minWidth: 200,
                                width: props.width ? props.width : 'inherit',
                            }}
                            value={props.value}
                            onChange={(e) => {
                                if (props.onChangeText) {
                                    props.onChangeText(e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: 20 }}>{props.button}</div>
                </div>
            </div>
        )
    }

    return (
        <div
            className="selectedInput"
            style={{
                minWidth: 125,
                flexDirection: 'row',
            }}
        >
            <div className="labelInput">{props.label}</div>
            <div className="selectFieldContainer">
                <input
                    type="date"
                    className="inputField"
                    disabled={!!props.onlyVisible}
                    style={{
                        minWidth: 200,
                        width: props.width ? props.width : 'inherit',
                    }}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChangeText) {
                            props.onChangeText(e.target.value)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default DateInput
