/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useMemo, useState } from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'
import CustomAxiosImages from '../../customComponents/customAxiosImages'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { ToastError, ToastSuccess } from '../../utils/toast'
import axios from 'axios'
import { config } from '../../config/config'

interface Props {
    endpointAPI: string
    validatorEndpointAPI: string
    requiredDataMessage?: string
    validExtensions: string
    closeLabel?: string
    isUpdate?: boolean
    exampleFileLink?: string
    onClose: (reloadFlag?: boolean) => void
}

const FileImportModal: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.cancel')
    const [showFileErrorMessage, setShowFileErrorMessage] = useState<string | undefined>()
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [dataFromFile, setDataFromFile] = useState<any[]>([])
    const [processLength, setProcessLength] = useState(0)
    const [loadingProcessIsActive, setLoadingProcessIsActive] = useState(false)

    const Axios = axios.create({
        baseURL: config.api.baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('access_token')
                ? `Bearer ${localStorage.getItem('access_token')}`
                : '',
        },
    })

    const checkItemsValidity = async (file: any) => {
        try {
            const fileData = new FormData()
            fileData.append('file', file)
            const validityData = await CustomAxiosImages.post(props.validatorEndpointAPI, fileData, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((response) => response.data)
            // if import of multiple elements
            if (validityData instanceof Array) {
                if (validityData.every((dataElement) => dataElement.valid)) {
                    setDataFromFile(
                        validityData.map((element) => {
                            delete element.valid
                            return element
                        })
                    )
                    setShowFileErrorMessage(undefined)
                } else {
                    setShowFileErrorMessage(t('systemMessages.fileFormatIncorrect'))
                }
                // if import of a single object
            } else {
                if (validityData.valid) {
                    delete validityData.valid
                    setDataFromFile([{ ...validityData }])
                    setShowFileErrorMessage(undefined)
                } else {
                    setShowFileErrorMessage(t('systemMessages.fileFormatIncorrect'))
                }
            }
        } catch (error: any) {
            console.error(error)
            setShowFileErrorMessage(error.response.data.message)
        }
    }

    const scanImportedFile = (file: any) => {
        if (file instanceof File) {
            void checkItemsValidity(file)
        }
    }

    const sendDataToDB = async () => {
        try {
            setLoadingProcessIsActive(true)
            let promises: Promise<void>[] = []
            if (props.isUpdate) {
                promises = dataFromFile.map(
                    async (element) =>
                        void Axios.put(props.endpointAPI, element).then(() => setProcessLength(processLength + 1))
                )
            } else {
                promises = dataFromFile.map(
                    async (element) =>
                        void Axios.post(props.endpointAPI, element).then(() => setProcessLength(processLength + 1))
                )
            }
            await Promise.all(promises).catch((error) => {
                console.log(error)
            })
            ToastSuccess(t('actionsMessages.successImportFile'))
            setLoadingProcessIsActive(false)
            props.onClose(true)
        } catch (error) {
            console.error(error)
            setLoadingProcessIsActive(false)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const renderProgressBar = useMemo(() => {
        return (
            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div
                        style={{ width: `${(processLength + 1) * (100 / dataFromFile.length)}%` }}
                        className="progress-bar__internal-bar"
                    />
                </div>
                <p>{`${processLength + 1}/${dataFromFile.length}`}</p>
            </div>
        )
    }, [processLength, dataFromFile])

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="modal delete-modal">
                {!loadingProcessIsActive ? (
                    props.requiredDataMessage ? (
                        <>
                            <div className="example-file-hint">
                                <span>{props.requiredDataMessage}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="modal--message">{t('fields.chooseFileForImport')}</p>
                            {props.isUpdate && (
                                <p className="modal--message" style={{ color: 'red' }}>
                                    {t('fields.updateWillOverwriteChanges')}
                                </p>
                            )}
                            <input
                                className="file-import-element"
                                onChange={(e) => scanImportedFile(e.target.files[0])}
                                type="file"
                                accept={props.validExtensions}
                            />
                            {props.exampleFileLink && (
                                <div className="example-file-hint">
                                    <span>{t('fields.noFileToImportHint')}</span>
                                    <a href={props.exampleFileLink} download>
                                        {t('fields.fileTemplateExample')}
                                    </a>
                                </div>
                            )}
                            {showFileErrorMessage && <p className="creation-alert-message">{showFileErrorMessage}</p>}
                        </>
                    )
                ) : null}
                {!loadingProcessIsActive ? (
                    <div className="modal--actions-container">
                        <button
                            disabled={dataFromFile.length === 0 || showFileErrorMessage !== undefined}
                            className="confirm-action"
                            onClick={() => void sendDataToDB()}
                        >
                            {t('common.send')}
                        </button>
                        <button className="close-action" onClick={() => props.onClose()}>
                            {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                        </button>
                    </div>
                ) : (
                    renderProgressBar
                )}
            </div>
        </div>
    )
}

export default FileImportModal
