import React from 'react'

interface Props {
    disabled?: boolean
    label: string
    value: boolean
    onChangeValue: (value: boolean) => void
}

const CheckboxInput: React.FC<Props> = (props) => {
    return (
        <div
            className="selectedInput rowInputContainer"
            style={{
                minWidth: 0,
                alignItems: 'center',
                width: 'inherit',
                margin: '7px 0'
            }}
        >
            <input disabled={props.disabled ? props.disabled : false} type="checkbox" className="checkboxInput" checked={props.value} onChange={(e) => props.onChangeValue(e.target.checked)} />
            <span
                className="labelInput"
                style={{
                    width: 'unset',
                    marginLeft: '16px',
                    color: '#000',
                    paddingBottom: 0
                }}
            >
                {props.label}
            </span>
        </div>
    )
}

export default CheckboxInput
