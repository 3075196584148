/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import { config } from '../config/config'

const CustomAxios = axios.create({
    baseURL: config.api.baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const CustomAxiosRemote = axios.create({
    baseURL: 'https://development.txcontrol.it/api',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const toCamelCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase)
        } else {
            transformedObject = {}
            for (const key in object) {
                if (object[key] !== undefined) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, (k) => k[1].toUpperCase())
                    transformedObject[newKey] = toCamelCase(object[key])
                }
            }
        }
    }
    return transformedObject
}

export const toSnackCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toSnackCase)
        } else {
            transformedObject = {}
            for (const key in object) {
                if (object[key] !== undefined) {
                    const newKey = key
                        .replace(/\.?([A-Z]+)/g, (x, y) => {
                            return `_${y.toLowerCase()}`
                        })
                        .replace(/^_/, '')
                    transformedObject[newKey] = toSnackCase(object[key])
                }
            }
        }
    }
    return transformedObject
}

CustomAxios.interceptors.response.use(
    (response) => {
        response.data = toCamelCase(response.data)
        return response
    },
    async (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

CustomAxiosRemote.interceptors.response.use(
    (response) => {
        response.data = toCamelCase(response.data)
        return response
    },
    async (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

CustomAxios.interceptors.request.use(
    (config) => {
        let newObject = toSnackCase(config.data)
        if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            newObject = new URLSearchParams(newObject).toString()
        }
        const token = localStorage.getItem('access_token')
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`
        }
        config.data = newObject
        return config
    },
    async (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

CustomAxiosRemote.interceptors.request.use(
    (config) => {
        let newObject = toSnackCase(config.data)
        if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            newObject = new URLSearchParams(newObject).toString()
        }
        const token = localStorage.getItem('access_token')
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`
        }
        config.data = newObject
        return config
    },
    async (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default CustomAxios
