import React, { useCallback, useEffect, useState } from 'react'
import Cron, { CronError } from 'react-js-cron'
import 'antd/dist/antd.min.css'
import * as cronLocales from '../resources/cronLocales'
import { Reducers, ReducerUser } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    initialValue?: string
    onValueChange: (newValue: string) => void
    disabled?: boolean
}

const CronWidget: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((store) => store.user)
    const [cronError, setCronError] = useState<CronError>()
    const [cronValue, setCronValue] = useState('')

    useEffect(() => {
        if (props.initialValue !== undefined && props.initialValue !== '') {
            setCronValue(props.initialValue)
        } else setCronValue('')
    }, [props.initialValue])

    const customSetValue = useCallback((newValue: string) => {
        setCronValue(newValue)
        props.onValueChange(newValue)
        if (cronError !== undefined) {
            console.error(cronError)
        }
    }, [])

    return (
        <Cron
            disabled={props.disabled}
            locale={cronLocales[user.language.split('-')[0]]}
            value={cronValue}
            setValue={customSetValue}
            onError={setCronError}
            className="my-project-cron"
            clearButtonProps={{
                type: 'default',
                hidden: true,
            }}
        />
    )
}

export default CronWidget
