import React, { useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { usePrevious } from '../../utils/hooks/common'
import { Network } from '../../types/data/system'
import { UserDataTable } from '../../types/data/user'
import { NetworkGrant } from '../../utils/enum/NetworkGrant'
import UserNetworkAssocModal from '../Modals/UserNetworkAssocModal'
import { NetworkUserAssoc } from './NetworkUserAssocTable'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { COLORS } from '../../styles/constants'

interface Props {
    currentUser: UserDataTable
    searchQuery: string
    showModal: boolean
    viewOnly?: boolean
    hideModal: () => void
    setNewNetworks: (network: Network[]) => void
}

const UserNetworkAssocTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedAssoc, setSelectedAssoc] = useState<NetworkUserAssoc | null>(null)
    const [usersAssociation, setUsersAssociation] = useState<NetworkUserAssoc[]>([])
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const previousQuery = usePrevious(props.searchQuery)
    const [showEdit, setShowEdit] = useState(true)
    const [showRemove, setShowRemove] = useState(true)
    const newAssociation: NetworkUserAssoc = {
        idGrant: 1,
        user: props.currentUser,
        network: null,
        tableData: { id: -1 },
    }
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const removeHandler = async (idNetwork: number, idUser: number) => {
        if (props.currentUser.ID === -1) {
            const newArray = usersAssociation.filter((u) => u.network.ID !== idNetwork)
            const networkArray = newArray.map((n) => {
                return {
                    ...n.network,
                    userGrant: n.idGrant,
                }
            })
            setUsersAssociation(newArray)
            props.setNewNetworks(networkArray)
            setShowRemove(false)
            setSelectedAssoc(null)
        } else {
            try {
                const body = {
                    id_network: idNetwork,
                    id_user: idUser,
                }
                await CustomAxios.delete('network-user-association/', { params: body })
                await fetchUsersAssociation()
                setShowRemove(false)
                setSelectedAssoc(null)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const editHandler = async (newAssoc: NetworkUserAssoc) => {
        if (props.currentUser.ID === -1) {
            const newArray = usersAssociation.map((u) => {
                if (u.network.ID === newAssoc.network.ID && u.user.ID === newAssoc.user.ID) {
                    return {
                        ...u,
                        idGrant: newAssoc.idGrant,
                    }
                }
                return u
            })
            const networkArray = newArray.map((m) => {
                return {
                    ...m.network,
                    userGrant: m.idGrant,
                }
            })
            setUsersAssociation(newArray)
            props.setNewNetworks(networkArray)
            setShowEdit(false)
            setSelectedAssoc(null)
        } else {
            try {
                const body = {
                    idNetwork: newAssoc.network.ID,
                    idUser: newAssoc.user.ID,
                    idGrant: newAssoc.idGrant,
                }
                await CustomAxios.put('network-user-association', body)
                await fetchUsersAssociation()
                setShowEdit(false)
                setSelectedAssoc(null)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const createHandler = async (newAssoc: NetworkUserAssoc) => {
        if (props.currentUser.ID === -1) {
            const newArray = [...usersAssociation, newAssoc]
            const networkArray = newArray.map((m) => {
                return {
                    ...m.network,
                    userGrant: newAssoc.idGrant,
                }
            })
            setUsersAssociation(newArray)
            props.setNewNetworks(networkArray)
            props.hideModal()
            setShowEdit(false)
            setSelectedAssoc(null)
        } else {
            try {
                const body = {
                    idNetwork: newAssoc.network.ID,
                    idUser: newAssoc.user.ID,
                    idGrant: newAssoc.idGrant,
                }
                await CustomAxios.post('network-user-association', body)
                await fetchUsersAssociation()
                setShowEdit(false)
                setSelectedAssoc(null)
            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        if (props.showModal) {
            setSelectedAssoc(newAssociation)
        }
    }, [props.showModal])

    useEffect(() => {
        void fetchUsersAssociation()
    }, [props.currentUser])

    const fetchUsersAssociation = async () => {
        if (props.currentUser.ID === -1) {
            const networkAssocArray = props.currentUser.networks.map((m) => {
                return {
                    idGrant: m.userGrant,
                    network: m,
                    tableData: { id: -1 },
                    user: props.currentUser,
                }
            })
            setUsersAssociation(networkAssocArray)
        } else {
            const usersAssoc = await CustomAxios.get(`network-user-association?user=${props.currentUser.ID}`).then(
                (res) => {
                    return res.data.data
                }
            )
            setUsersAssociation(usersAssoc)
        }
    }

    const filtered = useMemo(() => {
        if (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2)) {
            return usersAssociation.filter(
                (u) =>
                    u.user.firstName.toLowerCase().includes(props.searchQuery) ||
                    u.user.lastName.toLowerCase().includes(props.searchQuery)
            )
        }
        return usersAssociation
    }, [usersAssociation, props.searchQuery])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('routes.network'),
                field: 'network.name',
                render: (rowData: NetworkUserAssoc) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() =>
                            navigate(`/${ROUTE_NAMES.NETWORKS}/${rowData?.network.ID}`, { state: { isView: true } })
                        }
                    >
                        {rowData.network.name}
                    </span>
                ),
            },
            {
                title: t('fields.grant'),
                field: 'idGrant',
                render: (rowData: NetworkUserAssoc) => (
                    <span>{t(`userRoles.${NetworkGrant[rowData.idGrant - 1]}`)}</span>
                ),
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        if (props.viewOnly) {
            return []
        }
        const actions = [
            (rowData: NetworkUserAssoc) =>
                (user.idSysGrant === 0 || user.managedNetworks.includes(rowData.network.ID)) && {
                    icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                    onClick: () => {
                        setShowEdit(true)
                        setSelectedAssoc(rowData)
                    },
                    tooltip: t('common.edit'),
                },
            (rowData: NetworkUserAssoc) =>
                (user.idSysGrant === 0 || user.managedNetworks.includes(rowData.network.ID)) && {
                    icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                    onClick: () => {
                        setShowRemove(true)
                        setSelectedAssoc(rowData)
                    },
                    tooltip: t('common.remove'),
                },
        ]
        return actions
    }, [props.viewOnly])

    return (
        <>
            {showRemove && selectedAssoc && selectedAssoc.user ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.userNetworkAssociationDeleteConfirmMessage', {
                        user: `${selectedAssoc.user.firstName} ${selectedAssoc.user.lastName}`,
                    })}
                    onClose={() => {
                        setShowRemove(false)
                        setSelectedAssoc(null)
                    }}
                    onDelete={() => void removeHandler(selectedAssoc.network.ID, selectedAssoc.user.ID)}
                />
            ) : null}
            {(props.showModal || showEdit) && selectedAssoc ? (
                <UserNetworkAssocModal
                    assoc={selectedAssoc}
                    associated={usersAssociation}
                    onClose={() => {
                        setShowEdit(false)
                        props.hideModal()
                        setSelectedAssoc(null)
                    }}
                    onConfirm={(newAssoc) => {
                        if (props.showModal) {
                            void createHandler(newAssoc)
                        } else {
                            void editHandler(newAssoc)
                        }
                    }}
                    isNew={props.showModal}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={filtered}
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default UserNetworkAssocTable
