import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../../../types/reducers'
import { createBrand, updateBrand } from '../../../../store/actions/requests'
import PageContainer from '../../../../Components/StaticComponents/PageContainer'
import Footer from '../../../../Components/StaticComponents/Footer'
import { faArrowLeft, faFileImport, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../../Components/Inputs/TextField'
import Button from '../../../../Components/Inputs/Button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../../utils/routes'
import { Brand } from '../../../../types/data/system'
import LogoDefault from '../../../../resources/media/images/whitelabel/login-header.png'
import UploadButton from '../../../../Components/Inputs/UploadButton'
import CustomAxios from '../../../../customComponents/customAxios'
import ModelsTable from '../../../../Components/Tables/ModelsTable'
import AlertModal from '../../../../Components/Modals/AlertModal'
import CustomAxiosImages from '../../../../customComponents/customAxiosImages'
import DeleteModal from '../../../../Components/Modals/DeleteModal'
import { ToastError, ToastSuccess } from '../../../../utils/toast'
import PageHeaderCustom from '../../../../customComponents/PageHeaderCustom'
import Loader from '../../../../Components/StaticComponents/Loader'
import FileImportModal from '../../../../Components/Modals/FileImportModal'
import { useTranslation } from 'react-i18next'
import { config } from '../../../../config/config'

interface BrandDetailPageState {
    state: {
        isView?: boolean
    }
}

const BrandDetailPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [modelAsQuerySearch, setModelAsQuerySearch] = useState('')
    const [brand, setBrand] = useState<Brand>({
        ID: -1,
        name: '',
        description: '',
        logo: '',
    })
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logoImg = useRef(null)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { state }: BrandDetailPageState = useLocation()
    const [editMode, setEditMode] = useState(!(state && state.isView))
    const [showImportModal, setShowImportModal] = useState(false)
    const [reloadModelsTable, setReloadModelsTable] = useState(0)
    const { t } = useTranslation()

    useEffect(() => {
        if (id !== undefined && id !== 'new-brand') {
            void getBrand(id)
        } else {
            setBrand({
                ID: -1,
                name: '',
                description: '',
                logo: '',
            })
        }
    }, [id, user])

    const getBrand = async (id: string) => {
        try {
            setLoading(true)
            const baseUrl = config.api.baseURL
            const brand: Brand = await CustomAxios.get(`brands/${id}`).then((res) => {
                return res.data
            })
            setBrand({
                ...brand,
                logo: brand.logo !== null && brand.logo !== '' ? `${baseUrl}/${brand.logo}` : '',
            })
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCreateBrand = async () => {
        try {
            const newBrand = await dispatch(createBrand({ ...brand, logo: '' }))
            if (newBrand) {
                if (brand.logo) {
                    await handleUpdateBrand(
                        {
                            ...newBrand,
                            description: newBrand.description ? newBrand.description : '',
                            logo: brand.logo,
                        },
                        t('actionsMessages.successBrandCreate')
                    )
                    navigate(`/${ROUTE_NAMES.BRANDS}/${newBrand.ID}`)
                } else {
                    navigate(`/${ROUTE_NAMES.BRANDS}/${newBrand.ID}`)
                }
            }
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const handleUpdateBrand = async (brand: Brand, toastMessage: string = t('actionsMessages.successBrandUpdate')) => {
        try {
            const baseUrl = config.api.baseURL
            const logo = await uploadImage(brand)
            const brandToSave = {
                ...brand,
                description: brand.description ? brand.description : '',
                logo: logo !== null ? logo.toString().replace(baseUrl, '') : '',
            }
            dispatch(updateBrand(brandToSave))
            ToastSuccess(toastMessage)
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const uploadImage = async (brand: Brand) => {
        if (brand.logo instanceof File) {
            const data = new FormData()
            data.append('table', 'brands')
            data.append('object_id', brand.ID.toString())
            data.append('image', brand.logo)
            const newLogo = await CustomAxiosImages.post('images', data, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((res) => {
                return res.data.url
            })
            return newLogo
        }
        return brand.logo
    }

    const handleSendBrandData = () => {
        if (id !== undefined && id !== 'new-brand') {
            void handleUpdateBrand(brand)
        } else {
            void handleCreateBrand()
        }
    }

    const handleNewUpload = (file: File) => {
        const fr = new FileReader()
        fr.onload = function () {
            logoImg.current.src = fr.result
        }
        fr.readAsDataURL(file)
    }

    const initExitProcedure = () => {
        if (editMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.BRANDS}?page=0`)
        }
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`brands/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.BRANDS}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const brandDataIsCorrect = useMemo(() => {
        return brand.name !== ''
    }, [brand])

    const renderModels = () => {
        return (
            <div style={{ margin: '30px 0' }}>
                {id !== undefined && id !== 'new-brand' ? (
                    <>
                        {showImportModal && (
                            <FileImportModal
                                validExtensions=".trx"
                                validatorEndpointAPI="process-driver-file"
                                endpointAPI="device-models"
                                onClose={(reloadFlag) => {
                                    setShowImportModal(false)
                                    if (reloadFlag) {
                                        setReloadModelsTable(reloadModelsTable + 1)
                                    }
                                }}
                            />
                        )}
                        <div className="headerSection" style={{ borderTop: '1px solid #e2e2e2' }}>
                            <PageHeaderCustom titleKey="fields.models" descriptionKey="pageDescription.models" />
                            <div className="header-right-actions">
                                <div className="header-rapid-search">
                                    <TextField
                                        label={t('searches.searchByName')}
                                        value={modelAsQuerySearch}
                                        onChangeText={(value: string) => setModelAsQuerySearch(value)}
                                    />
                                </div>
                                {user.idSysGrant === 0 && (
                                    <>
                                        <Button
                                            label={t('common.addModel')}
                                            backgroundColor="#EA0B2A"
                                            textColor="#fff"
                                            iconColor="#fff"
                                            margin="0 25px 0 25px"
                                            icon={faPlus}
                                            onPress={() =>
                                                navigate(`/${ROUTE_NAMES.MODELS}/new-model`, {
                                                    state: { idBrand: brand.ID },
                                                })
                                            }
                                        />
                                        <Button
                                            label={t('common.importFromFile')}
                                            backgroundColor="#e10915"
                                            textColor="#fff"
                                            icon={faFileImport}
                                            iconColor="#fff"
                                            onPress={() => setShowImportModal(true)}
                                            margin="0 0 0 25px"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <ModelsTable
                            reload={reloadModelsTable}
                            searchQuery={modelAsQuerySearch}
                            idBrand={parseInt(id, 10)}
                        />
                    </>
                ) : (
                    <p style={{ fontSize: 16, fontStyle: 'italic', margin: '10px 20px' }}>
                        {t('systemMessages.createBrandFirstMessage')}
                    </p>
                )}
            </div>
        )
    }

    return (
        <PageContainer>
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.BRANDS}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={async () => confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('routes.brand')}
                                </span>{' '}
                                {`/ ${brand.ID === -1 ? t('fields.newBrand') : brand.name}`}
                            </h2>
                            <PageHeaderCustom descriptionKey="pageDescription.brandDetail" />
                        </div>
                        <div className="header-right-actions">
                            {!editMode ? (
                                <Button
                                    label={t('common.update')}
                                    backgroundColor="#EA0B2A"
                                    textColor="#fff"
                                    iconColor="#fff"
                                    margin="0"
                                    icon={faPen}
                                    onPress={() => setEditMode(true)}
                                />
                            ) : (
                                <>
                                    <Button
                                        label={t('common.back')}
                                        backgroundColor="#efefef"
                                        textColor="#3f3f3f"
                                        margin="0 0 0 25px"
                                        icon={faArrowLeft}
                                        onPress={() => initExitProcedure()}
                                    />
                                    {id !== undefined && id !== 'new-brand' && (
                                        <Button
                                            label={t('common.delete')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faTrash}
                                            onPress={() => initDeleteProcedure()}
                                        />
                                    )}
                                    <Button
                                        isDisabled={!brandDataIsCorrect}
                                        label={t('common.save')}
                                        backgroundColor="#e10915"
                                        textColor="#ffffff"
                                        margin="0 0 0 25px"
                                        iconColor="#ffffff"
                                        icon={faPen}
                                        onPress={() => handleSendBrandData()}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="sectionContent">
                        <div className="editContainer advanced-search-wrapper">
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.name')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.name')}
                                        value={brand.name}
                                        onChangeText={(value: string) => {
                                            setBrand({
                                                ...brand,
                                                name: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3 style={{ marginBottom: '8px' }}>{t('fields.description')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.description')}
                                        isTextarea
                                        value={brand.description}
                                        onChangeText={(value: string) => {
                                            setBrand({
                                                ...brand,
                                                description: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.logo')}</h3>
                                <div className="input-rows" style={{ position: 'relative' }}>
                                    <div className="imgUploadContainer">
                                        {editMode ? (
                                            <div style={{ position: 'absolute', top: -10, right: -10 }}>
                                                <UploadButton
                                                    acceptedData="image/png, image/jpeg"
                                                    onChange={(f) => {
                                                        setBrand({
                                                            ...brand,
                                                            logo: f,
                                                        })
                                                        handleNewUpload(f)
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <img
                                            src={
                                                brand.logo && typeof brand.logo === 'string' ? brand.logo : LogoDefault
                                            }
                                            className="logoBrand"
                                            ref={logoImg}
                                            alt="brand logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderModels()}
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default BrandDetailPage
