import { Slide, toast } from 'react-toastify'

export const ToastSuccess = (text: string): React.ReactText =>
    toast.success(text, {
        theme: 'dark',
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
    })

export const ToastWarning = (text: string): React.ReactText =>
    toast.warning(text, {
        theme: 'dark',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
    })

export const ToastInfo = (id: number, text: string): React.ReactText =>
    toast.info(text, {
        toastId: id,
        theme: 'dark',
        position: 'bottom-right',
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        transition: Slide
    })  

export const ToastError = (text: string): React.ReactText =>
    toast.error(text, {
        theme: 'dark',
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
    })