import React, { useEffect, useState } from 'react'
import Footer from '../Components/StaticComponents/Footer'
import PageContainer from '../Components/StaticComponents/PageContainer'

import { Divider } from 'antd'
import { Typography } from '@material-ui/core'
import axios from 'axios'

const ChangelogPage: React.FC = () => {
    const [data, setData] = useState([])
    const [keys, setKeys] = useState([])

    const getChangelogData = async () => {
        const changelogData = await (
            await axios.get('https://txcontrol.s3.eu-west-1.amazonaws.com/changelog.json')
        ).data

        setData(changelogData)
    }

    useEffect(() => {
        setKeys(Object.keys(data))
    }, [data])

    useEffect(() => {
        void getChangelogData()
    }, [])

    return (
        <PageContainer>
            <div className="headerSection row-header-section">
                <div>
                    <div>Changelog</div>
                    <Divider />
                    {keys.map((key) => (
                        <div key={key}>
                            <Typography variant="subtitle1">
                                <b>{key}</b>
                            </Typography>
                            {data[key].map((element) => (
                                <div
                                    key={element.ticket}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 12,
                                        marginLeft: 24,
                                    }}
                                >
                                    <Typography variant="button">{element.ticket}</Typography>
                                    <Typography variant="caption" style={{ marginLeft: 12 }}>
                                        {element.description}
                                    </Typography>
                                </div>
                            ))}
                            <Divider />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default ChangelogPage
