import { DashboardMainInfo } from './../../types/data/system'
import { ReduxAction } from '../../types/reducers'

export enum ANALYTICS_ACTIONS {
    SET_AVAILABLE_DASHBOARDS = 'analyticsAction/setAvailableDashboards'
}

export const setAvailableDashboards: ReduxAction<DashboardMainInfo[], ANALYTICS_ACTIONS> = (availableDashboards) => {
    return {
        type: ANALYTICS_ACTIONS.SET_AVAILABLE_DASHBOARDS,
        payload: availableDashboards
    }
}
