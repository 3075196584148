import { UserAccessTokenData, User } from '../../types/data/user'
import { ReduxAction } from '../../types/reducers'

export enum USER_ACTIONS {
    SET_USER_INFO = 'userAction/setUserInfo',
    SET_TOKEN_DATA = 'userAction/setTokenData',
    LOGOUT_USER = 'userAction/logoutUser',
    SET_USER_DEFAULT_PAGE_SIZE = 'userAction/setUserDefaultTablePageSize',
    SET_USER_MANAGED_NETWORKS = 'userAction/setUserManagedNetworks'
}

export const setUserInfo: ReduxAction<User, USER_ACTIONS> = (user) => {
    return {
        type: USER_ACTIONS.SET_USER_INFO,
        payload: user
    }
}

export const setUserDefaultTablePageSize: ReduxAction<number, USER_ACTIONS> = (newValue) => {
    return {
        type: USER_ACTIONS.SET_USER_DEFAULT_PAGE_SIZE,
        payload: newValue
    }
}

export const setManagedNetworks: ReduxAction<number[], USER_ACTIONS> = (managedNetworks) => {
    return {
        type: USER_ACTIONS.SET_USER_MANAGED_NETWORKS,
        payload: managedNetworks
    }
}

export const setTokenData: ReduxAction<UserAccessTokenData, USER_ACTIONS> = (user) => {
    return {
        type: USER_ACTIONS.SET_TOKEN_DATA,
        payload: user
    }
}

export const logoutUser = () => {
    return {
        type: USER_ACTIONS.LOGOUT_USER
    }
}
