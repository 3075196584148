export const COLORS = {
    events: {
        high: '#f44336',
        medium: '#fdc500',
        low: '#007bff'
    },
    deviceStatus: {
        reachable: '#26c000',
        unreachable: '#1174dd'
    },
    palette: {
        grey: '#888',
        darkGrey: '#212121',
        darkBlue: '#1174dd',
        green: '#26c000',
        red: '#e10915'
    }
}

export enum DEVICE_STATUS_CODES {
    reachable = 0,
    ureachable = 1
}

export enum DEVICE_EVENT_LEVEL {
    low = 0,
    medium = 1,
    high = 2
}
