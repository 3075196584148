import React from 'react'
import { useSelector } from 'react-redux'
import { ReducerGraphics, Reducers } from '../../types/reducers'

interface Props {
    children: any
}

const PageContainer: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphics>((state) => state.graphics)

    return (
        <div
            className="page-container"
            style={{
                marginLeft: graphic.collapsedMenu ? 250 : 50,
                width: graphic.collapsedMenu ? 'calc(100% - 250px)' : 'calc(100% - 50px)'
            }}
        >
            {props.children}
        </div>
    )
}

export default PageContainer
