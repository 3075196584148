import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faMinusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import DeleteModal from '../Modals/DeleteModal'
import { DeviceAvailableCommand, DeviceDefaultVars, DeviceModel } from '../../types/data/alarm'
import { usePrevious } from '../../utils/hooks/common'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

export interface ModelTableData {
    ID: number
    name: string
    type: string
    idBrand: number
    availableCommands: DeviceAvailableCommand[]
    defaultVars: DeviceDefaultVars[]
    modelSchema: any
    deviceType: number
}

interface Props {
    idBrand: number
    searchQuery: string
    reload: number
}

const ModelsTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedModel, setSelectedModel] = useState<DeviceModel | null>(null)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const [currentPage, setCurrentPage] = useState(0)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const previousQuery = usePrevious(props.searchQuery)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const removeHandler = async (modelId: number) => {
        try {
            await CustomAxios.delete(`device-models/${modelId}`)
            resetData(currentPage)
            setSelectedModel(null)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (tableRef.current && props.reload !== undefined) {
            resetData(currentPage, '', '')
        }
    }, [props.reload])

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData()
        }
    }, [props.searchQuery])

    const resetData = useCallback((page = 0, headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.name'),
                field: 'model',
                render: (rowData: DeviceModel) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() =>
                            navigate(`/${ROUTE_NAMES.MODELS}/${rowData?.ID}`, {
                                state: { idBrand: rowData.idBrand, isView: true },
                            })
                        }
                    >
                        {rowData.model}
                    </span>
                ),
            },
            {
                title: t('fields.deviceType'),
                field: 'device_type',
                render: (rowData: any) => (
                    <span
                        className="colorRed table-link-value"
                        onClick={() =>
                            navigate(`/${ROUTE_NAMES.DEVICE_TYPES}/${rowData?.deviceType.ID}`, {
                                state: { isView: true },
                            })
                        }
                    >
                        {rowData.deviceType.name}
                    </span>
                ),
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: DeviceModel) => ({
                icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () =>
                    navigate(`/${ROUTE_NAMES.MODELS}/${rowData?.ID}`, { state: { idBrand: rowData.idBrand } }),
                tooltip: t('common.edit'),
            }),
            (rowData: DeviceModel) => ({
                icon: () => <FontAwesomeIcon icon={faClone} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    navigate(`/${ROUTE_NAMES.MODELS}/new-model`, {
                        state: { idBrand: rowData.idBrand, modelDuplicated: rowData },
                    })
                },
                tooltip: t('common.duplicate'),
            }),
            (rowData: DeviceModel) => ({
                icon: () => <FontAwesomeIcon icon={faMinusCircle} color={COLORS.palette.red} size="xs" />,
                onClick: () => setSelectedModel(rowData),
                tooltip: t('common.remove'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {selectedModel ? (
                <DeleteModal
                    deleteMessage={t('systemMessages.typeDeleteConfirmMessage', { type: selectedModel.model })}
                    onClose={() => setSelectedModel(null)}
                    onDelete={() => void removeHandler(selectedModel.ID)}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'device-models'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&query=${props.searchQuery}`
                        }
                        const body = {
                            brand: props.idBrand,
                        }
                        void CustomAxios.get(url, { params: body }).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 3,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                onChangePage={(pageIndex) => {
                    setCurrentPage(pageIndex)
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default ModelsTable
