import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import {
    CircleMarker,
    LayersControl,
    MapContainer,
    Marker,
    TileLayer,
    Tooltip,
    useMap,
    useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-boundary-canvas'
import L, { latLngBounds, LatLngTuple } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import maintenanceImage from '../resources/media/images/icons/toolbox.png'
import { COLORS } from '../styles/constants'
import { MapDeviceItem } from '../types/data/map'
import { ThresholdLevel, ThresholdRule } from '../types/data/alarm'
import { t } from 'i18next'
import { Button, Chip } from '@material-ui/core'
import { faCompress, faExpand, faSatellite, faMapLocation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const unnestMarker = (marker: any) => {
    if (marker instanceof L.CircleMarker || (marker instanceof L.Marker && !(marker as any)._childClusters)) {
        return [marker]
    }
    const expandMarker = (markerToExpand) => {
        const rootMarkers = [...markerToExpand._markers]
        markerToExpand._childClusters.forEach((clusterChild) => {
            rootMarkers.push(...expandMarker(clusterChild))
        })
        return rootMarkers
    }
    return [...marker._markers, ...expandMarker(marker)]
}

interface UpdateViewProps {
    centerWasFixed: boolean
    centerOfMap: any
    callback: (items: any) => void
}

interface Props {
    mapEvents: MapDeviceItem[]
    mapCenter?: [number, number] | null
    region?: 'GBR' | 'CUB'
    deviceMarker?: LatLngTuple
    onDeviceMarkerChangePosition?: (newPosition: LatLngTuple) => void
    saveDevicesListElements?: (elements: any) => void
}

const GeneratedMap: React.FC<Props> = (props) => {
    const [centerOfMap, setCenterOfMap] = useState<LatLngTuple>([0, 0])
    const [zoomOfMap, setZoomOfMap] = useState(3)
    const [centerWasFixed, setCenterWasFixed] = useState(false)
    const [useSatellite, setUseSatellite] = useState(false)

    const mapRef = useRef(null)
    const updateMarkerList = (map: any, callback: (items: any) => void) => {
        const allMarkers = []
        let minZoom = 1000

        function removeDuplicated(arr: any[]) {
            const uniqueIds = []

            const unique = arr.filter((element) => {
                const isDuplicate = uniqueIds.includes(element.options.deviceId)
                if (!isDuplicate) {
                    uniqueIds.push(element.options.deviceId)
                    return true
                }
                return false
            })
            return unique
        }

        map.eachLayer((layer) => {
            if (
                layer instanceof L.Marker ||
                (layer instanceof L.CircleMarker && map.getBounds().contains(layer.getLatLng()))
            ) {
                if ((layer as any)._zoom < minZoom) {
                    minZoom = (layer as any)._zoom
                }
            }
        })

        map.eachLayer((layer) => {
            if (
                (layer instanceof L.Marker || layer instanceof L.CircleMarker) &&
                map.getBounds().contains(layer.getLatLng())
            ) {
                allMarkers.push(...unnestMarker(layer))
            }
        })

        callback(removeDuplicated(allMarkers))
    }

    const UpdateView = (params: UpdateViewProps) => {
        const map = useMap()
        const newCenter = centerWasFixed ? { lat: centerOfMap[0], lng: centerOfMap[1] } : map.getCenter()
        const newZoom = map.getZoom()

        let timeout
        map.on('layeradd', () => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                updateMarkerList(map, params.callback)
            }, 500)
        })
            .on('moveend', () => {
                updateMarkerList(map, params.callback)
            })
            .setView(newCenter, newZoom)

        return null
    }

    useEffect(() => {
        if (centerOfMap[0] === 0 && centerOfMap[1] === 0) {
            if (props.mapEvents.length > 0 && props.mapEvents[0]) {
                setCenterOfMap([props.mapEvents[0].lat, props.mapEvents[0].lon])
                setZoomOfMap(2)
                setCenterWasFixed(true)
            } else if (props.deviceMarker) {
                setCenterOfMap(props.deviceMarker)
                setZoomOfMap(2)
                setCenterWasFixed(true)
            }
        }
    }, [props.deviceMarker, props.mapEvents])

    const updateMarkerPosition = useCallback(
        (eventData: LatLngTuple) => {
            if (props.onDeviceMarkerChangePosition) {
                props.onDeviceMarkerChangePosition(eventData)
            }
        },
        [props.onDeviceMarkerChangePosition]
    )

    const FullscreenControl = () => {
        const map = useMap()

        const toggleFullscreen = () => {
            if (!document.fullscreenElement) {
                void map.getContainer().requestFullscreen()
            } else {
                if (document.exitFullscreen) {
                    void document.exitFullscreen()
                }
            }
        }
        return (
            <>
                <Button
                    style={{
                        position: 'absolute',
                        top: 7,
                        right: 7,
                        zIndex: 999,
                        width: 30,
                        height: 30,
                        backgroundColor: 'white',
                    }}
                    onClick={toggleFullscreen}
                >
                    <FontAwesomeIcon size="2x" icon={document.fullscreenElement ? faCompress : faExpand} />
                </Button>
            </>
        )
    }

    const getMapBounds = useMemo(() => {
        const markerBounds = latLngBounds([])
        if (props.mapEvents.length && props.mapEvents.length > 0) {
            props.mapEvents.forEach((mapEvent) => {
                if (mapEvent.lat !== undefined && mapEvent.lon !== undefined) {
                    try {
                        markerBounds.extend([mapEvent.lat, mapEvent.lon])
                    } catch (error) {
                        console.log('')
                    }
                }
            })
            return markerBounds
        }
        return null
    }, [props.mapEvents])

    // Only called with one marker on the map (device detail page)
    const ChangeView = ({ center, zoom }) => {
        const map = useMap()
        map.setView(center, zoom)
        // eslint-disable-next-line
        const mappp = useMapEvents({
            click(e) {
                updateMarkerPosition([e.latlng.lat, e.latlng.lng])
            },
        })
        return null
    }

    const getDeviceMapEventColor = (mapEvent: any) => {
        if (mapEvent.deviceStatus === 1) return COLORS.deviceStatus.unreachable
        if (mapEvent.maxAlarmLevel === 2) return COLORS.events.high
        if (mapEvent.maxAlarmLevel === 1 || mapEvent.maxAlarmLevel === 0) return COLORS.events.medium

        return COLORS.deviceStatus.reachable
    }

    const getThresholdRule = (rule: ThresholdRule) => {
        switch (rule) {
            case 'higher':
                return t('thresholdRules.higherString')
            case 'not equal':
                return t('thresholdRules.notEqual')
            case 'lower':
            default:
                return t('thresholdRules.lower')
        }
    }

    const getThresholdLevel = (level: ThresholdLevel) => {
        switch (level) {
            case 0:
                return t('alarmLevels.low')
            case 1:
                return t('alarmLevels.medium')
            case 2:
            default:
                return t('alarmLevels.high')
        }
    }

    const getOpenEventString = (deviceEvent: any): string => {
        if (deviceEvent.eventString) return deviceEvent.eventString
        const rule = deviceEvent.operator || 'higher'
        return t('thresholdEventDefaultMessage.fullPhrase', {
            level: getThresholdLevel(deviceEvent.level),
            variableName: deviceEvent.variable,
            measuredValue: deviceEvent.startValue,
            thresholdLevel: getThresholdRule(rule),
            thresholdValue: deviceEvent.referenceValue,
        })
    }

    const getMapMarkers = useMemo(() => {
        if (props.mapEvents && props.mapEvents.length > 0) {
            // eslint-disable-next-line array-callback-return
            return props.mapEvents.map((mapEvent) => {
                if (mapEvent.lat && mapEvent.lon) {
                    const markerPosition: LatLngTuple = [mapEvent.lat, mapEvent.lon]
                    if (mapEvent.maintenanceMode) {
                        const greenIcon = L.icon({
                            iconUrl: maintenanceImage,
                            iconSize: [30, 30],
                        })
                        return (
                            <Marker
                                eventHandlers={{
                                    click: () => {
                                        mapEvent.onSelect()
                                    },
                                }}
                                key={mapEvent.deviceId}
                                {...mapEvent}
                                position={markerPosition}
                                icon={greenIcon}
                            >
                                <Tooltip>{mapEvent.name}</Tooltip>
                            </Marker>
                        )
                    }
                    return (
                        <CircleMarker
                            eventHandlers={{
                                click: () => {
                                    mapEvent.onSelect()
                                },
                            }}
                            color={getDeviceMapEventColor(mapEvent)}
                            key={mapEvent.deviceId}
                            {...mapEvent}
                            center={markerPosition}
                        >
                            <Tooltip>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, maxWidth: 700 }}>
                                    {mapEvent.name}
                                    {mapEvent.openedEvents && mapEvent.openedEvents.length > 0 && (
                                        <Chip
                                            style={{ maxWidth: '100%' }}
                                            variant="default"
                                            component={() => (
                                                <span
                                                    style={{
                                                        backgroundColor: COLORS.palette.grey,
                                                        color: 'white',
                                                        padding: '8px 12px',
                                                        borderRadius: 8,
                                                        fontWeight: 500,
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                >
                                                    {getOpenEventString(mapEvent.openedEvents[0])}
                                                </span>
                                            )}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                        </CircleMarker>
                    )
                }
            })
        }

        return
    }, [props.mapEvents])

    const createClusterCustomIcon = useCallback((cluster) => {
        const clusterChildren = cluster.getAllChildMarkers()

        const containsEventMaxPriority = clusterChildren.find(
            (element) => element.options.maxAlarmLevel === 2 && element.options.maintenanceMode === 0
        )
        const containsEventMidOrLowPriority = clusterChildren.find(
            (element) =>
                (element.options.maxAlarmLevel === 1 || element.options.maxAlarmLevel === 0) &&
                element.options.deviceStatus !== 1
        )
        const containsUnreachableDevice = clusterChildren.find((element) => element.options.deviceStatus === 1)

        const clusterIcon = L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: containsEventMaxPriority
                ? 'red-marker-cluster'
                : containsEventMidOrLowPriority
                ? 'yellow-marker-cluster'
                : containsUnreachableDevice
                ? 'blue-marker-cluster'
                : 'green-marker-cluster',
            iconSize: L.point(33, 33, true),
        })

        return clusterIcon
    }, [])

    const getMarkerClusters = useMemo(() => {
        return (
            <MarkerClusterGroup
                iconCreateFunction={createClusterCustomIcon}
                spiderfyOnMaxZoom={false}
                spiderfyDistanceMultiplier={1}
                showCoverageOnHover={false}
            >
                {getMapMarkers}
            </MarkerClusterGroup>
        )
    }, [getMapMarkers, createClusterCustomIcon])

    const renderUpdateView = useMemo(() => {
        return <UpdateView centerWasFixed centerOfMap callback={props.saveDevicesListElements} />
    }, [centerOfMap])

    if (!props.region) {
        return (
            <MapContainer
                center={centerOfMap}
                zoom={zoomOfMap}
                minZoom={2}
                maxZoom={18}
                bounds={getMapBounds}
                layers={[]}
                ref={mapRef}
            >
                {props.deviceMarker ? <ChangeView center={centerOfMap} zoom={2} /> : renderUpdateView}
                <LayersControl position="topright">
                    <Button
                        style={{
                            position: 'absolute',
                            top: 50,
                            right: 7,
                            zIndex: 999,
                            width: 30,
                            height: 30,
                            backgroundColor: 'white',
                        }}
                        onClick={() => setUseSatellite(!useSatellite)}
                    >
                        <FontAwesomeIcon size="2x" icon={useSatellite ? faMapLocation : faSatellite} />
                    </Button>
                    <TileLayer
                        attribution={`${useSatellite ? 'Google' : 'OpenStreetMap'}`}
                        url={
                            useSatellite
                                ? 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                                : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        }
                    />
                    {props.deviceMarker && <CircleMarker color="#3388ff" center={props.deviceMarker} />}
                    {getMarkerClusters}
                </LayersControl>
                <FullscreenControl />
            </MapContainer>
        )
    }
}

export default GeneratedMap
