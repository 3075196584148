import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    titleKey?: string
    descriptionKey: string
}
const PageHeaderCustom: React.FC<Props> = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation()

    return (
        <div className="header-section-info-text">
            <h2>{t(props.titleKey)}</h2>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {t(props.descriptionKey)
                    .split('\n')
                    .map((e) => (
                        <span key={e} className="section-description">
                            {e}
                        </span>
                    ))}
            </div>
        </div>
    )
}

export default PageHeaderCustom
