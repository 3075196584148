import { BaseConfig } from './config'

const isIPAddressOrDuck =
    window.location.hostname.split('.').length === 4 || window.location.hostname.includes('txcontrol.ubsm.ma') || window.location.hostname.includes('localhost')

const apiPort = 3010
const grafanaPort = 3000

export const productionConfig: BaseConfig = {
    api: {
        baseURL: isIPAddressOrDuck
            ? `http://${window.location.hostname}:${apiPort}`
            : `https://${window.location.hostname}/api`,
    },
    grafana: {
        baseURL: isIPAddressOrDuck
            ? `http://${window.location.hostname}:${grafanaPort}/grafana_frame`
            : `https://${window.location.hostname}/grafana_frame`,
    },
    fileTemplates: {
        devices: `http://${window.location.hostname}:${apiPort}/public/templates/devices_import.csv`,
    },
}
