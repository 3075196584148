import { logoutExpiredUser } from '../store/actions/groupedActions'
import { DEVICE_EVENT_LEVEL, DEVICE_STATUS_CODES } from '../styles/constants'
import { ApiData } from '../types/common'

export const checkApiData = (
    data: ApiData<any> | any,
    dispatch: (action: any) => void,
    defaultReturn: any,
    objectKey?: string
) => {
    if (data.Result === 'Error' && data.Message.includes('TOKEN ERROR')) {
        dispatch(logoutExpiredUser())
        return
    }

    let returnData = null

    if (objectKey !== undefined) {
        returnData = data[objectKey]
    } else {
        returnData = data
    }

    if (returnData === null) {
        return defaultReturn
    }
    return returnData
}

/**
 * Deep copy of object
 * @param data
 */

export const deepCopy = <T = any>(data: T): T => {
    return JSON.parse(JSON.stringify(data))
}

/**
 * Handles the click on and outside a specific HTML element in order to open or close it
 * @param e mouse click
 * @param openStatus flag that indicates if the element is open or not
 * @param triggerElement target HTML element to open or close
 * @param panelElement father element
 * @returns updated open status
 */
export const handleDropdownClick = (
    e: MouseEvent,
    openStatus: boolean,
    triggerElement: React.MutableRefObject<null>,
    panelElement: React.MutableRefObject<null>
): boolean => {
    if (triggerElement.current && (triggerElement.current as HTMLElement).contains(e.target as HTMLElement)) {
        return !openStatus
    }
    if (panelElement.current && (panelElement.current as HTMLElement).contains(e.target as HTMLElement)) {
        return true
    }
    return false
}

export const statusPinStyleForDevice = (device: any) => {
    const item = device.device !== undefined ? device.device : device

    if (item.maintenanceMode) {
        return 'dotAlert unreachableDot'
    }

    if (!item.automaticCheck) {
        return 'dotAlert disabledDot'
    }

    if (item.openedEvents !== undefined && item.openedEvents.length > 0) {
        switch (item.maxAlarmLevel) {
            case DEVICE_EVENT_LEVEL.low:
            case DEVICE_EVENT_LEVEL.medium:
                return 'dotAlert warnDot'
            case DEVICE_EVENT_LEVEL.high:
                return 'dotAlert alarmDot'
            default:
                break
        }
    }

    const deviceStatusVar = item.deviceStatus !== undefined ? 'deviceStatus' : 'currentState'

    switch (item[deviceStatusVar]) {
        case DEVICE_STATUS_CODES.reachable:
            return 'dotAlert enabledDot'
        case DEVICE_STATUS_CODES.ureachable:
            return 'dotAlert unreachableDot'
        default:
            return 'dotAlert unknownDot'
    }
}

export const isValidString = (input) => {
    const regex = /^sk-[A-Za-z0-9]{48}$/
    return regex.test(input)
}

export const statusLabelForDevice = (device: any) => {
    const item = device.device !== undefined ? device.device : device

    if (item.maintenanceMode) {
        return 'fields.onMaintenanceMode'
    }

    if (!item.automaticCheck) {
        return 'deviceStatus.disabled'
    }

    const deviceStatusVar = item.deviceStatus !== undefined ? 'deviceStatus' : 'currentState'

    if (item[deviceStatusVar] === undefined || item[deviceStatusVar] === null) {
        return 'deviceStatus.waitingConnection'
    }
    if (item.openedEvents !== undefined && item.openedEvents.length > 0) {
        return 'deviceStatus.error'
    }
    const currentStatusLabel = [
        {
            value: 'deviceStatus.ok',
            status: DEVICE_STATUS_CODES.reachable,
        },
        {
            value: 'deviceStatus.unreachable',
            status: DEVICE_STATUS_CODES.ureachable,
        },
        {
            value: 'deviceStatus.disabled',
            status: 99,
        },
    ]

    const statusValue = currentStatusLabel.find(
        (stat) =>
            item[deviceStatusVar] !== null &&
            item[deviceStatusVar] !== undefined &&
            stat.status === item[deviceStatusVar]
    )

    return statusValue ? statusValue.value : ''
}
