import React from 'react'
import Button from '../../Components/Inputs/Button'
import Footer from '../../Components/StaticComponents/Footer'
import '../../styles/pages/main.sass'
import '../../styles/pages/amministration.sass'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

const ImportPage: React.FC = () => {
    return (
        <PageContainer>
            <div className="headerSection">Importazione dispositivi</div>
            <div className="body">
                <p>Importa dati da file</p>
                <p>Templates</p>
                <div>
                    <a
                        className="downloadLink"
                        href="https://22hbgtest.s3.eu-west-2.amazonaws.com/TRX/import-templates/import-nations-regions.csv"
                        download
                    >
                        import-nations-regions.csv
                    </a>
                    <br />
                    <a
                        className="downloadLink"
                        href="https://22hbgtest.s3.eu-west-2.amazonaws.com/TRX/import-templates/import-networks.csv"
                        download
                    >
                        import-networks.csv
                    </a>
                    <br />
                    <a
                        className="downloadLink"
                        href="https://22hbgtest.s3.eu-west-2.amazonaws.com/TRX/import-templates/import-devices.csv"
                        download
                    >
                        import-devices.csv
                    </a>
                </div>
                <div className="downloadFileContainer">
                    <span className="customFileUpload">
                        <input type="file" accept=".csv" />
                        Seleziona i file da caricare (.csv)*
                    </span>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 2 }} />
                        <div style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                            <Button
                                label="Invia"
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faLongArrowAltRight}
                                iconColor="#fff"
                                onPress={() => null}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </PageContainer>
    )
}

export default ImportPage
