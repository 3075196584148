import React, { useCallback, useEffect, useState } from 'react'
import '../../styles/components/modals.sass'
import { useTranslation } from 'react-i18next'
import { useSendRealtimeRequests } from '../../utils/hooks/common'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../StaticComponents/Loader'
import { COLORS } from '../../styles/constants'

interface Props {
    idDevice?: number
    ip?: string
    port?: string
    model?: string
    communityRead?: string
    snmpVersion?: string
    closeLabel?: string
    basicCheck?: boolean
    onClose: () => void
}

const RealtimeModal: React.FC<Props> = (props) => {
    const [responseData, setResponseData] = useState<any>()
    const { t } = useTranslation()
    const defaultCloseLabel = t('common.close')
    const emptyJsonData = t('systemMessages.emptyJsonDataMessage')

    const [basicCheckPassed, setBasicCheckPassed] = useState<boolean | undefined>()

    const data = useSendRealtimeRequests(props.idDevice ? 'commands/realtime-snmp' : 'commands/verify-connection', {
        ip: props.ip,
        idDevice: props.idDevice,
        port: props.port,
        model: props.model,
        snmpVersion: props.snmpVersion,
        communityRead: props.communityRead,
    })

    useEffect(() => {
        return () => {
            setResponseData(undefined)
        }
    }, [])

    useEffect(() => {
        if (data !== null) {
            if (props.basicCheck) {
                if (!data.response || data.response.includes('Error: ')) {
                    setBasicCheckPassed(false)
                } else {
                    setBasicCheckPassed(true)
                }
            }

            setResponseData(data)
        } else {
            setResponseData(undefined)
        }
    }, [data])

    const parseJsonData = useCallback(() => {
        if (!responseData || responseData === null) return emptyJsonData
        const jsonCopy = responseData.response.split(';')
        jsonCopy.pop()
        if (jsonCopy.length > 0) {
            return (
                <ul className="json-data-list-element">
                    {jsonCopy.map((element) => {
                        const [name, value] = element.split(':')
                        if (name === '' && value === '') return
                        return (
                            <li key={element}>
                                <p className="json-data-list-element__attribute-name">{`${name}:`}</p>
                                {value}
                            </li>
                        )
                    })}
                </ul>
            )
        }
        return emptyJsonData
    }, [responseData])

    return (
        <div className="alert-modal-wrapper">
            <div className="dark-cover" />
            <div className="modal json-detail-modal print-modal medium-modal">
                <h3 className="print-only">{`${t('fields.device')}: ${props.idDevice}`}</h3>
                <h4 className="print-only">{`${new Date().toLocaleString()}`}</h4>
                <div id="realtimeDataContainer" className="modal--message" style={{ marginBottom: 12 }}>
                    {props.basicCheck ? (
                        <>
                            {basicCheckPassed !== undefined ? (
                                basicCheckPassed ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <FontAwesomeIcon
                                            style={{ height: 50 }}
                                            icon={faCheckCircle}
                                            color={COLORS.palette.green}
                                        />
                                        {t('systemMessages.deviceReachable')}{' '}
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <FontAwesomeIcon
                                            style={{ height: 50 }}
                                            icon={faExclamationCircle}
                                            color={COLORS.palette.red}
                                        />
                                        {t('systemMessages.deviceNotReachable')}{' '}
                                    </div>
                                )
                            ) : (
                                <>
                                    <Loader height={'100px'} />
                                    <p>{t('systemMessages.connectingToDatabase')}</p>
                                </>
                            )}
                        </>
                    ) : !responseData || responseData === undefined ? (
                        <>
                            <Loader height={'100px'} />
                            <p>{t('systemMessages.connectingToDatabase')}</p>
                        </>
                    ) : (
                        <div id="realtimeDataContainer" className="modal--message" style={{ marginBottom: 12 }}>
                            {parseJsonData()}
                        </div>
                    )}
                </div>
                <div className="modal--actions-container">
                    {!props.basicCheck && (
                        <button disabled={!responseData} className="confirm-action" onClick={() => window.print()}>
                            {t('common.print')}
                        </button>
                    )}

                    <button className="close-action" onClick={() => props.onClose()}>
                        {props.closeLabel ? props.closeLabel : defaultCloseLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RealtimeModal
