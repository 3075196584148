import { faPlus, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../Components/Inputs/Button'
import CheckboxInput from '../../Components/Inputs/CheckboxInput'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import SchedulerTable from '../../Components/Tables/SchedulerTable'
import '../../styles/pages/main.sass'
import { AvailableCommand, NetworkGroup } from '../../types/data/system'
import { User } from '../../types/data/user'
import { ROUTE_NAMES } from '../../utils/routes'
import CustomAxios from '../../customComponents/customAxios'
import { Device } from '../../types/data/alarm'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { deepCopy } from '../../utils/functions'
import Datepicker from '../../Components/Datepicker'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'

const PlannedCommandPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [schedulerGroup, setSchedulerGroup] = useState<SelectOptionValue | null>({
        label: t('fields.group'),
        value: '',
    })
    const [schedulerDevice, setSchedulerDevice] = useState<SelectOptionValue | null>({
        label: t('fields.device'),
        value: '',
    })
    const [schedulerCreator, setSchedulerCreator] = useState<SelectOptionValue | null>({
        label: t('fields.user'),
        value: '',
    })
    const [schedulerFilterCommand, setSchedulerFilterCommand] = useState<SelectOptionValue | null>({
        label: t('fields.command'),
        value: '',
    })
    const [availableCommands, setAvailableCommands] = useState<AvailableCommand[]>([])
    const [availableNetworkGroups, setAvailableNetworkGroups] = useState<NetworkGroup[]>([])
    const [availableUsers, setAvailableUsers] = useState<User[]>([])
    const [availableDevices, setAvailableDevices] = useState<Device[]>([])
    const [toggleReset, setToggleReset] = useState(undefined)
    const [searchThroughEnabledSchedulers, setSearchThroughEnabledSchedulers] = useState(false)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)

    const [filterStartDate, setFilterStartDate] = useState('')
    const [filterEndDate, setFilterEndDate] = useState('')

    const getFilterParameters = () => {
        const filterParameters: any = {
            device: schedulerDevice.value === '' ? '' : parseInt(schedulerDevice.value, 10),
            command: schedulerFilterCommand.value === '' ? '' : parseInt(schedulerFilterCommand.value, 10),
            from: filterStartDate,
            to: filterEndDate,
            enabled: searchThroughEnabledSchedulers ? 1 : undefined,
            user: schedulerCreator.value === '' ? -1 : parseInt(schedulerCreator.value, 10),
            group: schedulerGroup.value === '' ? -1 : parseInt(schedulerGroup.value, 10),
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const filterParametersChanged = useMemo(() => {
        return (
            schedulerFilterCommand.value !== '' ||
            filterEndDate !== '' ||
            filterStartDate !== '' ||
            searchThroughEnabledSchedulers ||
            schedulerCreator.value !== '' ||
            schedulerGroup.value !== '' ||
            schedulerDevice.value !== ''
        )
    }, [
        schedulerFilterCommand,
        filterStartDate,
        filterEndDate,
        searchThroughEnabledSchedulers,
        schedulerCreator,
        schedulerGroup,
        schedulerDevice,
    ])

    const getFilteredDevices = () => {
        setFilterParameters(getFilterParameters())
    }

    const getAllNetworkGroups = async () => {
        try {
            const nGroups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            setAvailableNetworkGroups(nGroups)
        } catch (error) {
            console.error(error)
        }
    }

    const getAllUsers = async () => {
        try {
            const users: User[] = await CustomAxios.get('users').then((response) => response.data.data)
            setAvailableUsers(users)
        } catch (error) {
            console.error(error)
        }
    }

    const getAllDevices = async () => {
        try {
            const devices: Device[] = await CustomAxios.get('devices').then((response) => response.data.data)
            setAvailableDevices(devices)
        } catch (error) {
            console.error(error)
        }
    }

    const getAvailableCommands = async (filterParam: string) => {
        try {
            const commands: AvailableCommand[] = await CustomAxios.get(`available-commands${filterParam}`).then(
                (response) => response.data.data
            )
            if (commands.length > 0) {
                setAvailableCommands(commands)
            } else {
                setAvailableCommands([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const resetFilterParameters = () => {
        setFilterStartDate('')
        setFilterEndDate('')
        setSchedulerGroup({ label: t('fields.group'), value: '' })
        setSchedulerCreator({ label: t('fields.user'), value: '' })
        setSchedulerFilterCommand({ label: t('fields.command'), value: '' })
        setSchedulerDevice({ label: t('fields.device'), value: '' })
        setSearchThroughEnabledSchedulers(false)
        setAvailableCommands([])
        setFilterParameters(null)
        setToggleReset(!toggleReset)
    }

    const handleGroupSelection = (value: SelectOptionValue) => {
        setSchedulerGroup(value)
        setSchedulerDevice({ label: t('fields.device'), value: '' })
        setSchedulerFilterCommand({ label: t('fields.command'), value: '' })
    }

    const handleDeviceSelection = (value: SelectOptionValue) => {
        setSchedulerDevice(value)
        setSchedulerGroup({ label: t('fields.group'), value: '' })
        setSchedulerFilterCommand({ label: t('fields.command'), value: '' })
    }

    useEffect(() => {
        if (schedulerGroup.value !== '') {
            const searchParameter = `?group=${schedulerGroup.value}`
            void getAvailableCommands(searchParameter)
        }
    }, [schedulerGroup])

    useEffect(() => {
        if (schedulerDevice.value !== '') {
            const searchParameter = `?device=${schedulerDevice.value}`
            void getAvailableCommands(searchParameter)
        }
    }, [schedulerDevice])

    const availableCommandSelect = useMemo(() => {
        let commandToDisplay = deepCopy(availableCommands)
        commandToDisplay = commandToDisplay.filter(
            (command: AvailableCommand) => command.requiredGrant === user.idSysGrant
        )
        return (
            <SelectInput
                disabled={schedulerDevice.value === '' && schedulerGroup.value === ''}
                value={schedulerFilterCommand}
                onValueChange={(value) => setSchedulerFilterCommand(value)}
                options={commandToDisplay.map((command: AvailableCommand) => {
                    return { label: command.name, value: command.command }
                })}
                width={'100%'}
            />
        )
    }, [availableCommands, user, schedulerFilterCommand, schedulerDevice, schedulerGroup])

    return (
        <PageContainer>
            <div className="headerSection">
                <div className="header-section-info-text">
                    <PageHeaderCustom
                        titleKey="fields.schedulerManagement"
                        descriptionKey="pageDescription.schedulers"
                    />
                </div>
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterScheduler')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin="0 25px 0 0"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                    <Button
                        label={t('common.update')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        margin={user.idSysGrant === 0 ? '0 25px 0 0' : '0'}
                        icon={faRedo}
                        onPress={() => setToggleReset(!toggleReset)}
                    />
                    {user.idSysGrant === 0 && (
                        <Button
                            label={t('common.addScheduler')}
                            backgroundColor="#e10915"
                            textColor="#fff"
                            icon={faPlus}
                            iconColor="#fff"
                            onPress={() => navigate(`/${ROUTE_NAMES.PLANNED_COMMANDS}/new-scheduler`)}
                            margin="0"
                        />
                    )}
                </div>
            </div>
            <div>
                {filterPanelIsVisible && (
                    <div className="advanced-search-wrapper padding-search-wrapper">
                        <div className="search-wrapper-body">
                            <div className="filter-column">
                                <h3>{t('fields.schedulerInfo')}</h3>
                                <div className="input-rows">
                                    {availableNetworkGroups.length > 0 && availableDevices.length > 0 && (
                                        <>
                                            <SelectInput
                                                value={schedulerDevice}
                                                onValueChange={(value) => handleDeviceSelection(value)}
                                                options={availableDevices.map((device: Device) => {
                                                    return { label: device.name, value: device.ID.toString() }
                                                })}
                                                width={'100%'}
                                            />
                                            <SelectInput
                                                value={schedulerGroup}
                                                onValueChange={(value) => handleGroupSelection(value)}
                                                options={availableNetworkGroups.map((networkGroups: NetworkGroup) => {
                                                    return {
                                                        label: networkGroups.name,
                                                        value: networkGroups.id.toString(),
                                                    }
                                                })}
                                                width={'100%'}
                                            />
                                        </>
                                    )}
                                </div>
                                {availableCommandSelect}
                            </div>
                            <div className="filter-column column-without-title">
                                {availableUsers.length > 0 && (
                                    <SelectInput
                                        value={schedulerCreator}
                                        onValueChange={(value) => setSchedulerCreator(value)}
                                        options={availableUsers.map((user: User) => {
                                            return {
                                                label: `${user.firstName} ${user.lastName}`,
                                                value: user.ID.toString(),
                                            }
                                        })}
                                        width={'100%'}
                                    />
                                )}
                                <CheckboxInput
                                    label={t('fields.showOnlyEnabledSchedulers')}
                                    value={searchThroughEnabledSchedulers}
                                    onChangeValue={(value: boolean) => setSearchThroughEnabledSchedulers(value)}
                                />
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.searchInterval')}</h3>
                                <div className="calendar-selection-container">
                                    <Datepicker
                                        reset={toggleReset}
                                        initialValue={t('fields.selectStartOfInterval')}
                                        onDateChange={(newDate) => setFilterStartDate(newDate)}
                                    />
                                    <Datepicker
                                        reset={toggleReset}
                                        initialValue={t('fields.selectEndOfInterval')}
                                        onDateChange={(newDate) => setFilterEndDate(newDate)}
                                        minSelectableDate={filterStartDate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter-wrapper-action-section">
                            <button
                                disabled={filterParameters === null}
                                className="filter-secondary-button"
                                onClick={() => resetFilterParameters()}
                            >
                                {t('common.reset')}
                            </button>
                            <button
                                disabled={!filterParametersChanged}
                                className="standard-confirm-button"
                                onClick={() => getFilteredDevices()}
                            >
                                {t('common.search')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ padding: 20 }}>
                <SchedulerTable
                    reloads={toggleReset}
                    filterParams={filterParameters}
                    onFinish={async () => {
                        await getAllNetworkGroups()
                        await getAllUsers()
                        await getAllDevices()
                    }}
                />
            </div>
            <Footer />
        </PageContainer>
    )
}

export default PlannedCommandPage
