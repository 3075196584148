import { faPlus, faRedo, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../Components/Inputs/Button'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import TextInput from '../../Components/Inputs/TextInput'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import RemoteControlsTable from '../../Components/Tables/RemoteControlsTable'
import CustomAxios from '../../customComponents/customAxios'
import '../../styles/pages/main.sass'
import { Brand, Model, Network, NetworkGroup } from '../../types/data/system'
import { Reducers, ReducerUser } from '../../types/reducers'
import { ROUTE_NAMES } from '../../utils/routes'
import PageHeaderCustom from '../../customComponents/PageHeaderCustom'
import FileImportModal from '../../Components/Modals/FileImportModal'
import { config } from '../../config/config'

const RemoteControlListPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()

    const [selectedModel, setSelectedModel] = useState<SelectOptionValue | null>({
        label: t('fields.model'),
        value: '',
    })
    const [selectedBrand, setSelectedBrand] = useState<SelectOptionValue | null>({
        label: t('routes.brand'),
        value: '',
    })
    const [searchDeviceName, setSearchDeviceName] = useState<string>('')
    const [brands, setBrands] = useState<Brand[]>([])
    const [models, setModels] = useState<Model[]>([])
    const [networks, setNetworks] = useState<Network[]>([])
    const [networkGroups, setNetworkGroups] = useState<NetworkGroup[]>([])
    const [toggleReset, setToggleReset] = useState(undefined)
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [filterParameters, setFilterParameters] = useState<any | null>(null)
    const navigate = useNavigate()
    const [showImportModal, setShowImportModal] = useState(false)
    const [deviceLimitReached, setDeviceLimitReached] = useState(false)

    const fetchModelAndBrandData = async () => {
        try {
            const availableModels: Model[] = await CustomAxios.get('device-models?sort_by=model&order_by=ASC').then(
                (response) => response.data.data
            )
            const availableBrands: Brand[] = await CustomAxios.get('brands?sort_by=name&order_by=ASC').then(
                (response) => response.data.data
            )
            const availableNetworks: Network[] = await CustomAxios.get('networks').then(
                (response) => response.data.data
            )
            const availableNetworkGroups: NetworkGroup[] = await CustomAxios.get('network-groups').then(
                (response) => response.data.data
            )
            setBrands(availableBrands)
            setModels(availableModels)
            setNetworks(availableNetworks)
            setNetworkGroups(availableNetworkGroups)
        } catch (error) {
            console.error(error)
        }
    }

    const getFilterParameters = () => {
        const filterParameters: any = {
            name: searchDeviceName,
            brand: selectedBrand.value === '' ? '' : parseInt(selectedBrand.value, 10),
            device_model: selectedModel.value === '' ? '' : parseInt(selectedModel.value, 10),
        }
        Object.keys(filterParameters).forEach((key) => {
            const attributeValue = filterParameters[key]
            if (
                attributeValue === '' ||
                attributeValue === null ||
                attributeValue === -1 ||
                attributeValue === undefined
            ) {
                delete filterParameters[key]
            }
        })
        if (Object.keys(filterParameters).length > 0) {
            return { ...filterParameters }
        }
        return null
    }

    const filterParametersChanged = useMemo(() => {
        return searchDeviceName !== '' || selectedModel.value !== '' || selectedBrand.value !== ''
    }, [searchDeviceName, selectedModel, selectedBrand])

    const getFilteredSchedulers = () => {
        setFilterParameters(getFilterParameters())
    }

    const resetFilterParameters = () => {
        setSearchDeviceName('')
        setSelectedBrand({ label: t('routes.brand'), value: '' })
        setSelectedModel({ label: t('fields.model'), value: '' })
        setFilterParameters(null)
        setToggleReset(!toggleReset)
    }

    const availableModels = useMemo(() => {
        setSelectedModel({ label: t('fields.model'), value: '' })
        if (models.length > 0 && selectedBrand.value !== '') {
            return models.filter((model: Model) => model.idBrand.toString() === selectedBrand.value)
        }
        return []
    }, [selectedBrand])

    const getRequiredDataMessage = useMemo(() => {
        if (models.length > 0 && brands.length > 0 && networks.length > 0 && networkGroups.length > 0) {
            return ''
        }
        return t('systemMessages.requiredDataForMassiveImport')
    }, [models, brands, networks, networkGroups])

    return (
        <>
            {showImportModal && (
                <FileImportModal
                    requiredDataMessage={getRequiredDataMessage}
                    validExtensions=".csv"
                    validatorEndpointAPI="process-devices-file"
                    endpointAPI="devices"
                    exampleFileLink={config.fileTemplates.devices}
                    onClose={(reloadFlag) => {
                        setShowImportModal(false)
                        if (reloadFlag) {
                            setToggleReset(!toggleReset)
                        }
                    }}
                />
            )}
            <PageContainer>
                <div className="headerSection">
                    <div className="header-section-info-text">
                        <PageHeaderCustom titleKey="fields.devicesList" descriptionKey="pageDescription.devicesList" />
                    </div>
                    <div className="header-right-actions">
                        <Button
                            label={t('fields.filterDevices')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin="0 25px 0 0"
                            borderColor={filterPanelIsVisible ? '#212121' : undefined}
                            icon={faSlidersH}
                            onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                        />
                        <Button
                            label={t('common.update')}
                            backgroundColor="#efefef"
                            textColor="#3f3f3f"
                            margin={user.idSysGrant === 0 || user.managedNetworks.length > 0 ? '0 25px 0 0' : '0'}
                            icon={faRedo}
                            onPress={() => setToggleReset(!toggleReset)}
                        />
                        {(user.idSysGrant === 0 || user.managedNetworks.length > 0) && !deviceLimitReached && (
                            <Button
                                label={t('common.addDevice')}
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faPlus}
                                iconColor="#fff"
                                onPress={() => navigate(`/${ROUTE_NAMES.DEVICE}/new-device`)}
                                margin="0 25px 0 0"
                            />
                        )}
                        {/* {user.idSysGrant === 0 && !deviceLimitReached && (
                            <Button
                                label={t('common.importFromFile')}
                                backgroundColor="#e10915"
                                textColor="#fff"
                                icon={faFileImport}
                                iconColor="#fff"
                                onPress={() => setShowImportModal(true)}
                                margin="0"
                            />
                        )} */}
                    </div>
                </div>
                <div className="sectionContent">
                    {filterPanelIsVisible && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="search-wrapper-body search-wrapper-single-row-container">
                                <TextInput
                                    label=""
                                    placeholder={t('fields.name')}
                                    value={searchDeviceName}
                                    onChangeText={(value: string) => setSearchDeviceName(value)}
                                    width={'100%'}
                                />
                                <SelectInput
                                    label=""
                                    value={selectedBrand}
                                    onValueChange={(value) => setSelectedBrand(value)}
                                    options={brands.map((brand: Brand) => {
                                        return { label: brand.name, value: brand.ID.toString() }
                                    })}
                                    width={'100%'}
                                />
                                <SelectInput
                                    label=""
                                    disabled={selectedBrand.value === ''}
                                    value={selectedModel}
                                    onValueChange={(value) => setSelectedModel(value)}
                                    options={availableModels.map((model: Model) => {
                                        return { label: model.model, value: model.ID.toString() }
                                    })}
                                    width={'100%'}
                                />
                            </div>
                            <div className="filter-wrapper-action-section">
                                <button
                                    disabled={filterParameters === null}
                                    className="filter-secondary-button"
                                    onClick={() => resetFilterParameters()}
                                >
                                    {t('common.reset')}
                                </button>
                                <button
                                    disabled={!filterParametersChanged}
                                    className="standard-confirm-button"
                                    onClick={() => getFilteredSchedulers()}
                                >
                                    {t('common.search')}
                                </button>
                            </div>
                        </div>
                    )}
                    <RemoteControlsTable
                        filterParams={filterParameters}
                        reloads={toggleReset}
                        onFinish={async () => {
                            await fetchModelAndBrandData()
                        }}
                        onDeviceLimitReached={(reached) => {
                            setDeviceLimitReached(reached)
                        }}
                    />
                    <Footer />
                </div>
            </PageContainer>
        </>
    )
}

export default RemoteControlListPage
