import { EmptyReduxAction } from './../../types/reducers'
import { ReduxAction } from '../../types/reducers'

export enum GRAPHICS_ACTIONS {
    RESET_ALL = 'graphicsAction/resetAll',
    SET_FETCH_USER_LOADER_STATUS = 'graphicsAction/setFetchUserLoaderStatus',
    SET_FETCH_TODAY_ALARMS_LOADER_STATUS = 'graphicsAction/setFetchTodayAlarmsLoaderStatus',
    SET_FETCH_MAP_ALARMS_LOADER_STATUS = 'graphicsAction/setFetchMapAlarmsLoaderStatus',
    SET_FETCH_ALARM_TYPES_LOADER_STATUS = 'graphicsAction/setFetchAlarmTypesLoaderStatus',
    SET_FETCH_REGIONS_LOADER_STATUS = 'graphicsAction/setFetchRegionsLoaderStatus',
    SET_FETCH_NATIONS_LOADER_STATUS = 'graphicsAction/setFetchNationsLoaderStatus',
    SET_FETCH_USERS_LOADER_STATUS = 'graphicsAction/setFetchUsersLoaderStatus',
    SET_FETCH_BRANDS_LOADER_STATUS = 'graphicsAction/setFetchBLoaderStatus',
    SET_COLLAPSED_MENU = 'graphicAction/setCollapsedMenu',
    SET_SHOW_QUICK_HELP = 'graphicAction/setShowQuickHelp'
}

export const resetAll: EmptyReduxAction<GRAPHICS_ACTIONS> = () => {
    return {
        type: GRAPHICS_ACTIONS.RESET_ALL
    }
}
export const setFetchUserLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_USER_LOADER_STATUS,
        payload: status
    }
}

export const setFetchTodayAlarmsLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_TODAY_ALARMS_LOADER_STATUS,
        payload: status
    }
}
export const setFetchMapAlarmsLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_MAP_ALARMS_LOADER_STATUS,
        payload: status
    }
}

export const setFetchAlarmTypesLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_ALARM_TYPES_LOADER_STATUS,
        payload: status
    }
}

export const setFetchRegionsLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_REGIONS_LOADER_STATUS,
        payload: status
    }
}

export const setFetchNationsLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_NATIONS_LOADER_STATUS,
        payload: status
    }
}

export const setFetchUsersLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_USERS_LOADER_STATUS,
        payload: status
    }
}

export const setFetchBrandsLoaderStatus: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_FETCH_BRANDS_LOADER_STATUS,
        payload: status
    }
}

export const setCollapsedMenu: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_COLLAPSED_MENU,
        payload: status
    }
}

export const setShowQuickHelp: ReduxAction<boolean, GRAPHICS_ACTIONS> = (status) => {
    return {
        type: GRAPHICS_ACTIONS.SET_SHOW_QUICK_HELP,
        payload: status
    }
}
