/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ROUTE_NAMES {
    LOGIN = '/',
    VALIDATE_OTP = '/validate-otp',
    TODAY_ALARM = '/',
    GENIUS = 'genius',
    MAP = 'map',
    SEND_COMMANDS = 'send-commands',
    PLANNED_COMMANDS = 'planned-commands',
    LIST = 'list',
    POWERS_HISTORY = 'powers-history',
    EVENTS = 'events',
    EVENTS_HISTORY = 'events-history',
    USERS = 'users',
    NEW_USER = 'new-user',
    REMOTE_CONTROLS = 'remote-controls',
    IMPORT = 'import',
    ANALYTICS = 'analytics',
    ANALYTICS_DASHBOARD = 'analytics-dashboard',
    BRANDS = 'brands',
    MODELS = 'models',
    DEVICE_TYPES = 'device-types',
    NETWORKS = 'networks',
    GROUPS = 'groups',
    DEVICE = 'devices',
    PROFILE = 'profile',
    CONFIGURATION = 'configuration',
    INVALID_SUBSCRIPTION = 'subscription-invalid',
    CHANGELOG = 'changelog',
}
