import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { usePrevious } from '../../utils/hooks/common'
import { UserActivity, UserDataTable } from '../../types/data/user'
import moment from 'moment'
import ChangesDisplayModal from '../Modals/ChangesDisplayModal'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { COLORS } from '../../styles/constants'

interface Props {
    searchQuery: string
    from: string
    to: string
    currentUser: UserDataTable
}

const UserLastOperationsTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [selectedActivity, setSelectedActivity] = useState<UserActivity | null>(null)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const tableRef = React.useRef<any>()
    const previousQuery = usePrevious(props.searchQuery)
    const previousFrom = usePrevious(props.from)
    const previousTo = usePrevious(props.to)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (tableRef.current && (props.searchQuery.length > 2 || (previousQuery && previousQuery.length > 2))) {
            resetData()
        }
        if (props.from !== previousFrom) {
            resetData()
        }
        if (props.to !== previousTo) {
            resetData()
        }
    }, [props.searchQuery, props.to, props.from])

    const resetData = useCallback((headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page: 0, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.activity'),
                field: 'activity',
            },
            {
                title: t('fields.date'),
                field: 'timestamp',
                render: (rowData: UserActivity) => (
                    <span>{moment(rowData.timestamp).format('DD/MM/YYYY HH:mm:ss')}</span>
                ),
            },
            {
                title: t('fields.entity'),
                field: 'table',
            },
        ]
        return columns
    }, [])

    const actions = useMemo(() => {
        const actions = [
            (rowData: UserActivity) => ({
                icon: () => <FontAwesomeIcon icon={faInfoCircle} color={COLORS.palette.darkGrey} size="xs" />,
                onClick: () => {
                    setSelectedActivity(rowData)
                },
                tooltip: t('common.info'),
            }),
        ]
        return actions
    }, [])

    return (
        <>
            {selectedActivity ? (
                <ChangesDisplayModal
                    jsonObjectPre={JSON.stringify(selectedActivity.preActivity)}
                    jsonObjectPost={JSON.stringify(selectedActivity.postActivity)}
                    onClose={() => setSelectedActivity(null)}
                />
            ) : null}
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'user-activities'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += `&user=${props.currentUser.ID}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.searchQuery && props.searchQuery.length > 2) {
                            url += `&activity=${props.searchQuery.toLocaleUpperCase()}`
                        }
                        if (props.from && props.from) {
                            url += `&from=${props.from}`
                        }
                        if (props.to) {
                            url += `&to=${props.to}`
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 99,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default UserLastOperationsTable
