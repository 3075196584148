import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Network } from '../../types/data/system'
import { UserDataTable } from '../../types/data/user'
import { Reducers, ReducerUser } from '../../types/reducers'
import Button from '../Inputs/Button'
import TextField from '../Inputs/TextField'
import UserNetworkAssocTable from '../Tables/UserNetworkAssocTable'

interface Props {
    currentUser: UserDataTable
    setNewNetworks: (network: Network[]) => void
}

const UserNetworkAssocTab: React.FC<Props> = (props) => {
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [networkName, setNetworkName] = useState('')
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: '0 25px 0 0' }}>
                <div className="header-right-actions">
                    <div className="header-rapid-search base-font-size">
                        <TextField
                            label={t('searches.searchByName')}
                            value={networkName}
                            onChangeText={(value: string) => setNetworkName(value)}
                        />
                    </div>
                    {(loggedUser.idSysGrant === 0 ||
                        loggedUser.managedNetworks.find((networkId) =>
                            props.currentUser.networks.find((network) => network.ID === networkId)
                        )) && (
                        <Button
                            label={t('common.addNetworkAssociation')}
                            backgroundColor="#EA0B2A"
                            textColor="#fff"
                            iconColor="#fff"
                            margin="0 0 0 25px"
                            icon={faPlus}
                            onPress={() => {
                                setShowModal(true)
                            }}
                        />
                    )}
                </div>
            </div>
            <UserNetworkAssocTable
                currentUser={props.currentUser}
                searchQuery={networkName}
                showModal={showModal}
                hideModal={() => setShowModal(false)}
                setNewNetworks={(networks) => {
                    props.setNewNetworks(networks)
                }}
            />
        </div>
    )
}

export default UserNetworkAssocTab
