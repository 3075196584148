import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo, useState } from 'react'
import { UserDataTable } from '../../types/data/user'
import Button from '../Inputs/Button'
import UserLastOperationsTable from '../Tables/UserLastOperationsTable'
import 'react-datepicker/dist/react-datepicker.css'
import Datepicker from '../Datepicker'
import { ActivityTypes } from '../../utils/enum/UserActivityTypes'
import SelectInput, { SelectOptionValue } from '../Inputs/SelectInput'
import { useTranslation } from 'react-i18next'

interface Props {
    currentUser: UserDataTable
}

const activities = [
    {
        id: 0,
        label: ActivityTypes.EDIT,
        value: ActivityTypes.EDIT,
    },
    {
        id: 1,
        label: ActivityTypes.CREATE,
        value: ActivityTypes.CREATE,
    },
    {
        id: 2,
        label: ActivityTypes.DELETE,
        value: ActivityTypes.DELETE,
    },
]

const UserLastOperationsTab: React.FC<Props> = (props) => {
    const [filterPanelIsVisible, setFilterPanelIsVisible] = useState(false)
    const [toggleReset, setToggleReset] = useState(undefined)
    const { t } = useTranslation()
    const [filterSelectedActivity, setFilterSelectedActivity] = useState<SelectOptionValue>({
        label: t('fields.activity'),
        value: '',
    })
    const [startSearchInterval, setStartSearchInterval] = useState('')
    const [endSearchInterval, setEndSearchInterval] = useState('')

    const resetFilterParameters = () => {
        setFilterSelectedActivity({ label: t('fields.activity'), value: '' })
        setStartSearchInterval('')
        setEndSearchInterval('')
        setToggleReset(!toggleReset)
    }

    const filtersWereChanged = useMemo(() => {
        return startSearchInterval !== '' || endSearchInterval !== '' || filterSelectedActivity.value !== ''
    }, [startSearchInterval, endSearchInterval, filterSelectedActivity])

    return (
        <div style={{ margin: '30px 0' }}>
            <div style={{ margin: '0 25px 25px 0' }}>
                <div className="header-right-actions">
                    <Button
                        label={t('fields.filterActions')}
                        backgroundColor="#efefef"
                        textColor="#3f3f3f"
                        borderColor={filterPanelIsVisible ? '#212121' : undefined}
                        margin="0"
                        icon={faSlidersH}
                        onPress={() => setFilterPanelIsVisible(!filterPanelIsVisible)}
                    />
                </div>
            </div>

            {filterPanelIsVisible && (
                <div className="filterSearchContainer advanced-search-wrapper">
                    <div className="search-wrapper-body">
                        <div className="filter-column">
                            <h3>{t('fields.actionName')}</h3>
                            <div className="input-rows">
                                <SelectInput
                                    value={filterSelectedActivity}
                                    onValueChange={(value) => setFilterSelectedActivity(value)}
                                    options={activities.map((activity: any) => {
                                        return { label: activity.label, value: activity.value }
                                    })}
                                    width={'100%'}
                                />
                            </div>
                        </div>
                        <div className="filter-column">
                            <h3>{t('calendar.dateInterval')}</h3>
                            <div className="calendar-selection-container">
                                <Datepicker
                                    reset={toggleReset}
                                    initialValue={t('fields.selectStartOfInterval')}
                                    onDateChange={(newDate) => setStartSearchInterval(newDate)}
                                />
                                <Datepicker
                                    reset={toggleReset}
                                    initialValue={t('fields.selectEndOfInterval')}
                                    onDateChange={(newDate) => setEndSearchInterval(newDate)}
                                    minSelectableDate={startSearchInterval}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="filter-wrapper-action-section">
                        <button
                            disabled={!filtersWereChanged}
                            className="filter-secondary-button"
                            onClick={() => resetFilterParameters()}
                        >
                            {t('common.reset')}
                        </button>
                    </div>
                </div>
            )}
            <UserLastOperationsTable
                currentUser={props.currentUser}
                searchQuery={filterSelectedActivity.value}
                from={startSearchInterval !== '' ? startSearchInterval : undefined}
                to={endSearchInterval !== '' ? endSearchInterval : undefined}
            />
        </div>
    )
}

export default UserLastOperationsTab
