import { ANALYTICS_ACTIONS } from '../actions/analytics'
import { ReducerAnalytics, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerAnalytics = {
    availableDashboards: [],
}

const reducerState: (state: ReducerAnalytics, action: ReduxActionData<any, ANALYTICS_ACTIONS>) => ReducerAnalytics = (
    state,
    action: ReduxActionData<any, ANALYTICS_ACTIONS>
) => {
    state = state || defaultValue
    switch (action.type) {
        case ANALYTICS_ACTIONS.SET_AVAILABLE_DASHBOARDS:
            return {
                ...state,
                availableDashboards: action.payload,
            }
        default:
            return state
    }
}

export default reducerState
