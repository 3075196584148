import React, { useCallback, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import CustomAxios from '../../customComponents/customAxios'
import { ROUTE_NAMES } from '../../utils/routes'
import { Device, DeviceModel } from '../../types/data/alarm'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'

interface Props {
    currentModel: DeviceModel
    onClose: () => void
    onDeviceSelection: (devices: number[]) => void
}

const ModelDevicesTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const tableRef = React.useRef<any>()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const resetData = useCallback((headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page: 0, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.name'),
                field: 'name',
                render: (rowData: Device) => (
                    <a
                        className="colorRed table-link-value"
                        // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
                        href={`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`}
                    >
                        {rowData.name}
                    </a>
                ),
            },
        ]
        return columns
    }, [])

    return (
        <div style={{ overflowY: 'auto', height: '400px' }}>
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        const newOffset = pageSize + pageSize * (query.page - 1)
                        let url = 'devices'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += `&device_model=${props.currentModel.ID}`
                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        void CustomAxios.get(url).then((response) => {
                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                title=""
                options={{
                    selection: true,
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    actionsColumnIndex: 99,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                onSelectionChange={(data) => {
                    props.onDeviceSelection(data.map((item) => item.ID))
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </div>
    )
}

export default ModelDevicesTable
