import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../../types/reducers'
import { createGroup, updateGroup } from '../../../store/actions/requests'
import PageContainer from '../../../Components/StaticComponents/PageContainer'
import Footer from '../../../Components/StaticComponents/Footer'
import { faArrowLeft, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextField from '../../../Components/Inputs/TextField'
import Button from '../../../Components/Inputs/Button'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/routes'
import { Network, NetworkGroup } from '../../../types/data/system'
import LogoDefault from '../../../resources/media/images/whitelabel/login-header.png'
import UploadButton from '../../../Components/Inputs/UploadButton'
import CustomAxios from '../../../customComponents/customAxios'
import AlertModal from '../../../Components/Modals/AlertModal'
import SelectInput from '../../../Components/Inputs/SelectInput'
import GroupDevicesTab from '../../../Components/Tabs/GroupDevicesTab'
import GroupSchedulersTab from '../../../Components/Tabs/GroupSchedulersTab'
import CustomAxiosImages from '../../../customComponents/customAxiosImages'
import DeleteModal from '../../../Components/Modals/DeleteModal'
import Tabs, { TabElement } from '../../../Components/Tabs'
import { ToastError, ToastSuccess } from '../../../utils/toast'
import PageHeaderCustom from '../../../customComponents/PageHeaderCustom'
import Loader from '../../../Components/StaticComponents/Loader'
import { useTranslation } from 'react-i18next'
import { config } from '../../../config/config'

interface GroupDetailPageState {
    state: {
        isView?: boolean
        group?: NetworkGroup
    }
}

const GroupDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const groupSubmenuVoices: TabElement[] = [
        {
            label: t('fields.connectedDevices'),
            value: 'connectedDevices',
        },
        {
            label: t('fields.connectedSchedulers'),
            value: 'connectedSchedulers',
        },
    ]
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [group, setGroup] = useState<NetworkGroup>({
        id: -1,
        name: '',
        logo: '',
        description: '',
        idNetwork: null,
    })
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logoImg = useRef(null)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [networksList, setNetworksList] = useState<Network[]>([])
    const [selectedTab, setSelectedTab] = useState<string>(groupSubmenuVoices[0].value)
    const { state }: GroupDetailPageState = useLocation()
    const [editMode, setEditMode] = useState(!(state && state.isView))

    const getNetworks = async () => {
        const networks: Network[] = await CustomAxios.get('networks').then((res) => {
            return res.data.data
        })
        setNetworksList(networks)
    }

    useEffect(() => {
        if (user.idSysGrant !== 0 && user.managedNetworks.length === 0) {
            navigate(`${ROUTE_NAMES.TODAY_ALARM}`)
        }
        if (id !== undefined && id !== 'new-group') {
            void getGroup(id)
        } else {
            setGroup({
                id: -1,
                name: '',
                logo: '',
                description: '',
                idNetwork: state && state.group ? state.group.idNetwork : null,
            })
        }
        void getNetworks()
    }, [id, user])

    const getGroup = async (id: string) => {
        try {
            setLoading(true)
            const baseUrl = config.api.baseURL
            const group: NetworkGroup = await CustomAxios.get(`network-groups/${id}`).then((res) => {
                return res.data
            })
            setGroup({
                ...group,
                logo: group.logo !== null && group.logo !== '' ? `${baseUrl}/${group.logo}` : '',
            })
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCreateGroup = async () => {
        try {
            const newGroup = await dispatch(createGroup({ ...group, logo: '' }))
            if (newGroup) {
                if (group.logo) {
                    await handleUpdateGroup(
                        {
                            ...newGroup,
                            logo: group.logo,
                        },
                        t('actionsMessages.successNetworkGroupCreate')
                    )
                    navigate(`/${ROUTE_NAMES.GROUPS}/${newGroup.id}`)
                } else {
                    navigate(`/${ROUTE_NAMES.GROUPS}/${newGroup.id}`)
                }
            }
            setEditMode(false)
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const handleUpdateGroup = async (
        group: NetworkGroup,
        toastMessage: string = t('actionsMessages.successNetworkGroupUpdate')
    ) => {
        try {
            const baseUrl = config.api.baseURL
            const logo = await uploadImageLogo(group)
            const groupToSave = {
                ...group,
                logo: logo !== null ? logo.toString().replace(baseUrl, '') : '',
            }
            dispatch(updateGroup(groupToSave))
            setEditMode(false)
            ToastSuccess(toastMessage)
        } catch (error) {
            console.error(error)
            ToastError(t('actionsMessages.genericError'))
        }
    }

    const uploadImageLogo = async (group: NetworkGroup) => {
        if (group.logo instanceof File) {
            const data = new FormData()
            data.append('table', 'networks')
            data.append('object_id', `${group.id.toString()}`)
            data.append('image', group.logo)
            const newLogo = await CustomAxiosImages.post('images', data, {
                headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${user.token}` },
            }).then((res) => {
                return res.data.url
            })
            return newLogo
        }
        return group.logo
    }

    const handleNewLogoUpload = (file: File) => {
        const fr = new FileReader()
        fr.onload = function () {
            logoImg.current.src = fr.result
        }
        fr.readAsDataURL(file)
    }

    const renderCurrentTab = () => {
        switch (selectedTab) {
            case 'connectedDevices':
                return <GroupDevicesTab currentGroup={group} viewOnly={!editMode} />
            case 'connectedSchedulers':
                return <GroupSchedulersTab currentGroup={group} viewOnly={!editMode} />
            default:
                return <GroupDevicesTab currentGroup={group} viewOnly={!editMode} />
        }
    }

    const handleSendGroupData = () => {
        if (id !== undefined && id !== 'new-group') {
            void handleUpdateGroup(group)
        } else {
            void handleCreateGroup()
        }
    }

    const initExitProcedure = () => {
        if (editMode) {
            setShowAlertModal(true)
        } else {
            navigate(`/${ROUTE_NAMES.GROUPS}?page=0`)
        }
    }

    const initDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const cancelDeleteProcedure = () => {
        setShowDeleteModal(false)
    }

    const confirmDeleteElement = async () => {
        try {
            await CustomAxios.delete(`network-groups/${id}`)
            setEditMode(false)
            navigate(`/${ROUTE_NAMES.GROUPS}?page=0`)
        } catch (error) {
            console.error(error)
        }
    }

    const groupDataIsCorrect = useMemo(() => {
        return group.name !== '' && group.idNetwork
    }, [group])

    const renderTables = () => {
        if (group.id !== -1) {
            return (
                <div style={{ marginTop: '25px' }}>
                    <Tabs tabs={groupSubmenuVoices} onSelectedTabChange={(newTab) => setSelectedTab(newTab)} />
                    {renderCurrentTab()}
                </div>
            )
        }
        return (
            <p style={{ fontSize: 16, fontStyle: 'italic', margin: '10px 20px' }}>
                {t('systemMessages.createGroupFirstMessage')}
            </p>
        )
    }

    return (
        <PageContainer>
            {showAlertModal ? (
                <AlertModal
                    alertMessage={t('systemMessages.loseProgressOnExitMessage')}
                    onConfirm={() => navigate(`/${ROUTE_NAMES.GROUPS}?page=0`)}
                    onClose={() => setShowAlertModal(false)}
                />
            ) : null}
            {showDeleteModal && (
                <DeleteModal
                    deleteMessage={t('systemMessages.removeElementFromList')}
                    onClose={() => cancelDeleteProcedure()}
                    onDelete={async () => confirmDeleteElement()}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="headerSection addNewSection">
                        <div className="header-section-info-text">
                            <h2>
                                <span className="breadcrumb-index" onClick={() => initExitProcedure()}>
                                    {t('routes.groups')}
                                </span>{' '}
                                {`/ ${group.id === -1 ? t('fields.newGroup') : group.name}`}
                            </h2>
                            <PageHeaderCustom titleKey="" descriptionKey="pageDescription.groupDetail" />
                        </div>
                        {(user.idSysGrant === 0 ||
                            user.managedNetworks.find((networkId) => networkId === group.idNetwork)) && (
                            <div className="header-right-actions">
                                {!editMode ? (
                                    <Button
                                        label={t('common.update')}
                                        backgroundColor="#EA0B2A"
                                        textColor="#fff"
                                        iconColor="#fff"
                                        margin="0"
                                        icon={faPen}
                                        onPress={() => setEditMode(true)}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            label={t('common.back')}
                                            backgroundColor="#efefef"
                                            textColor="#3f3f3f"
                                            margin="0 0 0 25px"
                                            icon={faArrowLeft}
                                            onPress={() => initExitProcedure()}
                                        />
                                        {id !== undefined && id !== 'new-group' && (
                                            <Button
                                                label={t('common.delete')}
                                                backgroundColor="#efefef"
                                                textColor="#3f3f3f"
                                                margin="0 0 0 25px"
                                                icon={faTrash}
                                                onPress={() => initDeleteProcedure()}
                                            />
                                        )}
                                        <Button
                                            isDisabled={!groupDataIsCorrect}
                                            label={t('common.save')}
                                            backgroundColor="#e10915"
                                            textColor="#ffffff"
                                            margin="0 0 0 25px"
                                            iconColor="#ffffff"
                                            icon={faPen}
                                            onPress={() => handleSendGroupData()}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="sectionContent">
                        <div className="editContainer advanced-search-wrapper">
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.name')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.name')}
                                        value={group.name}
                                        onChangeText={(value: string) => {
                                            setGroup({
                                                ...group,
                                                name: value,
                                            })
                                        }}
                                    />
                                </div>
                                <h3>{t('fields.description')}</h3>
                                <div className="input-rows">
                                    <TextField
                                        onlyVisible={!editMode}
                                        label={t('fields.description')}
                                        isTextarea
                                        value={group.description}
                                        onChangeText={(value: string) => {
                                            setGroup({
                                                ...group,
                                                description: value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-column">
                                <h3>{t('fields.logo')}</h3>
                                <div className="input-rows" style={{ position: 'relative' }}>
                                    <div className="imgUploadContainer">
                                        {editMode ? (
                                            <div style={{ position: 'absolute', top: -10, right: -10 }}>
                                                <UploadButton
                                                    acceptedData="image/png, image/jpeg"
                                                    onChange={(f) => {
                                                        setGroup({
                                                            ...group,
                                                            logo: f,
                                                        })
                                                        handleNewLogoUpload(f)
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <img
                                            alt=""
                                            src={
                                                group.logo && typeof group.logo === 'string' ? group.logo : LogoDefault
                                            }
                                            className="logoBrand"
                                            ref={logoImg}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter-column">
                                <div className="variable-field-label-container">
                                    <h3>{t('fields.associatedNetwork')}</h3>
                                    <label className="required-asterisk">*</label>
                                </div>
                                <div className="input-rows" style={{ position: 'relative' }}>
                                    <SelectInput
                                        disabled={!editMode}
                                        options={networksList.map((n) => {
                                            return {
                                                value: n.ID.toString(),
                                                label: n.name,
                                            }
                                        })}
                                        placeholder={t('fields.associatedNetworkSelection')}
                                        value={
                                            group.idNetwork && networksList.length > 0
                                                ? {
                                                      value: networksList
                                                          .find((d) => d.ID === group.idNetwork)
                                                          .ID.toString(),
                                                      label: networksList.find((d) => d.ID === group.idNetwork).name,
                                                  }
                                                : null
                                        }
                                        onValueChange={(value) => {
                                            if (value) {
                                                setGroup({
                                                    ...group,
                                                    idNetwork: parseInt(value.value, 10),
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {renderTables()}
                    </div>
                </>
            )}
            <Footer />
        </PageContainer>
    )
}

export default GroupDetailPage
