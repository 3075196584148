import { faBorderNone } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useState } from 'react'
import Button from '../../Components/Inputs/Button'
import TextInput from '../../Components/Inputs/TextInput'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import '../../styles/pages/main.sass'
import '../../styles/pages/amministration.sass'
import { useSelector } from 'react-redux'
import SelectInput, { SelectOptionValue } from '../../Components/Inputs/SelectInput'
import { Reducers, ReducerData } from '../../types/reducers'

const RemoteControlsPage: React.FC = () => {
    const [remoteControl, setRemoteControl] = useState('')
    const [televisionBroadcaster, setTelevisionBroadcaster] = useState<SelectOptionValue | null>({
        label: 'Tutti',
        value: 'all',
    })
    const [region, setRegion] = useState<SelectOptionValue | null>({ label: 'Tutti', value: 'all' })

    const data = useSelector<Reducers, ReducerData>((state) => state.data)

    return (
        <PageContainer>
            <div className="headerSection">Elenco telecontrolli</div>
            <div className="userColumns" style={{ padding: 20 }}>
                <div className="userColumnsMainContainer" style={{ borderRightWidth: 0, marginBottom: 25, flex: 2 }}>
                    <Button
                        label="Aggiungi Telecontrollo"
                        textColor="#fff"
                        backgroundColor="#e10915"
                        icon={faBorderNone}
                        iconColor="#fff"
                        margin="0 0 25px 0"
                        onPress={() => {
                            return
                        }}
                    />
                    <SelectInput
                        label="Emittente"
                        value={televisionBroadcaster}
                        onValueChange={(value) => setTelevisionBroadcaster(value)}
                        options={[
                            { label: 'Tutti', value: 'all' },
                            { label: 'Brodcast Electronics', value: 'be' },
                        ]}
                        width={'100%'}
                    />
                    <SelectInput
                        label="Regioni"
                        value={region}
                        onValueChange={(value) => setRegion(value)}
                        options={[
                            { label: 'Tutti', value: 'all' },
                            ...data.regions.map((region) => {
                                return {
                                    label: region.Descrizione,
                                    value: region.ID,
                                }
                            }),
                        ]}
                    />
                    <TextInput
                        label="Telecontrollo"
                        value={remoteControl}
                        onChangeText={(value: string) => setRemoteControl(value)}
                    />
                </div>
                <div style={{ flex: 1 }} className="userColumnsSecondContainer" />
            </div>
            <Footer />
        </PageContainer>
    )
}

export default RemoteControlsPage
