import { useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import CustomAxios from '../../customComponents/customAxios'
import axios from 'axios'

/**
 * Hook that manage the interval
 * @param callback Callback function to execute in the loop
 * @param delay Delay time
 */
export const useInterval = (callback: any, delay: number): void => {
    const savedCallback = useRef(null)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = (): void => {
            savedCallback.current()
        }

        if (delay !== null) {
            const id = setInterval(tick, delay)
            return (): void => clearInterval(id)
        }

        return (): void => null
    }, [delay])
}

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const useTablePagination = (param: string): number => {
    const [queryParamsFromURL] = useSearchParams()
    const [parsedParam, setParsedParam] = useState(0)

    useEffect(() => {
        const pageParam = queryParamsFromURL.get(param)
        setParsedParam(pageParam !== null && pageParam !== '' ? parseInt(pageParam, 10) : parsedParam)
    }, [])

    return parsedParam
}

export const useSendRealtimeRequests = (url: string, body: any = null) => {
    const [data, setData] = useState<any>(null)
    const [isStopped, setIsStopped] = useState<boolean>(false)
    const [errorOccurred, setErrorOccured] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        return () => {
            setIsStopped(true)
        }
    }, [])

    useInterval(
        async () => {
            if (isStopped || loading) return
            await fetchData()
        },
        errorOccurred ? 2000 : 500
    )

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await CustomAxios.post(url, body).then((response) => response.data)

            if (!fetchedData) {
                console.error('Empty data', fetchedData)
                throw 'Empty data'
            }
            setData(fetchedData)
            setLoading(false)
        } catch (e) {
            if (axios.isCancel(e)) return
            console.error('Fetch error', e)
            setErrorOccured(true)
        }
    }

    return data
}
