import { developmentConfig } from './development'
import { productionConfig } from './production'

export interface BaseConfig {
    api: {
        baseURL: string
    }
    grafana: {
        baseURL: string
    }
    fileTemplates: {
        devices: string
    }
}
export interface Config extends BaseConfig {
    stripe: {
        paymentGateway: string
    }
}

const configExtension = {
    stripe: {
        paymentGateway: 'https://manager.txcontrol.it/payment-gateway',
    },
}

const getConfig = (): Config => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return { ...developmentConfig, ...configExtension }
        case 'production':
            return { ...productionConfig, ...configExtension }
        default:
            return { ...developmentConfig, ...configExtension }
    }
}

export const config = getConfig()
