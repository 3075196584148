import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../../Components/StaticComponents/Footer'
import PageContainer from '../../Components/StaticComponents/PageContainer'
import GrafanaAdapter from './GrafanaAdapter'
import CustomAxios from '../../customComponents/customAxios'
import Loader from '../../Components/StaticComponents/Loader'
import Button from '../../Components/Inputs/Button'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

interface Props {
    idDashboard: number
    dashboardName: string
}

const DashboardPage: React.FC<Props> = (props) => {
    const [dashboardData, setDashboardData] = useState<any>()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [firstRun, setFirstRun] = useState(true)
    const [restartingServices, setRestartingServices] = useState(false)

    const getDashboards = useCallback(async () => {
        try {
            if (firstRun) return

            setLoading(true)
            const data: any = await CustomAxios.get(`dashboards?id=${props.idDashboard}`).then(
                (response) => response.data.data[0]
            )
            setDashboardData(data)
            setTimeout(() => {
                setLoading(false)
            }, 3000)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }, [props.idDashboard, firstRun])

    const restartServices = useCallback(async () => {
        setRestartingServices(true)
        await CustomAxios.get('system/restart-services').then((response) => {
            setRestartingServices(false)
            return response.data
        })
    }, [])

    useEffect(() => {
        if (props.idDashboard && firstRun) {
            setFirstRun(false)
        }
    }, [])

    useEffect(() => {
        if (firstRun) return
        setDashboardData(undefined)
        void getDashboards()
    }, [props.idDashboard, firstRun])

    return (
        <PageContainer>
            <div
                className="headerSection row-header-section"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                {t('fields.dashboards')} - {props.dashboardName}
                {props.dashboardName.toLowerCase().includes('tx control service') && (
                    <Button
                        label={restartingServices ? 'Restart in progress...' : t('common.restartServices')}
                        backgroundColor="#e10915"
                        textColor="#ffffff"
                        margin="0 0 0 25px"
                        iconColor="#ffffff"
                        icon={faRefresh}
                        onPress={async () => !restartingServices && restartServices()}
                    />
                )}
            </div>

            <>
                {loading && <Loader />}
                {dashboardData ? <GrafanaAdapter hide={loading} dashboardData={dashboardData} /> : null}
            </>
            <Footer />
        </PageContainer>
    )
}

export default DashboardPage
